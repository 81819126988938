import styles from "./style.module.css";
import {  Box, Modal } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import { parseDateFormat } from "src/helpers/document";

const MessageModal = ({ open, handleClose, message }) => {
  return (
    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={styles.modalContainer}>
      <Box className={`${styles.innerContainer} standardSecondModal`}>
        <div className="h-auto max-h-[70vh] overflow-y-auto customScrollBar">
          <div className="flex justify-between items-center bg-gray-300 rounded-t-xl py-3 px-6 dark:bg-AFPDarkBgDarkest dark:text-BrightGray">
            <h2 className="text-xl md:text-2xl font-semibold">{message?.regarding + " " +parseDateFormat(message?.date)} </h2>
            <AiOutlineClose size={24} className="text-SmokeyGray cursor-pointer dark:text-BrightGray" onClick={handleClose} />
          </div>
          <div className="px-6 flex flex-col gap-2 mt-6 mb-8">
            <div className="dark:text-BrightGray" dangerouslySetInnerHTML={{ __html: message?.originalMessage }} />
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default MessageModal;
