import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { businessPremisesContacts } from 'src/store/actions/business';
import { getServiceAreaTechnicalLeaderStarted } from 'src/store/actions/dform';
import { useParams } from 'react-router-dom';
import ServiceAreaShowExpand from 'src/components/atoms/card/Card/ServiceAreaShowExpand';
import PrefiledArea from './PrefiledArea';
import AreaTableReadable from './AreaTableReadable';

export default function SupplyAreaTableReadable({ data, step_id, stepsCMS }) {
  const [currentHelperText, setCurrentHelperText] = useState("");

  const { id } = useParams();
  let newData = data;
  const dform = useSelector((state) => state.dform);
  const [rows] = useState(newData);
  const [technicalLeader, setTechnicalLeader] = useState(null);
  const application = useSelector((state) => state.application);
  const currentStep = useSelector((state) => state.currentStep);
  let [miniAids, setMiniAids] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(businessPremisesContacts());
  }, []);

  useEffect(() => {
    if (application.data && Object.keys(application.data).length && application['step_id'] === '03_VERSORGUNGSBEREICHE') {
      const result = application.data["03_VERSORGUNGSBEREICHE"];
      const inputTypeValue = result[0].inputTypeValue;
      const currentInputTypeValue = currentStep.data[0].inputTypeValue;
      if (inputTypeValue && currentInputTypeValue) {
        const matched = inputTypeValue.filter((obj1) => currentInputTypeValue.some((obj2) => obj1.selectedArea === obj2.selectedArea));
        if (matched && matched.length) {
          matched.length > 0 &&
          matched.map((item) => {
              dispatch(
                getServiceAreaTechnicalLeaderStarted({
                  service_id: item.selectedArea,
                  service_order_type: id,
                  contact_ids: application?.data["02_FIRMA"]?.find((group) => group.id === "02_FIRMA_TECHNICAL_MANAGER")?.inputTypeValue,
                  location_id: application?.data["02_FIRMA"][1]?.inputTypeValue,
                })
              );
            });
        }
      }
    }
  }, [application.data]);

  useEffect(() => {
    if (rows?.inputTypeValue?.technicalLeaders?.length > 0) {
      let techLeader = rows?.inputTypeValue[0]?.technicalLeaders?.map((leader) => {
        return { title: leader.name, value: leader.id };
      });
      setTechnicalLeader(techLeader);
    }
  }, [rows]);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".evidence-section");
      let currentSection = { key: "", position: 100 };

      sections?.forEach((section) => {
        const rect = section?.getBoundingClientRect();
        if (rect?.top <= 400 && rect?.bottom >= 10) {
          const key = section.getAttribute("data-key");
          currentSection = { key, position: rect?.top };
        }
      });

      setCurrentHelperText(currentSection.key);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (dform?.page_aids && dform?.page_aids["dform"] && dform?.page_aids["dform"].includes(step_id)) {
      if (!miniAids) {
        setMiniAids(true);
      }
    } else {
      if (miniAids) {
        setMiniAids(false);
      }
    }
  }, [dform?.page_aids]);

  return (
    <>
      <PrefiledArea data={data} />
      <h1 className="!mb-5 text-2xl font-tahoma-bold text-black w-full md:max-w-[350px] lg:max-w-full dark:text-BrightGray">{stepsCMS[data.id]?.title}</h1>
      <div className="">
        <div className="flex flex-col justify-start w-full">
          <p className="textStyle max-w-[80%] mb-8 pt-1">{stepsCMS[data?.id]?.description}</p>
          <div className="flex gap-x-4">
          <div className="flex flex-col lg:w-[68%] xl:w-[70%] 2xl:w-full w-full">
            <TableContainer component={Paper} sx={{ boxShadow: "none", backgroundColor: "transparent", overflowX: "auto", width: "95%" }}>
              <Table size="small" aria-label="">
                <TableHead>
                  <TableRow>
                    <TableCell className="!text-SmokeyGray !text-left">
                      <span className="text-black font-tahoma-bold dark:text-BrightGray">Versorgungsbereich</span>
                    </TableCell>
                    <TableCell className="!text-SmokeyGray !text-left whitespace-nowrap font-tahoma-bold">
                      <span className="text-black font-tahoma-bold dark:text-BrightGray">Enthaltene Hilfsmittel</span>
                    </TableCell>
                    <TableCell className="!text-SmokeyGray" align="left">
                      <span className="text-black font-tahoma-bold dark:text-BrightGray">Besondere Kriterien</span>
                    </TableCell>
                    <TableCell className="!text-SmokeyGray" align="left">
                      <span className="text-black font-tahoma-bold dark:text-BrightGray">Fachliche Leiter</span>
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {newData?.inputTypeValue?.length > 0 &&
                    newData.inputTypeValue?.map((row, index) => {
                      return (
                        <AreaTableReadable
                          key={row.id}
                          css="evidence-section"
                          data={row}
                          technicalLeader={technicalLeader}
                          index={index}
                          step_id={step_id}
                          datakey={row?.selectedArea}
                          datadisable={row?.description}
                        />
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            </div>
            {newData?.inputTypeValue?.length > 0 &&
              newData.inputTypeValue?.map((row, index) => {
                const showCard = currentHelperText === row.selectedArea || (currentHelperText === "" && index === 0);
                return <>{showCard && <ServiceAreaShowExpand showCard={showCard} page={"dform"} aid={step_id} miniAids={miniAids} setMiniAids={setMiniAids} />}</>;
              })}
          </div>
        </div>
      </div>
    </>
  );
}
