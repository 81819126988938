export const styleModal = {
  p: 2,
  left: "50%",
  top: "50%",
  width: "90%",
  maxWidth: "840px",
  // bgcolor: "#FBFBFB",
  borderRadius: "12px",
  position: "relative",
  border: "1px solid #CBCBCB",
  transform: "translate(-50%, -50%)",
};
