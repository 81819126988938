/* eslint-disable */
import { useState } from "react";
import ResetForm from "src/components/organisms/login/ResetForm";
import Login from "src/components/template/Login";
import { LoginPolicy } from "src/mockData/login";
const resetPreField = {
  title: "Kundenportal",
  subTitle: "Passwort zurücksetzen",
  LoginPolicy: LoginPolicy
}
const ResetPassword = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem("hs_theme");
    return savedTheme === "dark" || (!savedTheme && window.matchMedia("(prefers-color-scheme: dark)").matches);
  });
  return (
    <div className="loginwrap">
      <div className="loginwcontainer">
        <Login data={resetPreField}>
          <ResetForm isDarkMode={isDarkMode}/>
        </Login>
      </div>
    </div>
  );
};

export default ResetPassword;