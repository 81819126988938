import React from "react";
import { motion } from "framer-motion";

const DecreasefillCard = ({ children, css, miniAids }) => {
  const cardVariants = {
    initial: {
      opacity: 0,
      y: -15,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.3,
        duration: 0.2,
        ease: "linear",
      },
    },
  };
  return (
    <motion.div
      initial="initial"
      animate="animate"
      variants={cardVariants}
      className={`hidden md:flex flex-col h-auto pb-6 rounded-xl py-3  md:py- px-3 md:px-4 2xl:px-8 bg-white ${css} ${
        miniAids ? "" : "border border-AFPDisabled shadow-md hover:shadow-xl  duration-300 w-[100%]"
      }`}
    >
      {children}
    </motion.div>
  );
};

DecreasefillCard.defaultProps = {
  css: "",
  children: null,
  miniAids: false,
};

export default DecreasefillCard;
