import { put } from "redux-saga/effects";
import { authUserFailure, changePasswordUserSuccess, forgotUserSuccess, getCMSbyKeyFailure, getCMSbyKeySuccess, loginUserSuccess, logOutSuccess, registerUserSuccess, reseetUserSuccess, verifyRegisterFailure, verifyRegisterSuccess } from "src/store/actions/users";
import toast from "react-hot-toast";
import CookieStorage from "src/services/storage/cookiesStorage";
import { LOGINAPI, FORGOTPASSWORDAPI, REGISTERAPI, RESETPASSWORDAPI, CHANGEPASSWORDAPI, REGISTERAPIMODIFY, LOGOUTAPI, VERIFYREGISTERAPI, GET_CMS_BY_ID } from "src/store/sagas/requests/users";

export function* userLoginHandler(action) {
  const cookie = new CookieStorage();
  try {
    let { remember, ...rest } = action.payload;
    const response = yield LOGINAPI(rest);
    if (response.status) {
      cookie.setLogin(response?.data?.tokens?.access?.token, response?.data?.user?.id, remember);
      toast.success(response?.message);
      yield put(loginUserSuccess(response.data));
    } else {
      yield put(
        authUserFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(authUserFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* userRegisterHandler(action) {
  try {
    const response = yield REGISTERAPI(action.payload);
    if (response.status) {
      toast.success(response?.message);
      yield put(
        registerUserSuccess({
          ...response.data,
          email: response?.data?.user?.email,
          password: action?.payload?.password,
        })
      );
    } else {
      yield put(
        authUserFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(authUserFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* userForgotHandler(action) {
  try {
    const response = yield FORGOTPASSWORDAPI(action.payload);
    if (response.status) {
      toast.success(response?.message);
      yield put(forgotUserSuccess(response?.data));
    } else {
      yield put(
        authUserFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(authUserFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* userResetHandler(action) {
  try {
    const response = yield RESETPASSWORDAPI(action.payload);
    if (response.status) {
      toast.success(response?.message);
      yield put(reseetUserSuccess(response?.data));
    } else {
      yield put(
        authUserFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(authUserFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* userChangePasswordHandler(action) {
  const cookie = new CookieStorage();
  try {
    const response = yield CHANGEPASSWORDAPI(action.payload);
    if (response.status) {
      toast.success(response?.message);
      cookie.logout();
      window.location.reload();
      yield put(changePasswordUserSuccess(response?.data));
    } else {
      yield put(
        authUserFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(authUserFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* userRegisterHandlerInvitation(action) {
  try {
    const response = yield REGISTERAPIMODIFY(action.payload);
    if (response.status) {
      toast.success(response?.message);
      yield put(
        registerUserSuccess({
          ...response.data,
          email: response?.data?.email,
          password: action?.payload?.password,
        })
      );
    } else {
      yield put(
        authUserFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(authUserFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* userLogOutHandler(action) {
  const cookie = new CookieStorage();
  try {
    const payload = {
      user_id: action?.payload?.user_id,
      email: action?.payload?.email,
      access_token: action?.payload?.token
    }
    const response = yield LOGOUTAPI(payload);
    if (response.status) {
      cookie.logout();
      window.location.reload();
      yield put(logOutSuccess());
    } else {
      cookie.logout();
      window.location.reload();
    }
  } catch (error) {
    cookie.logout();
    window.location.reload();
  }
}

export function* verifyRegisterHandler(action) {
  try {
    const response = yield VERIFYREGISTERAPI(action.payload);
    if (response.status) {
      yield put(verifyRegisterSuccess(response?.data));
    } else {
      yield put(
        verifyRegisterFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(verifyRegisterFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* getCMSbyIDHandler(action) {
  try {
    const queryParams = {
      queryParams: {
        ...action.payload,
      },
    };
    const response = yield GET_CMS_BY_ID(queryParams);
    if (response.status) {
      yield put(getCMSbyKeySuccess(response.data));
    } else {
      yield put(
        getCMSbyKeyFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(
      getCMSbyKeyFailure({
        message: error?.message || "Etwas ist schief gelaufen!",
      })
    );
  }
}
