import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import CircularLoading from "src/components/atoms/loader/CircularLoading";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import { userLoginTimeDurationStarted } from "src/store/actions/userAnalytics";
import AnalyticsAverageBarChart from "./AnalyticsAverageBarChart";
import { optionsLoginTimeDaily } from "src/helpers/useranalytics";
import DocumentFilterSelect from "src/components/atoms/select/DocumentFilterSelect";

const UserAnalyticsAverageChart = () => {
  const [selectPeriodic, setSelectPeriodic] = useState(optionsLoginTimeDaily[0]?.value);
  const [loginTimes, setLoginTimes] = useState({
    category: null,
    count: null,
    periodic: optionsLoginTimeDaily[0]?.value,
  });
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const userAnalytics = useSelector((state) => state.userAnalytics);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectPeriodic !== loginTimes?.periodic) {
      setLoginTimes((prev) => {
        return { ...prev, category: null, count: null };
      });
      dispatch(userLoginTimeDurationStarted({ period: selectPeriodic }));
    }
  }, [selectPeriodic]);

  useEffect(() => {
    dispatch(userLoginTimeDurationStarted({ period: selectPeriodic }));
  }, []);

  useEffect(() => {
    if (userAnalytics?.login_time_average?.data) {
      let category = [];
      let count = [];
      userAnalytics?.login_time_average?.data?.forEach((data) => {
        category.push(data?.name);
        count.push(data?.averageDurationInHours);
      });
      setLoginTimes((prev) => {
        return { ...prev, category, count, periodic: selectPeriodic };
      });
    }
  }, [userAnalytics?.login_time_average]);

  return (
    <>
      <div className="flex flex-col md:flex-row justify-between">
        <h1 className="text-xl my-6  flex items-center gap-2">
          {cmsDashboarddata?.user_analytics?.login_session_average?.heading}
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "user_analytics",
                  unique: "login_session_average",
                  heading: "Benutzer Analyse",
                })
              );
            }}
          />
        </h1>
        <DocumentFilterSelect
          extra="w-full w-auto max-w-[220px]"
          extraLabel="hidden"
          label={"Anrede"}
          placeholder="Herr/Frau"
          id="salutation"
          options={optionsLoginTimeDaily}
          choose={false}
          setSelectDocument={setSelectPeriodic}
        />
      </div>
      {userAnalytics?.login_time_average?.isLoading && !loginTimes?.category && !loginTimes?.count && (
        <div className="min-h-96 flex justify-center items-center">
          <CircularLoading css="py-6" />
        </div>
      )}
      {loginTimes?.category && loginTimes?.count && <AnalyticsAverageBarChart data={loginTimes} />}
    </>
  );
};

export default UserAnalyticsAverageChart;
