import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {userLoginTimesDurationStarted } from "src/store/actions/userAnalytics";
import CircularLoading from "src/components/atoms/loader/CircularLoading";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import UserAnalyticsLoginTimesChart from "./UserAnalyticsLoginTimesChart";

const UserAnalyticsLoginTimes = () => {
  const dispatch = useDispatch();
  const procedure = useSelector((state) => state?.procedure);
  const userAnalytics = useSelector((state) => state.userAnalytics);
  const { data } = procedure;

  useEffect(() => {
    dispatch(userLoginTimesDurationStarted());
  }, []);

  return <>{data && userAnalytics?.login_duration?.data && <UserAnalyticsAbandoned />}</>;
};

const UserAnalyticsAbandoned = () => {
  const [loginTimes, setLoginTimes] = useState({
    category: null,
    count: null,
    type: "",
  });
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const userAnalytics = useSelector((state) => state.userAnalytics);
  const dispatch = useDispatch();
  const [view, setView] = useState("chart");

  useEffect(() => {
    if (userAnalytics?.login_duration?.data) {
      let category = [];
      let count = [];
      userAnalytics?.login_duration?.data?.forEach((data) => {
        category.push(data?.interval);
        count.push(data?.count);
      });
      setLoginTimes({ category, count });
    }
  }, [userAnalytics?.login_duration]);

  const handleView = (value) => {
    view !== value && setView(value);
  };

  return (
    <>
      <div className="flex flex-col md:flex-row justify-between">
        <h1 className="text-xl my-6 flex items-center gap-2">
          {cmsDashboarddata?.user_analytics?.login_times?.heading}
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "user_analytics",
                  unique: "login_times",
                  heading: "Benutzer Analyse",
                })
              );
            }}
          />
        </h1>
      </div>
      {userAnalytics?.abandoned_cart?.isLoading && !loginTimes?.category && !loginTimes?.count && (
        <div className="min-h-96 flex justify-center items-center">
          <CircularLoading css="py-6" />
        </div>
      )}
      {loginTimes?.category && loginTimes?.count && <UserAnalyticsLoginTimesChart data={loginTimes} view={view} handleView={handleView} />}
    </>
  );
};

export default UserAnalyticsLoginTimes;
