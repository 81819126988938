const Shorten = ({ data, stepsCMS }) => {
  return (
    <div>
      <h1 className="!mb-5 text-2xl font-tahoma-bold text-black dark:text-BrightGray">
        {stepsCMS[data?.id]?.title}
      </h1>
      <div className="textStyle md:max-w-[540px] lg:max-w-[720px] mb-5">
        <p dangerouslySetInnerHTML={{ __html: stepsCMS[data?.id]?.description }}></p>
      </div>
    </div>
  );
};

export default Shorten;
