import { BsBoxArrowUpRight, BsEye } from "react-icons/bs";
import { RiPencilFill } from "react-icons/ri";
import Card from "src/components/atoms/card/Card";
import InboxMessage from "src/components/molecules/afpcard/InboxMessage";
import ManageApp from "src/components/molecules/afpcard/ManageApp";
import CardHead from "src/components/atoms/card/CardHead";
import { SecondaryButton } from "src/components/atoms/button";
import { useSelector, useDispatch } from "react-redux";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import { useNavigate } from "react-router-dom";
import { isMobile, isTablet, browserName } from "react-device-detect";
import { userActivityOnStarted } from "src/store/actions/userAnalytics";
import { useUserActivity } from "src/helpers/useranalytics";

const DashboardMessage = ({ business, inbox, manageApp, messageList }) => {
  const dispatch = useDispatch();
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const profile = useSelector((state) => state.profile);
  const navigate = useNavigate();
  const navigateHandler = (url) => {
    if (profile?.data?.role === "admin") {
      navigate("/admin" + url);
    } else {
      navigate(url);
    }
  };
  return (
    <>
      <Card css="justify-between">
        <CardHead
          item={messageList[0]}
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "dashboard",
                unique: "inbox",
                heading: "Dashboard",
              })
            );
          }}
        />
        <InboxMessage inbox={inbox} />
        <div className="px-2 2xl:px-4">
          <SecondaryButton
            icon={<BsBoxArrowUpRight />}
            title={cmsDashboarddata?.dashboard?.inbox?.to_inbox}
            css="mt-3 mb-1 w-full font-tahoma font-semibold"
            handler={() => {
              navigateHandler("/posteingang");
              dispatch(userActivityOnStarted(useUserActivity("home_page", "dashboard.inbox.to_inbox", isMobile, isTablet, browserName)))
            }}
          />
        </div>
      </Card>
      <Card css="justify-between">
        <CardHead
          item={messageList[1]}
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "dashboard",
                unique: "manage_business",
                heading: "Dashboard",
              })
            );
          }}
        />
        <ManageApp business={business} />
        <div className="px-2 2xl:px-4">
          <SecondaryButton
            icon={<BsBoxArrowUpRight />}
            title={cmsDashboarddata?.dashboard?.manage_business?.to_business_premises}
            css="mt-3 mb-1 px-2 w-full font-tahoma font-semibold truncate"
            handler={() => {
              navigateHandler("/betriebsstaetten");
              dispatch(userActivityOnStarted(useUserActivity("home_page", "dashboard.manage_business.to_business_premises", isMobile, isTablet, browserName)))
            }}
          />
        </div>
      </Card>
      <Card css="justify-between">
        <CardHead
          item={messageList[2]}
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "dashboard",
                unique: "applications",
                heading: "Dashboard",
              })
            );
          }}
        />
        {manageApp?.formId ? (
          <div className="flex-1 px-2 2xl:px-4 mt-2">
            <div className="flex justify-start gap-1 items-center flex-wrap text-SmokeyGray text-md dark:text-BrightGray">
              <div className="flex-1 h-full">
                <h3 className="text-base font-tahoma-bold leading-7 text-black dark:text-BrightGray">{manageApp?.title}</h3>
                <p className="text-sm leading-7">{manageApp?.subTitle}</p>
              </div>
              <div className="gap-2 flex">
                <BsEye className="text-xl" />
                <RiPencilFill />
              </div>
            </div>
          </div>
        ) : (
          <div className="h-8"></div>
        )}
        <div className="px-4">
          <SecondaryButton
            icon={<BsBoxArrowUpRight />}
            title={cmsDashboarddata?.dashboard?.applications?.general_overview}
            css="mt-3 mb-1 w-full font-tahoma font-semibold"
            handler={() => {
              navigateHandler("/verfahren/uebersicht");
              dispatch(userActivityOnStarted(useUserActivity("home_page", "dashboard.applications.general_overview", isMobile, isTablet, browserName)))
            }}
          />
        </div>
      </Card>
    </>
  );
};

DashboardMessage.defaultProps = {
  business: [],
  inbox: [],
  manageApp: {},
  messageList: [],
};

export default DashboardMessage;
