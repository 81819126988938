import React from "react";
import { StandardSearch } from "src/components/molecules/search";
import { IoGrid } from "react-icons/io5";
import { IoListSharp } from "react-icons/io5";
import { SecondaryButton } from "src/components/atoms/button";
import { BiSolidPlusCircle } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";

let icons = [
  {
    id: 1,
    icon: <IoGrid />,
    key: "grid",
    css: "text-3xl",
  },
  {
    id: 2,
    icon: <IoListSharp />,
    key: "list",
    css: "text-4xl",
  },
];

const SearchHeader = ({ ToggleHandler, view, handleChange, searchTerm, handleOpen }) => {
  const dispatch = useDispatch();
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const { business_premises } = cmsDashboarddata;
  return (
    <div className="flex flex-col sm:flex-row items-center justify-end gap-6 mb-6 flex-wrap">
      <div className="w-full sm:w-auto flex justify-between md:justify-end gap-6">
        <div className="flex flex-row gap-2 items-center">
          {icons.map((icon) => {
            return React.cloneElement(icon.icon, {
              key: icon.key,
              onClick: () => {
                ToggleHandler(icon.key);
              },
              className: `cursor-pointer duration-600 ${icon.css} ${view === icon.key ? "text-AFPPrimary" : "text-SmokeyGray dark:text-BrightGray"}`,
            });
          })}
        </div>
        <SecondaryButton
          variant="secondary"
          title={business_premises?.add_button?.title}
          css={"font-tahoma-bold font-semibold !px-2"}
          handler={handleOpen}
          icon={<BiSolidPlusCircle className="text-AFPPrimaryDark cursor-pointer dark:text-AFPPrimaryLight" />}
        />
      </div>
      <div className="flex w-full sm:w-auto justify-end gap-x-2">
        <div className="relative w-64 rounded-full">
          <StandardSearch value={searchTerm} handleSearch={handleChange} placeholder={business_premises?.add_button?.search_placeholder} />
        </div>
        <EditIconCMS
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "business_premises",
                unique: "add_button",
                heading: "Betriebsstätten",
              })
            );
          }}
        />
      </div>
    </div>
  );
};

export default SearchHeader;
