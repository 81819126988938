// import jsPDF from "jspdf";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { evidenceCheckDetail } from "src/helpers/dform/validateEvidence";
import { setEvidenceById } from "src/store/actions/evidence";
import * as Actions from "src/store/actions/evidence";
import toast from "react-hot-toast";
import CustomCheckboxGreen from "src/components/atoms/input/CustomCheckbox/CustomCheckboxGreen";

const EvidenceTextArea = ({ evedenceData, evidence, handleCheckChangeDetailFill, entriesKey }) => {
  const initialValue = evidence?.evidenceDetail?.data[evedenceData.inventoryID.value] || "";
  const initailcheck = initialValue?.length > 1 ? true : false;
  const initailcheckValue = initialValue?.length > 1 ? "check" : "";

  const [isChecked, setIsChecked] = useState(initailcheck);
  const [textareaValue, setTextareaValue] = useState(initialValue);
  const [initialCheck, setInitialCheck] = useState(initailcheckValue);
  const dispatch = useDispatch();

  useEffect(() => {
    if (textareaValue?.length > 1) {
      if (isChecked !== true) {
        setIsChecked(true);
      }
    } else {
      if (isChecked === true) {
        setIsChecked(false);
      }
    }
  }, [textareaValue]);

  const handleClick = (event) => {
    const newValue = event.target.value;
    setTextareaValue(newValue);
    dispatch(
      setEvidenceById({
        key: evedenceData.inventoryID.value,
        value: newValue,
        type: "textarea",
      })
    );

    if (newValue) {
      if (!initialCheck) {
        setInitialCheck("check");
      }
    } else {
      setInitialCheck("");
      setIsChecked(false);
    }
  };

  const handleCheckbox = async (event) => {
    let checkedValue = event.target.checked;
    if (textareaValue) {
      setIsChecked(checkedValue);
    } else {
      toast.error("Bitte Text eingeben");
      setIsChecked(false);
      return;
    }

    if (!checkedValue) {
      setInitialCheck("");
      dispatch(Actions.removeEvidence(entriesKey, evedenceData?.inventoryID?.value));
    }
  };

  useEffect(() => {
    let newObj = {};
    let markObj = {};
    evidence?.data?.forEach((ab) => {
      ab?.prerequisiteGroup?.value;
      if (newObj[ab?.prerequisiteGroup?.value]) {
        newObj[ab?.prerequisiteGroup?.value]?.push(ab);
      } else {
        newObj[ab?.prerequisiteGroup?.value] = [ab];
        markObj[ab?.prerequisiteGroup?.value] = false;
      }
    });
    const isCheckUpdated = evidenceCheckDetail(newObj[entriesKey], evidence);
    if (isCheckUpdated) {
      handleCheckChangeDetailFill(entriesKey, true);
    } else {
      handleCheckChangeDetailFill(entriesKey, false);
    }
  }, [initialCheck]);

  return (
    <div id="pdf-content">
      <div className="" id={evedenceData?.inventoryID?.value}>
        <div className="flex items-start">
          <div className="ml-2 p-0">
            <div className="flex justify-between items-start gap-2">
              <div
                className="mb-2 font-bold dark:text-BrightGray"
                dangerouslySetInnerHTML={{
                  __html: evedenceData.prerequisite.ValueDescription,
                }}
              ></div>
              {isChecked && <CustomCheckboxGreen handle={handleCheckbox} isChecked={isChecked} />}
            </div>
            <div className="text-DarkCharcoal text-[14px] mb-6" dangerouslySetInnerHTML={{ __html: evedenceData?.longDescription?.value }}></div>
          </div>
        </div>
      </div>
      <div className="w-full border border-[#CBCBCB] rounded-lg p-4 dark:border-SmokeyGray">
        <textarea
          type="textarea"
          value={textareaValue}
          placeholder=""
          rows="4"
          className="border border-AFPMenuLight w-full border-none outline-none p-2 bg-transparent rounded-lg dark:bg-AFPDarkBgLight dark:border-SmokeyGray dark:text-BrightGray"
          onChange={(e) => handleClick(e)}
        />
      </div>
      {!isChecked ? <span className="text-red-400 text-sm mt-2">Pflichtfeld</span> : null}
    </div>
  );
};

export default EvidenceTextArea;
