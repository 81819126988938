import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import ContentLayout from "src/components/atoms/common/ContentLayout";
import Breadcrumbs from "src/components/molecules/Breadcrumbs";
import { inboxBreadcrumb } from "src/components/molecules/Breadcrumbs/BreadcrumbsList";
import InboxContent from "src/components/organisms/InboxContent";
import MessageModal from "src/components/organisms/cms/MessageModal";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import { messageList } from "src/store/actions/inbox";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";
import { isMobile, isTablet, browserName } from "react-device-detect";
import { userActivityOnStarted } from "src/store/actions/userAnalytics";
import { useUserActivity } from "src/helpers/useranalytics";

const Inbox = () => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const { isModalOpen, modalData, sidebar_menu } = cmsDashboarddata;
  const dispatch = useDispatch();

  const msgList = useSelector((state) => state?.inbox);

  useEffect(() => {
    dispatch(messageList());
    dispatch(userActivityOnStarted(useUserActivity("inbox", "inbox", isMobile, isTablet, browserName)))
  }, []);

  return (
    <ContentLayout>
      <div className="text-black relative md:pt-[4.5rem] pb-0 py-8 min-h-screen-minus-3 bg-AFPBGPrimary dark:bg-AFPDarkBgDarkest dark:text-white">
        {sidebar_menu?.sidebar_list?.inbox && <Breadcrumbs data={inboxBreadcrumb(sidebar_menu?.sidebar_list?.inbox)} /> }
        {cmsDashboarddata.listLoaded && (
          <>
            <h2 className="!mb-5 text-2xl font-tahoma-bold m-3 sm:m-4 md:m-6 flex items-center gap-3 ">
              {cmsDashboarddata?.inbox?.top?.heading}
              <EditIconCMS
                handleOpen={() => {
                  dispatch(
                    dashboardModalOpenCMS({
                      cms_key: "inbox",
                      unique: "top",
                      heading: "Inbox",
                    })
                  );
                }}
              />
            </h2>
            <div className="bg-AFPBGPrimary mx-3 sm:mx-4 md:mx-6 mt-0 p-0 dark:bg-AFPDarkBgDarkest">
              <p className="textStyle max-w-[540px] mb-5">{cmsDashboarddata?.inbox?.top?.sub_heading}</p>
            </div>
            {msgList?.isLoading ? (
              <div className="flex w-full h-[60vh] justify-center items-center">
                <CustomCircularLoader />
              </div>
            ) : (
              msgList?.data && <InboxContent data={msgList?.data} />
            )}
          </>
        )}
      </div>
      {isModalOpen && <MessageModal open={isModalOpen} cmsData={modalData} />}
    </ContentLayout>
  );
};

export default Inbox;
