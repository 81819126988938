import * as yup from "yup";

export const INITIALVALUESLOGIN = {
  email: "",
  password: "",
  remember: false,
};
export const INITIALVALUESFORGOT = {
  email: "",
};
export const INITIALVALUESRESET = {
  password: "",
  confirmpassword: "",
};

export const INITIALVALUESCHANGEPASSWORD = {
  oldPassword: "",
  password: "",
  repeat: "",
};

export const VALIDATIONSCHEMALOGIN = yup.object().shape({
  email: yup.string().required("Bitte geben Sie als Benutzernamen Ihre E-Mail-Adresse ein").email("E-Mail ist ungültig"),
  password: yup.string().required("Passwort muss ausgefüllt werden").min(6, "Das Passwort muss mindestens 6 Zeichen lang sein.").max(30, "Das Passwort darf nicht länger als 40 Zeichen sein."),
});
export const VALIDATIONSCHEMAFORGOT = yup.object().shape({
  email: yup.string().required("Bitte geben Sie als Benutzernamen Ihre E-Mail-Adresse ein").email("E-Mail ist ungültig"),
});
export const VALIDATIONSCHEMARESET = yup.object().shape({
  password: yup.string().required("Passwort muss ausgefüllt werden").min(6, "Das Passwort muss mindestens 6 Zeichen lang sein.").max(30, "Das Passwort darf nicht länger als 40 Zeichen sein."),
  confirmpassword: yup.string().required("Passwort muss ausgefüllt werden").min(6, "Das Passwort muss mindestens 6 Zeichen lang sein.").max(30, "Das Passwort darf nicht länger als 40 Zeichen sein.").oneOf([yup.ref('password'), null], "Die Passwörter müssen übereinstimmen."),
});

export const VALIDATIONSCHEMACHANGEPASSWORD = yup.object().shape({
  oldPassword: yup
    .string()
    .required("Altes Passwort muss ausgefüllt werden")
    .test(
      "Old password",
      "Altes Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Ziffer und ein Sonderzeichen enthalten und mindestens 8 Zeichen lang sein. Leerzeichen sind nicht erlaubt.",
      (value) => {
        return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?/\\~-])(?=\S+$).{8,}$/.test(value) && !/\s/.test(value);
      }
    ),
  password: yup
    .string()
    .required("Passwort muss ausgefüllt werden")
    .test(
      "password",
      "Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Ziffer und ein Sonderzeichen enthalten und mindestens 8 Zeichen lang sein. Keine Leerzeichen erlaubt.",
      (value) => {
        return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?/\\~-])(?=\S+$).{8,}$/.test(value) && !/\s/.test(value);
      }
    ),
  repeat: yup
    .string()
    .required("Passwort wiederholen muss ausgefüllt werden")
    .oneOf([yup.ref("password"), null], "Passwort must match"),
});

export const VALIDATIONSCHEMAINVITEDUSER = yup.object().shape({
  email: yup.string().required("Bitte geben Sie als Benutzernamen Ihre E-Mail-Adresse ein").email("E-Mail ist ungültig"),
  first_name: yup.string().required("Vorname muss ausgefüllt werden"),
  last_name: yup.string().required("Nachname muss ausgefüllt werden"),
});