/*eslint-disable */
import { useEffect, useState } from "react";
import { RiEditFill } from "react-icons/ri";

import { RiDeleteBin5Fill } from "react-icons/ri";
import { SecondaryButton } from "src/components/atoms/button";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import PaymentUserModal from "src/components/atoms/modal/PaymentUserModal";
import { useDispatch, useSelector } from "react-redux";
import { createdResetUserAccount, deleteAccountList, fetchAccountListRequest } from "src/store/actions/userSettings";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import { BiSolidPlusCircle } from "react-icons/bi";
import DeleteModal from "src/components/atoms/modal/DeleteModal";
import deleteDisabledIcon from "src/assets/icons/delete-desabled.png";
import editDisabledIcon from "src/assets/icons/edit-disabled.png";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";
import PaymentSettingForm from "./PaymentSettingForm";
import { isMobile, isTablet, browserName } from "react-device-detect";
import { userActivityOnStarted } from "src/store/actions/userAnalytics";
import { useUserActivity } from "src/helpers/useranalytics";

const PaymentSettings = () => {
  const dispatch = useDispatch();
  const accountList = useSelector((state) => state.settings);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);

  useEffect(() => {
    dispatch(userActivityOnStarted(useUserActivity("setting", "bank_details", isMobile, isTablet, browserName)))
    if (!accountList.loading) {
      dispatch(fetchAccountListRequest());
    }
  }, []);
  return (
    <>
      {cmsDashboarddata.listLoaded && (
        <>
          {accountList?.data && !accountList.isLoading ? (
            <div className="px-1 md:px-2 py-6">
              <PaymentSetting />
            </div>
          ) : (
            accountList.isLoading && (
              <div className="flex w-full h-[60vh] justify-center items-center">
                <CustomCircularLoader />
              </div>
            )
          )}
        </>
      )}
    </>
  );
};

const PaymentSetting = () => {
  const [editMode, setEditMode] = useState(false);
  const accountList = useSelector((state) => state.settings);
  const [usersList, setUsersList] = useState(accountList?.data);

  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const settings = useSelector((state) => state.settings);

  const cmsData = cmsDashboarddata?.settings;

  const [open, setOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [toggleFormik, setToggleFormik] = useState({ status: true, data: null });
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (accountList?.data?.length !== usersList) {
      setUsersList(accountList.data);
    }
  }, [accountList?.data]);

  useEffect(() => {
    const user = usersList?.find((user) => user.is_default);
    setToggleFormik((prev) => {
      return {
        ...prev,
        status: false,
      };
    });
    if (user) {
      setTimeout(() => {
        setToggleFormik((prev) => {
          return {
            ...prev,
            status: true,
            data: {
              account_holder: user.account_holder,
              iban: user.iban,
              bic: user.bic,
              credit_institute: user.credit_institute,
              invoice_email: user.invoice_email,
              id: user.id,
              is_default: user.is_default,
            },
          };
        });
      }, 400);
    } else {
      if (usersList.length > 0) {
        let user = usersList[0];
        setTimeout(() => {
          setToggleFormik((prev) => {
            return {
              ...prev,
              status: true,
              data: {
                account_holder: user.account_holder,
                iban: user.iban,
                bic: user.bic,
                credit_institute: user.credit_institute,
                invoice_email: user.invoice_email,
                id: user.id,
                is_default: user.is_default,
              },
            };
          });
        }, 400);
      }
    }
  }, []);

  const handleDelete = () => {
    dispatch(deleteAccountList(selectedId));
  };

  const handleToggleStandard = (index) => {
    const updatedUsersList = usersList.map((user) => (user.id === index ? { ...user, is_default: true } : { ...user, is_default: false }));
    setUsersList(updatedUsersList);
  };

  const handleEdit = (id) => {
    setToggleFormik((prev) => {
      return {
        ...prev,
        status: false,
      };
    });
    setEditMode(true);
    if (usersList?.length > 0) {
      const user = usersList?.find((user) => user.id === id);
      if (user) {
        setTimeout(() => {
          setToggleFormik((prev) => {
            return {
              ...prev,
              status: true,
              data: {
                account_holder: user.account_holder,
                iban: user.iban,
                bic: user.bic,
                credit_institute: user.credit_institute,
                invoice_email: user.invoice_email,
                id: user.id,
                is_default: user.is_default,
              },
            };
          });
        }, 400);
      }
    }
  };
  function maskLastFourDigits(input) {
    const lastFourDigits = input.slice(-4);
    const maskedDigits = "*".repeat(input.length - 4) + lastFourDigits;
    const visibleDigits = "****" + maskedDigits.slice(-4);
    return visibleDigits;
  }

  useEffect(() => {
    settings?.newUser?.created && (setOpen(false), dispatch(createdResetUserAccount()));
  }, [settings?.newUser]);

  const handleSelectedId = (id) => {
    setSelectedId(id);
    setShowDeleteModal(true);
  };

  const deleteModalClose = () => {
    setSelectedId("");
    setShowDeleteModal(false);
  };

  return (
    <>
      {usersList?.length > 0 &&
        (toggleFormik?.status ? (
          <PaymentSettingForm editMode={editMode} setEditMode={setEditMode} toggleFormik={toggleFormik} />
        ) : (
          <div className="min-h-96 flex justify-center items-center">
            <CustomCircularLoader />
          </div>
        ))}
      <div className="flex justify-end w-full max-w-[700px]">
        <EditIconCMS
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "settings",
                unique: "pay_discription",
                heading: "Einstellungen",
              })
            );
          }}
        />
      </div>
      <DeleteModal handleOpen={deleteModalClose} showModal={showDeleteModal} confirmHandle={handleDelete} />
      {usersList?.length > 0 && <p className="textStyle max-w-[700px]">{cmsData?.pay_discription?.discription}</p>}
      <div className="flex justify-between items-center gap-x-4 py-2 max-w-[700px]">
        <h2 className="py-4 h2Heading flex items-center gap-3 dark:text-BrightGray">
          {cmsData?.pay_listing?.title}
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "settings",
                  unique: "pay_listing",
                  heading: "Einstellungen",
                })
              );
            }}
          />
        </h2>
        <SecondaryButton
          variant="secondary"
          title={cmsData?.pay_modal_button?.title}
          css={"font-tahoma-bold font-semibold !px-2"}
          handler={handleOpen}
          icon={<BiSolidPlusCircle className="text-AFPPrimaryDark cursor-pointer hover:text-AFPPrimaryLight" />}
        />
        <EditIconCMS
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "settings",
                unique: "pay_modal_button",
                heading: "Einstellungen",
              })
            );
          }}
        />
      </div>

      <div className="flex flex-col max-w-[700px] pb-5 gap-6">
        <div className="flex gap-1 sm:grid sm:grid-cols-5">
          <p className="text-SmokeyGray text-[.75rem] sm:col-span-1 dark:text-BrightGray">{cmsData?.pay_listing?.name}</p>
          <p className="text-SmokeyGray text-[.75rem] sm:col-span-2 dark:text-BrightGray">{cmsData?.pay_listing?.bank_name}</p>
          <p className="text-SmokeyGray text-[.75rem] sm:col-span-1 dark:text-BrightGray">{cmsData?.pay_listing?.iban}</p>
          <span className="text-SmokeyGray text-[.75rem] sm:col-span-2 sm:hidden dark:text-BrightGray">/</span>
          <p className="text-SmokeyGray text-[.75rem] sm:col-span-1 dark:text-BrightGray">{cmsData?.pay_listing?.default}</p>
        </div>
        {!accountList?.isLoading && (
          <>
            {!(usersList?.length > 0) ? (
              <h2 className="font-tahoma-bold text-lg text-red-300 mb-6 mt-6 text-center">Keine Zahlungsadressen</h2>
            ) : (
              <>
                {usersList &&
                  usersList?.map((user) => (
                    <div className="grid grid-cols-1 sm:grid-cols-5" key={user.id}>
                      <p className={`text-AFPHeading text-[1rem] sm:col-span-1 cursor-pointer dark:text-BrightGray`} onClick={() => handleToggleStandard(user.id)}>
                        {user.account_holder}
                      </p>
                      <p className={`text-AFPHeading text-[1rem] sm:col-span-2 cursor-pointer dark:text-BrightGray`} onClick={() => handleToggleStandard(user.id)}>
                        {user.credit_institute}
                      </p>
                      <p className={`text-AFPHeading text-[1rem] sm:col-span-1 cursor-pointer dark:text-BrightGray`}>{maskLastFourDigits(user.iban)}</p>
                      <div className="flex gap-5 text-[1rem] sm:col-span-1 mt-4 sm:mt-0">
                        <div className="flex-1">{user.is_default && <IoIosCheckmarkCircleOutline className="text-AFPPrimary cursor-pointer" />}</div>

                        {user.role == "invitedUser" ? (
                          <img src={editDisabledIcon} className="w-4 h-4 cursor-not-allowed" />
                        ) : (
                          <RiEditFill className="text-SmokeyGray cursor-pointer dark:text-BrightGray" onClick={() => handleEdit(user.id)} />
                        )}
                        {user.role == "invitedUser" ? (
                          <img src={deleteDisabledIcon} className="w-4 h-4 cursor-not-allowed" />
                        ) : (
                          <RiDeleteBin5Fill
                            className="text-SmokeyGray cursor-pointer dark:text-BrightGray"
                            onClick={() => {
                              handleSelectedId(user.id);
                            }}
                          />
                        )}
                      </div>
                    </div>
                  ))}
              </>
            )}
          </>
        )}
      </div>

      {open && <PaymentUserModal open={open} handleClose={handleClose} />}
    </>
  );
};

export default PaymentSettings;
