import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";
import { verifyRegisterStarted } from "src/store/actions/users";

const SignupVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const users = useSelector((state) => state.users);
  const { id } = useParams();
  useEffect(() => {
    dispatch(verifyRegisterStarted({ token: id }));
  }, []);

  useEffect(() => {
    if (users?.verify?.status == "success") {
      navigate("/");
    }
  }, [users?.verify]);

  return (
    <div className="flexCenter min-h-screen bg-black">
      <div className="min-h-[100vh] w-full flex justify-center items-center bg-white">
        <div className="flex justify-center items-center mt-4">{users?.verify?.isLoading && <CustomCircularLoader />}</div>
      </div>
    </div>
  );
};

export default SignupVerification;
