import { Table } from "@mui/material";
import BusinessTableBody from "./BusinessTableBody";
import TableHeader from "src/components/atoms/common/TableHeader";
import { useSelector, useDispatch } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import { CircularProgress } from "@mui/material";
import useNestedArraySorting from "src/hooks/useNestedArraySorting";
import TableContainerDark from "src/components/atoms/table/TableContainerDark";

const BusinessPremisesTable = ({ businessPremisesBody, businessPremisesHeader }) => {
  const { items: sortedData, requestSortIncrease, requestSortDecrease } = useNestedArraySorting(businessPremisesBody);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const business = useSelector((state) => state.management);
  const { business_premises } = cmsDashboarddata;
  const dispatch = useDispatch();

  return (
    <TableContainerDark>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHeader tableData={businessPremisesHeader} increaseSort={requestSortIncrease} decreaseSort={requestSortDecrease} />
        {sortedData?.length > 0 && <BusinessTableBody bodyContent={sortedData} />}
        {business?.isLoading && !(sortedData?.length > 0) && <CircularProgress />}
      </Table>
      {sortedData?.length < 0 && (
        <h4 className="text-red-400 text-center my-6 flex justify-center items-center gap-2">
          {business_premises?.list_not_found?.not_found}
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "business_premises",
                  unique: "list_not_found",
                  heading: "Betriebsstätten",
                })
              );
            }}
          />
        </h4>
      )}
    </TableContainerDark>
  );
};

BusinessPremisesTable.defaultProps = {
  businessPremisesBody: [],
  businessPremisesHeader: [],
};

export default BusinessPremisesTable;
