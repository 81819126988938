import { put } from "redux-saga/effects";
import { getProcedureTemplate } from "../requests/procedure";

import { procedureFailure, setProcedureTemplates } from "src/store/actions/procedure";
import Cookies from "js-cookie";

export function* procedureTemplateHandler() {
  try {
    const paramsData = {
      queryParams: {
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield getProcedureTemplate(paramsData);
    if (response.status) {
      localStorage.setItem('form_id_name', JSON.stringify(response.data))
      yield put(setProcedureTemplates(response.data));
    } else {
      yield put(
        procedureFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(procedureFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}
