import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useState } from "react";

const PasswordField = ({ name, label, type, css, formik, isDark=false }) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <TextField
      className={css}
      id={name}
      name={name}
      label={label}
      variant="standard"
      type={showPassword ? "text" : type}
      {...formik.getFieldProps(name)}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      InputLabelProps={{
        sx: {
          color: isDark ? "#ebebeb" : "#000", 
          fontWeight: "600",
        },
      }}
      InputProps={{
        style: {
          borderColor: isDark ? "#706F6F" : "#00f",
          color: isDark ? "#ebebeb" : "#000"
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton className="dark:text-BrightGray" aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      sx={{
        "& .MuiOutlinedInput-root": {
          borderRadius: "12px",
          borderColor: isDark ? "#706F6F" : "#00f",
        },
        "& .Mui-disabled": {
          WebkitTextFillColor: "#6B7280 !important",
          background: "#F3F4F6 !important",
        },
        "& .MuiInputLabel-root.Mui-focused": {
          color: isDark ? "#ebebeb" : "#000", // Change color when focused
        },
        "& .MuiInputLabel-root": {
          color: isDark ? "#ebebeb" : "#000", // Default label color
        },
      }}
    />
  );
};

PasswordField.defaultProps = {
  name: "password",
  label: "Password",
  type: "password",
  css: "",
  formik: {
    getFieldProps: () => {},
    touched: {},
    errors: {},
  },
};

export default PasswordField;
