import React, { useState } from "react";
import { Autocomplete, Checkbox, TextField, ListItemText, MenuItem, List, Paper } from "@mui/material";
import { SecondaryButton } from "../../button";

const MultiStateSelection = ({ handleSelect, data, placeholder, selectedAreaArray, stepsCMS, dataID }) => {
  const [selectedValues, setSelectedValues] = useState(selectedAreaArray);
  const [confirmation, setConfirmation] = useState(false)
  const [dropdownStatus, setDropdownStatus] = useState({
    open:false,
  })

  const handleChange = (event, value) => { // eslint-disable-line no-unused-vars
    setSelectedValues(value?.map((item) => item?.value));
    if (!(value.length > 0) && !(selectedAreaArray?.length > 0)) {
      confirmation && setConfirmation(false);
    } else {
      setConfirmation(true);
    }
  };

  const handleSelectAll = () => {
    if (selectedValues?.length === data?.length) {
      setSelectedValues([]);
      confirmation && setConfirmation(false)
    } else {
      setSelectedValues(data?.map((item) => item?.value));
      setConfirmation(true)
    }
  };

  const isAllSelected = selectedValues?.length === data?.length;
  const isIndeterminate = selectedValues?.length > 0 && selectedValues?.length < data?.length;

  const selectAllColor = isAllSelected || isIndeterminate ? "#A31D1A" : undefined;

  const handleDropdownOpen = () => {
    setDropdownStatus({ open: true })
    setSelectedValues(selectedAreaArray)
  };

  const handleDropdownClose = () => {
    if (!(selectedValues?.length > 0) && !(selectedAreaArray?.length > 0)) {
      confirmation && setConfirmation(false)
    } else {
      if (selectedValues?.find((select) => !selectedAreaArray?.includes(select.value))) { // eslint-disable-line no-unused-vars
      } else {
        setConfirmation(true)
      }
    }
  };

  const confirmHandle = () => {
    handleSelect(selectedValues)
    setConfirmation(false)
    setDropdownStatus({ open: false })
  };

  const cancelhandle = () => {
    setConfirmation(false);
    setDropdownStatus({ open: false });
  };

  return (
    <>
      <Autocomplete
        className="dark:bg-AFPDarkBgLight"
        onFocus={(e) => console.log(e)}
        open={dropdownStatus?.open}
        onOpen={handleDropdownOpen}
        onClose={handleDropdownClose}
        multiple
        options={data}
        disableCloseOnSelect
        value={data.filter((item) => selectedValues?.includes(item?.value))}
        onChange={handleChange}
        getOptionLabel={(option) => option?.title}
        renderOption={(props, option, { selected }) => (
          <MenuItem {...props} className="dark:bg-AFPDarkBgLight dark:text-BrightGray">
            <Checkbox checked={selected} value={option?.value} style={{ marginRight: 8, color: selected ? "#A31D1A" : undefined }} />
            <ListItemText primary={option?.title} />
          </MenuItem>
        )}
        PaperComponent={(props) => (
          <Paper {...props} className="dark:bg-AFPDarkBgLight dark:text-BrightGray overflow-auto" />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={placeholder}
            sx={{
              "& .MuiAutocomplete-inputRoot": {
                padding: "6px 6px",
                "&:hover": {
                  outline: "none",
                },
                "&.Mui-focused": {
                  outline: "none",
                },
              },
              "& input::placeholder": {
                color: "#706F6F",
                opacity: 1,
              },
            }}
          />
        )}
        renderTags={() => null} // Hide tags in input
        ListboxComponent={(props) => (
          <List {...props} className="dark:bg-AFPDarkBgLight h-[40vh] dark:text-BrightGray">
            <MenuItem onClick={handleSelectAll}>
              <Checkbox checked={isAllSelected} indeterminate={isIndeterminate} style={{ marginRight: 8, color: selectAllColor }} />
              <ListItemText primary={stepsCMS[dataID]?.select_all} />
            </MenuItem>
            {props.children}
          </List>
        )}
      />
      {dropdownStatus?.open && (
        <>
          <div className="min-h-[40vh]"></div>
        </>
      )}
      {confirmation && (
        <div className="flex justify-center items-center w-[200px] gap-4 my-6 ">
          <SecondaryButton variant="secondary" title={stepsCMS[dataID]?.cancel} css="w-full" handler={cancelhandle} />
          <SecondaryButton variant="secondary" title={stepsCMS[dataID]?.confirm} css="w-full !bg-AFPPrimary !text-white" handler={() => confirmHandle()} />
        </div>
      )}
    </>
  );
};

export default MultiStateSelection;
