import { Box, Modal } from "@mui/material";
import styles from "./addLocation.module.css";
import AddDocumentForm from "./AddDocumentForm";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { VALIDATION_SCHEMA_ADD_DOCUMENT } from "src/services/validation/document";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import Cookies from "js-cookie";
import { uploadDocumentBase64Started, uploadDocumentStarted } from "src/store/actions/document";
import toast from "react-hot-toast";
import { AiOutlineClose } from "react-icons/ai";
import { styleModal } from "../modal";

const AddDocumentModal = ({ handleClose, open, documentDetails }) => {
  const document = useSelector((state) => state.document);
  const profile = useSelector((state) => state.profile);
  const [modalClosing, setModalClosing] = useState(false);
  const dmsDocument = document.dmsDocumentUpload;
  const dispatch = useDispatch();

  const [uploadedImage, setUploadedImage] = useState(null);
  const [showCamera, setShowCamera] = useState(false);

  const cmsDocumentdata = useSelector((state) => state?.cmsDocument?.document);
  const formik = useFormik({
    initialValues: { ...documentDetails, category_id: "", location_id: "" },
    validationSchema: VALIDATION_SCHEMA_ADD_DOCUMENT,
    onSubmit: (values) => {
      const category = document?.documentCategory?.data?.find((cat) => cat?.id === values?.category_id);
      const payload = {
        ...values,
        contact_id: profile?.data?.owner_contact_id,
        creator_user: profile?.data?.first_name + " " + profile?.data?.last_name,
        category: category?.name,
        date: new Date(),
      };
      if (uploadedImage) {
        dispatch(uploadDocumentBase64Started({ ...payload, base64: uploadedImage }));
      } else if (values.docs.length > 0) {
        const formData = new FormData();
        formData.append("user_id", Cookies.get("userId"));
        formData.append("document", values?.doc_url);
        dispatch(uploadDocumentStarted({ ...payload, file: formData }));
      } else {
        toast.error("Bitte Dokument auswählen");
      }
    },
  });

  useEffect(() => {
    if (dmsDocument?.isLoading) {
      setModalClosing(true);
    }
    if (modalClosing && !dmsDocument?.isLoading) {
      handleClose();
    }
  }, [dmsDocument]);

  return (
    <Modal keepMounted open={open} className={styles.customModalRoot}>
      <Box sx={styleModal} className={`${styles.customInnerChild} bg-AFPBGPrimary dark:bg-AFPDarkBgDarkest`}>
        <div className={`h-auto max-h-[90vh] overflow-y-auto pt-4 py-10 customScrollBar`}>
          <div className="relative">
            <div className="flex justify-between items-center mb-6 md:px-2">
              <h2 className="text-xl md:text-2xl font-tahoma-bold flex items-center gap-x-3 dark:text-BrightGray">
                {cmsDocumentdata?.upload?.title}
                <EditIconCMS
                  handleOpen={() => {
                    dispatch(
                      dashboardModalOpenCMS({
                        cms_key: "document",
                        unique: "upload",
                        heading: "Dokumentenverwaltung",
                      })
                    );
                  }}
                />
              </h2>
              <AiOutlineClose size={24} className="text-SmokeyGray cursor-pointer -mt-5 dark:text-BrightGray" onClick={handleClose} />
            </div>
            <hr className="mt-2 mb-9 dark:border-SmokeyGray" />
            <div className="flex justify-end">
              <EditIconCMS
                handleOpen={() => {
                  dispatch(
                    dashboardModalOpenCMS({
                      cms_key: "document",
                      unique: "type",
                      heading: "Dokumentenverwaltung",
                    })
                  );
                }}
              />
            </div>

            <AddDocumentForm formik={formik} uploadedImage={uploadedImage} setUploadedImage={setUploadedImage} showCamera={showCamera} setShowCamera={setShowCamera} />
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AddDocumentModal;
