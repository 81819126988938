/* eslint-disable */
import Login from "src/components/template/Login";
import ForgotForm from "src/components/organisms/login/ForgotForm";
import { useState } from "react";

const { loginPolicy, subHeading, heading } = {
  "heading": "Kundenportal",
  "subHeading": "Passwort vergessen?",
  "loginPolicy": "Geben Sie die E-Mail-Adresse ein, die mit Ihrem AfPQ-Kundenportal verbunden ist. Wir senden Ihnen einen Link, um ein neues Passwort anzulegen, der 24 Stunden gültig ist.",
};

const forgotPreField = {
  title: heading,
  subTitle: subHeading,
  LoginPolicy: loginPolicy,
};
const ForgotPassword = () => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem("hs_theme");
    return savedTheme === "dark" || (!savedTheme && window.matchMedia("(prefers-color-scheme: dark)").matches);
  });
  return (
    <div className="loginwrap">
      <div className="loginwcontainer">
        <Login data={forgotPreField}>
          <ForgotForm isDarkMode={isDarkMode}/>
        </Login>
      </div>
    </div>
  );
};

export default ForgotPassword;
