import { useEffect, useRef, useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import style from "./textAreaCms.module.css";

const TextAreaFeildCms = (props) => {
  const { label, id, extra, type, placeholder, disabled, extraLabel, extraInput, formik, editable, onChangeHandle } = props;

  const { touched, errors, setFieldValue, values } = formik;

  const [editableS, setEditableS] = useState(!editable);

  const textareaRef = useRef(null);

  const handleChange = (event) => {
    const { value } = event.target;
    if (onChangeHandle) {
      onChangeHandle(id, value);
    }
    setFieldValue(id, value);
    adjustTextareaHeight();
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;

    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [values[id]]);

  return (
    <div className={`${style.wrapper} ${extra}`}>
      <label htmlFor={id} className={`${style.inputLabel} ${extraLabel} dark:text-BrightGray`}>
        {label}
      </label>
      <div className={`w-full ${editable && style.editableContainer}`}>
        <textarea
          ref={textareaRef}
          disabled={!editableS || disabled}
          type={type}
          id={id}
          name={id}
          placeholder={placeholder}
          onChange={handleChange}
          value={values[id]}
          style={{ overflow: "hidden", resize: "none" }}
          className={`${style.textarea} ${extraInput} ${editableS ? "bg-white" : "bg-AFPBGPrimary"} ${touched[id] && errors[id] && "!border-red-500 !bg-gray-100 "} dark:text-BrightGray dark:bg-AFPDarkBgLight`}
        />
        {editable && (
          <AiFillEdit
            onClick={() => {
              !disabled && setEditableS(!editableS);
            }}
            size={16}
            className={`${editableS ? "text-SeaGreen" : "text-SmokeyGray"} cursor-pointer`}
          />
        )}
        {!editable && touched[id] && errors[id] && <div className="text-red-500 text-sm px-3 py-1">{errors[id]}</div>}
      </div>
    </div>
  );
};

TextAreaFeildCms.defaultProps = {
  label: "",
  id: "inputField",
  extra: "",
  type: "text",
  placeholder: "",
  disabled: false,
  extraLabel: "",
  extraInput: "",
  formik: {
    touched: {},
    errors: {},
    setFieldValue: () => {},
    values: {},
  },
  editable: false,
  onChangeHandle: () => {},
};

export default TextAreaFeildCms;
