import "./checkStrength.css";

const CheckStrengthBar = ({ strength }) => {
  return (
    <div className="flex w-full">
      <progress
        value={strength}
        max="5"
        className={`progressBar barStrength-${strength} text-black dark:text-BrightGray`}
      />
    </div>
  );
};

CheckStrengthBar.defaultProps = {
  strength: 0,
};

export default CheckStrengthBar;
