import React from "react";
import "./style.css";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import PageEdit from "src/assets/img/iconoir_page-edit.png";
import CheckIcon from "src/assets/img/check_icon.png";
import MailNotification from "src/assets/img/mail-notification.png";
import { useSelector } from "react-redux";

const ApplicationSuccess = ({ stepsCMS }) => {
  const isDarkMode = useSelector((state) => state.theme.darkmode);
  const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: "#784af4",
    }),
    "& .QontoStepIcon-completedIcon": {
      color: "#784af4",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  }));

  function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? <Check className="QontoStepIcon-completedIcon" /> : <div className="QontoStepIcon-circle" />}
      </QontoStepIconRoot>
    );
  }

  QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
  };

  const ColorlibConnector = styled(StepConnector)(() => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 42,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage: "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundImage: "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ ownerState }) => ({
    backgroundColor: isDarkMode ? "#333333" : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 80,
    height: 80,
    display: "flex",
    borderRadius: "16px",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundImage: "rgba(240, 240, 240, 0.2)",
    }),
    ...(ownerState.completed && {
      backgroundImage: "rgba(240, 240, 240, 0.2)",
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
      1: <img src={PageEdit} alt="icons" style={{ width: 30, height: 30 }} />,
      2: <img src={CheckIcon} alt="icons" style={{ width: 30, height: 30 }} />,
      3: <img src={MailNotification} alt="icons" style={{ width: 30, height: 30 }} />,
    };

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
    icon: PropTypes.node,
  };

  const steps = (step) => {
    return [step?.check, step?.prequalification, step?.monitoring];
  };

  const application = useSelector((state) => state.application);

  return (
    <div>
      <div className="flex justify-center items-center my-10">
        <div className="bg-slate-300 py-2 px-12 rounded text-center dark:bg-AFPDarkBgLight">
          <h3 className="pb-1 text-lg">{stepsCMS?.NACHWEISE_MIDDLE?.reference_number}</h3>
          <h2 className="font-bold text-3xl">
            {application?.dformStatus?.data?.data?.service_order_types_id}-{application?.dformStatus?.data?.data?.erp_service_order_id}
          </h2>
        </div>
      </div>
      <div>
        <h2 className="pl-7 font-bold text-xl mb-12">{stepsCMS?.NACHWEISE_MIDDLE?.next}</h2>

        <Stack sx={{ width: "100%" }} spacing={4}>
          <Stepper alternativeLabel activeStep={1} connector={<ColorlibConnector />}>
            {steps(stepsCMS?.NACHWEISE_MIDDLE).map((label) => (
              <Step key={label}>
                <StepLabel sx={{ ".MuiStepLabel-label" : { color: isDarkMode ? "#ebebeb" : "#333" } }} StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>{" "}
              </Step>
            ))}
          </Stepper>
        </Stack>
      </div>
    </div>
  );
};

export default ApplicationSuccess;
