import { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { globalChartOptions } from "src/helpers/useranalytics";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import ToggleView from "src/components/atoms/select/ToggleView";

const UserAnalyticsACBarChart = ({ data, view, handleView, selectPeriodic }) => {
  return (
    <>
      <ToggleView view={view} handleView={handleView} />
      {view === "chart" && <UserAnalyticsACChart data={data} selectPeriodic={selectPeriodic} />}
      {view === "list" && <UserAnalyticsACList data={data} selectPeriodic={selectPeriodic} />}
    </>
  );
};

const customOption = (selectPeriodic) => {
  return {
    tooltipID: "AbortedApplication",
    selectPeriodic: selectPeriodic,
    dataLabels: {
      enabled: selectPeriodic === "all" ? true : false,
    },
    extra: {
      colors: selectPeriodic === "all" ? ["#82CA9D"] : [],
    },
  };
};

const UserAnalyticsACChart = ({ data, selectPeriodic }) => {
  const seriesDefault = [
    {
      name: "gesamt",
      data: data?.count,
    },
  ];

  let series = selectPeriodic == "all" ? seriesDefault : data?.count;

  useEffect(() => {});

  return <Chart options={globalChartOptions(data, customOption(selectPeriodic))} series={series} type="bar" height={350} />;
};

const UserAnalyticsACList = ({ data, selectPeriodic }) => {
  const userAnalytics = useSelector((state) => state.userAnalytics);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const dispatch = useDispatch();
  return (
    <div className="mt-4">
      <EditIconCMS
        handleOpen={() => {
          dispatch(
            dashboardModalOpenCMS({
              cms_key: "user_analytics",
              unique: "abandoned_user_head",
              heading: "Benutzer Analyse",
            })
          );
        }}
      />

      <TableContainer component={Paper} style={{ borderRadius: "12px", background: "#fff" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className="bg-AFPLightGray">
              <TableCell>{cmsDashboarddata?.user_analytics?.abandoned_user_head?.title}</TableCell>
              <TableCell align={"right"}>{cmsDashboarddata?.user_analytics?.abandoned_user_head?.status}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectPeriodic === "all" ? (
              data?.category?.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{item}</TableCell>
                    <TableCell align={"right"}>{data?.count[index]}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <>
                {userAnalytics?.abandoned_cart?.data?.map((data, index) => {
                  return (
                    <>
                      <Row key={index} data={data}></Row>
                    </>
                  );
                })}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const Row = ({ data }) => {
  const [open, setOpen] = useState(false);

  const totalCount = data?.count?.reduce((total, item) => total + item.count, 0);
  return (
    <>
      <TableRow>
        <TableCell>{data?.name}</TableCell>
        <TableCell align="right">
          {totalCount}
          <IconButton className="!p-0" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableBody>
                {data?.count?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item?.name}</TableCell>
                      <TableCell align="right">{item?.count}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default UserAnalyticsACBarChart;
