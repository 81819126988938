import "./headmenu.css";
import { Button, Grow, Paper, Popper } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { LiaUserCircleSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import MenuLists from "./MenuLists";

export default function HeaderDropdown({ profile }) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const navigate = useNavigate();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event, url) => {
    if (anchorRef?.current && anchorRef?.current?.contains(event?.target)) {
      return;
    }
    if (url) {
      if (profile?.role === "admin") {
        navigate("/admin" + url);
      } else {
        navigate(url);
      }
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen?.current === true && open === false) {
      anchorRef?.current?.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const buttonStyle = {
    "&:hover": {
      backgroundColor: "transparent",
    },
  };

  return (
    <>
      <div className="w-10 h-10 flexCenter border-SmokeyGray cursor-pointer">
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          sx={buttonStyle}
        >
          <LiaUserCircleSolid
            className={`text-[1.6rem] text-[#706F6F] hover:text-AFPPrimary dark:text-BrightGray dark:hover:text-AFPPrimary ${open ? "text-AFPPrimary" : "text-[#706F6F]"}`}
          />
        </Button>
      </div>
      {open && (
        <Popper open={open} anchorEl={anchorRef?.current} role={undefined} placement="bottom-start" transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom-start" ? "left top" : "right top",
              }}
            >
              <Paper className="bg-white !rounded-2xl !shadow-xl border border-BrightGray dark:bg-AFPDarkBgLight dark:border-SmokeyGray">
                <MenuLists handleClose={handleClose} handleListKeyDown={handleListKeyDown} profile={profile} />
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
}

HeaderDropdown.defaultProps = {
  profile: { first_name: "", last_name: "" },
};
