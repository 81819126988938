import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccountListRequest } from "src/store/actions/userSettings";
import FilterField from "src/components/molecules/dform/FilterField";

function LegalInformation({ data, step_id }) {
  const [currentHelperText, setCurrentHelperText] = useState("");
  const accountList = useSelector((state) => state.settings);
  const cmsDocument = useSelector((state) => state.cmsDocument);
  const stepsCMS = cmsDocument?.dFormCMS?.data;
  const dispatch = useDispatch();
  useEffect(() => {
    if (!accountList.loading) {
      dispatch(fetchAccountListRequest());
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".evidence-section");
      let currentSection = { key: "", position: 100 };

      sections?.forEach((section) => {
        const rect = section?.getBoundingClientRect();
        if (rect?.top <= 400 && rect?.bottom >= 10) {
          const key = section?.getAttribute("data-key");
          const disable = section?.getAttribute("data-disable");
          if (!disable) {
            currentSection = { key, position: rect.top };
          }
        }
      });

      setCurrentHelperText(currentSection.key);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="flex justify-between">
      <div className="flex-1">
        <div className="text-black relative my-3 sm:my-4 md:my-6 min-h-screen dark:!text-BrightGray">
          <div className="mx-3 sm:mx-4 md:mx-6 mt-0 p-0 ">
            <div className="flex w-full justify-between items-start">
              <div className="w-full">
                {data.map((section, i) => {
                  const showCard = currentHelperText === section.id || (currentHelperText === "" && i === 0);
                  return (
                    <div className="flex evidence-section w-full gap-x-4 mb-8" data-key={section.id} data-disable={section?.helperTextDisable} key={i}>
                      <div className={`flex-1 ${showCard && "relative"}`}>
                        <FilterField data={section} css="!text-xl" showCard={showCard} step_id={step_id} helperText={stepsCMS[section?.id] && stepsCMS[section?.id]?.helpdesk} />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LegalInformation;
