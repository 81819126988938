import { cmsContentListFailure, cmsContentListSuccess, cmsContentUpdateFailure, cmsContentUpdateSuccess, cmsDFormContentListFailure, cmsDFormContentListSuccess, dashboardModalCloseCMS, getDFormCMSbyIdFailure, getDFormCMSbyIdSuccess } from "src/store/actions/cmsDashboard";
import { UPDATE_CMS_CONTENT, UPDATE_DFORM_CMS_BYID, getCMS, getCMS_DForm, getCMS_DForm_By_Id } from "../requests/cmsDashboard";
import toast from 'react-hot-toast'
import { put } from "redux-saga/effects";
import Cookies from "js-cookie";

export function* updateCMSContentHandler(action) {
    try {
      const response = yield UPDATE_CMS_CONTENT(action.payload);
      if (response.status) {
        toast.success(response?.message);
        yield put(cmsContentUpdateSuccess(response))
        yield put(dashboardModalCloseCMS(response))
      } else {
        yield put(
          cmsContentUpdateFailure({
            message: response?.message || "Etwas ist schief gelaufen!",
          })
        );
      }
    } catch (error) {
      yield put(cmsContentUpdateFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
    }
  }

  export function* fetchCMS() {
    try {
      const paramsData = {
        queryParams: {
          user_id: Cookies.get("userId"),
        },
        isAuthorized: true,
      };
      const data = yield getCMS(paramsData);
      yield put(cmsContentListSuccess(data));
    } catch (error) {
      yield put(cmsContentListFailure(error));
    }
  }

  export function* fetchCMS_DForm(action) {
    const queryParams = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    try {
      const response = yield getCMS_DForm(queryParams);
      yield put(cmsDFormContentListSuccess(response.data));
    } catch (error) {
      yield put(cmsDFormContentListFailure(error));
    }
  }

  export function* fetchCMS_DFormById(action) {
    const queryParams = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    try {
      const response = yield getCMS_DForm_By_Id(queryParams);
      if (response.status) {
        yield put(getDFormCMSbyIdSuccess({content: response?.data?.formData, step: response?.data?.formHead}));
      } else {
        yield put(getDFormCMSbyIdFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        }));
      }
    } catch (error) {
      yield put(getDFormCMSbyIdFailure(error));
    }
  }

  export function* updateDFormCMSbyIdHandler(action) {
    try {
      const response = yield UPDATE_DFORM_CMS_BYID(action.payload);
      if (response.status) {
        toast.success(response?.message);
        yield put(cmsContentUpdateSuccess(response))
        // yield put(dashboardModalCloseCMS(response))
      } else {
        yield put(
          cmsContentUpdateFailure({
            message: response?.message || "Etwas ist schief gelaufen!",
          })
        );
      }
    } catch (error) {
      yield put(cmsContentUpdateFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
    }
  }