import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useParams, useNavigate } from "react-router-dom";
import { validateDocTabs } from "src/helpers/setting";
import { PrimaryBodyTab, PrimaryHeadTab, PrimaryHeadTabDark } from "src/components/atoms/tabs";
import { useDispatch, useSelector } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";

export default function DocumentTab({ tabList, changeOccure = () => {} }) {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const { id } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    console.log("event: " + event);
    validateDocTabs(tabList, id, setValue, navigate, profile?.data?.role, newValue);
  };

  useEffect(() => {
    validateDocTabs(tabList, id, setValue, navigate, profile?.data?.role);
    changeOccure(id);
  }, [id]);

  return (
    <>
      <Box sx={{ width: "100%", padding: 2 }}>
        <EditIconCMS
          css="float-right"
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "document",
                unique: "tab",
                heading: "Settings",
              })
            );
          }}
        />
        <div className="dark:hidden">
          <PrimaryHeadTab handleChange={handleChange} value={value} tabList={tabList.tabs} />
        </div>
        <div className="hidden dark:block">
          <PrimaryHeadTabDark handleChange={handleChange} value={value} tabList={tabList.tabs} />
        </div>
        <PrimaryBodyTab value={value} tabList={tabList.tabs} />
      </Box>
    </>
  );
}
