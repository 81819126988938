const {
  ADD_BUSINESS_PREMISES,
  LIST_BUSINESS_PREMISES,
  LIST_UPDATE_BUSINESS_PREMISES,
  DETAIL_BUSINESS_PREMISES,
  DETAIL_SET_BUSINESS_PREMISES,
  BUSINESS_PREMISES_FAILURE,
  UPDATE_BUSINESS_PREMISES,
  DELETE_BUSINESS_PREMISES,
  UPDATE_BUSINESS_PREMISES_SUCCESS,
  CREATED_BUSINESS_PREMISES,
  CREATED_RESET_BUSINESS_PREMISES,
  SET_CONTACT_LIST,
  CLEAR_CONTACT_LIST,
  LIST_CONTACT,
  GET_PROFESSIONAL_GROUP,
  SET_PROFESSIONAL_GROUP,
  FAILURE_PROFESSIONAL_GROUP,
  GET_LEADER_BY_LOCATION_STARTED,
  GET_LEADER_BY_LOCATION_SUCCESS,
  GET_LEADER_BY_LOCATION_FAILURE,
  ADD_LEADER_BY_LOCATION_STARTED,
  ADD_LEADER_BY_LOCATION_SUCCESS,
  ADD_LEADER_BY_LOCATION_FAILURE,
  OWNER_BY_LOCATION_STARTED,
  OWNER_BY_LOCATION_SUCCESS,
  OWNER_BY_LOCATION_FAILURE,
  ADD_OWNER_BY_LOCATION_STARTED,
  ADD_OWNER_BY_LOCATION_SUCCESS,
  ADD_OWNER_BY_LOCATION_FAILURE,
  SET_CHECK_NEW_LEADER,
  SET_CHECK_NEW_LOCATION,
  GET_ALL_BUSINESS_FAILURE,
  GET_ALL_BUSINESS_SUCCESS,
  GET_ALL_BUSINESS_STARTED,
} = require("../actionTypes");

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  newUser: null,
  detail: null,
  contactList: null,
  checkNewLeader: false,
  checkNewLocation: false,
  professionalGroup: { isLoading: false, error: false, data: null },
  leaderList: { isLoading: false, error: false, data: null, locationId: "" },
  leaderNew: { isLoading: false, error: false, data: null },
  ownerByLocation: { isLoading: false, error: false, data: null },
  addOwnerByLocation: { isLoading: false, error: false, data: null },
  allLocation: { isLoading: false, error: false, data: null },
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BUSINESS_PREMISES: {
      return { ...state, newUser: action.payload, isLoading: true };
    }
    case CREATED_BUSINESS_PREMISES: {
      return { ...state, isLoading:false, newUser: { created: true } };
    }
    case CREATED_RESET_BUSINESS_PREMISES: {
      return { ...state, newUser: null };
    }
    case LIST_BUSINESS_PREMISES: {
      return { ...state, isLoading: true, list: null, detail: null };
    }
    case LIST_UPDATE_BUSINESS_PREMISES: {
      return { ...state, isLoading: false, list: action?.payload, detail: null };
    }
    case DETAIL_BUSINESS_PREMISES: {
      return { ...state, isLoading: true, detail: null };
    }
    case DETAIL_SET_BUSINESS_PREMISES: {
      return { ...state, isLoading: false, detail: action.payload };
    }
    case UPDATE_BUSINESS_PREMISES: {
      return { ...state, isLoading: true };
    }
    case UPDATE_BUSINESS_PREMISES_SUCCESS: {
      return { ...state, isLoading: false };
    }
    case DELETE_BUSINESS_PREMISES: {
      return { ...state, isLoading: true };
    }
    case BUSINESS_PREMISES_FAILURE: {
      return { ...state, isLoading: false, error: "error", contactList: null };
    }
    case LIST_CONTACT: {
      return { ...state, contactList: { isLoading: true } };
    }
    case SET_CONTACT_LIST: {
      return { ...state, contactList: { data: action.payload, isLoading: false } };
    }
    case CLEAR_CONTACT_LIST: {
      return { ...state, contactList: null };
    }
    case GET_PROFESSIONAL_GROUP: {
      return { ...state, professionalGroup: { ...state.professionalGroup, isLoading: true } };
    }
    case SET_PROFESSIONAL_GROUP: {
      return { ...state, professionalGroup: { ...state.professionalGroup, isLoading: false, data: action.payload } };
    }
    case GET_LEADER_BY_LOCATION_STARTED: {
      return { ...state, leaderList: { isLoading: true, error: false, data: null, locationId: action.payload?.location_id } };
    }
    case GET_LEADER_BY_LOCATION_SUCCESS: {
      return { ...state, leaderList: { ...state.leaderList, isLoading: false, error: false, data: action.payload } };
    }
    case GET_LEADER_BY_LOCATION_FAILURE: {
      return { ...state, leaderList: { isLoading: false, error: false, data: null, locationId: "" } };
    }
    case ADD_LEADER_BY_LOCATION_STARTED: {
      return { ...state, leaderNew: { isLoading: true, error: false, data: null } };
    }
    case ADD_LEADER_BY_LOCATION_SUCCESS: {
      return { ...state, leaderNew: { isLoading: false, error: false, data: null } };
    }
    case ADD_LEADER_BY_LOCATION_FAILURE: {
      return { ...state, leaderNew: { isLoading: false, error: false, data: null } };
    }
    case OWNER_BY_LOCATION_STARTED: {
      return { ...state, ownerByLocation: { ...state.ownerByLocation, isLoading: true, error: false, data: null } };
    }
    case OWNER_BY_LOCATION_SUCCESS: {
      return { ...state, ownerByLocation: { ...state.ownerByLocation, isLoading: false, error: false, data: action.payload } };
    }
    case OWNER_BY_LOCATION_FAILURE: {
      return { ...state, ownerByLocation: { ...state.ownerByLocation, isLoading: false, error: false, data: null } };
    }
    case FAILURE_PROFESSIONAL_GROUP: {
      return { ...state, professionalGroup: { ...state.professionalGroup, isLoading: false, error: true } };
    }
    case ADD_OWNER_BY_LOCATION_STARTED: {
      return { ...state, addOwnerByLocation: { ...state.addOwnerByLocation, isLoading: true, error: false, data: action.payload } };
    }
    case ADD_OWNER_BY_LOCATION_SUCCESS: {
      return { ...state, addOwnerByLocation: { ...state.addOwnerByLocation, isLoading: false, error: false, data: null } };
    }
    case ADD_OWNER_BY_LOCATION_FAILURE: {
      return { ...state, addOwnerByLocation: { ...state.addOwnerByLocation, isLoading: false, error: true } };
    }
    case SET_CHECK_NEW_LEADER: {
      return { ...state, checkNewLeader: action.payload };
    }
    case SET_CHECK_NEW_LOCATION: {
      return { ...state, checkNewLocation: action.payload };
    }
    case GET_ALL_BUSINESS_STARTED: {
      return { ...state, allLocation: { isLoading: true, data: null } };
    }
    case GET_ALL_BUSINESS_SUCCESS: {
      return { ...state, allLocation: { isLoading: false, error: false, data: action.payload } };
    }
    case GET_ALL_BUSINESS_FAILURE: {
      return { ...state, allLocation: { isLoading: false, error: true,  } };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
