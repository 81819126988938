import React, { useEffect, useState } from "react";
import { SecondaryButton } from "src/components/atoms/button";
import Vector from "src/assets/svg/Vector.svg";
import VectorDark from "src/assets/svg/VectorDark.svg";
import { FaCheck } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { saveEvidenceDBStarted, saveEvidenceERPStarted } from "src/store/actions/evidence";
import toast from "react-hot-toast";
import { saveEvidenceERPPayloadModify } from "src/helpers/evidence";
import BackdropButton from "src/components/atoms/button/BackdropButton";
import { isMobile, isTablet, browserName } from "react-device-detect";

const EvidenceFooter = ({ markAllValid, scrollIntoView }) => {
  const navigate = useNavigate();
  const { service } = useParams();
  const evidence = useSelector((state) => state.evidence);
  const profile = useSelector((state) => state.profile);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const [submittedStatus, setSubmittedStatus] = useState(false);
  const dispatch = useDispatch();
  const saveHandle = () => {
    let device_type = "PC";
      if (isMobile && !isTablet) {
        device_type = "Smartphone";
      } else if (isTablet) {
        device_type = "Tablet";
      }
    dispatch(
      saveEvidenceDBStarted({
        procedure_id: evidence?.evidenceDetail?.details?.id,
        service_order_id: service,
        evidence_data: evidence?.evidenceDetail?.data,
        creator_user: profile.data.first_name + " " + profile.data.last_name,
        actionType: "db",
        device_type: device_type,
        browser: browserName,
      })
    );
  };

  const saveERPHandle = () => {
    scrollIntoView()
    const allValuesTrue = Object.values(markAllValid).every((value) => value === true);
    const payloadModify = saveEvidenceERPPayloadModify({ service_order_type_id: service, data: evidence?.evidenceDetail?.details?.procedure_data });
    if (allValuesTrue) {
      dispatch(
        saveEvidenceERPStarted({
          procedure_id: evidence?.evidenceDetail?.details?.id,
          service_order_id: evidence?.evidenceDetail?.details?.erp_service_order_id,
          evidence_data: evidence?.evidenceDetail?.data,
          creator_user: profile?.data?.first_name + " " + profile?.data?.last_name,
          ...payloadModify,
        })
      );
    } else {
      toast.error("Bestätigen und markieren Sie die Beweise");
    }
  };

  useEffect(() => {
    if (evidence?.uploadEvidenceERP?.welcome == "proceed") {
      navigate("/evidence-welcome/" + service + "/" + evidence?.evidenceDetail?.details?.erp_service_order_id);
    }
  }, [evidence]);

  useEffect(() => {
    const allValuesTrue = Object.values(markAllValid).every((value) => value === true);
    if (allValuesTrue) {
      if (submittedStatus === false) {
        setSubmittedStatus(allValuesTrue);
      }
    } else {
      if (submittedStatus === true) {
        setSubmittedStatus(allValuesTrue);
      }
    }
  }, [evidence]);

  return (
    <div className="flex items-center mt-10 gap-4">
      <SecondaryButton
        icon={<><img src={Vector} alt="Vector" className="dark:hidden" /><img src={VectorDark} alt="Vector" className="hidden dark:inline" /></>}
        variant={"secondary"}
        title={cmsDashboarddata?.evidence_cms?.Evidence?.save_exit}
        handler={saveHandle}
        css="w-full md:w-64 h-12 border-black text-black font-tahoma-bold dark:text-BrightGray"
        loading={evidence?.saveEvidenceDB?.isLoading && evidence?.saveEvidenceDB?.actionType == "db"}
      />
      <BackdropButton
        icon={<FaCheck />}
        // disable={submittedStatus === false ? true : false}
        title={cmsDashboarddata?.evidence_cms?.Evidence?.submit}
        btnStyle="w-full md:max-w-[180px] h-12"
        position="after"
        handler={saveERPHandle}
        loading={evidence?.saveEvidenceERP?.isLoading || (evidence?.uploadEvidenceERP?.evidenceType == "pdf" && evidence?.uploadEvidenceERP?.isLoading)}
      />
    </div>
  );
};

export default EvidenceFooter;
