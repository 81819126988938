/* eslint-disable */
import { useState } from "react";
import { useSelector } from "react-redux";
import RegisterForm from "src/components/organisms/register/RegisterForm";
import RegisterSuccess from "src/components/organisms/register/RegisterSuccess";
import Register from "src/components/template/Register";

const InvitedSignup = () => {
  const users = useSelector((state)=>state.users)

  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem("hs_theme");
    return savedTheme === "dark" || (!savedTheme && window.matchMedia("(prefers-color-scheme: dark)").matches);
  });

  return (
    <div className="flexCenter min-h-screen bg-black dark:bg-AFPDarkBgLight">
      {!users?.userRegistered ? (
        <Register>
           <RegisterForm isDarkMode={isDarkMode}/> 
        </Register>
        ) : (
        <div className="min-h-[96vh] md:min-h-[80vh] w-[96%] md:w-[92%] lg:w-[88%] my-2 md:my-4 lg:my-8 flex justify-center items-center bg-white rounded-xl p-4 dark:bg-AFPDarkBgDarkest">
          <RegisterSuccess />
        </div>
      )} 
    </div>
  );
};

export default InvitedSignup;
