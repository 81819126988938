import React from "react";
import { PrimaryButton, SecondaryButton } from "src/components/atoms/button";
import { Box, Modal } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { cmsContentUpdate, dashboardModalCloseCMS } from "src/store/actions/cmsDashboard";
import TextAreaFeildCms from "src/components/atoms/input/TextAreaFeildCms";

const MessageModal = ({ open, cmsData }) => {

  const dispatch = useDispatch();
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const formik = useFormik({
    initialValues: cmsData.data,
    onSubmit: (values) => {
      let cmsNewData = cmsDashboarddata[cmsData.cms_key];
      cmsNewData[cmsData.unique] = values;
      let { cms_key, ...rest } = cmsNewData;
      dispatch(cmsContentUpdate({ cms_key, cms_data: rest }));
    },
  });
  const handleClose = () => {
    dispatch(dashboardModalCloseCMS());
  };
  return (
    <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box className="outline-none standardmodal">
        <div className="w-full max-h-[90vh] overflow-y-auto overflow-x-hidden customScrollBar p-1">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl md:text-2xl font-tahoma-bold dark:text-BrightGray">{cmsData.heading}</h2>
            <AiOutlineClose size={24} className="text-SmokeyGray cursor-pointer -mt-2 dark:text-BrightGray" onClick={handleClose} />
          </div>
          <form className={"flex flex-col gap-4 mb-2 justify-between min-h-48"} onSubmit={formik.handleSubmit}>
            <div className={`grid gap-6 ${Object.entries(cmsData.data)?.length > 1 ? "grid-cols-1 md:grid-cols-2" : "md:grid-cols-1"}`}>
              {Object.entries(cmsData.data)?.map(([key, value]) => {
                return <TextAreaFeildCms key={key} id={key} type="text" formik={formik} extraLabel="!ml-0" placeholder={value} label={value} extra="w-full  !items-start" />;
              })}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-6">
              <PrimaryButton title="Speichern" type="submit" loading={cmsDashboarddata?.isLoading} />
              <SecondaryButton variant={`${cmsDashboarddata?.isLoading ? "disabled" : "secondary"}`} title="Abbrechen" handler={handleClose} />
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default MessageModal;
