import React from "react";

const CardDoc = ({ children, css }) => {
  return <div className={`flex flex-col  w-48 h-56  rounded-xl border border-AFPDisabled shadow-md bg-white hover:shadow-xl duration-300 cursor-pointer dark:bg-AFPDarkBgLight dark:border-SmokeyGray ${css}`}>{children}</div>;
};

CardDoc.defaultProps = {
  css: "",
  children: null,
};

export default CardDoc;
