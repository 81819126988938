import UserAnalyticsAbandonedCart from "src/components/organisms/UserAnalyticsChart/UserAnalyticsAbandonedCart";
import UserAnalyticsAverageChart from "src/components/organisms/UserAnalyticsChart/UserAnalyticsAverageChart";
import UserAnalyticsBChartWrap from "src/components/organisms/UserAnalyticsChart/UserAnalyticsBChartWrap";
import UserAnalyticsEvidence from "src/components/organisms/UserAnalyticsChart/UserAnalyticsEvidence";
import UserAnalyticsLoginTimes from "src/components/organisms/UserAnalyticsChart/UserAnalyticsLoginTimes";
import UserAnalyticsSessionChart from "src/components/organisms/UserAnalyticsChart/UserAnalyticsSessionChart";
import UserAnalyticsSource from "src/components/organisms/UserAnalyticsChart/UserAnalyticsSource";
import UserAnalyticsStatistik from "src/components/organisms/UserAnalyticsChart/UserAnalyticsStatistik";
import de from 'apexcharts/dist/locales/de.json';

export const userAnalyticsList = [
  UserAnalyticsSessionChart,
  UserAnalyticsAverageChart,
  UserAnalyticsBChartWrap,
  UserAnalyticsAbandonedCart,
  UserAnalyticsLoginTimes,
  UserAnalyticsSource,
  UserAnalyticsEvidence,
  UserAnalyticsStatistik,
];

export const optionsLoginTimePeriodic = [
  {
    title: "wöchentlich",
    value: "weekly",
  },
  {
    title: "monatlich",
    value: "monthly",
  },
  {
    title: "Jährlich",
    value: "yearly",
  },
];

export const optionsLoginTime = [
  {
    title: "Gesamt",
    value: "all",
  },
  {
    title: "wöchentlich",
    value: "weekly",
  },
  {
    title: "monatlich",
    value: "monthly",
  },
  {
    title: "Jährlich",
    value: "yearly",
  },
];

export const optionsLoginTimeDaily = [
  {
    title: "Gesamt",
    value: "all",
  },
  {
    title: "Täglich",
    value: "days",
  },
  {
    title: "wöchentlich",
    value: "weekly",
  },
  {
    title: "monatlich",
    value: "monthly",
  },
  {
    title: "Jährlich",
    value: "yearly",
  },
];

export function transformData(inputData) {
  let newDAta = Object.keys(inputData).map((key) => ({
    name: key,
    data: inputData[key],
  }));
  return newDAta;
}

export const ACBarTooltip = (periodic) => {
  switch (periodic) {
    case "all":
      return {
        enabled: true,
        theme: "dark",
        custom: function ({ series, seriesIndex, dataPointIndex }) {
          return `<div style="padding: 8px;">${series[seriesIndex][dataPointIndex]}</div>`;
        },
      };
    case "monthly":
      return {
        y: {
          formatter: function (val) {
            return val.toString().replace('.', ',');
          },
        },
      };
    default:
      return {
        y: {
          formatter: function (val) {
            return val.toString().replace('.', ',');
          },
        },
      };
  }
};

export const periodicCountExtractor = (analyticData, selectPeriodic, defaultSelection) => {
  let category = [];
  let count = [];
  let periodicCount = {};
  if (analyticData) {
    analyticData?.forEach((data) => {
      category.push(data?.name);
      if (selectPeriodic == defaultSelection) {
        count.push(data?.count);
      } else {
        data?.count.forEach((countData) => {
          if (periodicCount[countData?.name]) {
            periodicCount[countData?.name] = [...periodicCount[countData?.name], countData?.count];
          } else {
            periodicCount[countData?.name] = [countData?.count];
          }
        });
      }
    });
    return { category, count: selectPeriodic == defaultSelection ? count : transformData(periodicCount) };
  } else {
    return null;
  }
};

export const useUserActivity = (pages, sub_pages, isMobile, isTablet, browserName) => {
  let device_type = "PC";
  if (isMobile && !isTablet) {
    device_type = "Smartphone";
  } else if (isTablet) {
    device_type = "Tablet";
  }

  const payload = {
    device_type,
    browser: browserName,
    pages,
    sub_pages,
  };
  return payload;
};

export const currentRegisterOptions = (data) => {
  return {
    chart: {
      width: 380,
      type: "pie",
      locales: [de],
      defaultLocale: 'de',
      toolbar: {
        export: {
          csv: {
            columnDelimiter: ';',          
            valueFormatter(y) {
              return y.toString().replace('.', ',');
            }
          }
        },
        show: true, 
        tools: {
          download: true, 
        },
      },
    },
    labels: data?.labels,
    dataLabels: {
      enabled: true,
      formatter: function (val, opts) {
        return opts?.w?.globals?.series[opts?.seriesIndex];
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          const total = data?.series?.reduce((a, b) => a + b, 0);
          const percentage = ((val / total) * 100)?.toFixed(2);
          return `${ percentage.toString().replace('.', ',')}%`;
        },
      },
    },
    plotOptions: {
      pie: {
        dataLabels: {
          minAngleToShowLabel: 5,
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
};

export const globalChartOptions = (data, customOption) => {
  let dataLabels = customOption?.dataLabels
    ? customOption.dataLabels
    : {
        enabled: true,
      };
  return {
    chart: {
      type: "bar",
      height: 350,
      locales: [de],
      defaultLocale: 'de',
      toolbar: {
        export: {
          csv: {
            columnDelimiter: ';',
            valueFormatter(y) {
              return y.toString().replace('.', ',');
            }
          }
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: data?.category?.length > 1 ? "55%" : '56px',
        endingShape: "rounded",
      },
    },
    dataLabels: dataLabels,
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: data?.category || [],
      labels: {
        show: true,
        hideOverlappingLabels: false,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: getTooltip(customOption.tooltipID, customOption?.selectPeriodic),
    ...customOption.extra,
  };
};

const getTooltip = (id, selectPeriodic) => {
  switch (id) {
    case "LoginSession":
      return {
        enabled: true,
        theme: "dark",
        custom: function ({ series, seriesIndex, dataPointIndex }) {
          const value = series[seriesIndex][dataPointIndex];
          if (value == null || isNaN(value) || value <= 0) {
            return '<div style="padding: 8px;">0</div>';
          }

          const hours = Math.floor(value);
          const minutes = Math.round((value - hours) * 60);

          let tooltipContent = "";
          if (hours > 0) {
            tooltipContent += `${hours} Stunde${hours > 1 ? "s" : ""}`;
          }
          if (minutes > 0) {
            if (tooltipContent) {
              tooltipContent += " ";
            }
            tooltipContent += `${minutes} minute${minutes > 1 ? "s" : ""}`;
          }
          return `<div style="padding: 8px;">${tooltipContent || "0"}</div>`;
        },
      };
    case "RegisterCount":
      return {
        enabled: true,
        theme: "dark",
        custom: function ({ series, seriesIndex, dataPointIndex }) {
          return `<div style="padding: 8px;">${series[seriesIndex][dataPointIndex]}</div>`;
        },
      };

    case "RegisterTimes":
      return {
        enabled: true,
        theme: "dark",
        custom: function ({ series, seriesIndex, dataPointIndex }) {
          return `<div style="padding: 8px;">${series[seriesIndex][dataPointIndex]}</div>`;
        },
      };

    case "AbortedApplication":
      return ACBarTooltip(selectPeriodic);

    default:
      return {
        enabled: true,
        theme: "dark",
        custom: function ({ series, seriesIndex, dataPointIndex }) {
          return `<div style="padding: 8px;">${series[seriesIndex][dataPointIndex]}</div>`;
        },
      };
  }
};

export const timeConvertionSessionInHours = (value) => {
  if (value == null || isNaN(value) || value <= 0) {
    return "0";
  }

  const hours = Math.floor(value);
  const minutes = Math.round((value - hours) * 60);

  let tooltipContent = "";
  if (hours > 0) {
    tooltipContent += `${hours} Stunde${hours > 1 ? "s" : ""}`;
  }
  if (minutes > 0) {
    if (tooltipContent) {
      tooltipContent += " ";
    }
    tooltipContent += `${minutes} minute${minutes > 1 ? "s" : ""}`;
  }
  return `${tooltipContent || ""}`;
};
