/*eslint-disable*/

import React from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import vector3 from "src/assets/img/vector3.png";
import vector3Dark from "src/assets/img/vector3-dark.png";
import Checkbox from "@mui/material/Checkbox";
import { formatDate } from "src/helpers/document";
import { BsFiletypeXml, BsFileZip } from "react-icons/bs";
import { API, BASE_URL } from "src/services/utils/defines";
import Cookies from "js-cookie";
import { green } from "@mui/material/colors";

const DocumentTableBody = ({ bodyContent, selectionHandler, selectedItem }) => {
  const downloadNewHandler = (url) => {
    const link = window.document.createElement("a");
    link.href = `${BASE_URL}${API?.DOWNLOAD_DMS_DOCUMENT}?user_id=${Cookies.get("userId")}&document_id=${url}`;
    link.download = "document";
    link.style.display = "none";
    window.document.body.appendChild(link);
    window.open(link.href, "_blank");
    setTimeout(() => {
      window.document.body.removeChild(link);
    }, 100);
  };

  return (
    <TableBody>
      {bodyContent?.map((item, index) => {
        return (
          <TableRow
            key={index}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              td: {
                borderBottom: "none",
                paddingLeft: "20px",
              },
              th: {
                borderBottom: "none",
              },
              background: index % 2 === 0 ? "#FBFBFB" : "inherit",
            }}
          >
            <TableCell>
              <Checkbox
                checked={selectedItem?.find((selected) => selected?.id == item?.id)?.checked ? true : false}
                onChange={() => {
                  selectionHandler(item?.id);
                }}
                sx={{
                  padding: 0,
                  margin: 0,
                  color: "#484848",
                  "&.Mui-checked svg": {
                    color: `${green[600]}`,
                  },
                }}
              />
            </TableCell>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.category}</TableCell>
            <TableCell>{item.location_name}</TableCell>
            <TableCell>{item.service_order_type}</TableCell>
            <TableCell>{formatDate(item.created_at)}</TableCell>

            <TableCell
              align="center"
              style={{
                minWidth: "60px",
                marginLeft: "20px",
              }}
            >
              <div
                className="text-SmokeyGray flex justify-center"
                onClick={() => {
                  downloadNewHandler(item?.id);
                }}
              >
                {(item.file_type == "PDF" && <><img src={vector3} className="cursor-pointer dark:hidden" /><img src={vector3Dark} className="cursor-pointer hidden dark:inline" /></>) ||
                  (item.file_type == "XML" && <BsFiletypeXml fontSize={24} className=" cursor-pointer" />) || 
                  (item.file_type == "ZIP" && <BsFileZip fontSize={24} className=" cursor-pointer" />)}
              </div>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

DocumentTableBody.defaultProps = {
  bodyContent: [],
};
export default DocumentTableBody;
