import { Paper, TableContainer } from '@mui/material'

const tableStyles = {
    backgroundColor: '#1E1E1E',
    // boxShadow: '0px 4px 15px rgba(255, 255, 255, 0.5)', // White shadow
    '& thead': {
        backgroundColor: '#333333',
        color: '#ffffff', // Header text color
    },
    '& tbody': {
        backgroundColor: '#1E1E1E',
        color: '#ffffff', // Body text color
    },
    '& tbody tr:nth-of-type(odd)': {
        backgroundColor: '#282828', // Style for odd rows
    },
    '& tbody tr:nth-of-type(even)': {
        backgroundColor: '#333333', // Style for even rows
    },
    // '& tbody tr:hover': {
    //     backgroundColor: '#444', // Hover effect
    // },
    '& th, & td': {
        // padding: '8px',
        // textAlign: 'left',
        color: '#ffffff', // Text color for cells
    },
    '& svg': {
        color: '#ffffff', // Icon color (assuming you're using SVG icons)
    },
};




const TableContainerDark = ({children}) => {
  return (
    <>
    <TableContainer component={Paper} className="dark:hidden">{children}</TableContainer>
    <TableContainer component={Paper} sx={tableStyles} className="hidden dark:block border border-SmokeyGray">{children}</TableContainer>
    </>
  )
}

export default TableContainerDark