import "./registersuccess.css";
import { useNavigate } from "react-router-dom";
import Logo from "src/assets/img/newLogo.png";
import LogoDark from "src/assets/img/darkLogo.png";
import { SubmitButton } from "src/components/atoms/button";
import PasswordToggle from "src/components/atoms/extra/PasswordToggle";
import { authReset} from "src/store/actions/users";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

const RegisterSuccess = () => {
  const users = useSelector((state)=>state.users)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const registerHandle = () => {
    navigate("/login");
    dispatch(authReset());
  };
  useEffect(()=>{
    localStorage.removeItem("register_tabs");
  },[])
  return (
    <>
      <div className="flex flex-col justify-around items-center min-h-[80vh] w-full p-1 md:px-[6%] md:py-[4%]">
        <div className="flexCenter w-full flex-col gap-3">
          <img src={Logo} alt="logo" className="dark:hidden" />
          <img src={LogoDark} alt="logo" className="dark:block hidden" />
          <h3 className="registerHeading">Registrierung erfolgreich</h3>
          <h4 className="text-center text-md text-SmokeyGray dark:text-BrightGray">
            Sie haben sich erfolgreich registriert!
          </h4>
        </div>
        <div className="registerdetail border-y">
          <div className="registerSuccessDivider">
            <h4 className="font-tahoma-bold text-black dark:text-BrightGray">Ihre Benutzerdaten:</h4>
          </div>
          <div className="col-span-1 rounded-br-xl rounded-tr-xl">
            <h4 className="invisible">:</h4>
            <h4>Benutzername:</h4>
            <h4 className="my-1">Passwort:</h4>
          </div>
          <div className="col-span-1 rounded-br-xl rounded-tr-xl ">
            <h4 className="invisible">:</h4>
            <h4 className="whitespace-nowrap overflow-hidden overflow-ellipsis">{users?.usercreated?.email}</h4>
            <PasswordToggle password={users?.usercreated?.password} />
          </div>
        </div>
        <div className="w-full flex justify-end border-b mb-2">
          <h4 className="w-full md:w-[60%] lg:w-[68%] xl:w-[40%] 2x:w-[66%] my-4">
          Ihre persönlichen Anmeldedaten können Sie jederzeit in Ihren Account-Einstellungen einsehen und bearbeiten.
          </h4>
        </div>
        <div className="w-full flex justify-end">
          <SubmitButton
            css="md:w-[300px] w-full"
            type="submit"
            btnTitle="Zum Login"
            loading={false}
            handler={registerHandle}
          />
        </div>
      </div>
    </>
  );
};

export default RegisterSuccess;
