import React from "react";
import { userAnalyticsList } from "src/helpers/useranalytics";

const UserAnalyticsChart = () => {
  return (
    <>
      <div class="grid grid-cols-1 xl:grid-cols-2 gap-8 mx-4 pb-8">
        {userAnalyticsList.map((Component, index) => (
          <UserAnalyticsContainer key={index}>
            <Component />
          </UserAnalyticsContainer>
        ))}
      </div>
    </>
  );
};

export const UserAnalyticsContainer = ({ children }) => {
  return (
    <div class="bg-white p-4 dark:opacity-80 dark:text-black">
      <div className="max-w-screen-md">{children}</div>
    </div>
  );
};

export default UserAnalyticsChart;
