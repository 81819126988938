/* eslint-disable */
import React, { useEffect, useState } from "react";
import Line1 from "src/assets/img/Line1.png";
import { useDispatch, useSelector } from "react-redux";
import { ownerByLocationStarted } from "src/store/actions/business";
import { addCurrentStepDetailValue } from "src/store/actions/currentStep";
import SearchDropDown2 from "src/components/atoms/Search/SearchDropDown/SearchDropDown2";
import { CircularProgress } from "@mui/material";
import { CiCirclePlus } from "react-icons/ci";
import ContactSignatureCanvas from "src/components/molecules/signature/ContactSignatureCanvas";
import { SecondaryButton } from "src/components/atoms/button";
import { useParams } from "react-router-dom";
import { particularApplicationSignatureReset } from "src/store/actions/application";

const SignatureDropdown = ({ data, stepsCMS }) => {
  const [fachleiterLocation, setFachleiterLocation] = useState([]);
  const business = useSelector((state) => state.management);
  const application = useSelector((state) => state.application);
  const [selectedLeader, setSelectedLeader] = useState([]);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const currentStep = useSelector((state) => state.currentStep);
  const applicationSignature = useSelector((state) => state.application.contactSignature.data);
  const { id } = useParams();

  const [openSignature, setOpenSignature] = useState(false);
  const handleClose = () => {
    setOpenSignature(false);
  };
  const handleOpen = () => {
    setOpenSignature(true);
  };

  useEffect(() => {
    let location_id = application?.data && application?.data["02_FIRMA"]?.find((location) => location.id === "02_FIRMA_BUSINESS_PREMISES");
    dispatch(ownerByLocationStarted({ location_id: location_id?.inputTypeValue }));
  }, []);

  useEffect(() => {
    let group = business?.ownerByLocation?.data;
    let location = group?.map((contact) => {
      return {
        title: contact?.title + " " + contact?.first_name + " " + contact?.last_name,
        value: contact?.contact_id,
      };
    });
    if (location && location.length > 0) {
      if(id === 'IWA'){
        let newInhaber = application?.data && application?.data["02_FIRMA"]?.find((data) => data?.id === "GROUP_OWNERSHIP");
        setSearch(newInhaber?.inputTypeValue);
        dispatch(
          addCurrentStepDetailValue({
            valueId: data.id,
            value: newInhaber?.inputTypeValue,
            valueKey: "inputTypeValue",
          })
        );
      } else {
        setSearch(location[0].value);
        dispatch(
          addCurrentStepDetailValue({
            valueId: data.id,
            value: location[0].value,
            valueKey: "inputTypeValue",
          })
        );
      }
    }
    setFachleiterLocation(location);
  }, [business?.ownerByLocation?.data]);

  const handleChange = (event, value) => {
    setSelectedLeader(value);
    if (value) {
      let contact_id = value.map((v) => v.id);
      if (contact_id) {
        dispatch(
          addCurrentStepDetailValue({
            valueId: data.id,
            value: contact_id,
            valueKey: "inputTypeValue",
          })
        );
      }
    }
  };

  useEffect(() => {
    let inhaaberSelected = currentStep?.data?.find((current) => current?.id == "05_SIGNATURE_OWNER");
    if (inhaaberSelected?.inputTypeValue) {
      if (!search) {
        setSearch(inhaaberSelected?.inputTypeValue);
      }
      /** if user have inhaber detail then set in dropdown */
    } else if (id == "IWA") {
      let firma02 = application?.data["02_FIRMA"].find((firma) => firma.id === "GROUP_OWNERSHIP");
      setSearch(firma02?.inputTypeValue);
      dispatch(
        addCurrentStepDetailValue({
          valueId: data.id,
          value: firma02?.inputTypeValue,
          valueKey: "inputTypeValue",
        })
      );
    }
  }, []);
  const clearExistingSignature = () => {
    if (search in applicationSignature) {
      dispatch(particularApplicationSignatureReset({ id: search }));
    }
  };
  return (
    <>
      {business?.ownerByLocation?.isLoading && (
        <div className="text-center">
          <CircularProgress sx={{ color: "#A31D1A" }} />
        </div>
      )}
      <div>
        {application?.dformStatus?.data?.data?.status !== "signatures-completed" &&
          application?.dformStatus?.data?.data?.status !== "completed" &&
          !business?.ownerByLocation?.isLoading &&
          fachleiterLocation?.length > 0 && (
            <SearchDropDown2 data={data} optionsList={fachleiterLocation} id="salutation" selectedVal={search} handleChange={(item) => setSearch(item)} />
          )}
        {!search ? <span className="text-red-400 text-sm">* Pflichtfeld</span> : null}
      </div>
      <>
        {search && <h1 className="text-black text-lg font-tahoma-bold mb-5 dark:text-BrightGray">Inhaber: {fachleiterLocation?.find((select) => select?.value == search)?.title}</h1>}
        {search && (
          <>
            {application?.dformStatus?.data?.data?.SignatureList?.find((signature) => signature?.contact_id == search) || application?.contactSignature?.data[search] ? (
              <div className="border border-AFPLightGray w-full flex flex-col justify-center items-center min-h-[259px] dark:border-SmokeyGray">
                <img
                  src={
                    application?.dformStatus?.data?.data?.SignatureList?.find((signature) => signature?.contact_id == search)?.signature_image ||
                    application?.contactSignature?.data[search] ||
                    Line1
                  }
                  alt="Signature"
                  className="mb-4"
                />
              </div>
            ) : (
              <div className="border border-AFPLightGray w-full md:w-[841px] h-[259px] flex flex-col justify-center items-center cursor-pointer dark:bg-AFPDarkBgLight" onClick={handleOpen}>
                <div className="mb-6">
                  <CiCirclePlus className="text-[4rem] cursor-pointer" />
                </div>
                <p className="text-black textStyle max-w-[540px]">Klicken um zu unterschreiben</p> <img src={Line1} alt="Line" />
              </div>
            )}
          </>
        )}
        {(application?.dformStatus?.data?.data?.SignatureList?.length > 0 &&
        application?.dformStatus?.data?.data?.SignatureList?.find((signature) => signature?.contact_id == search)
          ? false
          : application?.contactSignature?.data[search]
          ? application?.contactSignature?.data[search]
          : false) && (
          <div className="flex justify-center mb-4">
            <SecondaryButton variant="secondary" title={"Zurücksetzen"} css={"w-full max-w-[200px]"} handler={clearExistingSignature} />
          </div>
        )}
        {openSignature && (
          <ContactSignatureCanvas
            openSignature={openSignature}
            handleClose={handleClose}
            item={{ contact_id: search, first_name: fachleiterLocation?.find((select) => select?.value == search)?.title, last_name: "" }}
          />
        )}
      </>
    </>
  );
};

export default SignatureDropdown;
