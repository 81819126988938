import { CircularProgress } from "@mui/material";

const SecondaryButton = (props) => {
  const { title, variant, css, handler, icon, loading, after=false } = props;
  const variantcss =
    variant === "disabled"
      ? "text-SilverSand bg-transparent border-SmokeyGray cursor-not-allowed"
      : variant === "secondary"
      ? "text-AFPPrimary bg-transparent border-AFPPrimary hover:text-white hover:!bg-AFPPrimary hover:font-tahoma-bold dark:text-AFPPrimaryLight dark:hover:text-white dark:!font-normal"
      : variant === "warning"
      ? "text-black bg-transparent border-black hover:font-tahoma-bold dark:text-BrightGray"
      : variant === "sms"
      ? "text-[#005576] bg-transparent border-[#005576] w-[209px] text-sm h-[35px]"
      : "";
  return (
    <button
      type="button"
      onClick={handler}
      disabled={loading}
      className={`tracking-wide rounded-xl py-2 text-md flexCenter gap-1 border hover:bg-transparent duration-300 ${variantcss} ${css}`}
    >
      {loading ? (
        <CircularProgress size={18} sx={{ color: "#ee9593" }} />
      ) : (
        <>
          {after ===false && icon}
          {title}
          {after ===true && icon}
        </>
      )}
    </button>
  );
};

SecondaryButton.defaultProps = {
  title: "Button",
  css: "",
  variantcss: "",
  icon: null,
  loading: false,
  variant: "secondary",
  type: "button",
  handler: function () {},
};

export default SecondaryButton;
