/* eslint-disable */
import { ListItemIcon, MenuItem } from "@mui/material";
import React, { useEffect } from "react";
import { CiLight } from "react-icons/ci";
import { FaRegMoon } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { themSwitch } from "src/store/actions/theme";

const ThemeSwitchList = ({ title, handleClose }) => {
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.theme.darkmode);

  const toggleTheme = () => {
    dispatch(themSwitch(!isDarkMode));
  };

  useEffect(() => {
    const html = document.documentElement;
    if (isDarkMode) {
      localStorage.setItem("hs_theme", "dark");
      html.classList.add("dark");
      html.classList.remove("light");
      dispatch(themSwitch(true));
    } else {
      localStorage.setItem("hs_theme", "light");
      html.classList.remove("dark");
      html.classList.add("light");
      dispatch(themSwitch(false));
    }
  }, [isDarkMode]);

  useEffect(() => {
    const savedTheme = localStorage.getItem("hs_theme");
    const prefersDark = window.matchMedia("(prefers-color-scheme: dark)").matches;

    if (savedTheme === "dark" || (savedTheme === "auto" && prefersDark)) {
      dispatch(themSwitch(true));
    } else {
      dispatch(themSwitch(false));
    }
  }, []);

  return (
    <>
      <MenuItem
        onClick={() => {
          toggleTheme();
          setTimeout(() => handleClose(), 60);
        }}
        className="!text-gray-500 hover:text-gray-800 hover:bg-BrightGray !border-t-2 !border-SilverSand !border-solid !font-semibold dark:!text-BrightGray dark:hover:!text-AFPPrimaryLight dark:border-SmokeyGray"
      >
        <ListItemIcon className="bg-gray-200 rounded-full hover:bg-gray-300 dark:bg-gray-200 dark:hover:bg-gray-100 !min-w-0">
          {isDarkMode ? (
            <CiLight className="text-black text-[19px] rounded-full hover:text-AFPPrimary p-[2px]" />
          ) : (
            <FaRegMoon className="text-black text-[19px] rounded-full hover:text-AFPPrimary p-[2px]" />
          )}
        </ListItemIcon>
        <span className="pl-2">{isDarkMode ? title?.light_mode : title?.dark_mode}</span>
      </MenuItem>
    </>
  );
};

export default ThemeSwitchList;
