import { useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import style from "./inputStandardField.module.css";
const InputStandardField = (props) => {
  const {
    label,
    id,
    extra,
    type,
    placeholder,
    disabled,
    extraLabel,
    extraInput,
    formik,
    editable,
    onChangeHandle,
  } = props;

  const { touched, errors, setFieldValue, values } = formik;

  const [editableS, setEditableS] = useState(!editable);

  const handleChange = (event) => {
    const { value } = event.target;
    if (onChangeHandle) {
      onChangeHandle(id, value);
    }
    setFieldValue(id, value);
  };

  return (
    <div className={`${style.wrapper} ${extra}`}>
      <label htmlFor={id} className={`${style.inputLabel} ${extraLabel} dark:text-BrightGray`}>
        {label}
      </label>
      <div className={`w-full ${editable && style.editableContainer}`}>
        <input
          disabled={!editableS || disabled}
          type={type}
          id={id}
          name={id}
          placeholder={placeholder}
          onChange={handleChange} 
          value={values[id]} 
          className={`${style.input} ${extraInput} dark:!bg-AFPDarkBgLight dark:text-BrightGray dark:border-SmokeyGray ${disabled && "!bg-gray-100 opacity-40"} ${
            editableS ? "bg-white" : "bg-AFPBGPrimary"
          } ${touched[id] && errors[id] && "!border-red-500 !bg-gray-100 "}`}
        />
        {editable && (
          <AiFillEdit
            onClick={() => {
              !disabled && setEditableS(!editableS);
            }}
            size={16}
            className={`${
              editableS ? "text-SeaGreen" : "text-SmokeyGray"
            } cursor-pointer`}
          />
        )}
        {!editable && touched[id] && errors[id] && (
          <div className="text-red-500 text-sm px-3 py-1">{errors[id]}</div>
        )}
      </div>
    </div>
  );
};

InputStandardField.defaultProps = {
  label: "",
  id: "inputField",
  extra: "",
  type: "text",
  placeholder: "",
  disabled: false,
  extraLabel: "",
  extraInput: "",
  formik: {
    touched: {},
    errors: {},
    setFieldValue: () => {},
    values: {},
  },
  editable: false,
  onChangeHandle: () => {},
};

export default InputStandardField;
