import React, { useEffect, useState } from "react";
import { PrimaryButton, SecondaryButton } from "src/components/atoms/button";
import { useDispatch, useSelector } from "react-redux";
import FachleiterModal from "./FachleiterModal";
import { getProfessionalGroups, setCheckAddLeader } from "src/store/actions/business";
import toast from "react-hot-toast";
import { addCurrentStepDetailValue } from "src/store/actions/currentStep";
import { useSearchParams } from "react-router-dom";
import BackdropLoader from "src/components/atoms/loader/BackdropLoader";
import AutoCompleteLabelWrap from "src/components/atoms/input/AutoCompleteField/AutoCompleteLabelWrap";

const DropDownTag = ({ data, handleHover, stepsCMS }) => {
  const business = useSelector((state) => state?.management);
  const application = useSelector((state) => state?.application);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const [fachleiterLocation, setFachleiterLocation] = useState([]);
  const [isFachleiterModal, setFachleiterModal] = useState(false);
  const [selectedAids, setSelectedAids] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars
  const currentStepParam = searchParams.get("active");
  const locationDetailForm = JSON.parse(localStorage.getItem("locationDetailForm"));

  const handleMouseEnter = () => {
    if (typeof handleHover === "function") {
      handleHover(data);
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    const group = business?.leaderList?.data;
    const location = group?.map((contact) => {
      return {
        name: contact?.title + " " + contact?.first_name + " " + contact?.last_name,
        id: contact?.contact_id,
      };
    });
    setFachleiterLocation(location);

    if (location) {
      let selectedFachiler = data?.inputTypeValue?.length > 0 ? data?.inputTypeValue : "";
      let filterFachillers = selectedFachiler ? location?.filter((fachiliter) => selectedFachiler?.includes(fachiliter?.id)) : [];

      if (business.checkNewLeader && location?.length > 0) {
        const latestLeader = location[location.length - 1];
        filterFachillers = [...filterFachillers, latestLeader];
        dispatch(setCheckAddLeader(false));
      }

      if (filterFachillers?.length > 0) {
        setSelectedAids(filterFachillers);
        const contact_id = filterFachillers?.map((v) => v?.id);
        if (contact_id) {
          dispatch(
            addCurrentStepDetailValue({
              valueId: data?.id,
              value: contact_id,
              valueKey: "inputTypeValue",
            })
          );
        }
      }
    }

    if (application?.locationValid?.data && location && !(data?.inputTypeValue?.length > 0)) {
      if (application?.locationValid?.data?.exsiting_team_leaders?.length > 0) {
        const selectedLead = application?.locationValid?.data?.exsiting_team_leaders;
        const filterFachillers = location?.filter((fachiliter) => selectedLead?.includes(parseInt(fachiliter?.id)));
        setSelectedAids(filterFachillers);
        const contact_id = filterFachillers?.map((v) => v?.id);
        if (contact_id) {
          dispatch(
            addCurrentStepDetailValue({
              valueId: data.id,
              value: contact_id,
              valueKey: "inputTypeValue",
            })
          );
        }
      }
    }
    if (business?.leaderList?.isLoading) {
      setSelectedAids([]);
    }
  }, [business?.leaderList?.data, application?.locationValid?.data]);

  const handleOpen = () => {
    if (business?.leaderList?.locationId) {
      setFachleiterModal(true);
    } else {
      toast.error("Bitte wählen Sie den Geschäftssitz");
    }
  };
  const handleClose = () => {
    setFachleiterModal(false);
  };

  useEffect(() => {
    dispatch(getProfessionalGroups());
  }, []);

  const handleChange = (event, value) => {
    setSelectedAids(value);
    if (value) {
      const contact_id = value?.map((v) => v?.id);
      if (contact_id) {
        dispatch(
          addCurrentStepDetailValue({
            valueId: data?.id,
            value: contact_id,
            valueKey: "inputTypeValue",
          })
        );
      }
    }
  };

  return (
    <>
      {application?.locationValid?.data?.is_valid && (
        <div className="card mb-12" data-id={data?.id} onMouseEnter={handleMouseEnter}>
          <h1 className="text-xl font-tahoma-bold pt-7 text-black dark:text-BrightGray">{stepsCMS[data?.id]?.title}</h1>
          {business?.leaderList?.locationId ? (
            <>
              <div className="flex gap-6 pt-8 flex-col lg:flex-row w-full max-w-[760px]">
                {business?.leaderList?.isLoading || business?.allLocation?.isLoading ? (
                  <div className="w-96 flex-1 text-center">
                    <BackdropLoader open={business?.leaderList?.isLoading || business?.allLocation?.isLoading} />
                  </div>
                ) : (
                  <>
                    <div className="rounded-[12px] border border-AFPLightGray searchDropdown relative text-black w-full max-w-[390px] dark:text-BrightGray dark:bg-AFPDarkBgLight dark:border-SmokeyGray">
                      {fachleiterLocation?.length > 0 && (
                        <AutoCompleteLabelWrap
                          selectedPersonOption={selectedAids}
                          LegalPersonList={fachleiterLocation}
                          handleChange={handleChange}
                          isDisabled={!application?.locationValid?.data?.is_valid}
                        />
                      )}
                    </div>
                    <div className={`w-[180px] max-w-[300px] lg:w-[250px] ${locationDetailForm && currentStepParam != 2 ? "opacity-50" : ""}`}>
                      {!application?.locationValid?.data?.is_valid ? (
                        <SecondaryButton title={cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.create_new} css="w-full" variant="disabled" />
                      ) : (
                        <PrimaryButton
                          title={cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.create_new}
                          btnStyle="w-full"
                          handler={() => {
                            if (locationDetailForm && currentStepParam != 2) { // eslint-disable-line no-unused-vars
                            } else {
                              handleOpen();
                            }
                          }}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </>
          ) : (
            <p className="text-red-500 mt-8">Bitte wählen Sie Betriebsstätte aus</p>
          )}
          {!selectedAids.length > 0 && business?.leaderList?.locationId && !(business?.leaderList?.isLoading || business?.allLocation?.isLoading) ? (
            <span className="text-red-400 text-sm pt-1">* Pflichtfeld</span>
          ) : null}
          {isFachleiterModal && !business?.professionalGroup?.isLoading && business?.leaderList?.locationId && (
            <FachleiterModal handleClose={handleClose} handleOpen={isFachleiterModal} />
          )}
        </div>
      )}
    </>
  );
};

export default DropDownTag;
