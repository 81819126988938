/*eslint-disable*/
import { useFormik } from "formik";
import { Box, Modal } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import NewFormInhaber from "src/components/atoms/modal/AddBusinessModal/NewFormInhaber";
import { useDispatch, useSelector } from "react-redux";
import { VALIDATIONSCHEMAINHABER } from "src/services/validation/buinessPremises";
import { addOwnerByLocationStarted } from "src/store/actions/business";

const AddNewInhaverForm = ({ handleClose, handleOpen, addinhaberHandler, locationId }) => {
  const style = {
    p: 2,
    top: "50%",
    left: "50%",
    width: "90%",
    boxShadow: 24,
    maxWidth: "600px",
    // bgcolor: "#FBFBFB",
    borderRadius: "12px",
    position: "absolute",
    border: "1px solid #CBCBCB",
    transform: "translate(-50%, -50%)",
  };
  const business = useSelector((state) => state.management);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: { first_name: "", last_name: "", title: "", salutation: "Male", email: "", location_id: locationId },
    validationSchema: VALIDATIONSCHEMAINHABER,
    onSubmit: (values) => {
      addinhaberHandler({ ...values, status: "add" });
      dispatch(addOwnerByLocationStarted({ ...values, location_id: business?.leaderList?.locationId }));
      handleClose();
      formik.resetForm();
    },
  });

  return (
    <Modal open={handleOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style} className="outline-none bg-AFPBGPrimary dark:bg-AFPDarkBgDarkest dark:border-SmokeyGray">
        <div className={`max-h-[90vh] overflow-y-auto overflow-x-hidden customScrollBar w-full p-1`}>
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl md:text-2xl font-tahoma-bold dark:text-BrightGray">neu anlegen Inhaber</h2>
            <AiOutlineClose size={24} className="text-SmokeyGray cursor-pointer -mt-2 dark:text-BrightGray" onClick={handleClose} />
          </div>
          <NewFormInhaber formik={formik} />
        </div>
      </Box>
    </Modal>
  );
};

AddNewInhaverForm.defaultProps = {
  handleClose: () => {},
  handleOpen: false,
  addinhaberHandler: () => {},
  initialValues: null,
  isLoading: false,
};

export default AddNewInhaverForm;
