import { useEffect, useState } from "react";
import FirstRegisterTab from "./FirstRegisterTab";
import SecondRegisterTab from "./SecondRegisterTab";
import ThirdRegisterTab from "./ThirdRegisterTab";
import RegisterStepper from "./RegisterStepper";
import './registerform.css'
import { useDispatch } from "react-redux";
import { getCMSbyKeyStarted } from "src/store/actions/users";

const RegisterFormTabs = ({isDarkMode }) => {
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch()

  const tabs = [
    { title: "Tab 1", content: FirstRegisterTab },
    { title: "Tab 2", content: SecondRegisterTab },
    { title: "Tab 3", content: ThirdRegisterTab },
  ];

  const handleNext = (values, activeTab, next) => {
    saveDB(values, activeTab);
    if (next) {
      if (activeTab < tabs.length - 1) {
        setActiveTab((prev) => prev + 1);
      }
    }
  };

  const handleBack = () => {
    if (activeTab > 0) {
      setActiveTab((prev) => prev - 1);
    }
  };

  const saveDB = (values, activeTab) => {
    let registerTab = JSON.parse(localStorage.getItem("register_tabs"));
    if (registerTab) {
      let preinitialValues = registerTab && registerTab.find((item) => item.activetab === activeTab)?.data;
      if (preinitialValues) {
        let preExistedValues = registerTab.map((item) => {
          if (item.activetab === activeTab) {
            return {
              activetab: activeTab,
              data: values,
            };
          }
          return item;
        });
        localStorage.setItem("register_tabs", JSON.stringify(preExistedValues));
      } else {
        localStorage.setItem(
          "register_tabs",
          JSON.stringify([
            ...registerTab,
            {
              activetab: activeTab,
              data: values,
            },
          ])
        );
      }
    } else {
      localStorage.setItem(
        "register_tabs",
        JSON.stringify([
          {
            activetab: activeTab,
            data: values,
          },
        ])
      );
    }
  };

  const ActiveComponent = tabs[activeTab].content;

  useEffect(() => {
    dispatch(getCMSbyKeyStarted({cms_key:'legal'}))
  },[])

  return (
    <>
      <RegisterStepper activeStep={activeTab} isDark={isDarkMode}/>
      <ActiveComponent handleBack={handleBack} handleNext={handleNext} activeTab={activeTab} tabs={tabs} />
    </>
  );
};

export const ValuesValidateDB = (activeTab) => {
  let registerTab = JSON.parse(localStorage.getItem("register_tabs"));
  let preinitialValues = registerTab && registerTab.find((item) => item.activetab === activeTab)?.data;
  return preinitialValues;
};

export default RegisterFormTabs;
