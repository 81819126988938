import { IoListSharp } from "react-icons/io5";
import { FaChartArea } from "react-icons/fa";

const ToggleView = ({view,handleView}) => {
  return (
    <div className="flex items-center justify-end gap-4">
        <FaChartArea
          className={`text-3xl cursor-pointer ${view === "chart" && "text-AFPPrimary"}`}
          onClick={() => {
            handleView("chart");
          }}
        />
        <IoListSharp
          className={`text-3xl cursor-pointer ${view === "list" && "text-AFPPrimary"}`}
          onClick={() => {
            handleView("list");
          }}
        />
      </div>
  )
}

export default ToggleView