/* eslint-disable */
import { useFormik } from "formik";
import { InputStandardField } from "src/components/atoms/input";
import InputWithDropDown from "src/components/atoms/input/InputLabelFiled/InputWithDropDown";
import RegisterGrid from "src/components/molecules/RegisterGrid";
import { salutationOptions } from "src/mockData/register";
import { useSelector, useDispatch } from "react-redux";
import { setProfile } from "src/store/actions/settings";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import BackdropSubmit from "src/components/atoms/button/BackdropSubmit";
import AgreementList from "./AgreementList";

const PersonalForm = () => {
  const profile = useSelector((state) => state.profile);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const cmsData = cmsDashboarddata?.settings;

  const dispatch = useDispatch();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: profile?.data?.email,
      salutation: profile?.data?.salutation,
      title: profile?.data?.title,
      first_name: profile?.data?.first_name,
      last_name: profile?.data?.last_name,
      company_name: profile?.data?.company_name,
      phone: profile?.data?.phone,
      data_protection_declaration: profile?.data?.user_agreements?.data_protection_declaration || true,
      terms_condition: profile?.data?.user_agreements?.terms_condition || true,
      certificate: profile?.data?.user_agreements?.certificate || true,
      declaration: profile?.data?.user_agreements?.declaration || true,
    },
    onSubmit: (values) => {
      const {data_protection_declaration, terms_condition, certificate, declaration, ...restValues} = values
      const newPayload = {
        ...restValues,
        user_agreements: {
          data_protection_declaration,
          terms_condition,
          certificate,
          declaration
        }
      }
      dispatch(setProfile({ ...newPayload, id: profile?.data?.id }));
    },
  });

  return (
    <>
      <p className="textStyle pl-0 lg:pl-1 flex items-center gap-x-3">
        {cmsData?.ps_note?.note}
        <EditIconCMS
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "settings",
                unique: "ps_note",
                heading: "Einstellungen",
              })
            );
          }}
        />
      </p>
      <form onSubmit={formik.handleSubmit}>
        <RegisterGrid heading={cmsData?.ps_email?.email} css={"pl-1 pt-4 w-full md:w-[96%] lg:w-[94%] 2xl:w-[92%]"} extraCss="grid-cols-1 lg:grid-cols-2 lg:gap-x-8 ">
          <InputStandardField
            formik={formik}
            extra="!items-start"
            extraLabel="w-full mt-3 lg:mt-0"
            extraInput="bg-white"
            placeholder={cmsData?.ps_email?.email}
            id="email"
            type="text"
            disabled={true}
            editable={false}
          />
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "settings",
                  unique: "ps_email",
                  heading: "Einstellungen",
                })
              );
            }}
          />
        </RegisterGrid>
        <hr className="dark:border-SmokeyGray"/>
        <EditIconCMS
          css="mt-3"
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "settings",
                unique: "ps_information",
                heading: "Einstellungen",
              })
            );
          }}
        />
        <RegisterGrid heading={cmsData?.ps_information?.information} css={"pl-1 w-full md:w-[96%] lg:w-[94%] 2xl:w-[92%]"} extraCss="grid-cols-1 lg:grid-cols-2 lg:gap-8">
          <InputWithDropDown
            formik={formik}
            label={cmsData?.ps_information?.salutation}
            placeholder={cmsData?.ps_information?.salutation}
            id="salutation"
            name="salutation"
            type="text"
            options={salutationOptions}
          />
          <InputStandardField
            formik={formik}
            label={cmsData?.ps_information?.title}
            placeholder={cmsData?.ps_information?.title}
            id="title"
            name="title"
            type="text"
            extra="!items-start"
            extraLabel="w-full mt-3 lg:mt-0 !ml-0"
            // editable={true}
          />
          <InputStandardField
            formik={formik}
            extra="!items-start"
            extraLabel="w-full mt-3 lg:mt-0 !ml-0"
            label={cmsData?.ps_information?.first_name}
            placeholder={cmsData?.ps_information?.first_name}
            id="first_name"
            type="text"
            // editable={true}
          />
          <InputStandardField
            formik={formik}
            extra="!items-start"
            extraLabel="w-full mt-3 lg:mt-0"
            label={cmsData?.ps_information?.last_name}
            placeholder={cmsData?.ps_information?.last_name}
            id="last_name"
            type="text"
            // editable={true}
          />
        </RegisterGrid>
        <hr className="dark:border-SmokeyGray"/>
        <EditIconCMS
          css="mt-3"
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "settings",
                unique: "ps_contact",
                heading: "Einstellungen",
              })
            );
          }}
        />
        <RegisterGrid heading={cmsData?.ps_contact?.phone} css={"pl-1 w-full md:w-[96%] lg:w-[94%] 2xl:w-[92%]"} extraCss="grid-cols-1 lg:grid-cols-2 lg:gap-x-8">
          <InputStandardField formik={formik} extra="!items-start" placeholder={cmsData?.ps_contact?.phone} id="phone" type="text" 
          // editable={true}
           />
        </RegisterGrid>
        <hr className="dark:border-SmokeyGray"/>
        <RegisterGrid heading={cmsData?.ps_contact?.company} css={"pl-1 w-full md:w-[96%] lg:w-[94%] 2xl:w-[92%]"} extraCss="grid-cols-1 lg:grid-cols-2 lg:gap-x-8">
          <InputStandardField formik={formik} extra="!items-start" placeholder={cmsData?.ps_contact?.company} id="company_name" type="text" 
          // editable={true} 
          />
        </RegisterGrid>
        <hr className="dark:border-SmokeyGray"/>
        <EditIconCMS
          css="mt-3"
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "settings",
                unique: "legal",
                heading: "Einstellungen",
              })
            );
          }}
        />
        <RegisterGrid heading={cmsData?.legal?.rechtliches} css={"pl-1 w-full md:w-[96%] lg:w-[94%] 2xl:w-[92%]"} extraCss="grid-cols-1 lg:grid-cols-2 lg:gap-x-8">
          <AgreementList formik={formik} />
        </RegisterGrid>
        <hr className="dark:border-SmokeyGray"/>
        <div className="flex gap-x-3 items-center mt-4">
          <div className="w-40 ">
            <BackdropSubmit css="flexCenter 2xl:mt-8 w-40" btnCss="!py-3 rounded-xl" type="submit" btnTitle={cmsData?.ps_submit_button?.title} loading={profile?.loading} disabled={!formik.dirty} />
          </div>
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "settings",
                  unique: "ps_submit_button",
                  heading: "Einstellungen",
                })
              );
            }}
          />
        </div>
      </form>
    </>
  );
};

export default PersonalForm;
