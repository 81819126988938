/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EvidenceFooter from "src/components/molecules/FilterEvidence/EvidenceFooter";
import EvidenceKey from "./EvidenceKey";
import EvidenceShowExpand from "src/components/atoms/card/Card/EvidenceShowExpand";
import BackdropLoader from "src/components/atoms/loader/BackdropLoader";
import { evidenceStaticDocumentsStarted } from "src/store/actions/evidence";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";

const isEvidenceComplete = (evidences, evidenceDetails) => {
  return evidences.every((evidence) => {
    const evidenceDetail = evidenceDetails[evidence.inventoryID.value];
    return evidenceDetail && evidence.evidenceType.fieldTpe === "checkbox"
      ? !!evidenceDetail === true
      : evidence.evidenceType.fieldTpe === "textarea"
      ? evidenceDetail?.length > 1
      : evidenceDetail?.length > 0;
  });
};

const isEvidenceCompleteScroll = (evidences, evidenceDetails, keys) => {
  for (let evidence of evidences) {
    const evidenceDetail = evidenceDetails[evidence.inventoryID.value];
    if (evidenceDetail) {
      if (evidence.evidenceType.fieldTpe === "checkbox") {
        if (!!evidenceDetail === true) {
        } else {
          return { status: false, value: evidence.inventoryID.value, keys: keys };
        }
      } else {
        if (evidence.evidenceType.fieldTpe === "textarea") {
          if (evidenceDetail?.length > 1) {
          } else {
            return { status: false, value: evidence.inventoryID.value, keys: keys };
          }
        } else {
          if (evidenceDetail?.length > 0) {
          } else {
            return { status: false, value: evidence.inventoryID.value, keys: keys };
          }
        }
      }
    } else {
      return { status: false, value: evidence.inventoryID.value, keys: keys };
    }
  }
};

const EvidenceWrapper = () => {
  const download = useSelector((state) => state.download);
  const evidence = useSelector((state) => state.evidence);
  let [miniAids, setMiniAids] = useState(false);
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState(0);
  const [checkDocument, setCheckDocument] = useState(false);
  const [markAllValid, setMarkAllValid] = useState({});
  const [currentHelperText, setCurrentHelperText] = useState("");
  const handleChange = (panel) => (event, isExpanded) => {
    let NextExpand = Object.entries(evidenceDetail)?.length - 1;
    setExpanded(isExpanded ? panel : NextExpand > parseInt(panel) ? parseInt(panel) + 1 : false);
  };
  const handleClick = (panel) => {
    setExpanded(panel);
  };

  const [evidenceDetail, setEvidenceDetail] = useState(null);

  useEffect(() => {
    if (evidence?.data) {
      let newObj = {};
      let markObj = {};
      evidence?.data?.forEach((ab) => {
        ab?.prerequisiteGroup?.value;
        if (newObj[ab?.prerequisiteGroup?.value]) {
          newObj[ab?.prerequisiteGroup?.value]?.push(ab);
        } else {
          newObj[ab?.prerequisiteGroup?.value] = [ab];
          markObj[ab?.prerequisiteGroup?.value] = false;
        }
      });

      setEvidenceDetail(newObj);
      setMarkAllValid(markObj);

      const firstKey = Object.keys(newObj)[0];
    }
  }, []);

  useEffect(() => {
    if (evidence?.evidenceStaticDocument?.status === "success" || evidence?.evidenceStaticDocument?.status === "failed") {
      if (evidenceDetail) {
        Object.entries(evidenceDetail).forEach(([key, value], index) => {
          const isChecked = isEvidenceComplete(value, evidence?.evidenceDetail?.data);
          isChecked && markAllValidHandle(key, isChecked);
        });
      }
    }
  }, [evidenceDetail, checkDocument]);

  useEffect(() => {
    if (evidence?.evidenceStaticDocument?.status === "success" || evidence?.evidenceStaticDocument?.status === "failed") {
      setCheckDocument(true);
    }
  }, [evidence?.evidenceStaticDocument?.data]);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".evidence-section");
      let currentSection = { key: "", position: 100 };

      sections?.forEach((section, index) => {
        const rect = section?.getBoundingClientRect();

        if (rect?.top <= 500 && rect?.bottom >= 10) {
          const key = section?.getAttribute("data-key");
          currentSection = { key, position: rect?.top };
        }
      });

      setCurrentHelperText(currentSection.key);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const markAllValidHandle = (markKey, markValue) => {
    let markAllValidNew = markAllValid;
    markAllValidNew[markKey] = markValue;
    setMarkAllValid(markAllValidNew);
  };

  const scrollIntoView = () => {
    let scrollIntoView = false;
    if (evidenceDetail) {
      Object.entries(evidenceDetail).forEach(([key, value], index) => {
        if (!scrollIntoView) {
          const isChecked = isEvidenceCompleteScroll(value, evidence?.evidenceDetail?.data, key);
          if (isChecked && isChecked.status == false) {
            let indexof = Object?.keys(evidenceDetail)?.indexOf(isChecked?.keys);
            scrollIntoView = true;
            (indexof || indexof === 0) && handleClick(indexof);
            setTimeout(() => {
              const errorElement = document.getElementById(isChecked?.value);
              if (errorElement) {
                errorElement.scrollIntoView({ behavior: "smooth" });
              }
            }, 600);
          }
        }
      });
    }
  };

  useEffect(() => {
    let inventoryIDs = evidence?.data
      ?.map((item) => {
        return item?.inventoryID?.value;
      })
      .join(",");
    const payload = {
      location_id: evidence?.evidenceDetail?.details?.location_id,
      evidence_ids: inventoryIDs,
    };
    dispatch(evidenceStaticDocumentsStarted(payload));
  }, []);

  return (
    <>
      {evidence?.evidenceStaticDocument?.isLoading ? (
        <div className="flex justify-center items-center min-h-[80vh]">
          <CustomCircularLoader />
        </div>
      ) : (
        <>
          <div className="flex w-full gap-6 lg:gap-x-10 min-h-[70vh] relative">
            <div className="flex gap-4 flex-col w-[100%]">
              {evidenceDetail &&
                Object.entries(evidenceDetail).map(([key, value], index) => {
                  return (
                    <div key={index} className="flex justify-between gap-4 ">
                      {checkDocument && (
                        <EvidenceKey
                          miniAids={miniAids}
                          entriesKey={key}
                          value={value}
                          handleChange={handleChange}
                          expanded={expanded}
                          id={index}
                          markAllValidHandle={markAllValidHandle}
                          checkDocument={checkDocument}
                        />
                      )}
                      {value.map((item, i) => {
                        const showCard = currentHelperText == key + "-" + i;
                        return (
                          showCard && (
                            <EvidenceShowExpand
                              key={i}
                              showCard={showCard}
                              page={"dform"}
                              miniAids={miniAids}
                              setMiniAids={setMiniAids}
                              aid={"evidence001"}
                              css={"mt-20"}
                              inventoryID={item.inventoryID.value}
                              helperText={item.helpText}
                            />
                          )
                        );
                      })}
                    </div>
                  );
                })}
            </div>
          </div>
          <EvidenceFooter markAllValid={markAllValid} scrollIntoView={scrollIntoView} />
          <BackdropLoader open={download?.evidenceFileDownload?.isLoading || evidence?.deleteEvidenceERP?.isLoading} borderRadius={0} />
        </>
      )}
    </>
  );
};

export default EvidenceWrapper;
