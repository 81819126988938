import { MdDashboard, MdOutlineInbox, MdOutlineLocationCity, MdOutlineSettings, MdOutlineHelpOutline, MdOutlineWindow, MdEmail } from "react-icons/md";
import { GrCircleQuestion, GrDocumentText } from "react-icons/gr";
import { RiFileUploadLine, RiBankLine } from "react-icons/ri";
import inboxImg from "src/assets/svg/inbox-outline.svg";
import { IoMdDocument } from "react-icons/io";
import companyImg from "src/assets/svg/carbon_location-company.svg";
import certificateImg from "src/assets/svg/carbon_certificate-check.svg";
import { FaUsers } from "react-icons/fa";
import { AiOutlineDownload } from "react-icons/ai";
import { FaRegCircleUser } from "react-icons/fa6";
import { IoDocument } from "react-icons/io5";
import { GoChecklist } from "react-icons/go";
import { PiDotsThreeOutline } from "react-icons/pi";
import { IoMdAnalytics } from "react-icons/io";
import { SiLinksys } from "react-icons/si";

export const dashboardPortal = (doc, setting, packages, client, help) => {
  return [
    {
      id: 1,
      cmsId: "document",
      icon: <IoMdDocument size={24} className="text-BrightYellow" />,
      title: doc.heading,
      content: doc?.text,
      url: "/dokumente/afpq",
      stats: doc?.stats
    },
    {
      id: 2,
      cmsId: "setting",
      icon: <MdOutlineSettings size={24} className="text-BrightYellow" />,
      title: setting?.heading,
      content: setting?.text,
      url: "einstellungen/persoenliche-daten",
      stats: setting?.stats
    },
    {
      id: 5,
      cmsId: "help",
      icon: <GrCircleQuestion size={24} className="text-BrightYellow" />,
      title: help?.heading,
      content: help?.text,
      url: "/hilfe",
      stats: help?.stats
    },
  ];
};

export const messageList = (title1, title2, title3) => [
  {
    id: 1,
    title: title1,
    img: inboxImg,
  },
  {
    id: 2,
    title: title2,
    img: companyImg,
  },
  {
    id: 3,
    title: title3,
    img: certificateImg,
  },
];

export const business = (businessLocation) => {
  let result = [];
  result.push({
    id: businessLocation?.primary?.id,
    title: businessLocation?.primary?.name,
    date: businessLocation?.primary?.date,
    locationId: businessLocation?.primary?.locationId,
    css: "text-AFPPrimary",
  });
  if (businessLocation?.secondary) {
    result.push({
      id: businessLocation.secondary?.id,
      title: businessLocation.secondary?.name,
      date: businessLocation.secondary?.date,
      locationId: businessLocation.secondary?.locationId,
      css: "text-SeaGreen",
    });
  }
  return result;
};

export const inbox = (text1) => {
  return [
    {
      id: 1,
      title: text1,
      icon: <MdEmail className="text-AFPPrimary" />,
    },
  ];
};

export const manageApp = (dynamicForm) => {
  return dynamicForm
    ? {
        title: dynamicForm?.serviceOrderType,
        subTitle: dynamicForm?.name + " : " + dynamicForm?.date,
        formId: dynamicForm?.formId,
      }
    : {};
};

export const navList = (data) => {
  return [
    {
      id: 1,
      title: data?.home_page,
      icon: [<MdDashboard className="mr-2 text-xl text-AFPPrimary" />, <MdDashboard className="mr-2 text-xl" />],
      link: "/",
      click: "/",
    },
    {
      id: 2,
      title: data?.inbox,
      icon: [<MdOutlineInbox className="mr-2 text-xl text-AFPPrimary" />, <MdOutlineInbox className="mr-2 text-xl" />],
      link: ["/posteingang", "/posteingang/new"],
      click: "/posteingang",
    },
    {
      id: 3,
      title: data?.location,
      icon: [<MdOutlineLocationCity className="mr-2 text-xl text-AFPPrimary" />, <MdOutlineLocationCity className="mr-2 text-xl" />],
      link: ["/betriebsstaetten", "/betriebsstaetten/new"],
      click: "/betriebsstaetten",
    },
    {
      id: 4,
      title: data?.applications,
      icon: [<GoChecklist className="mr-2 text-xl text-AFPPrimary" />, <GoChecklist className="mr-2 text-xl" />],
      link: ["/verfahren/uebersicht", "/verfahren/abgeschlossen"],
      click: "/",
      subLink: true,
      menu: [
        {
          id: 41,
          title: data?.overview,
          icon: [<MdOutlineWindow className="mr-2 text-md text-AFPPrimary" />, <MdOutlineWindow className="mr-2 text-md" />],
          link: ["/verfahren/uebersicht", "/uebersicht"],
          click: "/verfahren/uebersicht",
        },
        {
          id: 42,
          title: data?.completed_procedure,
          icon: [<GrDocumentText className="mr-2 text-AFPPrimary" />, <GrDocumentText className="mr-2" />],
          link: ["/verfahren/abgeschlossen", "/abgeschlossen"],
          click: "/verfahren/abgeschlossen",
        },
      ],
    },
    {
      id: 5,
      title: data?.document,
      icon: [<IoDocument className="mr-2 text-xl text-AFPPrimary" />, <IoDocument className="mr-2 text-xl" />],
      link: ["/dokumente/afpq", "/dokumente/eigene"],
      click: "/",
      subLink: true,
      menu: [
        {
          id: 51,
          title: data?.from_us,
          icon: [<AiOutlineDownload className="mr-2 text-xl text-AFPPrimary" />, <AiOutlineDownload className="mr-2 text-xl" />],
          link: ["/dokumente/afpq", "/afpq"],
          click: "/dokumente/afpq",
        },
        {
          id: 52,
          title: data?.uploaded_by,
          icon: [<RiFileUploadLine className="mr-2 text-xl text-AFPPrimary" />, <RiFileUploadLine className="mr-2 text-xl" />],
          link: ["/dokumente/eigene", "/eigene"],
          click: "/dokumente/eigene",
        },
      ],
    },
    {
      id: 6,
      title: data?.setting,
      icon: [<MdOutlineSettings className="mr-2 text-xl text-AFPPrimary" />, <MdOutlineSettings className="mr-2 text-xl" />],
      link: ["/einstellungen/persoenliche-daten", "/einstellungen/zahlungsadressen", "/einstellungen/nutzerverwaltung", "/einstellungen/kennwort-aendern"],
      click: "/",
      subLink: true,
      menu: [
        {
          id: 61,
          title: data?.personal_data,
          icon: [<FaRegCircleUser className="mr-2 text-xl text-AFPPrimary" />, <FaRegCircleUser className="mr-2 text-xl" />],
          link: ["/einstellungen/persoenliche-daten", "/profile"],
          click: "/einstellungen/persoenliche-daten",
        },
        {
          id: 63,
          title: data?.bank_details,
          icon: [<RiBankLine className="mr-2 text-xl text-AFPPrimary" />, <RiBankLine className="mr-2 text-xl" />],
          link: ["/einstellungen/zahlungsadressen", "/bank"],
          click: "/einstellungen/zahlungsadressen",
        },
        {
          id: 62,
          title: data?.user_management,
          icon: [<FaUsers className="mr-2 text-xl text-AFPPrimary" />, <FaUsers className="mr-2 text-xl" />],
          link: ["/einstellungen/nutzerverwaltung", "/management"],
          click: "/einstellungen/nutzerverwaltung",
        },
        {
          id: 64,
          title: data?.change_password,
          icon: [<PiDotsThreeOutline className="mr-2 text-xl text-AFPPrimary" />, <PiDotsThreeOutline className="mr-2 text-xl" />],
          link: ["/einstellungen/kennwort-aendern", "/kennwort-aendern"],
          click: "/einstellungen/kennwort-aendern",
        },
      ],
    },
    {
      id: 7,
      title: data?.help_support,
      icon: [<MdOutlineHelpOutline className="mr-2 text-xl text-AFPPrimary" />, <MdOutlineHelpOutline className="mr-2 text-xl" />],
      link: ["/hilfe", "/hilfe"],
      click: "/hilfe",
    },
  ];
};
export const navListAdmin = (data) => {
  return [
    {
      id: 1,
      title: data?.home_page,
      icon: [<MdDashboard className="mr-2 text-xl text-AFPPrimary" />, <MdDashboard className="mr-2 text-xl" />],
      link: "/admin",
      click: "/admin",
    },
    {
      id: 201,
      title: data['user-analytics'],
      icon: [<IoMdAnalytics  className="mr-2 text-xl text-AFPPrimary" />, <IoMdAnalytics  className="mr-2 text-xl" />],
      link: ["/benutzer-analyse","/benutzer-analyse"],
      click: "/admin/benutzer-analyse",
    },
    {
      id: 2,
      title: data?.inbox,
      icon: [<MdOutlineInbox className="mr-2 text-xl text-AFPPrimary" />, <MdOutlineInbox className="mr-2 text-xl" />],
      link: ["/posteingang", "/posteingang/new"],
      click: "/admin/posteingang",
    },
    {
      id: 3,
      title: data?.location,
      icon: [<MdOutlineLocationCity className="mr-2 text-xl text-AFPPrimary" />, <MdOutlineLocationCity className="mr-2 text-xl" />],
      link: ["/betriebsstaetten", "/betriebsstaetten/new"],
      click: "/admin/betriebsstaetten",
    },
    {
      id: 4,
      title: data?.applications,
      icon: [<GoChecklist className="mr-2 text-xl text-AFPPrimary" />, <GoChecklist className="mr-2 text-xl" />],
      link: ["/verfahren/uebersicht", "/verfahren/abgeschlossen", "/verfahren/verfahren-cms", "/verfahren/nachweise-hochladen-cms"],
      click: "/",
      subLink: true,
      menu: [
        {
          id: 41,
          title: data?.overview,
          icon: [<MdOutlineWindow className="mr-2 text-md text-AFPPrimary" />, <MdOutlineWindow className="mr-2 text-md" />],
          link: ["/verfahren/uebersicht", "/uebersicht"],
          click: "/admin/verfahren/uebersicht",
        },
        {
          id: 42,
          title: data?.completed_procedure,
          icon: [<GrDocumentText className="mr-2 text-AFPPrimary" />, <GrDocumentText className="mr-2" />],
          link: ["/verfahren/abgeschlossen", "/abgeschlossen"],
          click: "/admin/verfahren/abgeschlossen",
        },
        {
          id: 43,
          title: data?.dynamic_form_cms,
          icon: [<GrDocumentText className="mr-2 text-AFPPrimary" />, <GrDocumentText className="mr-2" />],
          link: ["/verfahren/verfahren-cms", "/verfahren-cms"],
          click: "/admin/verfahren/verfahren-cms",
        },
        {
          id: 43,
          title: data?.evidence_form_cms,
          icon: [<GrDocumentText className="mr-2 text-AFPPrimary" />, <GrDocumentText className="mr-2" />],
          link: ["/verfahren/nachweise-hochladen-cms", "/nachweise-hochladen-cms"],
          click: "/admin/verfahren/nachweise-hochladen-cms",
        },
      ],
    },
    {
      id: 5,
      title: data?.document,
      icon: [<IoDocument className="mr-2 text-xl text-AFPPrimary" />, <IoDocument className="mr-2 text-xl" />],
      link: ["/dokumente/afpq", "/dokumente/eigene"],
      click: "/",
      subLink: true,
      menu: [
        {
          id: 51,
          title: data?.from_us,
          icon: [<AiOutlineDownload className="mr-2 text-xl text-AFPPrimary" />, <AiOutlineDownload className="mr-2 text-xl" />],
          link: ["/dokumente/afpq", "/afpq"],
          click: "/admin/dokumente/afpq",
        },
        {
          id: 52,
          title: data?.uploaded_by,
          icon: [<RiFileUploadLine className="mr-2 text-xl text-AFPPrimary" />, <RiFileUploadLine className="mr-2 text-xl" />],
          link: ["/dokumente/eigene", "/eigene"],
          click: "/admin/dokumente/eigene",
        },
      ],
    },
    {
      id: 6,
      title: data?.setting,
      icon: [<MdOutlineSettings className="mr-2 text-xl text-AFPPrimary" />, <MdOutlineSettings className="mr-2 text-xl" />],
      link: [
        "/einstellungen/persoenliche-daten",
        "/einstellungen/zahlungsadressen",
        "/einstellungen/nutzerverwaltung",
        "/einstellungen/kennwort-aendern",
        "/kennwort-aendern",
        "/nutzerverwaltung",
        "/zahlungsadressen",
        "/persoenliche-daten",
      ],
      click: "/admin/",
      subLink: true,
      menu: [
        {
          id: 61,
          title: data?.personal_data,
          icon: [<FaRegCircleUser className="mr-2 text-xl text-AFPPrimary" />, <FaRegCircleUser className="mr-2 text-xl" />],
          link: ["/einstellungen/persoenliche-daten", "/persoenliche-daten"],
          click: "/admin/einstellungen/persoenliche-daten",
        },
        {
          id: 63,
          title: data?.bank_details,
          icon: [<RiBankLine className="mr-2 text-xl text-AFPPrimary" />, <RiBankLine className="mr-2 text-xl" />],
          link: ["/einstellungen/zahlungsadressen", "/zahlungsadressen"],
          click: "/admin/einstellungen/zahlungsadressen",
        },
        {
          id: 62,
          title: data?.user_management,
          icon: [<FaUsers className="mr-2 text-xl text-AFPPrimary" />, <FaUsers className="mr-2 text-xl" />],
          link: ["/einstellungen/nutzerverwaltung", "/nutzerverwaltung"],
          click: "/admin/einstellungen/nutzerverwaltung",
        },
        {
          id: 64,
          title: data?.change_password,
          icon: [<PiDotsThreeOutline className="mr-2 text-xl text-AFPPrimary" />, <PiDotsThreeOutline className="mr-2 text-xl" />],
          link: ["/einstellungen/kennwort-aendern", "/kennwort-aendern"],
          click: "/admin/einstellungen/kennwort-aendern",
        },
      ],
    },
    {
      id: 8,
      title: data?.legal,
      icon: [<SiLinksys className="mr-2 text-xl text-AFPPrimary" />, <SiLinksys className="mr-2 text-xl" />],
      link: ["/rechtliches", "/Rechtliches/new"],
      click: "/admin/rechtliches",
    },
    {
      id: 7,
      title: data?.help_support,
      icon: [<MdOutlineHelpOutline className="mr-2 text-xl text-AFPPrimary" />, <MdOutlineHelpOutline className="mr-2 text-xl" />],
      link: ["/hilfe", "/hilfe"],
      click: "/admin/hilfe",
    },
  ];
};

