import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import PastContent from "src/components/organisms/PastTable";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import { getCompletedServiceOrderStarted } from "src/store/actions/serviceOrder";
import { isMobile, isTablet, browserName } from "react-device-detect";
import { userActivityOnStarted } from "src/store/actions/userAnalytics";
import { useUserActivity } from "src/helpers/useranalytics";

const Past = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userActivityOnStarted(useUserActivity("applications", "completed_procedure", isMobile, isTablet, browserName)))
    dispatch(getCompletedServiceOrderStarted({ service_type: "completed" }));
  }, []);

  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const { procedures } = cmsDashboarddata;

  return (
    <>
      <div className="bg-AFPBGPrimary dark:bg-AFPDarkBgDarkest">
        <h2 className="text-2xl font-tahoma-bold m-0 flex items-center gap-x-3">
          {procedures?.heading_past?.title_past}
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "procedures",
                  unique: "heading_past",
                  heading: "Anträge/Verfahren",
                })
              );
            }}
          />
        </h2>
        <div className="bg-AFPBGPrimary mt-3 sm:mt-4 md:mt-5 dark:bg-AFPDarkBgDarkest">
          <p className="textStyle max-w-[540px] mb-5">{procedures?.heading_past?.sub_title}</p>
        </div>
      </div>
      <div className="flex items-start flex-wrap my-4 mr-5 gap-y-4 w-full">
        <div className="w-full">
          <PastContent />
        </div>
      </div>
    </>
  );
};

export default Past;
