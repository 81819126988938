import {
  AUTH_USER_FAILURE,
  AUTH_USER_RESET,
  FORGOT_USER_START,
  FORGOT_USER_SUCCESS,
  LOGIN_USER_START,
  LOGIN_USER_SUCCESS,
  REGISTER_USER_START,
  REGISTER_USER_SUCCESS,
  RESET_USER_START,
  RESET_USER_SUCCESS,
  CHANGE_PASSWORD_USER_SUCCESS,
  CHANGE_PASSWORD_USER_START,
  REGISTER_USER_START_MODIFY,
  LOGOUT_USER_START,
  LOGOUT_USER_SUCCESS,
  VERIFY_REGISTER_STARTED,
  VERIFY_REGISTER_SUCCESS,
  VERIFY_REGISTER_FAILURE,
  GET_CMS_BY_KEY_STARTED,
  GET_CMS_BY_KEY_SUCCESS,
  GET_CMS_BY_KEY_FAILURE,
} from "../actionTypes";

export const loginUser = (payload) => ({ type: LOGIN_USER_START, payload });

export const loginUserSuccess = (payload) => {
  return {
    type: LOGIN_USER_SUCCESS,
    payload,
  };
};

export const registerUser = (payload) => ({
  type: REGISTER_USER_START,
  payload,
});

export const registerUserSuccess = (payload) => {
  return {
    type: REGISTER_USER_SUCCESS,
    payload,
  };
};

export const forgotUser = (payload) => ({
  type: FORGOT_USER_START,
  payload,
});

export const forgotUserSuccess = (payload) => {
  return {
    type: FORGOT_USER_SUCCESS,
    payload,
  };
};
export const resetUser = (payload) => ({
  type: RESET_USER_START,
  payload,
});

export const reseetUserSuccess = (payload) => {
  return {
    type: RESET_USER_SUCCESS,
    payload,
  };
};
export const changePasswordUser = (payload) => ({
  type: CHANGE_PASSWORD_USER_START,
  payload,
});

export const changePasswordUserSuccess = (payload) => {
  return {
    type: CHANGE_PASSWORD_USER_SUCCESS,
    payload,
  };
};

export const authUserFailure = (payload) => {
  return {
    type: AUTH_USER_FAILURE,
    payload,
  };
};

export const authReset = (payload) => ({
  type: AUTH_USER_RESET,
  payload,
});

export const registerUserModify = (payload) => ({
  type: REGISTER_USER_START_MODIFY,
  payload,
});

export const logOutStarted = (payload) => ({ type: LOGOUT_USER_START, payload });

export const logOutSuccess = () => ({ type: LOGOUT_USER_SUCCESS });

export const verifyRegisterStarted = (payload) => ({
  type: VERIFY_REGISTER_STARTED,
  payload,
});

export const verifyRegisterSuccess = (payload) => {
  return {
    type: VERIFY_REGISTER_SUCCESS,
    payload,
  };
};

export const verifyRegisterFailure = (payload) => {
  return {
    type: VERIFY_REGISTER_FAILURE,
    payload,
  };
};

export const getCMSbyKeyStarted = (payload) => {
  return {
    type: GET_CMS_BY_KEY_STARTED,
    payload,
  };
};

export const getCMSbyKeySuccess = (payload) => {
  return {
    type: GET_CMS_BY_KEY_SUCCESS,
    payload,
  };
};
export const getCMSbyKeyFailure = (payload) => {
  return {
    type: GET_CMS_BY_KEY_FAILURE,
    payload,
  };
};
