const InboxMessage = ({ inbox }) => {
  return (
    <div className="flex-1 py-4">
      <ul className="pl-1">
        {inbox.map((item) => {
          return (
            <li key={item.id} className="flex justify-start gap-2 items-center flex-wrap text-SmokeyGray text-sm leading-7 dark:text-BrightGray">
              <span className="text-lg">{item.icon}</span>
              {item.title}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

InboxMessage.defaultProps = {
  inbox: []
};


export default InboxMessage;
