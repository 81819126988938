import {
  ADD_USER_ACCOUNT,
  UPDATE_USER_ACCOUNT,
  ACCOUNTS_LIST,
  FETCH_ACCOUNTLIST_REQUEST,
  FETCH_ACCOUNTLIST_SUCCESS,
  FETCH_ACCOUNTLIST_FAILURE,
  SET_ACCOUNT_LIST,
  DELETE_ACCOUNT_LIST,
  START_USER_MANAGEMENT_UPDATE,
  RESET_USER_MANAGEMENT_UPDATE,
  REMOVE_USER_MANAGEMENT_BY_ID,
  Add_USER_MANAGEMENT_LIST,
  CREATED_USER_ACCOUNT,
  CREATED_RESET_USER_ACCOUNT,
  ADD_USER_MANAGEMENT,
  BUSINESSLIST_INVITE_USER_STARTED,
  BUSINESSLIST_INVITE_USER,
  FETCH_MANAGEMENT_FAILURE,
  USER_MANAGEMENT_LIST_STARTED,
  USER_MANAGEMENT_LIST,
  UPDATE_USER_MANAGEMENT_BY_ID_STARTED,
  UPDATE_USER_MANAGEMENT_BY_ID,
  REMOVE_USER_MANAGEMENT_BY_ID_SUCCESS,
  ADD_USER_MANAGEMENT_SUCCESS,
  ADD_USER_MANAGEMENT_FAILURE,
  AUTH_USER_FAILURE,
} from "../actionTypes";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  newUser: null,
  user: {
    updateUser: null,
  },
  newmUser: { isLoading: false, data: null, status: "pending" },
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER_ACCOUNT: {
      return { ...state, newUser: action.payload, isLoading: false, addUserLoading: true };
    }
    case CREATED_USER_ACCOUNT: {
      return { ...state, newUser: { created: true }, addUserLoading: false };
    }
    case CREATED_RESET_USER_ACCOUNT: {
      return { ...state, newUser: null };
    }

    case UPDATE_USER_ACCOUNT: {
      return { ...state, data: action.payload, isLoading: true };
    }
    case ACCOUNTS_LIST: {
      return { ...state, isLoading: true };
    }
    case FETCH_ACCOUNTLIST_REQUEST: {
      return { ...state, isLoading: true, error: null };
    }
    case SET_ACCOUNT_LIST: {
      return { ...state, isLoading: true, error: null };
    }
    case DELETE_ACCOUNT_LIST: {
      return { ...state, isLoading: true, error: null };
    }
    case START_USER_MANAGEMENT_UPDATE: {
      return { ...state, error: null, user: { ...state.user, updateUser: action.payload } };
    }
    case RESET_USER_MANAGEMENT_UPDATE: {
      return { ...state, error: null, user: { ...state.user, updateUser: null } };
    }

    case REMOVE_USER_MANAGEMENT_BY_ID: {
      let filterList = state.user.managementList.filter((user) => user.id != action.payload.id);
      return { ...state, error: null, user: { ...state.user, managementList: filterList, deleteStatus: { isLoading: true, status: 'started'} } };
    }

    case REMOVE_USER_MANAGEMENT_BY_ID_SUCCESS: {
      return { ...state, error: null, isLoading: false, user:{...state.user, deleteStatus: { isLoading: false, status: 'success'} } };
    }
    case Add_USER_MANAGEMENT_LIST: {
      return { ...state, error: null, isLoading: false, user: { ...state.user, isLoading: false, managementList: [...state.user.managementList, action.payload] } };
    }
    case UPDATE_USER_MANAGEMENT_BY_ID: {
      return { ...state, error: null, isLoading: false, user: { ...state.user, updateUser: null } };
    }
    case UPDATE_USER_MANAGEMENT_BY_ID_STARTED: {
      return { ...state, error: null, isLoading: true };
    }

    case FETCH_ACCOUNTLIST_SUCCESS: {
      return { ...state, isLoading: false, data: action.payload.data };
    }
    case FETCH_ACCOUNTLIST_FAILURE: {
      return { ...state, isLoading: false, error: action.payload, data: null };
    }

    /* user management */

    case ADD_USER_MANAGEMENT: {
      return { ...state, newmUser: { isLoading: true, data: null, status: "pending" } };
    }
    case ADD_USER_MANAGEMENT_SUCCESS: {
      return { ...state, newmUser: { isLoading: false, data: action.payload, status: "success" } };
    }
    case ADD_USER_MANAGEMENT_FAILURE: {
      return { ...state, newmUser: { isLoading: false, data: null, status: "failed" } };
    }

    case BUSINESSLIST_INVITE_USER_STARTED: {
      return { ...state, user: { ...state.user, roleBusinessList: null, roleBusinessListLoading: true } };
    }
    case BUSINESSLIST_INVITE_USER: {
      return { ...state, user: { ...state.user, roleBusinessList: action.payload, roleBusinessListLoading: false } };
    }
    case USER_MANAGEMENT_LIST_STARTED: {
      return { ...state, user: { ...state.user, managementList: null, isLoading: true } };
    }
    case USER_MANAGEMENT_LIST: {
      return { ...state, user: { ...state.user, managementList: action.payload, isLoading: false } };
    }
    case FETCH_MANAGEMENT_FAILURE: {
      return { ...state, user: { ...state.user, isLoading: false, roleBusinessListLoading: false, deleteStatus: { isLoading: false, status: 'failed'} } };
    }
    case AUTH_USER_FAILURE: {
      return { ...state, isLoading: false, error: action.payload, addUserLoading: false };
    }

    default: {
      return state;
    }
  }
};

export default reducers;
