import { useNavigate } from "react-router-dom";

const { loginNote, registration } = {
  loginNote: "Noch keinen Account?",
  registration: "Registrieren",
};

const AccountYet = () => {
  const navigate = useNavigate();
  const registerHandle = () => {
    navigate("/registrieren");
  };
  return (
    <div className="flex justify-center gap-2 mt-2 2xl:mt-8 text-SmokeyGray text-sm 2xl:text-base dark:text-BrightGray">
      <p className="font-tahoma">{loginNote}</p>
      <span className="cursor-pointer font-tahoma-bold text-[#1C1A1A] dark:text-BrightGray" onClick={registerHandle}>
        {registration}
      </span>
    </div>
  );
};

export default AccountYet;
