import { Checkbox } from "@mui/material";
import { green } from "@mui/material/colors";
import { useSelector } from "react-redux";

const AgreementList = ({formik}) => {
    const cmsLegal = useSelector((state)=> state.users.cms)
  return (
    <>
        <div className="flex justify-start items-start">
          <Checkbox
            className="!cursor-not-allowed"
            sx={{
              color: "#484848",
              "&.Mui-checked": {
                color: green[600],
              },
            }}
            checked={formik.values["data_protection_declaration"]}
            // onChange={(e) => formik.setFieldValue("data_protection_declaration", e.target.checked)}
          />
          <div className="mt-2">
            <div className="font-tahoma-bold text-black dark:text-BrightGray font-medium w-full">
              {isValidURL(cmsLegal?.data?.cms_data?.protection?.link) ? (
                <a target="_blank" href={cmsLegal?.data?.cms_data?.protection?.link} className="underline text-blue-600 dark:text-blue-500">
                  {cmsLegal?.data?.cms_data?.protection?.title}
                </a>
              ) : (
                cmsLegal?.data?.cms_data?.protection?.title
              )}
            </div>
            {formik.touched["data_protection_declaration"] && formik.errors["data_protection_declaration"] && (
              <div className="text-red-500 text-sm pl-1 py-1">{formik.errors["data_protection_declaration"]}</div>
            )}
          </div>
        </div>
        <div className="flex justify-start items-start mt-3">
          <Checkbox
            className="!cursor-not-allowed"
            sx={{
              color: "#484848",
              "&.Mui-checked": {
                color: green[600],
              },
            }}
            checked={formik.values["terms_condition"]}
            // onChange={(e) => formik.setFieldValue("terms_condition", e.target.checked)}
          />
          <div className="mt-2">
            <div className="font-tahoma-bold text-black dark:text-BrightGray font-medium w-full">
              {isValidURL(cmsLegal?.data?.cms_data?.condition?.link) ? (
                <a target="_blank" href={cmsLegal?.data?.cms_data?.condition?.link} className="underline text-blue-600 dark:text-blue-500">
                  {cmsLegal?.data?.cms_data?.condition?.title}
                </a>
              ) : (
                cmsLegal?.data?.cms_data?.condition?.title
              )}
            </div>
            {formik.touched["terms_condition"] && formik.errors["terms_condition"] && <div className="text-red-500 text-sm pl-1 py-1">{formik.errors["terms_condition"]}</div>}
          </div>
        </div>
        <div className="flex justify-start items-start mt-3">
          <Checkbox
            className="!cursor-not-allowed"
            sx={{
              color: "#484848",
              "&.Mui-checked": {
                color: green[600],
              },
            }}
            checked={formik.values["certificate"]}
            // onChange={(e) => formik.setFieldValue("certificate", e.target.checked)}
          />
          <div className="mt-2">
            <div className="font-tahoma-bold text-black dark:text-BrightGray font-medium w-full">
              {isValidURL(cmsLegal?.data?.cms_data?.certificate?.link) ? (
                <a target="_blank" href={cmsLegal?.data?.cms_data?.certificate?.link} className="underline text-blue-600 dark:text-blue-500">
                  {cmsLegal?.data?.cms_data?.certificate?.title}
                </a>
              ) : (
                cmsLegal?.data?.cms_data?.certificate?.title
              )}
            </div>
            {formik.touched["certificate"] && formik.errors["certificate"] && <div className="text-red-500 text-sm pl-1 py-1">{formik.errors["certificate"]}</div>}
          </div>
        </div>
        <div className="flex justify-start items-start mt-3">
          <Checkbox
            className="!cursor-not-allowed"
            sx={{
              color: "#484848",
              "&.Mui-checked": {
                color: green[600],
              },
            }}
            checked={formik.values["declaration"]}
            // onChange={(e) => formik.setFieldValue("declaration", e.target.checked)}
          />
          <div className="mt-2">
            <div className="font-tahoma-bold text-black dark:text-BrightGray font-medium w-full">
              {isValidURL(cmsLegal?.data?.cms_data?.declaration?.link) ? (
                <a target="_blank" href={cmsLegal?.data?.cms_data?.declaration?.link} className="underline text-blue-600 dark:text-blue-500">
                  {cmsLegal?.data?.cms_data?.declaration?.title}
                </a>
              ) : (
                cmsLegal?.data?.cms_data?.declaration?.title
              )}
            </div>
            {formik.touched["declaration"] && formik.errors["declaration"] && <div className="text-red-500 text-sm pl-1 py-1">{formik.errors["declaration"]}</div>}
          </div>
        </div>
    </>
  );
};

function isValidURL(url) {
    const regex = /^(https?:\/\/)?([a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})?)(:[0-9]{1,5})?(\/.*)?$/;
    return regex.test(url);
  }

export default AgreementList;
