import { CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// Mock data (use the actual data from your response)

const HelpDeskCheckbox = ({ formik, itemKey, selectedLink }) => {
  const [selectedArticle, setSelectedArticle] = useState(null);
  const helpdesk = useSelector((state) => state.helpdesk);

  const handleCheckboxChange = (article) => {
    setSelectedArticle((prevSelected) => (prevSelected?.article_id === article.article_id ? null : article));
    formik?.setFieldValue(itemKey + ".helpdesk", {
      article_id: article?.article_id,
      url: article?.url,
    });
  };

  useEffect(() => {
    let helpdesk12 = helpdesk?.data && helpdesk?.data?.find((article) => article?.article_id == selectedLink?.article_id);
    if (helpdesk12) {
      setSelectedArticle(helpdesk12);
    }
  }, [helpdesk]);

  const renderCheckboxList = () => {
    return (
      helpdesk?.data &&
      helpdesk?.data?.map((article) => (
        <div key={article.article_id}>
          <label className="flex justify-start items-center gap-4 cursor-pointer dark:text-BrightGray">
            <input type="checkbox" checked={selectedArticle?.article_id === article.article_id} onChange={() => handleCheckboxChange(article)} />
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "block",
                width: "100%",
              }}
            >
              {article.title}
            </span>
          </label>
        </div>
      ))
    );
  };

  return (
    <div>
      {helpdesk?.isLoading ? (
        <div className="flex justify-center items-center min-h-[40vh]">
          <CircularProgress sx={{ color: "#A31D1A" }} />
        </div>
      ) : (
        renderCheckboxList()
      )}
    </div>
  );
};

export default HelpDeskCheckbox;
