import React from "react";
import AutoCompleteLabel from "./AutoCompleteLabel";
import AutoCompleteLabelDark from "./AutoCompleteLabelDark";

const AutoCompleteLabelWrap = ({ selectedPersonOption, LegalPersonList, handleChange, isDisabled }) => {
  return (
    <>
      <div className="dark:hidden">
        <AutoCompleteLabel selectedPersonOption={selectedPersonOption} LegalPersonList={LegalPersonList} handleChange={handleChange} isDisabled={isDisabled} />
      </div>
      <div className="dark:block hidden">
        <AutoCompleteLabelDark selectedPersonOption={selectedPersonOption} LegalPersonList={LegalPersonList} handleChange={handleChange} isDisabled={isDisabled} />
      </div>
    </>
  );
};

export default AutoCompleteLabelWrap;
