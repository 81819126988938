import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import style from "./passwordCheckList.module.css";
import { useDispatch, useSelector } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { checkListRetn, forValidatePassword } from "src/mockData/mock";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import CheckStrengthBar from "src/components/atoms/progress/CheckStrengthBar";
import { MdCheck, MdInfo, MdOutlineVisibilityOff, MdOutlineVisibility } from "react-icons/md";

const PasswordCheckList = (props) => {
  const { label, id, placeholder, disabled, formik, type, unique } = props;
  const [password, setPassword] = useState(formik?.values[id]|| '');
  const [showPassword, setShowPassword] = useState(false);
  const [validate, setValidate] = useState(type === "standard" ? forValidatePassword?.standard : forValidatePassword?.passwordCheck);
  const { handleChange, touched, errors } = formik;
  const strength = Object.values(validate).reduce((a, item) => a + item, 0);

  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const passwordNote = cmsDashboarddata?.settings?.ch_notes?.title;

  const handleChangePassword = (password) => {
    setPassword(password);
    type !== "standard" &&
      (() => {
        setValidate({
          hasNumber: password.match(/\d+/g) ? true : false,
          hasCap: password.match(/[A-Z]+/g) ? true : false,
          hasLow: password.match(/[a-z]+/g) ? true : false,
          hasSpecialChar: password.match(/[!@#$%^&*()_+{}[\]:;<>,.?/~-]+/) ? true : false,
          has8digit: password.length > 7 ? true : false,
        });
      })();
  };

  useEffect(() => {
    let password = formik?.values[id]
    if(password){
      type !== "standard" &&
        (() => {
          setValidate({
            hasNumber: password.match(/\d+/g) ? true : false,
            hasCap: password.match(/[A-Z]+/g) ? true : false,
            hasLow: password.match(/[a-z]+/g) ? true : false,
            hasSpecialChar: password.match(/[!@#$%^&*()_+{}[\]:;<>,.?/~-]+/) ? true : false,
            has8digit: password.length > 7 ? true : false,
          });
        })();
    }
  },[])

  const checkList = checkListRetn(validate);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const dispatch = useDispatch();

  return (
    <div className={`${style.wrapper} contentBody`}>
      <label htmlFor={id} className={`${style.label} flex items-center gap-3 dark:text-BrightGray`}>
        {label}
        <EditIconCMS
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "settings",
                unique: unique,
                heading: "Einstellungen",
              })
            );
          }}
        />
      </label>
      <div className={style.container}>
        <div className={`${style.inputContainer} ${touched[id] && errors[id] && "border-red-500 text-red-500 placeholder:text-red-500"} dark:bg-AFPDarkBgLight`}>
          <input
            disabled={disabled}
            type={showPassword ? "text" : "password"}
            id={id}
            name={id}
            value={password}
            onBlur={formik.handleBlur}
            onChange={(e) => {
              handleChange(e);
              handleChangePassword(e.target.value);
            }}
            placeholder={placeholder}
            className={`${style.input} dark:text-BrightGray dark:bg-AFPDarkBgLight`}
          />
          <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
            {showPassword ? <MdOutlineVisibilityOff className="text-base dark:text-BrightGray" /> : <MdOutlineVisibility className="text-base dark:text-BrightGray" />}
          </IconButton>
        </div>
        {type !== "standard" && (
          <>
            <div className="-mt-[22px] z-10 px-1">
              <CheckStrengthBar strength={strength} />
            </div>
            {strength <= 4 && (
              <span className={`${style.info} dark:text-BrightGray`}>
                <MdInfo />
                {passwordNote}
                <EditIconCMS
                  handleOpen={() => {
                    dispatch(
                      dashboardModalOpenCMS({
                        cms_key: "settings",
                        unique: "ch_notes",
                        heading: "Einstellungen",
                      })
                    );
                  }}
                />
              </span>
            )}
            <ul>
              {checkList.map((list) => {
                return (
                  <li key={list?.id} className={`${list.hashCode && "text-SeaGreen"} flex justify-start items-center gap-2 text-sm`}>
                    <MdCheck className={`${list.hashCode ? "visible " : "invisible"}`} /> {list.title}
                  </li>
                );
              })}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

PasswordCheckList.defaultProps = {
  label: "Password",
  id: "password",
  placeholder: "Enter your password",
  disabled: false,
  formik: {
    onChange: () => {},
    onBlur: () => {},
    handleChange: () => {},
    touched: {},
    errors: {},
    handleBlur: () => {},
  },
  type: "standard",
};

export default PasswordCheckList;
