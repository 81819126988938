import React, { useEffect, useState } from "react";
import UserAnalyticsACBarChart from "./UserAnalyticsACBarChart";
import { useDispatch, useSelector } from "react-redux";
import { userAbandonedCartStarted } from "src/store/actions/userAnalytics";
import CircularLoading from "src/components/atoms/loader/CircularLoading";
import DocumentFilterSelect from "src/components/atoms/select/DocumentFilterSelect";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import { procedureTemplates } from "src/store/actions/procedure";
import { optionsLoginTime, periodicCountExtractor } from "src/helpers/useranalytics";

const UserAnalyticsAbandonedCart = () => {
  const dispatch = useDispatch();
  const procedure = useSelector((state) => state?.procedure);
  const [procedureList, setProcedureList] = useState(null);
  const { data } = procedure;

  useEffect(() => {
    dispatch(procedureTemplates());
  }, []);

  useEffect(() => {
    setProcedureList(
      data?.map((item) => {
        return { title: item.description + " " + item.serviceOrderType, value: item.serviceOrderType };
      })
    );
  }, [data]);
  return <>{data && procedureList && <UserAnalyticsAbandoned procedureList={procedureList} />}</>;
};

const UserAnalyticsAbandoned = ({ procedureList }) => {
  const [selectDocument, setSelectDocument] = useState(procedureList[0]?.value);
  const [selectPeriodic, setSelectPeriodic] = useState(optionsLoginTime[0]?.value);
  const [loginTimes, setLoginTimes] = useState({
    category: null,
    count: null,
    type: "",
    periodic: optionsLoginTime[0]?.value,
  });
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const userAnalytics = useSelector((state) => state.userAnalytics);
  const dispatch = useDispatch();

  const [view, setView] = useState("chart");

  if (selectDocument !== loginTimes?.type || selectPeriodic !== loginTimes?.periodic) {
    setLoginTimes((prev) => ({
      ...prev,
      category: null,
      count: null,
      type: selectDocument,
      periodic: selectPeriodic,
    }));

    dispatch(
      userAbandonedCartStarted({
        service_order_type: selectDocument,
        period: selectPeriodic,
      })
    );
  }

  useEffect(() => {
    const anaylyticsData = periodicCountExtractor(userAnalytics?.abandoned_cart?.data, selectPeriodic, optionsLoginTime[0]?.value);

    anaylyticsData &&
      setLoginTimes((prev) => {
        return {
          ...prev,
          category: anaylyticsData?.category,
          count: anaylyticsData?.count,
          periodic: selectPeriodic,
        };
      });
  }, [userAnalytics?.abandoned_cart]);

  const handleView = (value) => {
    view !== value && setView(value);
  };

  return (
    <>
      <div className="flex flex-col md:flex-row justify-between mb-2">
        <h1 className="text-xl my-6 flex items-center gap-2">
          {cmsDashboarddata?.user_analytics?.abandoned_user?.heading}
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "user_analytics",
                  unique: "abandoned_user",
                  heading: "Benutzer Analyse",
                })
              );
            }}
          />
        </h1>
        <div className="flex justify-end flex-wrap gap-2">
          <DocumentFilterSelect
            extra="w-full w-auto max-w-[220px]"
            extraLabel="hidden"
            label={"Anrede"}
            placeholder="Herr/Frau"
            id="salutation"
            options={procedureList}
            choose={false}
            setSelectDocument={setSelectDocument}
          />
          <DocumentFilterSelect
            extra="w-full w-auto max-w-[220px]"
            extraLabel="hidden"
            label={"Anrede"}
            placeholder="Herr/Frau"
            id="salutation"
            options={optionsLoginTime}
            choose={false}
            setSelectDocument={setSelectPeriodic}
          />
        </div>
      </div>
      {userAnalytics?.abandoned_cart?.isLoading && !loginTimes?.category && !loginTimes?.count && (
        <div className="min-h-96 flex justify-center items-center">
          <CircularLoading css="py-6" />
        </div>
      )}
      {loginTimes?.category && loginTimes?.count && <UserAnalyticsACBarChart data={loginTimes} view={view} handleView={handleView} selectPeriodic={loginTimes?.periodic} />}
    </>
  );
};

export default UserAnalyticsAbandonedCart;
