import React from "react";

const Card = ({ children, css }) => {
  return (
    <div className={`flex flex-col h-full w-full rounded-xl border border-AFPDisabled shadow-md !bg-white py-3  md:py-5 px-3 md:px-4 2xl:px-8 hover:shadow-xl duration-300 dark:!bg-AFPDarkBgLight dark:border-SmokeyGray dark:shadow-slate-700 ${css}`}>
      {children}
    </div>
  );
};

Card.defaultProps = {
  css: "",
  children: null,
};

export default Card;