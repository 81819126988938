import { Box, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SecondaryButton } from "../../button";
import DeleteIcon from "src/assets/icons/delete_icon.png";
import { useSelector } from "react-redux";

const style = {
  top: "50%",
  left: "50%",
  boxShadow: 24,
  width: "90%",
  maxWidth: "600px",
  position: "absolute",
  transform: "translate(-50%, -50%)",
};

const DeleteModal = ({ handleOpen = false, showModal = false, confirmHandle }) => {
  const [readyDelete, setReadyDelete] = useState(false);
  const cancel = () => {
    handleOpen();
  };

  const settings = useSelector((state) => state?.settings);

  useEffect(() => {
    if (
      readyDelete === true &&
      settings?.user?.deleteStatus?.isLoading === false &&
      (settings?.user?.deleteStatus?.status === "success" || settings?.user?.deleteStatus?.status === "failed")
    ) {
      handleOpen();
    }
  }, [settings?.user?.deleteStatus]);

  return (
    <Modal open={showModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={style} className="outline-none bg-AFPBGPrimary dark:bg-AFPDarkBgDarkest dark:border-SmokeyGray">
        <div className="flex md:w-full w-full">
          <img src={DeleteIcon} alt="Delete-icon" className="w-2/5" />
          <div className={`overflow-y-auto overflow-x-hidden customScrollBar w-full  pt-4`}>
            <div className="w-full mt-16 mb-24 pl-4">
              <h2 className="font-bold text-xl dark:text-BrightGray">Löschen ?</h2>
              <h2 className="mt-4 font-thin dark:text-BrightGray">Sind Sie sicher, dass Sie dies löschen möchten?</h2>
            </div>
            <div className="flex flex-row">
              <SecondaryButton
                variant={settings?.user?.deleteStatus?.isLoading && readyDelete ? "disabled" : "secondary"}
                title="Abbrechen"
                css="w-full !rounded-none border-l-0 border-b-0 border-r-0 !shadow-none"
                handler={() => {
                  cancel();
                }}
              />
              <SecondaryButton
                loading={settings?.user?.deleteStatus?.isLoading && readyDelete}
                variant={settings?.user?.deleteStatus?.isLoading && readyDelete ? "disabled" : "secondary"}
                title="Löschen"
                css="w-full !rounded-none border-r-0 border-b-0 !shadow-none"
                handler={() => {
                  confirmHandle();
                  setTimeout(() => {
                    setReadyDelete(true);
                  }, 100);
                }}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
