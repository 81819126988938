import { Box, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getServiceAreaDetailMultiStarted, resetServiceAreaDetail } from "src/store/actions/dform";
import { PrimaryButton } from "../../button";
import { useParams } from "react-router-dom";
import { addCurrentStepDetailValue } from "src/store/actions/currentStep";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";
import { addServiceApplicationStepStarted } from "src/store/actions/application";
import Cookies from "js-cookie";
import MultiStateSelection from "../../select/StateSelection/MultiStateSelection";
import ServiceAreaSelection from "./ServiceAreaSelection";
import toast from "react-hot-toast";

const style = {
  p: 4,
  top: "50%",
  left: "50%",
  width: "90%",
  boxShadow: 24,
  maxWidth: "60%",
  borderRadius: "12px",
  position: "absolute",
  border: "1px solid #CBCBCB",
  transform: "translate(-50%, -50%)",
};

const AddAidsModal = ({ handleClose, data, open, step_id, stepsCMS }) => {
  const application = useSelector((state) => state.application);
  const dispatch = useDispatch();
  const { id } = useParams();
  const dform = useSelector((state) => state.dform);
  const currentStep = useSelector((state) => state.currentStep);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const { service_area, service_area_detail_multi } = dform;
  let serviceareaList = service_area?.data
    ? service_area?.data?.map((area) => {
        return { title: area.name, value: area.id };
      })
    : null;
  const [areaList, setAreaList] = useState(serviceareaList);
  const [selectedAids, setSelectedAids] = useState({});
  const [selectedAreaArray, setSelectedAreaArray] = useState([]);
  const [fieldErrors, setFieldErrors] = useState({
    leader: {
      error: null,
      status: false,
    },
  });
  const [nowSubmitted, setNowSubmitted] = useState({ status: false, isLoading: false });

  const handleRemoveServices = (id) => {
    const selectedAreaArrayFilter = selectedAreaArray.filter((item)=> item != id)
    setSelectedAreaArray(selectedAreaArrayFilter)
  }

  const handleSelectChange = (e) => {
    const value = e[0];
    setSelectedAreaArray(e);
    if (value) {
        // dispatch(resetServiceAreaDetail());
        if (application?.data["02_FIRMA"]?.find((group) => group.id === "02_FIRMA_TECHNICAL_MANAGER")?.inputTypeValue) {
          if (e?.length > 0) {
            let filterArea = e.filter((area) => !selectedAreaArray.includes(area));
            if (filterArea?.length > 0) {
              for (let i = 0; i < filterArea.length; i++) {
                dispatch(
                  getServiceAreaDetailMultiStarted({
                    service_id: filterArea[i],
                    service_order_type: id,
                    contact_ids: application?.data["02_FIRMA"]?.find((group) => group.id === "02_FIRMA_TECHNICAL_MANAGER")?.inputTypeValue,
                    location_id: application?.data["02_FIRMA"][1]?.inputTypeValue,
                  })
                );
              }
            }
          }
          // dispatch(
          //   getServiceAreaDetailStarted({
          //     service_id: value,
          //     service_order_type: id,
          //     contact_ids: application?.data["02_FIRMA"]?.find((group) => group.id === "02_FIRMA_TECHNICAL_MANAGER")?.inputTypeValue,
          //     location_id: application?.data["02_FIRMA"][1]?.inputTypeValue,
          //   })
          //   getServiceAreaDetailMultiStarted({
          //     service_id: value,
          //     service_order_type: id,
          //     contact_ids: application?.data["02_FIRMA"]?.find((group) => group.id === "02_FIRMA_TECHNICAL_MANAGER")?.inputTypeValue,
          //     location_id: application?.data["02_FIRMA"][1]?.inputTypeValue,
          //   })
          // );
        } else if ((id === "AA" || id === "EA") && JSON.parse(localStorage.getItem("locationValidation"))) {
          let locationValidation = JSON.parse(localStorage.getItem("locationValidation"));
          if (e?.length > 0) {
            let filterArea = e?.filter((area) => !selectedAreaArray?.includes(area));
            if (filterArea?.length > 0) {
              for (let i = 0; i < filterArea?.length; i++) {
                dispatch(
                  getServiceAreaDetailMultiStarted({
                    service_id: filterArea[i],
                    service_order_type: id,
                    contact_ids: locationValidation?.exsiting_team_leaders?.join(","),
                    location_id: application?.data["02_FIRMA"][1]?.inputTypeValue,
                  })
                );
              }
            }
          }
        }
      
    }
  };

  useEffect(() => {
    if (service_area?.data?.length > 0) {
      let areaList = service_area?.data?.map((area) => {
        return { title: area.name, value: area.id };
      });
      setAreaList(areaList);
    }
  }, [service_area]);

  useEffect(() => {
    return () => {
      dispatch(resetServiceAreaDetail());
    };
  }, []);

  const ValidateSelectedAreaFachiliter = () => {
    let selectedLeader = Object.values(selectedAids).filter((data) => {
      if (data?.length>0) {
        return data;
      }
    });

    if (selectedAreaArray) {
      if (selectedAreaArray?.length == selectedLeader?.length) {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  const submitHandle = () => {
    if (ValidateSelectedAreaFachiliter()) {

      let mappedArea = selectedAreaArray?.map((area)=>{
        return {
          ...service_area_detail_multi?.data[area],
          selectedArea:area,
          selectedTechnicalLeaders: selectedAids[area]
        }
      })

      dispatch(
        addCurrentStepDetailValue({
          valueId: data.id,
          value: [
            ...(currentStep?.data[0]?.inputTypeValue ?? []),
            ...mappedArea,
          ],
          valueKey: "inputTypeValue",
        })
      );
      setNowSubmitted({ status: false, isLoading: true });
      setTimeout(() => {
        setNowSubmitted({ status: true, isLoading: false });
      }, 600);
    } else {
      setFieldErrors((prev) => {
        return {
          ...prev,
          leader: {
            error: "Bitte auswählen Fachliche Leiter",
            status: true,
          },
        };
      });
      toast.error("Bitte auswählen Fachliche Leiter")
    }
  };

  useEffect(() => {
    if (data.inputTypeValue) {
      const filterData = areaList.filter((item) => {
        return !data.inputTypeValue.some((obj2) => obj2.description === item.title);
      });
      setAreaList(filterData);
    }
  }, []);

  useEffect(() => {
    if (nowSubmitted.status) {
      dispatch(
        addServiceApplicationStepStarted({
          procedure_data: [{ ...application?.data, [currentStep.stepID]: currentStep.data }],
          step_id: step_id,
          service_order_types_id: id,
          procedure_form_id: Cookies.get("submitted_id"),
        })
      );
      handleClose();
    }
  }, [nowSubmitted.status]);

  return (
    <>
      <Modal open={open} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style} className="outline-none bg-AFPBGPrimary dark:bg-AFPDarkBgDarkest dark:border-SmokeyGray" tabIndex={"undefined"}>
          <div className={`max-h-[90vh] overflow-y-auto overflow-x-hidden customScrollBar w-full p-1`}>
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl md:text-2xl font-tahoma-bold dark:text-BrightGray">{stepsCMS[data?.id]?.modal_heading}</h2>
              <AiOutlineClose size={24} className="text-SmokeyGray cursor-pointer -mt-2 dark:text-BrightGray" onClick={handleClose} />
            </div>

            <div className="min-h-[20vh]">
              {service_area.isLoading ? (
                <div className="flex justify-center items-center">
                  <CustomCircularLoader />
                </div>
              ) : areaList ? (
                <>
                  <div className="w-full">
                    <p className="mb-1 ml-1 font-tahoma-bold dark:text-BrightGray">{stepsCMS[data?.id]?.modal_selection}</p>
                    <MultiStateSelection handleSelect={handleSelectChange} data={areaList} placeholder={"Auswählen"} selectedAreaArray={selectedAreaArray} stepsCMS={stepsCMS} dataID={data?.id}/>
                  </div>
                </>
              ) : (
                <h4 className="text-red-400 text-center my-6">Keine Daten gefunden</h4>
              )}

              <ServiceAreaSelection fieldErrors={fieldErrors} setFieldErrors={setFieldErrors} selectedAids={selectedAids} setSelectedAids={setSelectedAids} selectedAreaArray={selectedAreaArray} handleRemoveServices={handleRemoveServices} stepsCMS={stepsCMS} dataID={data?.id} />
            </div>
            <div className="w-full block">
              {service_area_detail_multi?.data && Object.entries(service_area_detail_multi?.data)?.length > 0 && selectedAreaArray?.length>0 && (
                <div className="flex justify-center items-center">
                  <PrimaryButton
                    title={cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.save}
                    btnStyle="max-w-[150px]"
                    type="submit"
                    loading={nowSubmitted.isLoading}
                    handler={submitHandle}
                  />
                </div>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AddAidsModal;
