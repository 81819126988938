import React, { useEffect, useState } from "react";
import CustomCircularLoader from "../../common/CustomCircularLoader";
import AutoCompleteLabel from "../../input/AutoCompleteField/AutoCompleteLabel";
import { useSelector } from "react-redux";
import { IoTrashBinOutline } from 'react-icons/io5';

const ServiceAreaSelection = ({ fieldErrors, setFieldErrors, selectedAids, setSelectedAids, selectedAreaArray, handleRemoveServices, stepsCMS, dataID }) => {
  const dform = useSelector((state) => state.dform);
  const { service_area_detail_multi } = dform;

  return (
    <>
      {service_area_detail_multi.isLoading && !(service_area_detail_multi?.data && Object.entries(service_area_detail_multi?.data)?.length > 0) ? (
        <div className="flex justify-center items-center mt-4">
          <CustomCircularLoader />
        </div>
      ) : (
        service_area_detail_multi?.data &&
        Object.entries(service_area_detail_multi?.data)?.length > 0 && (
          <>
            {selectedAreaArray.map((data, index) => {
              return (
                <>
                  <ServiceAreaSelectionWrap
                    fieldErrors={fieldErrors}
                    setFieldErrors={setFieldErrors}
                    selectedAids={selectedAids}
                    setSelectedAids={setSelectedAids}
                    service_area_detail={service_area_detail_multi?.data[data]}
                    keyed={data}
                    handleRemoveServices={handleRemoveServices}
                    stepsCMS={stepsCMS}
                    dataID={dataID}
                  />
                  {selectedAreaArray?.length > index + 1 && <hr className="my-8 dark:border-SmokeyGray" />}
                </>
              );
            })}
          </>
        )
      )}
      <>
        {service_area_detail_multi.isLoading && service_area_detail_multi?.data && Object.entries(service_area_detail_multi?.data)?.length > 0 && (
          <div className="flex justify-center items-center mt-4">
            <CustomCircularLoader />
          </div>
        )}
      </>
    </>
  );
};
const ServiceAreaSelectionWrap = ({ fieldErrors, setFieldErrors, selectedAids, setSelectedAids, service_area_detail, keyed, handleRemoveServices, stepsCMS, dataID }) => {
  const [technicalLeader, setTechnicalLeader] = useState(null);

  useEffect(() => {
    if (service_area_detail?.technicalLeaders?.length > 0) {
      let techLeader = service_area_detail?.technicalLeaders?.map((leader) => {
        return { name: leader.name, id: leader.id };
      });
      setTechnicalLeader(techLeader);
    }
  }, [service_area_detail]);

  const handleChange = (event, value) => {
    setSelectedAids((prev) => {
      const newPrev = prev;
      prev[keyed] = value;
      return newPrev;
    });
    if (value?.length > 0) {
      setFieldErrors((prev) => {
        return {
          ...prev,
          leader: {
            error: null,
            status: false,
          },
        };
      });
    }
  };

  return (
    <>
      {service_area_detail?.technicalLeaders?.length > 0 ? (
        <>
          <div className="mb-6 mt-12">
            <div className="flex justify-end items-end w-full pr-4">
              <span className="rounded-full bg-gray-200 p-2 hover:text-AFPPrimary cursor-pointer" onClick={()=>{handleRemoveServices(keyed)}}>
            <IoTrashBinOutline className="" />
              </span>
            </div>
            {service_area_detail?.description && <p className="mb-4 ml-1 font-tahoma-bold dark:text-BrightGray">{service_area_detail?.description}</p>}
            {service_area_detail?.includedAid && service_area_detail.includedAid.split(",").length > 0 && (
              <>
                <p className="mt-3 mb-1 ml-1 font-tahoma-bold dark:text-BrightGray">{stepsCMS[dataID]?.head_aids_included}</p>
                <div className="ml-4 dark:text-BrightGray">
                  {service_area_detail?.includedAid?.split(",")?.map((ia) => {
                    return <li className="dark:text-BrightGray" key={ia}>{ia}</li>;
                  })}
                </div>
              </>
            )}
            {service_area_detail?.specialCriteria && service_area_detail?.specialCriteria?.length > 0 && (
              <>
                <p className="mt-3 mb-1 ml-1 font-tahoma-bold dark:text-BrightGray">{stepsCMS[dataID]?.head_special_criteria}</p>
                <div className="ml-4 dark:text-BrightGray">
                  {service_area_detail?.specialCriteria?.map((sc) => {
                    return <li className="dark:text-BrightGray" key={sc}>{sc}</li>;
                  })}
                </div>
              </>
            )}
            <p className="mt-3 mb-1 ml-1 font-tahoma-bold dark:text-BrightGray">{stepsCMS[dataID]?.head_technical_area}</p>
            <div className="rounded-[12px] border border-AFPLightGray flex justify-between px-0 pr-2 flex-1">
              {technicalLeader?.length > 0 && <AutoCompleteLabel selectedPersonOption={selectedAids[keyed] || []} LegalPersonList={technicalLeader} handleChange={handleChange} />}
            </div>
            <p className="pl-2 text-sm text-red-400 mt-2">{fieldErrors?.leader?.status === true && !(selectedAids[keyed]?.length > 0) && fieldErrors?.leader?.error}</p>
          </div>
        </>
      ) : (
        <p className="text-red-400 text-center mt-8 font-tahoma-bold">Keine Daten gefunden</p>
      )}
    </>
  );
};

export default ServiceAreaSelection;
