import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileRequest } from "src/store/actions/settings";
import PersonalForm from "src/components/organisms/settings/PersonalForm";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";
import { isMobile, isTablet, browserName } from "react-device-detect";
import { userActivityOnStarted } from "src/store/actions/userAnalytics";
import { useUserActivity } from "src/helpers/useranalytics";
import { getCMSbyKeyStarted } from "src/store/actions/users";

const Personal = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);

  useEffect(() => {
    dispatch(userActivityOnStarted(useUserActivity("setting", "personal_data", isMobile, isTablet, browserName)))
    if (!profile.loading) {
      dispatch(fetchProfileRequest());
    }
    dispatch(getCMSbyKeyStarted({cms_key:'legal'}))
  }, []);

  return (
    <div className="px-1 md:px-2 py-6">
      {cmsDashboarddata.listLoaded && (
        <>
          {profile?.data && (!profile.loading || profile?.status == "profile") ? (
            <PersonalForm />
          ) : (
            profile.loading && (
              <div className="flex w-full h-[60vh] justify-center items-center">
                <CustomCircularLoader />
              </div>
            )
          )}
        </>
      )}
    </div>
  );
};

export default Personal;
