import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { addApplicationStepStarted, createCompleteApplicationStarted } from "src/store/actions/application";
import { useEffect, useState } from "react";
import StepFooterButtons from "src/components/molecules/dform/StepFooterButtons";
import StepsForm from "src/components/molecules/dform/StepsForm";
import { ServiceOrderPayloadModify } from "src/helpers/dform";
import { FormStepValidation, signatureValid, umzugAddressValid } from "src/helpers/dform/validateForm";
import Cookies from "js-cookie";

const DynamicFormSteps = ({ activeStep, stepChangeHandle, id, step_id }) => {
  const application = useSelector((state) => state.application);
  const currentStep = useSelector((state) => state.currentStep);
  const { data, addingStep } = application;
  const [closeForm, setCloseForm] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let locationValidation = JSON.parse(localStorage.getItem("locationValidation"));

  /** Speichern & Beenden button handling */
  const saveHandle = () => {
    setCloseForm(true);
    saveFormData(false);
  };

  /** saving form data in DB */
  const saveFormData = (addNewService) => {
    let detailServiceArea = validateTechnicalServiceArea();
    let checkedLocation = step_id === "02_FIRMA" && localStorage.getItem("location_reset_id");
    if(checkedLocation && (step_id === "02_FIRMA" && (currentStep?.data?.find((item)=> item?.inputTypeId === 'business-premises')?.inputTypeValue ))){
      locationReset(addNewService);
    } else {
    if (detailServiceArea?.status) {
      toast.success("Nicht zugewiesene fachliche Leiter werden aus dem Antrag entfernt.")
      setTimeout(()=>{
      dispatch(
        addApplicationStepStarted({
          procedure_data: [{ ...data, [currentStep.stepID]: currentStep.data, "02_FIRMA": detailServiceArea?.data }],
          step_id: step_id,
          service_order_types_id: id,
          procedure_form_id: Cookies.get("submitted_id"),
          addNewService,
        })
      );
      }, 400)
    } else {
      let checkstep = currentStep.stepID === "02_FIRMA";
      let locationid = checkstep && currentStep?.data?.find((item)=> item?.inputTypeId === 'business-premises')?.inputTypeValue
      locationid && localStorage.removeItem("location_reset_id")
      dispatch(
        addApplicationStepStarted({
          procedure_data: [{ ...data, [currentStep.stepID]: currentStep.data }],
          step_id: step_id,
          service_order_types_id: id,
          procedure_form_id: Cookies.get("submitted_id"),
          addNewService,
        })
      );
    }
    }
  };

  /** location reset functionaltiy */

  const locationReset = (addNewService) => {
    dispatch(
      addApplicationStepStarted({
        procedure_data: [{ ["01_START"]: data["01_START"], [currentStep.stepID]: currentStep.data }],
        step_id: step_id,
        service_order_types_id: id,
        procedure_form_id: Cookies.get("submitted_id"),
        addNewService,
        reset_location: true,
      }) );
      // should rename location_id to new one
      // localStorage.setItem("location_reset_id", currentStep?.data?.find((item)=> item?.inputTypeId === 'business-premises')?.inputTypeValue)
      localStorage.removeItem("location_reset_id")
      localStorage.removeItem("location_reset_confirm")
      localStorage.setItem("percentageStep", 1)
  };

  /** Technical manager without supply areas validation */

  const validateTechnicalServiceArea = () => {
    let needPreviousStepChange = {
      status: false,
      data: null,
    };
    if (step_id === "03_VERSORGUNGSBEREICHE") {
      let findServiceAreaDetail = currentStep?.data?.find((data) => data?.inputType == "service-areas-modal");

      let find02_firma = application?.data["02_FIRMA"] && application?.data["02_FIRMA"]?.find((data) => data?.inputType == "dropdownTag")?.inputTypeValue;
      let find02_firmaDetail = application?.data["02_FIRMA"];

      if (findServiceAreaDetail && find02_firma) {
        let find02_Leaders = new Set(find02_firma);
        if (find02_Leaders) {
          findServiceAreaDetail?.inputTypeValue?.forEach((typeValue) => {
            typeValue?.selectedTechnicalLeaders?.forEach((leader) => {
              if (find02_Leaders.has(leader?.id)) {
                find02_Leaders.delete(leader?.id);
              }
            });
          });
          let find02_Leader_Exist = Array.from(find02_Leaders);
          if (find02_Leader_Exist?.length > 0) {
            find02_firmaDetail = find02_firmaDetail?.map((detail) => {
              if (detail?.inputType == "dropdownTag") {
                let newDetail = detail;
                newDetail.inputTypeValue = detail?.inputTypeValue?.filter((value) => !find02_Leader_Exist?.includes(value));
                return newDetail;
              } else {
                return detail;
              }
            });
            needPreviousStepChange = {
              status: true,
              data: find02_firmaDetail,
            };
          }
        }
      }
    }
    return needPreviousStepChange;
  };

  const prevStepHandle = () => {
    if (!addingStep.isLoading) {
      activeStep !== 0 ? stepChangeHandle(activeStep - 1) : navigate("/verfahren/uebersicht");
    }
  };

  useEffect(() => {
    let findCheck = FormStepValidation(currentStep?.data);

    if (findCheck?.length > 0) {
      setIsDisable(true);
    } else {
      const isFirmaStep = currentStep?.stepID === "02_FIRMA";
      if (isFirmaStep) {
        if (application?.locationValid?.data?.is_valid) {
          setIsDisable(false);
        } else {
          setIsDisable(true);
        }
      } else {
        setIsDisable(false);
      }
    }
  }, [currentStep?.data, application?.locationValid?.data?.is_valid]);

  const nextStepHandle = () => {
    /** check only umzug new address validation */
    if (id == "UA" && activeStep == 2) {
      let locationfindCheck = umzugAddressValid(currentStep);
      if (locationfindCheck?.length > 0) {
        toast.error("Bitte geben Sie die neue Adresse ein");
        return;
      }
    }
    /** if this aa, iwa, ka, ua form in 2 step and not valid location move formward prevent */
    if (id == "AA" || id == "EA" || id == "IWA" || id == "KA" || id == "UA" || id == "SA" || id == "EPQ" || id == "EP" || id == "REPQ" || id == "FA") {
      const serviceAreaDetail = JSON.parse(localStorage.getItem("locationValidation"));
      if (activeStep == 1) {
        if (!serviceAreaDetail?.is_valid) {
          toast.error("gemäß unserer AGBs ist aktuell keine Kündigung möglich. Dies ist nur innerhalb von 6 Wochen nach einem Verfahren möglich");
          return;
        }
      }
    }
    /** Form Step Validation */
    let findCheck = FormStepValidation(currentStep?.data);
    if (findCheck?.length > 0) {
      toast.error("Bitte bestätigen Sie, bevor Sie fortfahren");
    } else {
      if (!addingStep.isLoading) {
        if (activeStep === 0) {
          if (currentStep?.data[0]?.inputTypeValue === true) {
            saveFormData(false);
          } else {
            toast.error("Bitte bestätigen Sie, bevor Sie fortfahren");
          }
        } else {
          saveFormData(false);
        }
      }
    }
  };

  useEffect(() => {
    if (closeForm) {
      if (addingStep.isLoading === false && addingStep.status === true) {
        setTimeout(() => {
          navigate("/verfahren/uebersicht");
        }, 400);
      }
    }
    if (addingStep.isLoading === false && addingStep.status === true) {
      if (!closeForm) {
        stepChangeHandle(activeStep + 1);
      }
    }
  }, [addingStep]);

  /** saving dynamic form details in erp */
  const createServiceOrderSubmit = () => {
    let findCheck = signatureValid(currentStep?.data);
    if (findCheck?.length > 0) {
      toast.error("Bitte bestätigen Sie, bevor Sie fortfahren");
    } else {
      let ownerFind = currentStep?.data.find((stepvalue) => stepvalue.id === "05_SIGNATURE_OWNER");
      let owner_id = id == "EPQ" || id == "EP" ? (ownerFind ? ownerFind.inputTypeValue : "") : locationValidation?.existing_owner_id;
      if (id == "IWA") {
        dispatch(
          createCompleteApplicationStarted({
            ...ServiceOrderPayloadModify({
              procedure_data: [{ ...application.data, [currentStep.stepID]: currentStep.data }],
              step_id: step_id,
              service_order_types_id: id,
              procedure_form_id: Cookies.get("submitted_id"),
              owner_id,
            }),
            existing_owner_id: locationValidation?.existing_owner_id,
          })
        );
      } else {
        dispatch(
          createCompleteApplicationStarted(
            ServiceOrderPayloadModify({
              procedure_data: [{ ...application.data, [currentStep.stepID]: currentStep.data }],
              step_id: step_id,
              service_order_types_id: id,
              procedure_form_id: Cookies.get("submitted_id"),
              owner_id,
            })
          )
        );
      }
    }
  };

  return (
    <>
      <div className="pl-4 md:pl-8 lg:pl-16 pr-2 md:pr-3 lg:pr-5">
        <div className="relative md:pt-[4.5rem] py-4 mx-3 sm:mx-4 md:mx-8">
          <div className="text-black w-full dark:text-BrightGray">
            <StepsForm
              activeStep={activeStep}
              data={data}
              step_id={step_id}
              stepID={currentStep.stepID}
              createServiceOrderSubmit={createServiceOrderSubmit}
              saveFormData={saveFormData}
            />
            <StepFooterButtons activeStep={activeStep} saveHandle={saveHandle} prevStepHandle={prevStepHandle} nextStepHandle={nextStepHandle} disable={isDisable} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DynamicFormSteps;
