import Cookies from "js-cookie";
import styles from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ApplicationCard from "src/components/atoms/card/Card/ApplicationCard";
import { setApplicationSidebar } from "src/store/actions/application";

const ModalContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const procedure = useSelector((state) => state?.procedure);
  const { data } = procedure;
  const profile = useSelector((state) => state.profile);

  return (
    <>
      <div className="flex flex-col">
        <div className="grid grid-cols-1 md:grid-cols-2 ">
          {data &&
            data.map((item) => (
              <div key={item.id} className="flex flex-col items-center w-full md:max-w-md mx-auto mb-5 md:mb-0">
                <div className={`flex flex-col mb-8 ${styles.cardMainContainer}`}>
                  <ApplicationCard css={styles.cardContainer}>
                    <img src={item.imgUrl} className="mx-auto my-auto p-0 text-AFPPrimary" alt={item.description} />
                    <div className="items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <button
                        onClick={() => {
                          Cookies.remove("submitted_id");
                          localStorage.removeItem("locationDetailForm");
                          localStorage.setItem("percentageStep", "0");
                          localStorage.setItem("dformId", item?.id)
                          if (profile?.data?.role === "admin") {
                            navigate("/admin/verfahren/antrag/" + item?.serviceOrderType);
                          } else {
                            navigate("/verfahren/antrag/" + item?.serviceOrderType);
                          }
                          dispatch(setApplicationSidebar());
                        }}
                        className={`${styles.cardButton} hidden tracking-wide  text-white rounded-xl py-2 Center gap-1 border  border-AFPPrimary bg-AFPPrimary hover:bg-AFPPrimaryDark font-tahoma-bold w-full relative px-2  transition-all text-center ease-in duration-75 `}
                      >
                        <h1 className="text-center">Antrag beginnen</h1>
                      </button>
                    </div>
                  </ApplicationCard>
                  <div className="pt-3 text-center md:text-start">
                    <p className={`font-tahoma-bold ${styles.pStyle} cursor-pointer dark:text-BrightGray`}>{item.description}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default ModalContent;
