import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useParams, useNavigate } from "react-router-dom";
import { validateDocTabs } from "src/helpers/setting";
import { PrimaryBodyTab } from "src/components/atoms/tabs";
import { useSelector } from "react-redux";

export default function ProcedureTab({ tabList }) {
  const profile = useSelector((state) => state.profile);
  const { id } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  useEffect(() => {
    validateDocTabs(tabList, id, setValue, navigate, profile?.data?.role);
  }, [id]);

  return (
    <>
      <Box sx={{ width: "100%", padding: 2, paddingTop: 0, paddingBottom: 0 }}>
        <PrimaryBodyTab value={value} tabList={tabList.tabs} />
      </Box>
    </>
  );
}
