/*eslint-disable*/
import React, { useEffect, useState } from "react";
import PastTable from "./PastTable";
import { useSelector, useDispatch } from "react-redux";
import { TableContainer, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { pastDataHeader } from "src/mockData/procedureMock";
import TableHeader from "src/components/atoms/common/TableHeader";
import Paper from "@mui/material/Paper";
import "./pastTable.css";
import DocumentSearch from "src/components/molecules/search/DocumentSearch";
import { getAllBusinessStarted } from "src/store/actions/business";
import Cookies from "js-cookie";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";
import TableContainerDark from "src/components/atoms/table/TableContainerDark";
const PastContent = () => {
  const increaseSort = () => {};
  const decreaseSort = () => {};
  const dispatch = useDispatch();
  const [filterdData, setFilteredData] = useState([]);
  const [showTableData, setShowTableData] = useState([]);
  const filterData = filterdData.length > 0 ? filterdData?.map((item) => item.name) : [];
  const business = useSelector((state) => state?.management);
  const serviceOrder = useSelector((state) => state.serviceOrder.completed);

  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const { procedures } = cmsDashboarddata;

  useEffect(() => {
    const getLocalData = JSON.parse(localStorage.getItem("getAllLocation"));
    if (getLocalData != null) {
      const extractedFields = getLocalData.map((item) => ({
        name: item.name,
        location_id: item.location_id,
      }));
      setFilteredData([...extractedFields]);
    } else if (getLocalData == null) {
      dispatch(getAllBusinessStarted());
    }
  }, [business?.allLocation?.data]);

  useEffect(() => {
    if (serviceOrder && serviceOrder?.data) setShowTableData(serviceOrder?.data);
  }, [serviceOrder]);
  const handleChange = (event, newValue) => {
    if (newValue?.length == 0) {
      setShowTableData(serviceOrder.data);
    } else {
      if (filterdData?.length > 0) {
        const location_ids = filterdData.length > 0 && filterdData.filter((item) => newValue?.includes(item.name));
        const result =
          location_ids?.length > 0 &&
          location_ids?.map((item) => {
            return item.location_id.toString();
          });
        const filterData = serviceOrder?.data?.filter((item) => result.includes(item.location_id));
        setShowTableData(filterData);
      }
    }
  };
  return (
    <>
      {!serviceOrder?.isLoading && (
        <>
          <div className="w-full mt-8 flex justify-end gap-x-4 mb-8">
            <DocumentSearch placeholder={procedures?.past_btn_search?.location_search} handleChange={handleChange} locations={filterData} />
            <EditIconCMS
              handleOpen={() => {
                dispatch(
                  dashboardModalOpenCMS({
                    cms_key: "procedures",
                    unique: "past_btn_search",
                    heading: "Anträge/Verfahren",
                  })
                );
              }}
            />
          </div>
          <div className="w-full flex justify-end">
            <EditIconCMS
              handleOpen={() => {
                dispatch(
                  dashboardModalOpenCMS({
                    cms_key: "procedures",
                    unique: "past_table_head",
                    heading: "Anträge/Verfahren",
                  })
                );
              }}
            />
          </div>
        </>
      )}

      {serviceOrder?.isLoading ? (
        <div className="flex justify-center items-center min-h-[60vh]">
          <CustomCircularLoader />
        </div>
      ) : serviceOrder?.data && serviceOrder?.data?.length > 0 && showTableData.length > 0 ? (
        <PastTable tableBody={showTableData} />
      ) : (
        <TableContainerDark>
          <Table>
            <TableHeader tableData={pastDataHeader(procedures.past_table_head)} increaseSort={increaseSort} decreaseSort={decreaseSort} />
            <TableBody>
              <TableRow className="w-full">
                <TableCell colSpan={6} align="center">
                  <h5 className="text-red-400 text-center">Keine Daten gefunden</h5>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainerDark>
      )}
    </>
  );
};
export default PastContent;
