export const modalStyleStandard = {
    p: 2,
    top: "50%",
    left: "50%",
    width: "90%",
    boxShadow: 24,
    maxWidth: "600px",
    // bgcolor: "#FBFBFB",
    borderRadius: "12px",
    position: "absolute",
    border: "1px solid #CBCBCB",
    transform: "translate(-50%, -50%)",
  };