import EditIconCMS from "../../button/EditIconCMS";

const CardHead = ({ children, item, handleOpen }) => {
  return (
    <>
      <div className="flex gap-2 items-center">
        <img src={item.img} alt={item.title} className="w-7" />
        <h2 className="text-black text-lg font-semibold -mt-1 text-wrap truncate dark:text-AFPBGPrimary dark:text-BrightGray">{item.title}</h2>
        <EditIconCMS handleOpen={handleOpen} css="!w-[16px] h-full" />
      </div>
      {children}
    </>
  );
};

CardHead.defaultProps = {
  item: {
    img: "",
    title: "Default Title",
  },
  children: null,
};

export default CardHead;
