import React, { useEffect, useState } from "react";
import CheckBoxDF from "../CheckBoxDF";
import BusinessPremisesButton from "../BusinessPremisesButton.js";
import DropDownTag from "../DropDownTag";
import Shorten from "../Shorten";
import VerifyCheckBoxWithAccount from "../verifyCheckBoxWithAccount";
import { useSelector } from "react-redux";
import SupplyAreaTable from "src/components/organisms/dform/CoverageInfo/SupplyAreaTabel";
import SignatureDropdown from "src/components/organisms/dform/SignatureDropdown";
import OwnershipDropdown from "src/components/organisms/dform/OwnershipDropdown";
import VerifyWithAccount from "src/components/organisms/dform/VerifyWithAccount";
import ServiceOrderCast from "src/components/organisms/dform/ServiceOrderCast";
import AddressRelocation from "src/components/organisms/dform/AddressRelocation";
import DatePickerOn from "src/components/organisms/dform/DatePickerOn";
import SupplyAreaTableReadable from "src/components/organisms/dform/CoverageInfo/SupplyAreaTabelReadable";
import ShowExpanded from "src/components/atoms/card/Card/ShowExpanded";

const FilterField = ({ data, handleHover, css, step_id, showCard, helperText }) => {
  const cmsDocument = useSelector((state) => state.cmsDocument);
  const stepsCMS = cmsDocument?.dFormCMS?.data;
  let [miniAids, setMiniAids] = useState(false);
  const dform = useSelector((state) => state.dform);

  useEffect(() => {
    if (dform?.page_aids && dform?.page_aids["dform"] && dform?.page_aids["dform"]?.includes(step_id)) {
      if (!miniAids) {
        setMiniAids(true);
      }
    } else {
      if (miniAids) {
        setMiniAids(false);
      }
    }
  }, [dform?.page_aids]);

  if (data.inputType === "checkBox") {
    return (
      <>
        <CheckBoxDF data={data} stepsCMS={stepsCMS} css={css} miniAids={miniAids} />
        <ShowExpanded showCard={showCard} page={"dform"} aid={step_id} helperText={helperText} />
      </>
    );
  } else if (data.inputType === "groupButton") {
    return (
      <>
        <BusinessPremisesButton data={data} handleHover={handleHover} stepsCMS={stepsCMS} step_id={step_id} />
        <ShowExpanded showCard={showCard} page={"dform"} aid={step_id} helperText={helperText} />
      </>
    );
  } else if (data.inputType === "dropdownTag") {
    return (
      <>
        <DropDownTag data={data} handleHover={handleHover} stepsCMS={stepsCMS} />
        <ShowExpanded showCard={showCard} page={"dform"} aid={step_id} helperText={helperText} />
      </>
    );
  } else if (data.inputType === "shorten" || data.inputType === "shorten-final") {
    return (
      <>
        <Shorten data={data} handleHover={handleHover} stepsCMS={stepsCMS} />
        <ShowExpanded showCard={showCard} page={"dform"} aid={step_id} helperText={helperText} />
      </>
    );
  } else if (data.inputType === "verifyCheckBoxWithAccount") {
    return (
      <>
        <VerifyCheckBoxWithAccount data={data} handleHover={handleHover} stepsCMS={stepsCMS} />
        <ShowExpanded showCard={showCard} page={"dform"} aid={step_id} helperText={helperText} change={true} />
      </>
    );
  } else if (data.inputType === "service-areas-modal") {
    return (
      <>
        <SupplyAreaTable data={data} stepsCMS={stepsCMS} step_id={step_id} />
        <ShowExpanded showCard={showCard} page={"dform"} aid={step_id} helperText={helperText} />
      </>
    );
  } else if (data.inputType === "service-areas-modal-readable") {
    return (
      <>
        <SupplyAreaTableReadable data={data} stepsCMS={stepsCMS} step_id={step_id} />
        <ShowExpanded showCard={showCard} page={"dform"} aid={step_id} helperText={helperText} />
      </>
    );
  } else if (data.inputType === "signaturedropdown") {
    return (
      <>
        <SignatureDropdown data={data} stepsCMS={stepsCMS} />
        <ShowExpanded showCard={showCard} page={"dform"} aid={step_id} helperText={helperText} />
      </>
    );
  } else if (data.inputType === "address-relocation") {
    return (
      <>
        <AddressRelocation data={data} stepsCMS={stepsCMS} />
        <ShowExpanded showCard={showCard} page={"dform"} aid={step_id} helperText={helperText} />
      </>
    );
  } else if (data.inputType === "groupButtonInhaber") {
    return (
      <>
        <OwnershipDropdown data={data} handleHover={handleHover} stepsCMS={stepsCMS} />
        <ShowExpanded showCard={showCard} page={"dform"} aid={step_id} helperText={helperText} />
      </>
    );
  } else if (data.inputType === "verifyWithAccount") {
    return (
      <>
        <VerifyWithAccount data={data} handleHover={handleHover} stepsCMS={stepsCMS} />
        <ShowExpanded showCard={showCard} page={"dform"} aid={step_id} helperText={helperText} />
      </>
    );
  } else if (data.inputType === "service-order-cost") {
    return (
      <>
        <ServiceOrderCast data={data} stepsCMS={stepsCMS} step_id={step_id} />
        <ShowExpanded showCard={showCard} page={"dform"} aid={step_id} helperText={helperText} />
      </>
    );
  } else if (data.inputType === "date-picker-on") {
    return (
      <>
        <DatePickerOn data={data} stepsCMS={stepsCMS} />
        <ShowExpanded showCard={showCard} page={"dform"} aid={step_id} helperText={helperText} />
      </>
    );
  } else {
    return null;
  }
};

export default FilterField;
