import { Checkbox } from "@mui/material";
import { green } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { PiWarningFill } from "react-icons/pi";
import { useDispatch } from "react-redux";
import { evidenceCheckDetail } from "src/helpers/dform/validateEvidence";
import { setEvidenceById } from "src/store/actions/evidence";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const EvidenceInput = ({ evedenceData, evidence, handleCheckChangeDetailFill, entriesKey }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState(
    evidence?.evidenceDetail?.data[evedenceData.inventoryID.value] ? evidence?.evidenceDetail?.data[evedenceData.inventoryID.value] : ""
  );
  const [initialCheck, setInitialCheck] = useState("");
  const handleInput = (event) => {
    let checkedValue = event.target.value;

    setInputValue(checkedValue);
    dispatch(
      setEvidenceById({
        key: evedenceData?.evedenceData?.evidenceType?.fieldTpe,
        value: checkedValue,
      })
    );
    if (checkedValue) {
      if (!initialCheck) {
        setInitialCheck("check");
      }
    } else {
      setInitialCheck("");
    }
  };
  useEffect(() => {
    let newObj = {};
    let markObj = {};
    evidence?.data?.forEach((ab) => {
      ab?.prerequisiteGroup?.value;
      if (newObj[ab?.prerequisiteGroup?.value]) {
        newObj[ab?.prerequisiteGroup?.value]?.push(ab);
      } else {
        newObj[ab?.prerequisiteGroup?.value] = [ab];
        markObj[ab?.prerequisiteGroup?.value] = false;
      }
    });
    const isCheckUpdated = evidenceCheckDetail(newObj[entriesKey], evidence);
    if (isCheckUpdated) {
      handleCheckChangeDetailFill(entriesKey, true);
    } else {
      handleCheckChangeDetailFill(entriesKey, false);
    }
  }, [initialCheck]);
  return (
    <div className="flex gap-2 pb-5 flex-col" id={evedenceData?.inventoryID?.value}>
      <div className="text-DarkCharcoal text-[14px] my-2" dangerouslySetInnerHTML={{ __html: evedenceData?.longDescription?.value }}></div>
      <div className="flex max-w-[500px] border border-AFPDisabled rounded-sm py-1 px-1">
        <input type="text" placeholder="" className=" bg-transparent w-full pl-3 outline-none border-none" value={inputValue} onChange={handleInput} />
        <Checkbox
          {...label}
          defaultChecked
          sx={{
            color: green[800],
            "&.Mui-checked": {
              color: green[600],
            },
          }}
        />
      </div>
      <PiWarningFill fontSize={20} className=" text-AFPPrimary" />
      {!initialCheck ? <span className="text-red-400 text-sm mt-2">Pflichtfeld</span> : null}
    </div>
  );
};

export default EvidenceInput;
