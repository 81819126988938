import Logo from "src/assets/img/newLogo.png";
import LogoDark from "src/assets/img/darkLogo.png";

const Register = ({ children }) => {
  return (
    <div className="registerwrap">
      <div className="mb-8">
        <img src={Logo} alt="logo" className="dark:hidden" />
        <img src={LogoDark} className="hidden dark:block" alt="logo" />
      </div>

      <div className="w-full md:w-[88%] lg:w-[80%]">{children}</div>
    </div>
  );
};

export default Register;
