import { Paper, Table, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";

const UserAnalyticsTable = ({ heads, unique, children }) => {
  const dispatch = useDispatch();
  return (
    <>
      <EditIconCMS
        handleOpen={() => {
          dispatch(
            dashboardModalOpenCMS({
              cms_key: "user_analytics",
              unique,
              heading: "Benutzer Analyse",
            })
          );
        }}
      />
      <TableContainer component={Paper} style={{ borderRadius: "12px", background: "#fff" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className="bg-AFPLightGray">
              {heads?.map((item, index) => {
                return heads.length - 1 === index ? <TableCell align={"right"}>{item}</TableCell> : <TableCell>{item}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          {children}
        </Table>
      </TableContainer>
    </>
  );
};
export default UserAnalyticsTable;
