/*eslint-disable*/
import { initialValuesRegisterTab1, validationSchemaRegisterTab1 } from "src/services/validation/Auth";
import { InputStandardField, PasswordCheckList, PasswordCompare } from "src/components/atoms/input";
import { salutationOptions } from "src/mockData/register";
import { useFormik } from "formik";
import StandardSelect from "src/components/atoms/select/StandardSelect";
import RegisterGrid from "src/components/molecules/RegisterGrid";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "src/components/atoms/button";
import { GrNext } from "react-icons/gr";
import { ValuesValidateDB } from "./";

const { heading, subHeading, personalData, salutation, title, firstName, lastName, companyName, phone, loginData, email, passwordLevel, repeat, confirmEmail } = {
  heading: "Registrierung",
  subHeading: "Bitte erstellen Sie einen neuen Account",
  personalData: "Persönliche Daten:",
  salutation: "Anrede",
  title: "Titel",
  firstName: "Vorname",
  lastName: "Nachname",
  companyName: "Firmenname",
  phone: "Telefonnummer",
  loginData: "Login Daten:",
  email: "Bitte geben Sie als Benutzernamen Ihre E-Mail-Adresse ein:",
  confirmEmail: "E-Mail bestätigen:",
  password: "Bitte vergeben Sie ein Passwort:",
  passwordLevel: "Passwort",
  passwordNote: "Bitte wählen Sie ein stärkeres Passwort!",
  repeat: "Passwort wiederholen",
  validation: {
    has8digit: "Mind. 8 Zeichen",
    hasLow: "Mind. 1x Kleinbuchstaben",
    hasCap: "Mind. 1x Großbuchstaben",
    hasNumber: "Mind. 1x Ziffern",
    hasSpecialChar: "Mind. 1x Sonderzeichen",
  },
};

const FirstRegisterTab = ({ activeTab, handleBack, handleNext, tabs }) => {
  const navigate = useNavigate();
  
  let preinitialValues = ValuesValidateDB(activeTab)
  const formik = useFormik({
    initialValues: preinitialValues ? {...initialValuesRegisterTab1, ...preinitialValues} : initialValuesRegisterTab1,
    validationSchema: validationSchemaRegisterTab1,
    onSubmit: (values) => {
      let { repeat, ...rest } = values; // eslint-disable-line no-unused-vars
      handleNext(values, activeTab, true)
    },
  });

  const isSalutationSelected = formik.values.salutation && formik.values.salutation !== "";

  const step1Handle = () => {
    formik.submitForm()
  }

  return (
    <>
      <h3 className="registerHeading mt-3">{heading}</h3>
      <h4 className="text-center text-lg text-SmokeyGray mt-1 mb-6 dark:text-BrightGray">{subHeading}</h4>
      <hr className="dark:border-SmokeyGray" />
      <form onSubmit={formik.handleSubmit}>
        <RegisterGrid heading={personalData}>
          <StandardSelect
            extra="mb-5"
            extraLabel="w-full md:w-[200px] lg:w-[260px] xl:w-[300px] 2xl:w-[340px]"
            choose={!isSalutationSelected}
            label={salutation}
            placeholder="Herr/Frau"
            id="salutation"
            options={salutationOptions}
            formik={formik}
          />
          <InputStandardField extra="md:!flex-row !mb-5" label={title} placeholder={title} id="title" type="text" formik={formik} />

          <InputStandardField extra="md:!flex-row !mb-5" label={firstName} placeholder={firstName} id="first_name" type="text" formik={formik} />

          <InputStandardField extra="md:!flex-row !mb-5" label={lastName} placeholder={lastName} id="last_name" type="text" formik={formik} />

          <InputStandardField extra="md:!flex-row !mb-5" label={phone} placeholder={phone} id="phone_number" type="number" formik={formik} />
        </RegisterGrid>
        <hr className="dark:border-SmokeyGray" />

        <RegisterGrid heading={loginData}>
          <InputStandardField extra="!mb-5 !flex-col" extraLabel="!w-full" label={email} placeholder="max@mustermann.de" id="email" type="email" formik={formik} />
          
          <InputStandardField extra="!mb-5 !flex-col" extraLabel="!w-full" label={confirmEmail} placeholder="max@mustermann.de" id="confirm_email" type="email" formik={formik} />

          <div className="mb-5">
            <PasswordCheckList id="password" label={passwordLevel} placeholder={passwordLevel} type="checkpassword" formik={formik} />
          </div>
          <div className="mb-5">
            <PasswordCompare placeholder={repeat} id="repeat" name="repeat" formik={formik} />
          </div>
        </RegisterGrid>
        <hr className="dark:border-SmokeyGray" />
        <div className="flex flex-col md:grid md:grid-cols-4 my-4 md:my-6 lg:my-8 rounded-xl">
          <div className="col-span-1 lg:col-span-1 bg-white rounded-xl md:rounded-tr-none md:rounded-br-none invisible"></div>
          <div className="col-span-3 lg:col-span-3 md:grid">
            <h4 className="w-full text-right my-4">
              Sie haben bereits ein Konto?{" "}
              <span
                onClick={() => {
                  navigate("/login");
                }}
                className="underline cursor-pointer hover:text-AFPPrimary mr-3 dark:hover:text-AFPPrimaryLight"
              >
                Anmeldung
              </span>
            </h4>
          </div>
        </div>
      </form>
      <div className="flex justify-center mt-6 gap-8">
        <PrimaryButton
          handler={step1Handle}
          disable={activeTab === tabs.length - 1 ? true: false}
          btnStyle="!w-[150px]"
          title="Nächste"
          icon={<GrNext />}
          position={"after"}
        />
      </div>
    </>
  );
};

export default FirstRegisterTab;
