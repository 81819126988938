import FromToYou from "src/components/pages/document/FromToYou";
import UserFiles from "src/components/pages/document/UserFiles";
import Overview from "src/components/pages/procedure/Overview";
import Past from "src/components/pages/procedure/Past";
import ChangePassword from "src/components/pages/settings/ChangePassword";
import Management from "src/components/pages/settings/Management";
import PaymentSettings from "src/components/pages/settings/PaymentSettings";
import Personal from "src/components/pages/settings/Personal";

export const tabList = (setting) => {
  return {
    boxCss: "",
    tabs: [
      {
        id: 0,
        uuid: "persoenliche-daten",
        label: setting?.personal_data,
        component: <Personal />,
      },
      {
        id: 1,
        uuid: "zahlungsadressen",
        label: setting?.payment_settings,
        component: <PaymentSettings />,
      },
      {
        id: 2,
        uuid: "nutzerverwaltung",
        label: setting?.user_management,
        component: <Management />,
      },
      {
        id: 3,
        uuid: "kennwort-aendern",
        label: setting?.change_password,
        component: <ChangePassword />,
      },
    ],
  };
};

export const TABLISTHEAD = (personal_data, payment_settings, user_management, change_password) => {
  return [
    {
      id: 0,
      uuid: "personal",
      label: personal_data,
      component: <Personal />,
      key: ["personal_data"],
    },
    {
      id: 2,
      uuid: "payment-settings",
      label: payment_settings,
      component: <PaymentSettings />,
      key: ["payment_settings"],
    },
    {
      id: 3,
      uuid: "payment-settings",
      label: user_management,
      component: <Management />,
      key: ["user_management"],
    },
    {
      id: 4,
      uuid: "payment-settings",
      label: change_password,
      component: <ChangePassword />,
      key: ["change_password"],
    },
  ];
};

export const documenTabs = (document) => {
  return {
    boxCss: "",
    tabs: [
      {
        id: 0,
        uuid: "afpq",
        label: document.toyou,
        title: "Von uns, für Sie",
        component: <FromToYou />,
      },
      {
        id: 1,
        uuid: "eigene",
        label: document.userfiles,
        title: "Von mir hochgeladen",
        component: <UserFiles />,
      },
    ],
  };
};

export const applicationTabs = () => {
  return {
    boxCss: "",
    tabs: [
      {
        id: 0,
        uuid: "uebersicht",
        label: "Übersicht",
        component: <Overview />,
      },
      {
        id: 1,
        uuid: "abgeschlossen",
        label: "Abgeschlossene Verfahren",
        component: <Past />,
      },
    ],
  };
};

export const UMSTABLEHEAD = (email, name, business_premises) => {
  return [
    {
      id: 1,
      title: email,
      icons: true,
      sortKey: "email",
    },
    {
      id: 2,
      title: name,
      icons: true,
      sortKey: "first_name",
    },
    {
      id: 3,
      title: business_premises,
      icons: true,
      sortKey: "b_location",
    },
    {
      id: 4,
      title: '',
      icons: false,
      sortKey: "",
    },
  ];
};

export const settingRole = [
  { title: "Bearbeiter", value: "bearbeiter" },
  { title: "Inhaber", value: "inhaber" },
];

export const documentRole = [
  { title: "Alle Dokumente", value: "Alle Dokumente" },
  { title: "Alle Rechnungen", value: "Invoice/credit note" },
  { title: "Alle Zertifikate", value: "Verfahren" },
];
