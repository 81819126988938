import React from "react";

const ApplicationCard = ({ children, css }) => {
  return (
    <div className={`relative flex flex-col w-60 sm:w-80 h-48 rounded-xl border-[#CBCBCB] shadow-md bg-[#fff] hover:border-AFPPrimary border hover:shadow-xl duration-300 dark:bg-AFPDarkBgLight dark:border-SmokeyGray dark:hover:border-AFPPrimary ${css}`}>
      {children}
    </div>
  );
};

ApplicationCard.defaultProps = {
  css: "",
  children: null,
};

export default ApplicationCard;
