/*eslint-disable */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import FilterField from "src/components/molecules/dform/FilterField";

const CoverageInfo = ({ data, step_id }) => {
  const [currentHelperText, setCurrentHelperText] = useState("");
  const cmsDocument = useSelector((state) => state.cmsDocument);
  const stepsCMS = cmsDocument?.dFormCMS?.data;
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll(".evidence-section");
      let currentSection = { key: "", position: 100 };

      sections?.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect?.top <= 400 && rect?.bottom >= 10) {
          const key = section?.getAttribute("data-key");
          currentSection = { key, position: rect?.top };
        }
      });

      setCurrentHelperText(currentSection?.key);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  const handleHover = (item) => {
    console.log({ inputHint: item.inputHint, helpText: item.helpText });
  };

  return (
    <div className="flex justify-between mb-40">
      <div className={`w-full`}>
        {data.map((section, i) => {
          const showCard = currentHelperText === section.id || (currentHelperText === "" && i === 0);
          return (section?.inputType === "service-areas-modal" || section?.inputType === "service-areas-modal-readable") ?
           (
            <div className={`flex evidence-section w-full gap-x-4 justify-between mt-4 p-2 ${(section?.inputType === "service-areas-modal" || section?.inputType === "service-areas-modal-readable") && (section?.inputTypeValue?.length>0 && id =='KA' ? 'min-h-[34vh] xl19:min-h-[42vh] xl24:min-h-[48vh]' : 'min-h-[54vh] xl19:min-h-[64vh] xl24:min-h[84vh]') }`} data-key={section.id} data-disable={section?.helperTextDisable} key={i}>
              <div className={`${section.id !== "03_SUPPLY_AREAS" ? "w-full md:min-w-[calc(100%-400px)] lg:min-w-[calc(100%-550px)] flex-1" : " w-full relative"}`}>
                <FilterField data={section} step_id={step_id} />
              </div>
            </div>
          ): (
            <div className="flex evidence-section w-full gap-x-4" data-key={section.id} data-disable={section?.helperTextDisable} key={i}>
              <div className={`flex-1 ${showCard && "relative"}`}>
                <FilterField
                  data={section}
                  handleHover={handleHover}
                  showCard={showCard}
                  page={"dform"}
                  step_id={step_id}
                  helperText={stepsCMS[section?.id] && stepsCMS[section?.id]?.helpdesk}
                />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default CoverageInfo;
