import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import businessReducer from "./reducers/business";
import cmsDashboardReducer from "./reducers/cmsDashboard";
import documentReducer from "./reducers/document";
import profileReducer from "./reducers/settings";
import settingsReducers from "./reducers/userSettings";
import usersReducer from "./reducers/users";
import helpReducer from "./reducers/help";
import inboxReducer from "./reducers/inbox";
import procedureReducer from "./reducers/procedure";
import applicationReducer from "./reducers/application";
import dformReducer from "./reducers/dfrom";
import dashboardReducer from "./reducers/dashboard";
import marketingReducer from "./reducers/marketing";
import recruitReducer from "./reducers/recruitClient";
import serviceOrderReducer from "./reducers/serviceOrder";
import currentStepReducer from "./reducers/currentStep";
import evidenceReducer from "./reducers/evidence";
import helpdeskReducer from "./reducers/helpdesk";
import downloadReducer from "./reducers/download";
import userAnalyticsReducer from "./reducers/userAnalytics";
import themeReducer from "./reducers/theme";
import { watcherSaga } from "./sagas/rootSaga";

const reducers = combineReducers({
  users: usersReducer,
  settings: settingsReducers,
  profile: profileReducer,
  management: businessReducer,
  document: documentReducer,
  cmsDocument: cmsDashboardReducer,
  help: helpReducer,
  inbox: inboxReducer,
  procedure: procedureReducer,
  application: applicationReducer,
  dform: dformReducer,
  dashboard: dashboardReducer,
  marketing: marketingReducer,
  recruit: recruitReducer,
  serviceOrder: serviceOrderReducer,
  currentStep: currentStepReducer,
  evidence: evidenceReducer,
  helpdesk: helpdeskReducer,
  download: downloadReducer,
  userAnalytics: userAnalyticsReducer,
  theme: themeReducer,
});

const sageMiddleware = createSagaMiddleware();
const middleware = [sageMiddleware];

const composeEnhancers = typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

const store = createStore(reducers, composeEnhancers(applyMiddleware(...middleware)));

sageMiddleware.run(watcherSaga);

export default store;
