import { TableCell, TableHead, TableRow } from "@mui/material";
import { SlArrowUp, SlArrowDown } from "react-icons/sl";
const MHead = ({ headList }) => {
  return (
    <>
      <TableHead>
        <TableRow>
          {headList.map((head) => {
            return (
              <TableCell
                key={head.id}
                align={head.align}
              >
                <div className={`font-tahoma-bold text-SmokeyGray`}>
                  <p className="text-SmokeyGray dark:text-BrightGray">{head?.title}</p>
                  {head?.icons === true && (
                    <>
                      <div className="w-[20px] flex flex-col justify-center items-center text-SmokeyGray">
                        <SlArrowUp
                          size={9}
                          className="flex cursor-pointer hover:text-AFPPrimary font-medium"
                        />
                        <SlArrowDown
                          size={9}
                          className="flex cursor-pointer hover:text-AFPPrimary font-medium"
                        />
                      </div>
                    </>
                  )}
                </div>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    </>
  );
};

MHead.defaultProps = {
  headList: [],
};

export default MHead;
