import React, { useEffect, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerStyles.css";
import { FaCalendarAlt } from "react-icons/fa";
import { addCurrentStepDetailValue } from "src/store/actions/currentStep";
import { useDispatch } from "react-redux";
import { formatDate } from "src/helpers/document";
import de from "date-fns/locale/de"; // Import the German locale

registerLocale("de", de); // Register the German locale

const DatePickerOn = ({ data, stepsCMS }) => {
  const [selectedDate, setSelectedDate] = useState(data?.inputTypeValue ? new Date(data?.inputTypeValue?.split('.').reverse().join('-')) : new Date());
  const datePickerRef = useRef(null);
  const dispatch = useDispatch();

  const handleDateChange = (date) => {
    setSelectedDate(date);
    dispatch(
      addCurrentStepDetailValue({
        valueId: data.id,
        value: formatDate(date),
        valueKey: "inputTypeValue",
      })
    );
  };

  const handleIconClick = () => {
    datePickerRef.current.setFocus();
  };

  useEffect(() => {
    dispatch(
      addCurrentStepDetailValue({
        valueId: data.id,
        value: formatDate(new Date()),
        valueKey: "inputTypeValue",
      })
    );
  }, []);

  return (
    <div className="mt-12 min-h-[24vh]">
      <div className="w-9/12">
        <h1 className={`!mb-5 text-2xl font-tahoma-bold text-black dark:!text-BrightGray`}>{stepsCMS[data?.id]?.title}</h1>

        <div className="justify-start gap-12 flex flex-col md:flex-row">
          <p className="textStyle max-w-[90%] mb-5" dangerouslySetInnerHTML={{ __html: stepsCMS[data?.id]?.description }}></p>
        </div>
      </div>
      <div className="date-picker-container">
        <DatePicker
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="yyyy/MM/dd"
          className="date-picker-input dark:bg-AFPDarkBgLight dark:border-SmokeyGray"
          ref={datePickerRef}
          locale="de"
          minDate={new Date()} 
        />
        <FaCalendarAlt className="calendar-icon text-AFPPrimary" onClick={handleIconClick} />
      </div>
    </div>
  );
};

export default DatePickerOn;
