import { useState } from "react";
import DecreasefillCard from "../DecreasefillCard";
import ExpandableCard from "../ExpandableCard";

const ServiceAreaShowExpand = ({ showCard, page, aid, helperText, miniAids }) => {
  const [cardExapand, setCardExpand] = useState(false)
  return (
    <div className={`hidden lg:block ${miniAids ? "md:min-w-[90px] lg:min-w-[90px] xl:min-w-[90px]" : cardExapand ? "w-[700px] xl19:w-[720px] xl24:w-[900px]" : "w-[700px]"}`}>
      <div
        className={`w-full h-10 relative bg-transparent ${cardExapand && "xl19:w-[720px] xl24:w-[900px]"}`}
        style={{
          position: "sticky",
          top: "220px",
        }}
      >
        {showCard && (
          <div className="z-10 absolute w-full top-0 left-0">
            <DecreasefillCard className="relative w-[100%]" miniAids={miniAids}>
              <ExpandableCard page={page} aid={aid} miniAids={miniAids} helperText={helperText} setCardExpand={setCardExpand} />
            </DecreasefillCard>
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceAreaShowExpand;
