import React from 'react';
import SignatureByContact from './SignatureByContact';
import { Box, Modal } from '@mui/material';
import { AiOutlineClose } from 'react-icons/ai';
import { useSelector } from 'react-redux';

const style = {
  p: 2,
  top: "50%",
  left: "50%",
  width: "90%",
  boxShadow: 24,
  maxWidth: "850px",
  // bgcolor: "#FBFBFB",
  borderRadius: "12px",
  position: "absolute",
  border: "1px solid #CBCBCB",
  transform: "translate(-50%, -50%)",
};

const ContactSignatureCanvas = ({ openSignature, handleClose, item }) => {
  const cmsDocument = useSelector((state) => state.cmsDocument);
  const stepsCMS = cmsDocument?.dFormCMS?.data;
  return (
    <>
      {openSignature && (
        <Modal open={openSignature} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={style} className="outline-none bg-AFPBGPrimary dark:bg-AFPDarkBgDarkest dark:border-SmokeyGray">
            <div className={`overflow-y-auto overflow-x-hidden customScrollBar w-full p-1`}>
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl md:text-2xl font-tahoma-bold dark:text-BrightGray">{stepsCMS?.SIGNATURE_HINT?.heading}</h2>
                <AiOutlineClose size={24} className="text-SmokeyGray cursor-pointer -mt-2 dark:text-BrightGray" onClick={handleClose} />
              </div>
              <p className="text-base mb-6 dark:text-BrightGray">{stepsCMS?.SIGNATURE_HINT?.description}</p>
              <SignatureByContact item={item} handleClose={handleClose} />
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ContactSignatureCanvas;
