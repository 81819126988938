import * as yup from "yup";

export const initialValuesRegister = {
  salutation: "",
  title: "",
  first_name: "",
  last_name: "",
  company_name: "",
  email: "",
  password: "",
  repeat: "",
};

export const validationSchemaRegister = yup.object().shape({
  salutation: yup.string().required("Anrede muss ausgefüllt werden"),
  // title: yup.string().required("Titel muss ausgefüllt werden"),
  first_name: yup.string().required("Vorname muss ausgefüllt werden"),
  last_name: yup.string().required("Nachname muss ausgefüllt werden"),
  company_name: yup.string().required("Firmenname muss ausgefüllt werden"),
  email: yup.string().required("Bitte geben Sie als Benutzernamen Ihre E-Mail-Adresse ein").email("E-Mail ist ungültig"),
  password: yup
    .string()
    .required("Passwort muss ausgefüllt werden")
    .test(
      "password",
      "Das Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Ziffer und ein Sonderzeichen enthalten und mindestens 8 Zeichen lang sein. Leerzeichen sind nicht zulässig.",
      (value) => {
        return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?/\\~-])(?=\S+$).{8,}$/.test(value) && !/\s/.test(value);
      }
    ),
  repeat: yup
    .string()
    .required("Passwort wiederholen muss ausgefüllt werden")
    .oneOf([yup.ref("password"), null], "Das Passwort muss übereinstimmen"),
});

export const initialValuesRegisterTab1 = {
  salutation: "",
  title: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  email: "",
  confirm_email: "",
  password: "",
  repeat: "",
};

export const initialValuesRegisterTab2 = {
  data_protection_declaration: false,
  declaration: false,
  terms_condition: false,
  certificate: false,
};

export const initialValuesRegisterTab3 = {
  company_name: "",
  ik_number: [""],
  street: "",
  house_number: "",
  post_code: "",
  city: "",
  is_main_location: false,
  is_store_location: false,
  legal_form: "",
};

export const validationSchemaRegisterTab1 = yup.object().shape({
  salutation: yup.string().required("Anrede muss ausgefüllt werden"),
  first_name: yup.string().required("Vorname muss ausgefüllt werden"),
  last_name: yup.string().required("Nachname muss ausgefüllt werden"),
  email: yup.string().required("Bitte geben Sie als Benutzernamen Ihre E-Mail-Adresse ein").email("E-Mail ist ungültig"),
  confirm_email: yup.string().required("Bitte bestätigen Sie Ihre E-Mail-Adresse").oneOf([yup.ref("email"), null], "Die E-Mail-Adressen stimmen nicht überein"),
  phone_number: yup.string().required("Telefonnummer muss ausgefüllt werden"),
  password: yup
    .string()
    .required("Passwort muss ausgefüllt werden")
    .test(
      "password",
      "Das Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Ziffer und ein Sonderzeichen enthalten und mindestens 8 Zeichen lang sein. Leerzeichen sind nicht zulässig.",
      (value) => {
        return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+{}[\]:;<>,.?/\\~-])(?=\S+$).{8,}$/.test(value) && !/\s/.test(value);
      }
    ),
  repeat: yup
    .string()
    .required("Passwort wiederholen muss ausgefüllt werden")
    .oneOf([yup.ref("password"), null], "Das Passwort muss übereinstimmen"),
});

export const validationSchemaRegisterTab2 = yup.object({
  data_protection_declaration: yup.boolean().oneOf([true], "Bitte bestätigen Sie die Datenschutzerklärung.").required("Bitte bestätigen Sie die Datenschutzerklärung."),
   terms_condition: yup
    .boolean()
    .oneOf([true], "Bitte akzeptieren Sie die AGB")
    .required("Bitte akzeptieren Sie die AGB"),
    certificate: yup
    .boolean()
    .oneOf([true], "Bitte akzeptieren Sie die Zertifizierungsvereinbarung")
    .required("Bitte akzeptieren Sie die Zertifizierungsvereinbarung"),
    declaration: yup
    .boolean()
    .oneOf([true], "Bitte akzeptieren Sie die Ich bestätige")
    .required("Bitte akzeptieren Sie die Ich bestätige"),
});

export const validationSchemaRegisterTab3 = yup.object({
  company_name: yup.string().trim().required("Firmennamen ist erforderlich"),

  ik_number: yup.array().of(yup.string().required("IK-Nummer darf nicht leer sein")).min(1, "Mindestens eine IK-Nummer ist erforderlich").required("IK-Nummer ist erforderlich"),

  street: yup.string().trim().required("Straße ist erforderlich"),

  house_number: yup.string().trim().required("Hausnummer ist erforderlich"),

  post_code: yup.string().trim().required("Postleitzahl ist erforderlich").length(5, "PLZ muss genau 5 Zeichen lang sein"),

  city: yup.string().trim().required("Stadt ist erforderlich"),

  is_main_location: yup.boolean().required("Hauptstandortstatus ist erforderlich"),

  is_store_location: yup.boolean().required("Ladengeschäft-Status ist erforderlich"),

  legal_form: yup.string().trim().required("Rechtsform ist erforderlich"),
});
