import { useSelector } from "react-redux";
import { Paper, Table, TableBody, TableContainer } from "@mui/material";
import EvidenceCMSButtonCard from "./EvidenceCMSButtonCard";
import MTable from "src/components/pages/settings/Management/MTable";

const EvideceTabCMS = () => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);

  return (
    <div className="my-4">
      <hr className="my-8 dark:border-SmokeyGray" />
      <div>
        {cmsDashboarddata?.evidence_cms && (
          <div>
            <TableContainer component={Paper} className={"w-[650px]"}>
              <Table aria-label="simple table">
                <MTable headList={headList}>
                  <TableBody>
                    {Object.keys(cmsDashboarddata?.evidence_cms).map((value) => {
                      return value !== "cms_key" && <EvidenceCMSButtonCard value={value} />;
                    })}
                  </TableBody>
                </MTable>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </div>
  );
};

export default EvideceTabCMS;

const headList = [
  {
    id: 1,
    title: "Title",
    key: ["title", "subtitle"],
    align: "left",
  },
  {
    id: 4,
    title: "Actions ",
    key: ["date"],
    align: "center",
  },
];
