import {
  USER_ABANDONED_CART_FAILURE,
  USER_ABANDONED_CART_STARTED,
  USER_ABANDONED_CART_SUCCESS,
  USER_ACTIVITY_ON_FAILURE,
  USER_ACTIVITY_ON_STARTED,
  USER_ACTIVITY_ON_SUCCESS,
  USER_ACTIVITY_STATISTIK_FAILURE,
  USER_ACTIVITY_STATISTIK_STARTED,
  USER_ACTIVITY_STATISTIK_SUCCESS,
  USER_ANALYTICS_EVIDENCE_FAILURE,
  USER_ANALYTICS_EVIDENCE_STARTED,
  USER_ANALYTICS_EVIDENCE_SUCCESS,
  USER_DEVICE_SOURCE_FAILURE,
  USER_DEVICE_SOURCE_STARTED,
  USER_DEVICE_SOURCE_SUCCESS,
  USER_LOGIN_SESSION_FAILURE,
  USER_LOGIN_SESSION_STARTED,
  USER_LOGIN_SESSION_SUCCESS,
  USER_LOGIN_TIME_DURATION_FAILURE,
  USER_LOGIN_TIME_DURATION_STARTED,
  USER_LOGIN_TIME_DURATION_SUCCESS,
  USER_LOGIN_TIMES_DURATION_FAILURE,
  USER_LOGIN_TIMES_DURATION_STARTED,
  USER_LOGIN_TIMES_DURATION_SUCCESS,
  USER_LOGIN_TIMES_FAILURE,
  USER_LOGIN_TIMES_STARTED,
  USER_LOGIN_TIMES_SUCCESS,
} from "../actionTypes";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  login_times: {
    data: null,
    isLoading: false,
    error: null,
  },
  login_time_average: {
    data: null,
    isLoading: false,
    error: null,
  },
  login_session: {
    data: null,
    isLoading: false,
    error: null,
  },
  abandoned_cart: {
    data: null,
    isLoading: false,
    error: null,
  },
  login_duration: {
    data: null,
    isLoading: false,
    error: null,
  },
  evidence_analytics: {
    data: null,
    isLoading: false,
    error: null,
  },
  activity_statistik: {
    data: null,
    isLoading: false,
    error: null,
  },
  activity_on: {
    data: null,
    isLoading: false,
    error: null,
  },
};

const userAnalyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_TIMES_STARTED:
      return {
        ...state,
        login_times: {
          data: null,
          isLoading: true,
          error: null,
        },
      };
    case USER_LOGIN_TIMES_SUCCESS:
      return {
        ...state,
        login_times: {
          data: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case USER_LOGIN_TIMES_FAILURE:
      return {
        ...state,
        login_times: {
          data: null,
          isLoading: false,
          error: action.payload,
        },
      };
    case USER_LOGIN_SESSION_STARTED:
      return {
        ...state,
        login_session: {
          data: null,
          isLoading: true,
          error: null,
        },
      };
    case USER_LOGIN_SESSION_SUCCESS:
      return {
        ...state,
        login_session: {
          data: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case USER_LOGIN_SESSION_FAILURE:
      return {
        ...state,
        login_session: {
          data: null,
          isLoading: false,
          error: action.payload,
        },
      };
    case USER_LOGIN_TIME_DURATION_STARTED:
      return {
        ...state,
        login_time_average: {
          data: null,
          isLoading: true,
          error: null,
        },
      };
    case USER_LOGIN_TIME_DURATION_SUCCESS:
      return {
        ...state,
        login_time_average: {
          data: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case USER_LOGIN_TIME_DURATION_FAILURE:
      return {
        ...state,
        login_time_average: {
          data: null,
          isLoading: false,
          error: action.payload,
        },
      };
      case USER_ABANDONED_CART_STARTED:
        return {
          ...state,
          abandoned_cart: {
            data: null,
            isLoading: true,
            error: null,
          },
        };
      case USER_ABANDONED_CART_SUCCESS:
        return {
          ...state,
          abandoned_cart: {
            data: action.payload,
            isLoading: false,
            error: null,
          },
        };
      case USER_ABANDONED_CART_FAILURE:
        return {
          ...state,
          abandoned_cart: {
            data: null,
            isLoading: false,
            error: action.payload,
          },
        };
      case USER_LOGIN_TIMES_DURATION_STARTED:
        return {
          ...state,
          login_duration: {
            data: null,
            isLoading: true,
            error: null,
          },
        };
      case USER_LOGIN_TIMES_DURATION_SUCCESS:
        return {
          ...state,
          login_duration: {
            data: action.payload,
            isLoading: false,
            error: null,
          },
        };
      case USER_LOGIN_TIMES_DURATION_FAILURE:
        return {
          ...state,
          login_duration: {
            data: null,
            isLoading: false,
            error: action.payload,
          },
        };
      case USER_DEVICE_SOURCE_STARTED:
        return {
          ...state,
          source_device: {
            data: null,
            isLoading: true,
            error: null,
          },
        };
      case USER_DEVICE_SOURCE_SUCCESS:
        return {
          ...state,
          source_device: {
            data: action.payload,
            isLoading: false,
            error: null,
          },
        };
      case USER_DEVICE_SOURCE_FAILURE:
        return {
          ...state,
          source_device: {
            data: null,
            isLoading: false,
            error: action.payload,
          },
        };
      case USER_ANALYTICS_EVIDENCE_STARTED:
        return {
          ...state,
          evidence_analytics: {
            data: null,
            isLoading: true,
            error: null,
          },
        };
      case USER_ANALYTICS_EVIDENCE_SUCCESS:
        return {
          ...state,
          evidence_analytics: {
            data: action.payload,
            isLoading: false,
            error: null,
          },
        };
      case USER_ANALYTICS_EVIDENCE_FAILURE:
        return {
          ...state,
          evidence_analytics: {
            data: null,
            isLoading: false,
            error: action.payload,
          },
        };
      case USER_ACTIVITY_STATISTIK_STARTED:
        return {
          ...state,
          activity_statistik: {
            data: null,
            isLoading: true,
            error: null,
          },
        };
      case USER_ACTIVITY_STATISTIK_SUCCESS:
        return {
          ...state,
          activity_statistik: {
            data: action.payload,
            isLoading: false,
            error: null,
          },
        };
      case USER_ACTIVITY_STATISTIK_FAILURE:
        return {
          ...state,
          activity_statistik: {
            data: null,
            isLoading: false,
            error: action.payload,
          },
        };
      case USER_ACTIVITY_ON_STARTED:
        return {
          ...state,
          activity_on: {
            data: null,
            isLoading: true,
            error: null,
          },
        };
      case USER_ACTIVITY_ON_SUCCESS:
        return {
          ...state,
          activity_on: {
            data: action.payload,
            isLoading: false,
            error: null,
          },
        };
      case USER_ACTIVITY_ON_FAILURE:
        return {
          ...state,
          activity_on: {
            data: null,
            isLoading: false,
            error: action.payload,
          },
        };
    default:
      return state;
  }
};

export default userAnalyticsReducer;
