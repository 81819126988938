import { useFormik } from "formik";
import { Box, Modal } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import { SecondaryButton } from "src/components/atoms/button";
import { INHABERINITIALVALUSE, VALIDATIONSCHEMAINHABER } from "src/services/validation/buinessPremises";
import NewFormInhaber from "./NewFormInhaber";
import { useEffect, useState } from "react";
import ExistingFormInhaber from "./ExistingFormInhaber";
import { useDispatch, useSelector } from "react-redux";
import { modalStyleStandard } from "../modalStyle";
import EditIconCMS from "../../button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";

const AddInhaberForm = ({ handleClose, handleOpen, addinhaberHandler, initialValues, isLoading }) => {
  const [NewInhablerSelect, setNewInhaberSelect] = useState({
    new: null,
    exiting: null,
  });
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const business = useSelector((state) => state?.management);
  const cmsData = cmsDashboarddata?.business_premises?.new_inhaber_group_label;
  const formik = useFormik({
    initialValues: initialValues !== null ? initialValues : INHABERINITIALVALUSE,
    validationSchema: VALIDATIONSCHEMAINHABER,
    onSubmit: (values) => {
      addinhaberHandler({ ...values, status: initialValues === null ? "add" : "edit" });
      handleClose();
      formik.resetForm();
    },
  });

  const dispatch = useDispatch();

  const selectHandle = (values) => {
    setNewInhaberSelect(values);
  };

  useEffect(() => {
    if (initialValues !== null) {
      setNewInhaberSelect({
        new: "selected",
        exiting: null,
      });
    }
  }, []);

  return (
    <Modal open={handleOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Box sx={modalStyleStandard} className="outline-none bg-AFPBGPrimary dark:bg-AFPDarkBgDarkest dark:border-SmokeyGray">
        <div className={`max-h-[90vh] overflow-y-auto overflow-x-hidden customScrollBar w-full p-1`}>
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl md:text-2xl font-tahoma-bold dark:text-BrightGray">
            {cmsData?.heading}
              <EditIconCMS
                handleOpen={() => {
                  dispatch(
                    dashboardModalOpenCMS({
                      cms_key: "business_premises",
                      unique: "new_inhaber_group_label",
                      heading: "Dokumentenverwaltung",
                    })
                  );
                }}
              />

            </h2>
            <AiOutlineClose size={24} className="text-SmokeyGray cursor-pointer -mt-2 dark:text-BrightGray" onClick={handleClose} />
          </div>
          {NewInhablerSelect.new === null && NewInhablerSelect.exiting === null && (
            <>
              {isLoading !== true && (
                business?.contactList?.data?.filter((data) => data?.contact_class === "INHABER")?.length>0 && <SecondaryButton
                  variant="secondary"
                  title="Vorhandener Inhaber"
                  css="w-full my-8"
                  handler={() => {
                    selectHandle({
                      new: null,
                      exiting: "selected",
                    });
                  }}
                />
              )}
              <SecondaryButton
                variant="secondary"
                title="neu Inhaber"
                css="w-full my-8"
                handler={() => {
                  selectHandle({
                    new: "selected",
                    exiting: null,
                  });
                }}
              />
            </>
          )}
          {NewInhablerSelect.new && <NewFormInhaber formik={formik} handleClose={handleClose} />}

          {NewInhablerSelect.exiting && (
            <ExistingFormInhaber
              handleClose={handleClose}
              addinhaberHandler={addinhaberHandler}
              inhaberList={business?.contactList?.data?.filter((data) => data.contact_class === "INHABER")}
            />
          )}
        </div>
      </Box>
    </Modal>
  );
};

AddInhaberForm.defaultProps = {
  handleClose: () => {},
  handleOpen: false,
  addinhaberHandler: () => {},
  initialValues: null,
  isLoading: false,
};

export default AddInhaberForm;
