import React from 'react'

const AddressRelocation = ({data}) => {
  return (
    <>
      {Object.keys(data?.inputTypeValue)?.length > 0 && <table className="table-auto border-1 border-black w-full my-5">
        <thead>
          <tr className="border border-1 border-black dark:border-SmokeyGray">
            <th className="font-tahoma-bold flex flex-start pl-3 py-1 dark:text-BrightGray">Informationen zur Adresse</th>
          </tr>
        </thead>
        <tbody className="border border-1 border-black dark:border-SmokeyGray">
       {Object.keys(data?.inputTypeValue).map((item,index) => {
          return (
          <tr className="border border-1 border-black dark:border-SmokeyGray">
            <td className="border border-1 border-black pl-3 py-1 capitalize dark:border-SmokeyGray dark:text-BrightGray">{item.replace("_"," ")}</td>
            <td className="pl-2 py-1 dark:text-BrightGray">{" " + Object.values(data?.inputTypeValue)[index]}</td>
          </tr>
        ) })
        }
        </tbody>
      </table>}
      </>
  )
}

export default AddressRelocation ;