/* eslint-disable */
import { InputField, PasswordField } from "src/components/atoms/input";
import { INITIALVALUESLOGIN, VALIDATIONSCHEMALOGIN } from "src/services/validation/users";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "src/store/actions/users";
import { SubmitButton } from "src/components/atoms/button";
import AccountYet from "src/components/atoms/extra/AccountYet";
import { isMobile, isTablet, browserName } from "react-device-detect";
import { useEffect, useState } from "react";
import { Checkbox } from "@mui/material";
import { green } from "@mui/material/colors";

const { email, password, checkbox, forget, btnHeading } = {
  email: "E-Mail",
  password: "Passwort",
  checkbox: "Für 30 Tage speichern",
  forget: "Passwort vergessen?",
  btnHeading: "Einloggen",
};

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const users = useSelector((state) => state.users);

  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem("hs_theme");
    return savedTheme === "dark" || (!savedTheme && window.matchMedia("(prefers-color-scheme: dark)").matches);
  });

  const formik = useFormik({
    initialValues: INITIALVALUESLOGIN,
    validationSchema: VALIDATIONSCHEMALOGIN,
    onSubmit: (values) => {
      let device_type = "PC";
      if (isMobile && !isTablet) {
        device_type = "Smartphone";
      } else if (isTablet) {
        device_type = "Tablet";
      }
      const payload = {
        ...values,
        device_type: device_type,
        browser: browserName,
      };
      dispatch(loginUser(payload));
    },
  });

  useEffect(()=>{
    localStorage.removeItem("register_tabs");
  },[])

  return (
    <form className="p-4 py-1 2xl:py-4" onSubmit={formik.handleSubmit}>
      <div className="flex flex-col gap-8">
        <InputField name="email" css="!font-tahoma-bold" label={email} type="email" variant="standard" formik={formik} isDark={isDarkMode} />
        <PasswordField name="password" css="!font-tahoma-bold" label={password} formik={formik} isDark={isDarkMode} />
      </div>
      <div className="flex items-center justify-between 2xl:my-6 my-2 ml-2">
        <div className="flex items-start">
          <Checkbox
            id="is-default-remember"
            className="!cursor-pointer"
            sx={{
              color: "#484848",
              padding:0,
              "&.Mui-checked": {
                color: green[600],
              },
            }}
            checked={formik.values["remember"]}
            onChange={(e) => formik.setFieldValue("remember", e.target.checked)}
          />
          <label htmlFor="is-default-remember" className="flex items-center cursor-pointer text-[#1C1A1A] font-tahoma-bold dark:text-BrightGray">
            <span className="ml-2">{checkbox}</span>
          </label>
        </div>
        <p onClick={() => navigate("/passwort-vergessen")} className="text-sm text-SmokeyGray cursor-pointer dark:text-BrightGray">
          {forget}
        </p>
      </div>
      <SubmitButton css="flexCenter mt-4 2xl:mt-16" type="submit" btnTitle={btnHeading} loading={users.isLoading} />
      <AccountYet />
    </form>
  );
};

export default LoginForm;
