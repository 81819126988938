import React, { useState, useRef } from "react";
import { PrimaryButton, SecondaryButton } from "src/components/atoms/button";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { applicationSignatureById } from "src/store/actions/application";

const SignatureByContact = ({ item, handleClose }) => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [signatureDataURL, setSignatureDataURL] = useState(null);
  const dispatch = useDispatch();

  const getTouchPos = (canvas, touchEvent) => {
    const rect = canvas.getBoundingClientRect();
    return {
      x: touchEvent.touches[0].clientX - rect.left,
      y: touchEvent.touches[0].clientY - rect.top,
    };
  };

  const startDrawing = (event) => {
    event.preventDefault();
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const pos = event.type.includes("touch") ? getTouchPos(canvas, event) : { x: event.nativeEvent.offsetX, y: event.nativeEvent.offsetY };

    context.beginPath();
    context.moveTo(pos.x, pos.y);
    setIsDrawing(true);
  };

  const draw = (event) => {
    event.preventDefault();
    if (!isDrawing) return;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const pos = event.type.includes("touch") ? getTouchPos(canvas, event) : { x: event.nativeEvent.offsetX, y: event.nativeEvent.offsetY };

    context.lineTo(pos.x, pos.y);
    context.stroke();
  };

  const finishDrawing = (event) => {
    event.preventDefault();
    const canvas = canvasRef.current;
    const dataURL = canvas.toDataURL();
    setSignatureDataURL(dataURL);
    setIsDrawing(false);
  };

  const clearSignature = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    setSignatureDataURL(null);
  };

  const handleSaveSignature = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const isEmptyCanvas = context.getImageData(0, 0, canvas.width, canvas.height).data.every((pixel) => pixel === 0);

    if (!signatureDataURL || isEmptyCanvas) {
      toast.error("Bitte unterschreiben Sie vor dem Speichern.");
      return;
    }

    signatureUploadHandle(signatureDataURL);
  };

  const signatureUploadHandle = (signature) => {
    dispatch(applicationSignatureById({ id: item?.contact_id, signatureImage: signature }));
    handleClose();
  };

  return (
    <>
      <p className="font-tahoma-bold dark:text-BrightGray">{item?.first_name + " " + item?.last_name}</p>
      <div className="text-black relative md:pt-[2rem] pb-0 py-8 bg-AFPBGPrimary dark:bg-AFPDarkBgDarkest dark:text-BrightGray">
        <div className="flex justify-center ">
          <canvas
            className="border border-black dark:!border-SmokeyGray"
            ref={canvasRef}
            width={800}
            height={300}
            onMouseDown={startDrawing}
            onMouseMove={draw}
            onMouseUp={finishDrawing}
            onMouseOut={finishDrawing}
            onTouchStart={startDrawing}
            onTouchMove={draw}
            onTouchEnd={finishDrawing}
          />
        </div>
        <div className="md:flex-row flex-col flex justify-center items-center gap-6 mt-24">
          <SecondaryButton variant={"secondary"} title={"Zurücksetzen"} css={"w-full max-w-[200px]"} handler={clearSignature} />
          <PrimaryButton title="Einreichen" btnStyle="w-full max-w-[200px] " handler={handleSaveSignature} />
        </div>
      </div>
    </>
  );
};

export default SignatureByContact;
