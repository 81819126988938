import { useFormik } from "formik";
import { Box, Checkbox, Modal } from "@mui/material";
import { InputStandardField } from "src/components/atoms/input";
import { INITIALVALUESADDUSERPAYMENT, VALIDATIONSCHEMAADDUSERPAYMENT } from "src/services/validation/settings";
import { IoIosClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { addUserAccount } from "src/store/actions/userSettings";
import Cookies from "js-cookie";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import styles from "./payment.module.css";
import BackdropButton from "src/components/atoms/button/BackdropButton";
import { green } from "@mui/material/colors";

const style = {
  p: 2,
  top: "50%",
  left: "50%",
  width: " 94%",
  maxWidth: 600,
  padding: "20px",
  boxShadow: 24,
  border: "none",
  outline: "none",
  position: "absolute",
  borderRadius: "12px",
  // bgcolor: "background.paper",
  backdropFilter: "blur(40px)",
  transform: "translate(-50%, -50%)",
  WebkitBackdropFilter: "blur(40px)",
};

const { accountHolder, iban, bic, creditInstitution, invoiceEmail, confirminvoiceEmail } = {
  accountHolder: "Kontoinhaber/in",
  iban: "IBAN",
  bic: "BIC",
  creditInstitution: "Kreditinstitut",
  invoiceEmail: "Rechnung E-Mail",
  confirminvoiceEmail: "bestätigen Rechnung E-Mail",
};

const PaymentUserModal = ({ open, handleClose }) => {
  const dispatch = useDispatch();

  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const settings = useSelector((state) => state.settings);

  const cmsData = cmsDashboarddata?.settings?.pay_modal;

  const formik = useFormik({
    initialValues: INITIALVALUESADDUSERPAYMENT,
    validationSchema: VALIDATIONSCHEMAADDUSERPAYMENT,

    onSubmit: (values) => {
      dispatch(addUserAccount({ ...values, user_id: Cookies.get("userId") }));
    },
  });

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className={styles.customModalMain}>
      <Box sx={style} className={`${styles.customModalArea} bg-AFPBGPrimary dark:bg-AFPDarkBgDarkest dark:border-SmokeyGray`} tabIndex={"undefined"}>
        <div className={`max-h-[90vh] overflow-y-auto overflow-x-hidden customScrollBar w-full p-1`}>
          <div className="relative">
            <h4 className="text-center font-tahoma-bold text-2xl mb-12 flex items-center gap-x-3 justify-center dark:text-BrightGray">
              {cmsData?.title}
              <EditIconCMS
                handleOpen={() => {
                  dispatch(
                    dashboardModalOpenCMS({
                      cms_key: "settings",
                      unique: "pay_modal",
                      heading: "Einstellungen",
                    })
                  );
                }}
              />
            </h4>
            <IoIosClose className="absolute -top-2 -right-1 text-3xl cursor-pointer dark:text-BrightGray" onClick={handleClose} />
          </div>
          <div>
            <form className="flex flex-col gap-4" onSubmit={formik.handleSubmit}>
              <InputStandardField
                extra="!items-start"
                extraLabel="w-full mt-3 lg:mt-0"
                extraInput="bg-white"
                label={accountHolder}
                placeholder={accountHolder}
                formik={formik}
                name="account_holder"
                id="account_holder"
              />
              <InputStandardField
                extra="!items-start"
                extraLabel="w-full mt-3 lg:mt-0"
                extraInput="bg-white"
                label={iban}
                placeholder={iban}
                formik={formik}
                name="iban"
                id="iban"
              />
              <InputStandardField 
                extra="!items-start"
                extraLabel="w-full mt-3 lg:mt-0" 
                extraInput="bg-white" 
                label={bic} 
                placeholder={bic}
                formik={formik} 
                name="bic" 
                id="bic" 
              />
              <InputStandardField
                extra="!items-start"
                extraLabel="w-full mt-3 lg:mt-0"
                extraInput="bg-white"
                label={creditInstitution}
                placeholder={creditInstitution}
                formik={formik}
                name="credit_institute"
                id="credit_institute"
              />
              <InputStandardField
                extra="!items-start"
                extraLabel="w-full mt-3 lg:mt-0"
                extraInput="bg-white"
                label={invoiceEmail}
                placeholder={invoiceEmail}
                formik={formik}
                name="invoice_email"
                id="invoice_email"
              />
              <InputStandardField
                extra="!items-start"
                extraLabel="w-full mt-3 lg:mt-0"
                extraInput="bg-white"
                label={confirminvoiceEmail}
                placeholder={confirminvoiceEmail}
                formik={formik}
                name="invoice_email_repeat"
                id="invoice_email_repeat"
              />
              <div className="flex items-start">
                <Checkbox
                  id="is-default-checkbox"
                  className="!cursor-pointer"
                  sx={{
                    color: "#484848",
                    padding:0,
                    "&.Mui-checked": {
                      color: green[600],
                    },
                  }}
                  checked={formik.values["is_default"]}
                  onChange={(e) => formik.setFieldValue("is_default", e.target.checked)}
                />
                <label htmlFor="is-default-checkbox" className="flex items-center cursor-pointer text-[#1C1A1A] font-tahoma-bold dark:text-BrightGray">
                  <span className="ml-2">{cmsData?.default}</span>
                </label>
              </div>
              <div className="w-[150px] mx-auto my-4 mt-8">
                <BackdropButton title={cmsData?.button_title} type="submit" loading={settings?.addUserLoading} />
              </div>
            </form>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default PaymentUserModal;
