const LocationTable = ({ data }) => {
  return (
    <div className="my-4">      
      <div className="grid grid-cols-12">
          <div className="col-span-3"></div>
          <div className="col-span-5 border border-gray-300 py-1 px-2 bg-gray-100 text-md font-tahoma-bold text-black dark:bg-AFPDarkBgDarkest dark:border-SmokeyGray dark:text-BrightGray">Betriebsstätten</div>
          <div className="col-span-4">
            <div className="border border-gray-300 py-1 px-2 border-l-0 bg-gray-100 font-tahoma-bold text-black dark:bg-AFPDarkBgDarkest dark:border-SmokeyGray dark:text-BrightGray">Role</div>
          </div>
        </div>

        {data.map((item) => {
          return (
            <div className="grid grid-cols-12">
              <div className="col-span-3"></div>
              <div className="col-span-5 border border-gray-300 py-1 px-2 border-t-0 dark:bg-AFPDarkBgLight dark:border-SmokeyGray dark:text-BrightGray">{item.name}</div>
              <div className="col-span-4">
                <div className="border border-gray-300 py-1 px-2 border-l-0  border-t-0 dark:bg-AFPDarkBgLight dark:border-SmokeyGray dark:text-BrightGray">{item.role.charAt(0).toUpperCase() + item.role.slice(1)}</div>
              </div>
            </div>
          );
        })}    
    </div>
  );
};

export default LocationTable;
