import {
  LOGIN_USER_START,
  LOGIN_USER_SUCCESS,
  REGISTER_USER_START,
  REGISTER_USER_SUCCESS,
  FORGOT_USER_START,
  FORGOT_USER_SUCCESS,
  AUTH_USER_FAILURE,
  AUTH_USER_RESET,
  RESET_USER_START,
  RESET_USER_SUCCESS,
  CHANGE_PASSWORD_USER_START,
  CHANGE_PASSWORD_USER_SUCCESS,
  REGISTER_USER_START_MODIFY,
  LOGOUT_USER_START,
  LOGOUT_USER_SUCCESS,
  VERIFY_REGISTER_FAILURE,
  VERIFY_REGISTER_SUCCESS,
  VERIFY_REGISTER_STARTED,
  GET_CMS_BY_KEY_FAILURE,
  GET_CMS_BY_KEY_SUCCESS,
  GET_CMS_BY_KEY_STARTED,
} from "../actionTypes";

const initialState = {
  data: [],
  isLoading: false,
  error: null,
  userLogin: false,
  userPassword: '',
  userRegistered: false,
  userForgot: false,
  userReset: false,
  verify:{
    isLoading: true,
    status:'idle'
  },
  cms:{
    isLoading: false,
    data:null,
    error:''
  }
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_START: {
      return { ...state, isLoading: true };
    }
    case LOGIN_USER_SUCCESS: {
      return { ...state, isLoading: false, data: action.payload, userLogin:true };
    }

    case REGISTER_USER_START: {
      return { ...state, isLoading: true };
    }
    case REGISTER_USER_START_MODIFY: {
      return { ...state, isLoading: true };
    }
    case REGISTER_USER_SUCCESS: {
      return { ...state, isLoading: false, userRegistered:true, usercreated: {password: action.payload.password, email: action.payload.email} };
    }

    case FORGOT_USER_START: {
      return { ...state, isLoading: true };
    }
    case FORGOT_USER_SUCCESS: {
      return { ...state, isLoading: false, userForgot: true };
    }

    case RESET_USER_START: {
      return { ...state, isLoading: true };
    }
    case RESET_USER_SUCCESS: {
      return { ...state, isLoading: false, userReset: true };
    }

    case CHANGE_PASSWORD_USER_START: {
      return { ...state, isLoading: true };
    }
    case CHANGE_PASSWORD_USER_SUCCESS: {
      return { ...state, isLoading: false };
    }

    case AUTH_USER_RESET: {
      return { ...state, isLoading: false, userForgot: false, userRegistered: false, userReset: false };
    }
    case AUTH_USER_FAILURE: {
      return { ...state, isLoading: false, error: action.payload };
    }

    case LOGOUT_USER_START: {
      return { ...state, logout: { isLoading: true, status: 'idle' } };
    }
    case LOGOUT_USER_SUCCESS: {
      return { ...state, logout: { isLoading: false, status: 'success' } };
    }

    case VERIFY_REGISTER_STARTED: {
      return { ...state, verify: { isLoading: true, status: 'started' } };
    }
    case VERIFY_REGISTER_SUCCESS: {
      return { ...state, verify: { isLoading: true, status: 'success' } };
    }
    case VERIFY_REGISTER_FAILURE: {
      return { ...state, verify: { isLoading: true, status: 'failed' } };
    }

    case GET_CMS_BY_KEY_STARTED: {
      return { ...state, cms: { ...state.cms, isLoading: true} };
    }
    case GET_CMS_BY_KEY_SUCCESS: {
      return { ...state, cms: { ...state.cms, isLoading: false, data: action.payload } };
    }
    case GET_CMS_BY_KEY_FAILURE: {
      return { ...state, cms: { ...state.cms,  isLoading: false, error: 'failed' } };
    }

    default: {
      return state;
    }
  }
};

export default reducers;
