import { put } from "redux-saga/effects";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import {
  DELETE_UPLOADED_EVIDENCE,
  EVIDENCE_STATIC_DOCUMENTS_API,
  NEW_SERVICE_ORDER_EVIDENCE,
  NEW_SERVICE_ORDER_EVIDENCE_PENDING,
  SAVE_EVIDENCE_DB,
  SAVE_EVIDENCE_ERP,
  SERVICE_ORDER_EVIDENCE_UPLOAD,
  SIGNATURE_UPLOAD,
  UPLOAD_EVIDENCE,
  UPLOAD_SERVER_ORDER_EVIDENCE,
} from "../requests/evidence";
import {
  deleteUploadedEvidenceFailure,
  deleteUploadedEvidenceSuccess,
  evidenceStaticDocumentsFailure,
  evidenceStaticDocumentsSuccess,
  getEvidenceDBFailure,
  getEvidenceDBSuccess,
  newServiceOrderEvidenceFailure,
  newServiceOrderEvidenceSuccess,
  saveEvidenceDBFailure,
  saveEvidenceDBStarted,
  saveEvidenceDBSuccess,
  saveEvidenceERPFailure,
  saveEvidenceERPSuccess,
  setEvidenceById,
  signatureUploadFailure,
  signatureUploadSuccess,
  uploadEvidenceFailure,
  uploadEvidenceSuccess,
  uploadServerOrderEvidenceFailure,
  uploadServerOrderEvidenceSuccess,
  uploadServiceOrderEvidenceFailure,
  uploadServiceOrderEvidenceStarted,
  uploadServiceOrderEvidenceSuccess,
} from "src/store/actions/evidence";
import { GET_APPLICATION_PROCESS_BY_SERVICE_ORDER_ID_STATUS, GET_APPLICATION_PROCESS_STATUS } from "../requests/application";

export function* newServiceOrderEvidenceHandler(action) {
  try {
    const newPayload = {
      ...action.payload,
      user_id: Cookies.get("userId"),
    };
    const response = yield NEW_SERVICE_ORDER_EVIDENCE(newPayload);
    if (response.status) {
      yield put(newServiceOrderEvidenceSuccess(response?.data));
    } else {
      yield put(
        newServiceOrderEvidenceFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(newServiceOrderEvidenceFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* newServiceOrderEvidencePendingHandler(action) {
  try {
    const newPayload = {
      queryParams: {
        service_order_number: action.payload?.procedure_id,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield NEW_SERVICE_ORDER_EVIDENCE_PENDING(newPayload);
    if (response.status) {
      yield put(newServiceOrderEvidenceSuccess(response?.data));
    } else {
      yield put(
        newServiceOrderEvidenceFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(newServiceOrderEvidenceFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* signatureUploadHandler(action) {
  try {
    const newPayload = {
      ...action.payload,
      user_id: Cookies.get("userId"),
    };
    const response = yield SIGNATURE_UPLOAD(newPayload);
    if (response.status) {
      yield put(signatureUploadSuccess(response?.data));
      toast.success(response?.message);
    } else {
      yield put(
        signatureUploadFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(signatureUploadFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* uploadEvidenceHandler(action) {
  try {
    const response = yield UPLOAD_EVIDENCE(action.payload.file);
    if (response.status) {
      yield put(uploadEvidenceSuccess({ ...response?.data, evidenceId: action.payload.evidenceId }));
      yield put(
        setEvidenceById({
          key: action.payload.evidenceId,
          value: response?.data,
          type: "file",
        })
      );
      const fileName = response?.data?.fileName;
      const payload = {
        file_name: fileName,
        user_id: Cookies.get("userId"),
        subject: "Evidence-Inventory",
        document_name: "Evidence-Inventory-" + action.payload.evidenceId,
        service_order_number: action.payload.service_order_number,
        evidenceId: action.payload.evidenceId,
        creator_user: action?.payload?.creator_user,
      };
      yield put(uploadServiceOrderEvidenceStarted(payload));
    } else {
      yield put(
        uploadEvidenceFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(uploadEvidenceFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* uploadServiceOrderEvidenceHandler(action) {
  try {
    const response = yield SERVICE_ORDER_EVIDENCE_UPLOAD(action.payload);
    if (response.status) {
      yield put(
        uploadServiceOrderEvidenceSuccess({
          key: action.payload.evidenceId,
          value: response?.data,
          type: "file",
          proceed: action.payload?.proceed ? "proceed" : "pending",
        })
      );
      toast.success(response?.message);
    } else {
      yield put(
        uploadServiceOrderEvidenceFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(uploadServiceOrderEvidenceFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* saveEvidenceDBHandler(action) {
  try {
    const { actionType, ...rest } = action.payload;
    const newPayload = {
      ...rest,
      user_id: Cookies.get("userId"),
    };
    const response = yield SAVE_EVIDENCE_DB(newPayload);
    if (response.status) {
      yield put(saveEvidenceDBSuccess(response?.data));
      actionType == "db" && toast.success(response?.message);
    } else {
      yield put(
        saveEvidenceDBFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(saveEvidenceDBFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* saveEvidenceERPHandler(action) {
  try {
    const newPayload = {
      ...action.payload,
      user_id: Cookies.get("userId"),
    };
    const response = yield SAVE_EVIDENCE_ERP(newPayload);
    if (response.status) {
      yield put(saveEvidenceERPSuccess({ data: response?.data, proceed: "proceed" }));
    } else {
      yield put(
        saveEvidenceERPFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(saveEvidenceERPFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* getEvidenceDBHandler(action) {
  try {
    const queryParams = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_APPLICATION_PROCESS_STATUS(queryParams);
    if (response.status) {
      if (response.data.evidence_data) {
        yield put(getEvidenceDBSuccess(response.data));
      } else {
        yield put(getEvidenceDBSuccess({}));
      }
    } else {
      yield put(
        getEvidenceDBFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(
      getEvidenceDBFailure({
        message: error?.message || "Etwas ist schief gelaufen!",
      })
    );
  }
}

export function* getEvidenceDBByServiceOrderIdHandler(action) {
  try {
    const queryParams = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_APPLICATION_PROCESS_BY_SERVICE_ORDER_ID_STATUS(queryParams);
    if (response.status) {
      if (response.data.evidence_data) {
        yield put(getEvidenceDBSuccess(response.data));
      } else {
        yield put(getEvidenceDBSuccess({}));
      }
    } else {
      yield put(
        getEvidenceDBFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(
      getEvidenceDBFailure({
        message: error?.message || "Etwas ist schief gelaufen!",
      })
    );
  }
}

export function* uploadServerOrderEvidenceHandler(action) {
  try {
    const response = yield UPLOAD_SERVER_ORDER_EVIDENCE(action.payload.file);
    if (response.status) {
      if (action.payload.fileType === "file") {
        yield put(
          setEvidenceById({
            key: action.payload.evidence_id,
            value: response?.data,
            type: "file",
          })
        );
      }
      const fileName = response?.data?.fileName;
      const payload = {
        file_name: fileName,
        user_id: Cookies.get("userId"),
        subject: "Evidence-Inventory",
        document_name: "Evidence-Inventory-" + action.payload.evidence_id,
        service_order_number: action.payload.service_order_number,
        evidence_id: action.payload.evidence_id,
        creator_user: action?.payload?.creator_user,
      };
      yield put(uploadServerOrderEvidenceSuccess(payload));

      toast.success(response?.message);
    } else {
      yield put(
        uploadServerOrderEvidenceFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(uploadServerOrderEvidenceFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* deleteSingleEvidenceFile(action) {
  try {
    const response = yield DELETE_UPLOADED_EVIDENCE(action.payload);
    if (response.status) {
      const data = {
        ...response?.data,
        service_order_number: action.payload.service_order_number,
        evidence_id: action.payload.evidence_id,
      };
      toast.success(response?.message);
      yield put(deleteUploadedEvidenceSuccess({ data: data }));

      const newArray = action.payload?.evidence_data[data?.evidence_id];
      const Evidencefilter = newArray.filter((evidence) => evidence?.file_name !== data?.file_name);

      yield put(
        saveEvidenceDBStarted({
          procedure_id: action.payload?.procedure_id,
          service_order_id: action.payload?.service_order_id,
          evidence_data: { ...action.payload?.evidence_data, [data?.evidence_id]: Evidencefilter },
          creator_user: action.payload?.creator_user,
          actionType: "db",
        })
      );
    } else {
      yield put(
        deleteUploadedEvidenceFailure({
          message: response?.message || "Evidence not deleted!",
        })
      );
    }
  } catch (error) {
    yield put(deleteUploadedEvidenceFailure({ message: error?.message || "Evidence not deleted!" }));
  }
}

export function* getEvidenceStaticDocumentsHandler(action) {
  try {
    const queryParams = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield EVIDENCE_STATIC_DOCUMENTS_API(queryParams);
    if (response.status) {
        yield put(evidenceStaticDocumentsSuccess(response.data));
    } else {
      yield put(
        evidenceStaticDocumentsFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(
      evidenceStaticDocumentsFailure({
        message: error?.message || "Etwas ist schief gelaufen!",
      })
    );
  }
}