import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { resetApplication, resetApplicationSidebar } from "src/store/actions/application";
import { getStepsStarted } from "src/store/actions/dform";
import DynamicFormWrap from "../DynamicFormWrap";
import { cmsDFormContentList } from "src/store/actions/cmsDashboard";
import { reseetCurrentStepDetail } from "src/store/actions/currentStep";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";

const DynamicForm = ({ fromEditScreen = "" }) => {
  const [reRender, setRerender] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const dform = useSelector((state) => state.dform);
  const cmsDocument = useSelector((state) => state.cmsDocument);
  const application = useSelector((state) => state.application);

  useEffect(() => {
    dispatch(getStepsStarted({ service_order_type: id }));
    dispatch(cmsDFormContentList({ service_order_type: id }));
    return () => {
      dispatch(resetApplicationSidebar());
      dispatch(resetApplication());
      dispatch(reseetCurrentStepDetail());
      localStorage.setItem("percentageStep", "0");
      localStorage.removeItem("locationDetailForm");
    };
  }, []);

  useEffect(() => {
    if (application && application.data != null && fromEditScreen == true) {
      localStorage.setItem("percentageStep", JSON.stringify(Object.keys(application.data).length - 1));
      setRerender(true);
    }
  }, [application.data]);

  return (
    <>
      <div className="relative pt-[7rem] pb-10 py-8 min-h-screen !z-20">
        {dform.isLoading || cmsDocument?.dFormCMS?.isLoading ? (
          <div className="flex justify-center items-center h-[80vh]">
            <CustomCircularLoader />
          </div>
        ) : (
          cmsDocument?.dFormCMS?.data !== null && <DynamicFormWrap id={id} fromEditScreen={fromEditScreen} reRender={reRender} />
        )}
      </div>
    </>
  );
};

export default DynamicForm;
