import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { resetApplication } from "src/store/actions/application";
import { getStepsStarted } from "src/store/actions/dform";
import { cmsDFormContentList } from "src/store/actions/cmsDashboard";
import DynamicFormFinishedWrap from "../DynamicFormWrap/DynamicFormFinishedWrap";
import { reseetCurrentStepDetail } from "src/store/actions/currentStep";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";
import { getEvidenceDBBySOidStarted } from "src/store/actions/evidence";

const DynamicFormVerify = () => {
  const { id, procedure_id } = useParams();
  const dispatch = useDispatch();
  const dform = useSelector((state) => state.dform);
  const cmsDocument = useSelector((state) => state.cmsDocument);
  const evidence = useSelector((state) => state.evidence);

  useEffect(() => {
    dispatch(getEvidenceDBBySOidStarted({ service_order_number: procedure_id }));
    dispatch(getStepsStarted({ service_order_type: id, order_type: "finished" }));
    dispatch(cmsDFormContentList({ service_order_type: id }));
    return () => {
      dispatch(resetApplication());
      dispatch(reseetCurrentStepDetail());
    };
  }, []);

  return (
    <>
      <div className="relative md:pt-[7rem] pb-10 py-8 min-h-screen">
        {dform.isLoading || cmsDocument?.dFormCMS?.isLoading ? (
          <div className="flex justify-center items-center">
            <CustomCircularLoader />
          </div>
        ) : (
          <>
            { evidence?.evidenceDetail?.details && Object.entries(evidence?.evidenceDetail?.details)?.length > 0 ? (
              cmsDocument?.dFormCMS?.data !== null && <DynamicFormFinishedWrap id={id} procedure_id={evidence?.evidenceDetail?.details?.id} />
            ) : (
              <h4 className="text-red-400 text-center my-6 mt-12">Keine Daten gefunden</h4>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default DynamicFormVerify;
