import React from "react";
import style from "./checkbox.module.css";
import Checkbox from "@mui/material/Checkbox"; 
import { green } from "@mui/material/colors";

const ArrayCheckBox = ({ css, inputcss, label, formik, set, selection, index }) => {
  return (
    <>
      <div className={css}>
        <Checkbox
          id={`${set}[${index}]${selection}`}
          className={`!cursor-pointer ${inputcss}`}
          sx={{
            color: "#484848",
            padding: 0,
            "&.Mui-checked": {
              color: green[600],
            },
          }}
          checked={formik.values[`${set}`][index][`${selection}`]}
          onChange={(e) => formik.setFieldValue(`${set}[${index}][${selection}]`, e.target.checked)}
        />
        <label htmlFor={`${set}[${index}]${selection}`} className={`${style.label} dark:text-BrightGray`}>
          <span className="ml-2">{label}</span>
        </label>
      </div>
    </>
  );
};

ArrayCheckBox.defaultProps = {
  css: "flex items-center",
  label: "Label",
  inputcss: "",
  formik: {
    setFieldValue: () => {},
    values: { email: "" },
  },
};

export default ArrayCheckBox;
