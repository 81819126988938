import { Table } from "@mui/material";
import MHead from "./MHead";
import TableContainerDark from "src/components/atoms/table/TableContainerDark";


const MTable = ({ headList, children }) => {
  return (
    <TableContainerDark>
      <Table aria-label="simple table">
        <MHead headList={headList} />
        {children}
      </Table>
    </TableContainerDark>
  );
};

export default MTable;
