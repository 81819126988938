import "../SearchDropDown/style.css";
import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

const FormikSearchDropDown = ({ id, optionsList = [], formik }) => {
  const [query, setQuery] = useState(""); 
  const [isOpen, setIsOpen] = useState(false);  
  const inputRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", toggleDropdownOutsideClick);
    return () => document.removeEventListener("click", toggleDropdownOutsideClick);
  }, []);

  const toggleDropdownOutsideClick = (e) => {
    if (e.target !== inputRef.current) {
      setIsOpen(false); 
    }
  };

  const selectOption = (option) => {
    setQuery(""); 
    formik.setFieldValue(id, option.value); 
    setIsOpen(false);  
  };

  const getDisplayValue = () => {
    if (query) return query;  
    const selectedVal = formik.values[id];  
    if (selectedVal) {
      const selectedOption = optionsList.find((option) => option.value === selectedVal);
      return selectedOption ? selectedOption.title : "";  
    }
    return "";
  };

  const filterOptions = (options) => {
    return options.filter((option) =>
      option.title.toLowerCase().includes(query.toLowerCase())
    );
  };

  return (
    <div className={`searchDropdown relative text-black w-full dark:text-AFPDarkBgLight`}>
      <div className="control">
        <div className="selected-value">
          <input
            className="dark:!bg-AFPDarkBgLight dark:!text-BrightGray dark:!border-SmokeyGray"
            ref={inputRef}
            type="text"
            placeholder="Auswählen"
            value={getDisplayValue()}  
            name="searchTerm"
            onChange={(e) => {
              setQuery(e.target.value);  
              formik.setFieldValue(id, null);  
            }}
            onClick={() => setIsOpen(!isOpen)} 
          />
        </div>
        <div className={`arrow dark:text-BrightGray ${isOpen ? "open" : ""}`}>
          {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </div>
        {formik.touched[id] && formik.errors[id] && (
          <div className="text-red-500 text-sm px-3 py-1">{formik.errors[id]}</div>
        )}
      </div>

      {isOpen && (
        <div className="options open dark:!bg-AFPDarkBgLight dark:text-BrightGray dark:!border-SmokeyGray">
          {filterOptions(optionsList).map((option, index) => (
            <div
              onClick={() => selectOption(option)}
              className={`option dark:!text-BrightGray dark:hover:!bg-AFPDarkBgDarkest ${formik.values[id] === option.value ? "bg-AFPBGPrimary dark:bg-AFPDarkBgDarkest dark:!text-white font-semibold" : ""}`}  
              key={`${id}-${index}`}
            >
              {option.title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FormikSearchDropDown;
