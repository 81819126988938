import "./sidebar.css";
import { useState, useEffect } from "react";
import Logo from "src/assets/img/newLogo.png";
import LogoDark from "src/assets/img/darkLogo.png";
import { navList, navListAdmin } from "src/mockData/dashboard";
import MenuIcon from "@mui/icons-material/Menu";
import ClearIcon from "@mui/icons-material/Clear";
import NavLink from "src/components/molecules/sidebar/NavLink";
import CookieStorage from "src/services/storage/cookiesStorage";
import { BiLogOut } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import { useNavigate } from "react-router-dom";

const SidebarC = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const cookie = new CookieStorage();
  const profile = useSelector((state) => state.profile);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
    if (!openMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768 && openMenu) {
        setOpenMenu(false);
      } else {
        setOpenMenu(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMenuClose = () => {
    setOpenMenu(false);
    document.body.style.overflow = "auto";
  };

  return (
    <>
      <nav className="navWrapper">
        <div className="navcontainer">
          <div className="flex md:block w-full text-left md:pb-2 text-blueGray-600 mr-0  whitespace-nowrap text-base text-SmokeyGray uppercase font-semibold p-4 px-0 cursor-pointer">
            <div className="w-full mb-2 mx-auto">
              <img src={Logo} alt="logo" className="max-w-[180px] sm:w-full dark:hidden" onClick={()=>navigate('/')} />
              <img src={LogoDark} alt="logo" className="max-w-[180px] sm:w-full hidden dark:block" onClick={()=>navigate('/')} />
            </div>
            <div className="mb-2 flex items-center md:hidden text-AFPPrimary">
              <button
                onClick={toggleMenu}
                className="cursor-pointer outline-none"
              >
                {!openMenu ? <MenuIcon /> : <ClearIcon />}
              </button>
            </div>
          </div>

          <div className="navLink">
            <div className="flex justify-end">
              <EditIconCMS
                handleOpen={() => {
                  dispatch(
                    dashboardModalOpenCMS({
                      cms_key: "sidebar_menu",
                      unique: "sidebar_list",
                      heading: "Sidebar",
                    })
                  );
                }}
              />
            </div>
            <ul className="md:flex-col md:min-w-full flex flex-col list-none">
              {profile?.data?.role !== "admin"
                ? navList(cmsDashboarddata?.sidebar_menu?.sidebar_list)?.map((item) => {
                    return <NavLink key={item.id+"top"} link={item} />;
                  })
                : navListAdmin(cmsDashboarddata?.sidebar_menu?.sidebar_list)?.map((item) => {
                    return <NavLink key={item.id} link={item} />;
                  })}
            </ul>
          </div>
        </div>
      </nav>

      {/* mobile menu */}
      <nav className={openMenu ? "navMenu active" : "navMenu"}>
        <div className="w-full z-20">
          <div className="max-w-[300px] p-5 bg-white h-[100vh] rounded-br-lg pb-20 overflow-y-scroll">
          <div className="flex justify-end">
              <EditIconCMS
                handleOpen={() => {
                  dispatch(
                    dashboardModalOpenCMS({
                      cms_key: "sidebar_menu",
                      unique: "sidebar_list",
                      heading: "Sidebar",
                    })
                  );
                }}
              />
            </div>
            <ul className="md:flex-col md:min-w-full flex flex-col list-none bg-white ">
              {profile?.data?.role !== "admin"
                ? navList(cmsDashboarddata?.sidebar_menu?.sidebar_list)?.map((item) => {
                    return (
                      <NavLink
                        key={item.id}
                        link={item}
                        handleMenuClose={handleMenuClose}
                      />
                    );
                  })
                : navListAdmin(cmsDashboarddata?.sidebar_menu?.sidebar_list)?.map((item) => {
                    return (
                      <NavLink
                        key={item.id}
                        link={item}
                        handleMenuClose={handleMenuClose}
                      />
                    );
                  })}
              <li className="sideNavWrapper">
                <div
                  className={`sideNavcontainer`}
                  onClick={() => {
                    cookie.logout();
                    window.location.reload();
                  }}
                >
                  <BiLogOut className="text-xl" />
                  <span className="ml-2">{cmsDashboarddata?.sidebar_menu?.top_menu?.logout}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
        {openMenu && <div className="blurbg z-10" />}
      </nav>
    </>
  );
};

export default SidebarC;
