import React, { useEffect, useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { RiDeleteBinFill } from "react-icons/ri";
import AutoCompleteField from "src/components/atoms/input/AutoCompleteField";

const AutoCompleteFieldCard = ({ user, LegalPersonList, setLeaderList, leaderList, removeFachlicheLeiterHandler, handler, disabled }) => {
  const [selectedPersonOption, setSelectedPersonOption] = useState();

  useEffect(() => {
    let selectedPerson2 = JSON.parse(user.professional_groups).map((member) => {
      return { id: member };
    });
    setSelectedPersonOption(selectedPerson2);
  }, []);

  useEffect(() => {
    if (selectedPersonOption) {
      let newLeader = leaderList.map((leader) =>
        leader.id === user.id
          ? {
              ...leader,
              professional_groups: selectedPersonOption.length > 0 ? JSON.stringify(selectedPersonOption.map((option) => option.id)) : "[]",
            }
          : leader
      );
      setLeaderList(newLeader);
    }
  }, [selectedPersonOption]);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 py-1">
      <div className="xl:col-span-1">{user.title + " " + user.first_name + " " + user.last_name}</div>
      <div className="xl:col-span-2 text-sm text-SmokeyGray">
        <div className="flex gap-8 justify-between items-center">
          <div className="rounded-[12px] border border-AFPLightGray flex flex-1">
            {selectedPersonOption && (
              <AutoCompleteField
                disabled={disabled}
                LegalPersonList={LegalPersonList}
                selectedPersonOption={JSON.parse(user.professional_groups).map((member) => {
                  return { id: member };
                })}
                setSelectedPersonOption={setSelectedPersonOption}
              />
            )}
          </div>
          <div className="flex justify-end gap-2">
            <AiFillEdit className={`text-SmokeyGray ${!disabled ? "cursor-pointer" : "cursor-not-allowed opacity-30"}`} onClick={handler} />
            <RiDeleteBinFill
              className={`text-SmokeyGray ${!disabled ? "cursor-pointer" : "cursor-not-allowed opacity-30"}`}
              onClick={() => !disabled && removeFachlicheLeiterHandler(user.contact_id)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AutoCompleteFieldCard;
