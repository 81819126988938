/*eslint-disable*/
import { TableCell, TableRow, TableBody } from "@mui/material";
import { useEffect, useState } from "react";
import { HiMiniPencil } from "react-icons/hi2";
import { useDispatch, useSelector } from "react-redux";
import DeleteModal from "src/components/atoms/modal/DeleteModal";
import { updateUserManagement } from "src/store/actions/userSettings";
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle } from "react-icons/io";
import deleteIcon from "src/assets/icons/delete.png";
import deleteIconDark from "src/assets/icons/delete-dark.png";
import { useNavigate } from "react-router-dom";

const UserTableBody = ({ bodyList, standardBody, userRemove }) => {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [locationNames, setLocationNames] = useState([]);
  const [showInhaber, setShowInhaber] = useState("");
  const profile = useSelector((state) => state?.profile);
  const navigate = useNavigate();
  const settings = useSelector((state) => state.settings);

  useEffect(() => {
    const getAllLocation = JSON?.parse(localStorage?.getItem("getAllLocation"));

    const locationIdToNameMap = getAllLocation?.reduce((map, location) => {
      map[location?.location_id] = location?.name;
      return map;
    }, {});

    // Function to get the location names for each user
    const getLocationNamesForUsers = (users, locationMap) => {
      const result = users?.map((user) => {
        let locationNames;
        if (Array.isArray(user?.location_id)) {
          locationNames = user?.location_id?.map((id) => locationMap[id] || "Unbekannt");
        }
        return { id: user?.id, location_names: locationNames };
      });
      return result;
    };

    // Get the location names for each user
    if (bodyList) {
      const usersWithLocationNames = getLocationNamesForUsers(bodyList, locationIdToNameMap);
      setLocationNames(usersWithLocationNames);
    }
  }, [bodyList]);

  const showAllInhaber = (id) => {
    if (id == showInhaber) {
      setShowInhaber("");
    } else setShowInhaber(id);
  };

  const userHandle = (user) => {
    dispatch(updateUserManagement(user));
    window.scrollTo(0, 0);
  };
  const deleteModalClose = () => {
    setSelectedId("");
    setShowDeleteModal(false);
  };
  const handleDelete = () => {
    userRemove(selectedId);
  };

  const handleSelectedId = (id) => {
    setSelectedId(id);
    setShowDeleteModal(true);
  };

  return (
    <>
      {showDeleteModal && <DeleteModal handleOpen={deleteModalClose} showModal={showDeleteModal} confirmHandle={handleDelete} />}
      <TableBody>
        <TableRow
          key={"ksiedid"}
          sx={{
            ...standardBody.TableBodyRow,
            "&:last-child td, &:last-child th": { border: 0 },
            td: {
              borderBottom: "none",
              color: "#000",
            },
            th: {
              borderBottom: "none",
            },
            background: "#F3F4F6",
          }}
        >
          <TableCell className="dark:text-BrightGray">{profile?.data?.email}</TableCell>
          <TableCell className="dark:text-BrightGray">{profile?.data?.first_name + " " + profile?.data?.last_name}</TableCell>
          <TableCell className="contentHover">
            <div className="flex justify-between">
              <div className="flex flex-col">
                {settings?.user?.roleBusinessList?.map((business, index) => {
                  return (
                    (showInhaber === profile?.data?.id || index == 0) && <div className="flex flex-col">
                      <p className="dark:text-BrightGray">{business?.name}</p>
                    </div>
                  );
                })}
              </div>

              {showInhaber != profile?.data?.id ? (
                <IoIosArrowDropdownCircle
                  onClick={() => {
                    showAllInhaber(profile?.data?.id);
                  }}
                  size={16}
                  className="cursor-pointer"
                />
              ) : (
                <IoIosArrowDropupCircle
                  onClick={() => {
                    showAllInhaber(profile?.data?.id);
                  }}
                  size={16}
                  className="cursor-pointer"
                />
              )}
            </div>
          </TableCell>
          <TableCell className="!flex justify-start gap-2 px-6 py-4">
            <HiMiniPencil
              className="text-md cursor-pointer"
              onClick={() => {
                const link = "/einstellungen/persoenliche-daten";
                if (profile?.data?.role === "admin") {
                  navigate("/admin" + link);
                } else {
                  navigate(link);
                }
              }}
            />
          </TableCell>
        </TableRow>
        {bodyList?.length > 0 &&
          bodyList?.map((item, index) => {
            return (
              <TableRow
                key={index}
                sx={{
                  ...standardBody.TableBodyRow,
                  "&:last-child td, &:last-child th": { border: 0 },
                  td: {
                    borderBottom: "none",
                    color: "#000",
                  },
                  th: {
                    borderBottom: "none",
                  },
                  background: index % 2 === 0 ? "#fff" : "#F3F4F6",
                }}
              >
                <TableCell>{item["email"]}</TableCell>
                <TableCell>{item["first_name"] + " " + item["last_name"]}</TableCell>
                <TableCell className="contentHover">
                  {locationNames.length > 0 && (
                    <div className="flex justify-between">
                      <div className="flex flex-col">
                        {showInhaber != item.id
                          ? locationNames?.map(
                              (user) =>
                                user.id == item.id && (
                                  <div className="flex flex-col">
                                    {user.location_names && user.location_names.length > 0 && <span className="flex flex-col">{user.location_names[0]}</span>}
                                  </div>
                                )
                            )
                          : locationNames?.map(
                              (user) =>
                                user.id == item.id && (
                                  <div className="flex flex-col">
                                    {user.location_names && user.location_names.length > 0 && user.location_names.map((show) => <span className="flex flex-col">{show}</span>)}
                                  </div>
                                )
                            )}
                      </div>
                      {item.location_id ? (
                        showInhaber != item.id ? (
                          <IoIosArrowDropdownCircle
                            onClick={() => {
                              showAllInhaber(item.id);
                            }}
                            size={16}
                            className="cursor-pointer"
                          />
                        ) : (
                          <IoIosArrowDropupCircle
                            onClick={() => {
                              showAllInhaber(item.id);
                            }}
                            size={16}
                            className="cursor-pointer"
                          />
                        )
                      ) : null}
                    </div>
                  )}
                </TableCell>
                <TableCell className="!flex justify-start gap-2 px-6 py-4">
                  <HiMiniPencil
                    className="text-md cursor-pointer"
                    onClick={() => {
                      userHandle(item);
                    }}
                  />
                  <img
                    src={deleteIcon}
                    alt="deleteIcon"
                    className="h-4 w-auto cursor-pointer dark:hidden"
                    onClick={() => {
                      handleSelectedId(item.id);
                    }}
                  />
                  <img
                    src={deleteIconDark}
                    alt="deleteIcon"
                    className="h-4 w-auto cursor-pointer hidden dark:inline"
                    onClick={() => {
                      handleSelectedId(item.id);
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    </>
  );
};

UserTableBody.defaultProps = {
  bodyList: [],
  standardBody: {
    TableBody: {},
    TableBodyRow: {},
  },
};

export default UserTableBody;
