import React, { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { MdOutlineVisibility } from "react-icons/md";
import { MdOutlineVisibilityOff } from "react-icons/md";
import StrengthBar from "src/components/atoms/progress/StrengthBar";
import CustomPasswordBar from "src/components/atoms/progress/CustomPasswordBar";
import style from './passwordCompare.module.css'
const PasswordCompare = (props) => {
  const {
    id,
    name,
    placeholder,
    disabled,
    formik,
  } = props;

  const { handleChange, handleBlur, errors, touched } = formik;
  const [password, setPassword] = useState(formik?.values[id]|| "");
  const [validate, setValidate] = useState({
    hasLow: false,
    hasCap: false,
    hasNumber: false,
    has8digit: false,
  });

  const [showPassword, setShowPassword] = useState(false);

  const strength = Object.values(validate).reduce((a, item) => a + item, 0);

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
    validatePassword(e.target.value);
  };
  
  useEffect(()=>{
    if(formik?.values[id]){
      validatePassword(formik?.values[id]);
    }
  },[])

  const validatePassword = (password) => {
    if (password.match(/\d+/g)) {
      setValidate((o) => ({ ...o, hasNumber: true }));
    } else {
      setValidate((o) => ({ ...o, hasNumber: false }));
    }

    if (password.match(/[A-Z]+/g)) {
      setValidate((o) => ({ ...o, hasCap: true }));
    } else {
      setValidate((o) => ({ ...o, hasCap: false }));
    }

    if (password.match(/[a-z]+/g)) {
      setValidate((o) => ({ ...o, hasLow: true }));
    } else {
      setValidate((o) => ({ ...o, hasLow: false }));
    }

    if (password.length > 7) {
      setValidate((o) => ({ ...o, has8digit: true }));
    } else {
      setValidate((o) => ({ ...o, has8digit: false }));
    }
  };

  const feedback = {
    1: "Week",
    2: "Medium",
    3: "Strong",
    4: "Very Storng",
  }[strength];

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={`${style.wrapper} contentBody}`}>
      
      <div className="w-full flex flex-col gap-2">
        <div
          className={`flex h-10 w-full items-center justify-center rounded-xl border text-sm outline-none z-20 bg-white pl-3 pr-1 py-0 dark:bg-AFPDarkBgLight  ${
            (touched[name] &&
            errors[name]) &&
            "border-red-500 text-red-500 placeholder:text-red-500"
          }`}
        >
          <input
            disabled={disabled}
            type={showPassword ? "text" : "password"}
            id={id}
            name={name}
            value={password}
            onBlur={handleBlur}
            onChange={(e) => {
              handleChange(e);
              handleChangePassword(e);
            }}
            placeholder={placeholder}
            className={`flex h-full w-full items-center justify-center rounded-xl  text-sm outline-none z-20 bg-[#fffff] py-3} dark:text-BrightGray dark:bg-AFPDarkBgLight`}
          />
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
          >
            {showPassword ? (
              <MdOutlineVisibilityOff className="text-base dark:text-BrightGray" />
            ) : (
              <MdOutlineVisibility className="text-base dark:text-BrightGray" />
            )}
          </IconButton>
        </div>

        {errors[name] && touched[name] && (
          <div className="warning">{errors[name]}</div>
        )}

        {id == "password" && (
          <>
            {strength > 0 && (
              <CustomPasswordBar strength={strength} feedback={feedback} />
            )}
          </>
        )}

        {id == "repeat" && <StrengthBar formik={formik} />}
      </div>
    </div>
  );
};

PasswordCompare.defaultProps = {
  label: "Password",
  id: "passwordField",
  name: "password",
  extra: "",
  placeholder: "Enter your password",
  disabled: false,
  formik: {
    handleChange: () => {},
    handleBlur: () => {},
    errors: {},
    touched: {},
  },
};

export default PasswordCompare;
