import React, { useEffect } from "react";
import { FaRegFileAlt, FaRegFileImage } from "react-icons/fa";
import { GoFile } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { downloadEvidenceFileReset } from "src/store/actions/download";
import { deleteUploadedEvidenceStarted } from "src/store/actions/evidence";
import { useParams } from "react-router-dom";
import { API, BASE_URL } from "src/services/utils/defines";
import Cookies from "js-cookie";
import { MdDeleteOutline, MdOutlineCloudDownload } from "react-icons/md";

function isImage(fileName) {
  const fileExtension = fileName.split(".").pop().toLowerCase();
  if (fileExtension === "jpg" || fileExtension == "jpeg" || fileExtension == "png") {
    return true;
  }
  return false;
}

const modifyFileName = (filename) => {
  if (!filename || typeof filename !== 'string') {
    return ''; 
  }

  const dotIndex = filename.lastIndexOf('.');
  if (dotIndex === -1 || dotIndex === 0) {
    return filename; 
  }

  const namePart = filename.substring(0, dotIndex); 
  const extension = filename.substring(dotIndex); 
  if (namePart.length <= 38) {
    return filename;
  }
  return `${namePart.substring(0, 32)}...${extension}`;
};

const EvidenceFileCard = ({ index, data, fileType, evidence, initiatePdfPreview }) => {
  const { service } = useParams();
  const dispatch = useDispatch();
  const download = useSelector((state) => state.download);
  const profile = useSelector((state) => state.profile);

  useEffect(() => {
    if (download?.evidenceFileDownload?.evidence_id === data?.evidence_id && download?.evidenceFileDownload?.isLoading === false) {
      handleOpenPdf(download);
    }
  }, [download?.evidenceFileDownload]);

  const getExtension = (filename) => filename.split(".").pop();

  const handleOpenPdf = (download) => {
    if (download?.evidenceFileDownload?.evidence_id === data?.evidence_id) {
      const url = download?.evidenceFileDownload?.data;
      const filename = download?.evidenceFileDownload?.file_name;
      const extension = getExtension(filename);
      if (extension === "jpg" || extension === "jpeg" || extension === "png" || extension === "pdf") {
        extension !== "pdf" && fileType == "fileupload" ? initiatePdfPreview(url, "imageupload") : initiatePdfPreview(url, fileType);
      } else {
        window.open(url, "_blank");
      }
      dispatch(downloadEvidenceFileReset());
    }
  };

  /* Checks if a static document exists in the evidence data and returns a download URL if found, otherwise returns false. */
  const documentDMSCheck = (data) => {
    let staticDocExist = evidence?.evidenceStaticDocument?.data?.find((item)=>item?.evidence_id === data?.evidence_id)
    if(staticDocExist?.documents?.length > 0){
      let dochas = staticDocExist?.documents?.find((item)=>item?.property_filename === data?.file_name)
      if(dochas){
        return { url:`${BASE_URL}${API?.DOWNLOAD_DMS_DOCUMENT}?user_id=${Cookies?.get("userId")}&document_id=${dochas?.id}`, status:true}
      }
      return {status:false}
    }
    return {status:false}
  }

  const getFileTypeHandler = (data) => {
    const filename = data?.file_name;
    const extension = getExtension(filename)?.toLowerCase();
    /* check the static document if exist */
    let oldDoc = documentDMSCheck(data)
    let url = oldDoc?.status ? oldDoc?.url : `${BASE_URL}${API.DOWNLOAD_EVIDENCE_FILE}?service_order_number=${data?.service_order_number}&file_name=${data?.file_name}&evidence_id=${
      data?.evidence_id
    }&user_id=${Cookies.get("userId")}`;
    if (extension === "jpg" || extension == "jpeg" || extension == "png" || extension === "pdf") {
      extension !== "pdf" && fileType == "fileupload" ? initiatePdfPreview(url, "imageupload") : initiatePdfPreview(url, fileType);
    } else {
      window.open(url, "_blank");
    }
  };

  const handleDeleteEvidence = (e, data) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(
      deleteUploadedEvidenceStarted({
        service_order_number: data?.service_order_number,
        file_name: data?.file_name,
        evidence_id: data?.evidence_id,
        procedure_id: evidence?.evidenceDetail?.details?.id,
        service_order_id: service,
        evidence_data: evidence?.evidenceDetail?.data,
        creator_user: profile.data.first_name + " " + profile.data.last_name,
      })
    );
  };

  return (
    <>
      <div key={index} className="w-full border rounded-md text-[#D5D5D5] cursor-pointer relative dark:text-BrightGray dark:border-SmokeyGray">
        <div className="w-full h-full overflow-hidden flex justify-start items-center p-2 gap-1" onClick={() => getFileTypeHandler(data)}>
          {fileType === "fileupload" ? (
            <>
              {isImage(data?.file_name) ? (
                <div className="flex justify-between items-center w-full">
                  <div className="flex gap-1 items-center">
                    <FaRegFileImage className="text-2xl cursor-pointer text-AFPPrimary" />
                    <p className="text-sm truncate text-gray-800 max-w-xs dark:text-BrightGray">{modifyFileName(data?.file_name)}</p>
                  </div>
                  <div className="flex items-center gap-2 bg-white dark:bg-AFPDarkBgLight">
                    <MdOutlineCloudDownload className="text-3xl text-gray-700 rounded-full border border-gray-200 p-1 dark:text-BrightGray dark:border-SmokeyGray" />
                    <MdDeleteOutline className="text-3xl text-gray-700 rounded-full border border-gray-200 p-1 dark:text-BrightGray dark:border-SmokeyGray" onClick={(e) => handleDeleteEvidence(e, data)} />
                  </div>
                </div>
              ) : (
                <div className="flex justify-between items-center w-full">
                  <div className="flex gap-1 items-center">
                    <FaRegFileAlt className="text-2xl cursor-pointer text-AFPPrimary" />
                    <p className="text-sm truncate text-gray-800 max-w-xs dark:text-BrightGray">{modifyFileName(data?.file_name)}</p>
                  </div>
                  <div className="flex items-center gap-2 bg-white dark:bg-AFPDarkBgLight">
                    <MdOutlineCloudDownload className="text-3xl text-gray-700 rounded-full border border-gray-200 p-1 dark:text-BrightGray dark:border-SmokeyGray" />
                    <MdDeleteOutline className="text-3xl text-gray-700 rounded-full border border-gray-200 p-1 dark:text-BrightGray dark:border-SmokeyGray" onClick={(e) => handleDeleteEvidence(e, data)} />
                  </div>
                </div>
              )}
            </>
          ) : fileType === "imageupload" ? (
            <>
              <div className="flex justify-between items-center w-full">
                <div className="flex gap-1 items-center">
                  <FaRegFileImage className="text-2xl cursor-pointer text-AFPPrimary" />
                  <p className="text-sm truncate text-gray-800 max-w-xs dark:text-BrightGray">{modifyFileName(data?.file_name)}</p>
                </div>
                <div className="flex items-center gap-2 bg-white dark:bg-AFPDarkBgLight">
                  <MdOutlineCloudDownload className="text-3xl text-gray-700 rounded-full border border-gray-200 p-1 dark:text-BrightGray dark:border-SmokeyGray" />
                  <MdDeleteOutline className="text-3xl text-gray-700 rounded-full border border-gray-200 p-1 dark:text-BrightGray dark:border-SmokeyGray" onClick={(e) => handleDeleteEvidence(e, data)} />
                </div>
              </div>
            </>
          ) : (
            <div className="flex justify-between items-center w-full">
              <div className="flex gap-1 items-center">
                <GoFile className="text-4xl cursor-pointer" />
                <p className="text-sm truncate text-gray-800 max-w-xs dark:text-BrightGray">{modifyFileName(data?.file_name)}</p>
              </div>
              <div className="flex items-center gap-2 bg-white dark:bg-AFPDarkBgLight">
                <MdOutlineCloudDownload className="text-3xl text-gray-700 rounded-full border border-gray-200 p-1 dark:text-BrightGray dark:border-SmokeyGray" />
                <MdDeleteOutline className="text-3xl text-gray-700 rounded-full border border-gray-200 p-1 dark:text-BrightGray dark:border-SmokeyGray" onClick={(e) => handleDeleteEvidence(e, data)} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EvidenceFileCard;

