import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detailBusinessPremises } from "src/store/actions/business";

const LocationDetail = ({ locationId, selectedInhaber, id }) => {
  const [location_details, setLocation_details] = useState(null)
  const dispatch = useDispatch()
  const profile = useSelector((state) => state.profile);
  const business = useSelector((state) => state.management);
  const locationValidation = JSON.parse(localStorage.getItem("locationValidation"));
  const allContact = JSON.parse(localStorage.getItem("businessPremisesContactHandler"));
  const companyData = JSON.parse(localStorage.getItem("getAllLocation")).filter((item) => item?.location_id === locationId);
  useEffect(()=>{
    if(business?.detail?.id == companyData[0]?.id){
        setLocation_details(business?.detail)
    } else {
        dispatch(detailBusinessPremises({ id: companyData[0].id }));
    }
  },[business?.detail])

  return (
    <>
      {location_details && 
          <Fragment>
            <p className="bg-gray-100 dark:bg-AFPDarkBgLight dark:text-BrightGray dark:border-SmokeyGray py-1 px-4 border border-gray-200 md:text-base text-xs w-full">Betriebsstättenname</p>
            <p className="py-1 px-4 border border-gray-200 md:border-l-0 md:text-base dark:border-SmokeyGray dark:text-BrightGray text-xs">{location_details?.name}</p>
            {id === "IWA"
              ? allContact?.map(
                  (owner) =>
                    owner?.contact_id === locationValidation?.existing_owner_id && (
                      <>
                        <p className="bg-gray-100 dark:bg-AFPDarkBgLight dark:text-BrightGray dark:border-SmokeyGray py-1 px-4 border border-gray-200 md:border-t-0 md:text-base text-xs w-full">Inhaber</p>
                        <p className="py-1 px-4 border border-gray-200 md:border-l-0 md:text-base dark:border-SmokeyGray dark:text-BrightGray md:border-t-0 text-xs">
                          <span key={owner.contact_id}>{owner?.title + " " + " " + owner?.first_name + " " + owner?.last_name}</span>
                        </p>
                      </>
                    )
                )
              : selectedInhaber && (
                  <>
                    <p className="bg-gray-100 dark:bg-AFPDarkBgLight dark:text-BrightGray dark:border-SmokeyGray py-1 px-4 border border-gray-200 md:border-t-0 md:text-base text-xs w-full">Inhaber</p>
                    <p className="py-1 px-4 border border-gray-200 md:border-l-0 md:text-base dark:border-SmokeyGray dark:text-BrightGray md:border-t-0 text-xs">{selectedInhaber}</p>
                  </>
                )}

            <p className="bg-gray-100 dark:bg-AFPDarkBgLight dark:text-BrightGray dark:border-SmokeyGray py-1 px-4 border border-gray-200 md:border-t-0 md:text-base text-xs w-full">IK-Nummer:</p>
            <p className="py-1 px-4 border border-gray-200 md:text-base md:border-t-0 md:border-l-0 text-xs dark:border-SmokeyGray dark:text-BrightGray">
              {location_details?.ik_number?.map((num, i) => {
                const lengthIK = location_details?.ik_number?.length;

                return (
                  <span key={num}>
                    {num}
                    {i !== lengthIK - 1 && ", "}
                  </span>
                );
              })}
            </p>
            <p className="bg-gray-100 dark:bg-AFPDarkBgLight dark:text-BrightGray dark:border-SmokeyGray py-1 px-4 border border-gray-200 md:border-t-0 md:text-base text-xs w-full">Firmenname</p>
            <p className="py-1 px-4 border border-gray-200 md:border-t-0 md:border-l-0 md:text-base text-xs dark:border-SmokeyGray dark:text-BrightGray">{profile?.data?.company_name}</p>
            <p className="bg-gray-100 dark:bg-AFPDarkBgLight dark:text-BrightGray dark:border-SmokeyGray py-1 px-4 border border-gray-200 md:border-t-0 md:text-base text-xs w-full">Straße mit Nummer</p>
            <p className="py-1 px-4 border border-gray-200 md:border-t-0 md:border-l-0 md:text-base text-xs dark:border-SmokeyGray dark:text-BrightGray">{location_details?.street+ " " + location_details?.house_number}</p>
            <p className="bg-gray-100 dark:bg-AFPDarkBgLight dark:text-BrightGray dark:border-SmokeyGray py-1 px-4 border border-gray-200 md:border-t-0 md:text-base text-xs w-full">PLZ</p>
            <p className="py-1 px-4 border border-gray-200 md:border-t-0 md:border-l-0 md:text-base text-xs dark:border-SmokeyGray dark:text-BrightGray">{location_details?.post_code + " " + location_details?.city}</p>
            <p className="bg-gray-100 dark:bg-AFPDarkBgLight dark:text-BrightGray dark:border-SmokeyGray py-1 px-4 border border-gray-200 md:border-t-0 md:text-base text-xs w-full">Telefon</p>
            <p className="py-1 px-4 border border-gray-200 md:border-t-0 md:border-l-0 md:text-base text-xs dark:border-SmokeyGray dark:text-BrightGray">{location_details?.phone_number}</p>
            <p className="bg-gray-100 dark:bg-AFPDarkBgLight dark:text-BrightGray dark:border-SmokeyGray py-1 px-4 border border-gray-200 md:border-t-0 md:text-base text-xs w-full">E-Mail</p>
            <p className="py-1 px-4 border border-gray-200 md:border-t-0 md:border-l-0 md:text-base text-xs dark:border-SmokeyGray dark:text-BrightGray">{location_details?.official_email}</p>
          </Fragment>
        }
    </>
  );
};

export default LocationDetail;
