import * as yup from "yup";

export const INITIALVALUESBUSINESSPREMISES = {
  name: "",
  ik_number: [""],
  street: "",
  house_number: "",
  city: "",
  post_code: "",
  country: "Deutschland",
  official_email: "",
  phone_number: "",
  legal_form: "",
  is_store_location: false,
  is_main_location: false
};

export const LEADERSINITIALVALUSE = {
  first_name: "",
  last_name: "",
  email: "",
  title: "",
  salutation: "",
  selectedPersonOption: [],
};

export const VALIDATIONSCHEMABUSINESSPREMISES = yup.object().shape({
  name: yup.string().required("Name muss ausgefüllt werden"),
  // ik_number: yup.string().required("IK Number muss ausgefüllt werden"),
  street: yup.string().required("Straße muss ausgefüllt werden"),
  house_number: yup.string().required("Hausnummer muss ausgefüllt werden"),
  city: yup.string().required("Stadt muss ausgefüllt werden"),
  post_code: yup.string().required("PLZ muss ausgefüllt werden").length(5, "PLZ muss genau 5 Zeichen lang sein"),
  legal_form: yup.string().required("Rechtsform muss ausgefüllt werden"),
  country: yup.string().required("Land muss ausgefüllt werden"),
  official_email: yup.string().required("Email muss ausgefüllt werden").email("E-Mail ist ungültig"),
  phone_number: yup.string().required("Telefonnummer muss ausgefüllt werden"),
});

export const VALIDATIONSCHEMALEADERS = yup.object().shape({
  first_name: yup.string().required("Vorname muss ausgefüllt werden"),
  last_name: yup.string().required("Nachname muss ausgefüllt werden"),
  salutation: yup.string().required("Anrede muss ausgefüllt werden"),
  selectedPersonOption: yup.array().min(1, "Berufsgruppe erforderlich"),
  email: yup.string().required("Bitte geben Sie als Benutzernamen Ihre E-Mail-Adresse ein").email("E-Mail ist ungültig"),
});

export const INHABERINITIALVALUSE = {
  first_name: "",
  last_name: "",
  title: "",
  salutation: "",
  email: "",
};

export const VALIDATIONSCHEMAINHABER = yup.object().shape({
  first_name: yup.string().required("Vorname muss ausgefüllt werden"),
  last_name: yup.string().required("Nachname muss ausgefüllt werden"),
  email: yup.string().required("Email muss ausgefüllt werden"),
  salutation: yup.string().required("Anrede muss ausgefüllt werden"),
});
