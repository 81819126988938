import { put } from "redux-saga/effects";
import { GET_DASHBOARD_DATA, GET_DASHBOARD_MESSAGE } from "../requests/dashboard";
import { getDashboardDataFailure, getDashboardDataSuccess, getDashboardMessageFailure, getDashboardMessageSuccess } from "src/store/actions/dashboard";
import Cookies from "js-cookie";

export function* getDashboardDateHandler() {
  try {
    const queryParams = {
      queryParams: {
        user_id: Cookies.get('userId')
      },
      isAuthorized: true,
    };
    const response = yield GET_DASHBOARD_DATA(queryParams);
    if (response.status) {
      yield put(getDashboardDataSuccess(response.data));
    } else {
      yield put(
        getDashboardDataFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(
      getDashboardDataFailure({
        message: error?.message || "Etwas ist schief gelaufen!",
      })
    );
  }
}

export function* getDashboardMessageHandler() {
  try {
    const queryParams = {
      queryParams: {
        user_id: Cookies.get('userId')
      },
      isAuthorized: true,
    };
    const response = yield GET_DASHBOARD_MESSAGE(queryParams);
    if (response.status) {
      yield put(getDashboardMessageSuccess(response.data));
    } else {
      yield put(
        getDashboardMessageFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(
      getDashboardMessageFailure({
        message: error?.message || "Etwas ist schief gelaufen!",
      })
    );
  }
}
