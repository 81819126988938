import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Table, TableBody, TableRow, TableCell, Typography} from "@mui/material";
import useSortableData from "src/hooks/useSortableData";
import { INBOXTABLEHEAD } from "src/mockData/InboxMock";
import TableHeader from "src/components/atoms/common/TableHeader";
import MessageModal from "src/components/atoms/modal/MessageModal";
import { MdEmail } from "react-icons/md";
import { HiOutlineMailOpen } from "react-icons/hi";
import { messageRead } from "src/store/actions/inbox";
import { parseDateFormat } from "src/helpers/document";
import TableContainerDark from "src/components/atoms/table/TableContainerDark";

const InboxTable = ({ msgList }) => {
  const { items: sortedData, requestSortIncrease, requestSortDecrease } = useSortableData(msgList);

  const [open, setOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [openedMessageIds, setOpenedMessageIds] = useState([]);
  const dispatch = useDispatch();

  const handleOpen = (msg) => {
    dispatch(messageRead({ message_id: msg.id, is_open: true }));
    setSelectedMessage(msg);
    setOpen(true);
    setOpenedMessageIds((prevIds) => [...prevIds, msg.id]);
  };
  const handleClose = () => setOpen(false);

  const cmsDashboarddata = useSelector((state) => state.cmsDocument);

  const list = cmsDashboarddata.inbox.table;
  const headList = INBOXTABLEHEAD(list?.date, list?.regarding, list?.business);

  return (
    <>
      <TableContainerDark>
        <Table aria-label="simple table">
          <TableHeader tableData={headList} increaseSort={requestSortIncrease} decreaseSort={requestSortDecrease} />
          {sortedData?.length > 0 ? (
            <TableBody>
              {sortedData.map((msg, index) => {
                const rowClassName = msg.isOpen ? "open-msg" : "closed-msg";
                const isMessageOpened = openedMessageIds.includes(msg.id);
                const fontWeight = !msg.isOpen && !isMessageOpened ? "600" : "400";
                return (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "&:nth-of-type(even)": {
                        background: "#FBFBFB",
                        borderBottom: "none",
                      },
                      td: {
                        borderBottom: "none",
                      },
                      th: {
                        borderBottom: "none",
                      },
                      className: rowClassName,
                    }}
                    onClick={() => handleOpen(msg)}
                  >
                    <TableCell component="th" scope="row">
                      <div className="flex items-center gap-2">
                        {!msg.isOpen && !isMessageOpened ? <MdEmail style={{ cursor: "pointer" }} /> : <HiOutlineMailOpen style={{ cursor: "pointer" }} />}
                        <Typography variant="body1" fontWeight={!msg.isOpen && !isMessageOpened ? "600" : "400"} style={{ cursor: "pointer" }}>
                          {parseDateFormat(msg?.date)}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1" fontWeight={fontWeight} className="flex items-center">
                        <span className="min-w-[15rem] cursor-pointer">{msg.regarding}</span>{" "}
                      </Typography>
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1" fontWeight={fontWeight}>
                        <span className=" cursor-pointer">{msg.business}</span>
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <h5 className="text-red-400 dark:text-red-300 text-center">Es liegen keine Nachrichten vor</h5>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainerDark>
      {open && <MessageModal open={open} handleClose={handleClose} message={selectedMessage} />}
    </>
  );
};

export default InboxTable;
