import {
  FORGOT_USER_START,
  LOGIN_USER_START,
  REGISTER_USER_START,
  RESET_USER_START,
  CHANGE_PASSWORD_USER_START,
  FETCH_PROFILE_REQUEST,
  SET_PROFILE,
  ADD_USER_ACCOUNT,
  ACCOUNTS_LIST,
  FETCH_ACCOUNTLIST_REQUEST,
  UPDATE_USER_ACCOUNT,
  DELETE_ACCOUNT_LIST,
  ADD_BUSINESS_PREMISES,
  LIST_BUSINESS_PREMISES,
  DETAIL_BUSINESS_PREMISES,
  UPDATE_BUSINESS_PREMISES,
  DELETE_BUSINESS_PREMISES,
  UPDATE_CMS_CONTENT,
  LIST_CMS_CONTENT,
  LIST_CONTACT,
  ADD_USER_MANAGEMENT,
  BUSINESSLIST_INVITE_USER_STARTED,
  USER_MANAGEMENT_LIST_STARTED,
  FETCH_HELP_LIST_REQUEST,
  UPDATE_USER_MANAGEMENT_BY_ID_STARTED,
  REMOVE_USER_MANAGEMENT_BY_ID,
  LIST_FROM_TO_YOU_STARTED,
  MESSAGE_LIST,
  GET_PROFESSIONAL_GROUP,
  PROCEDURE_TEMPLATES,
  GET_STEPS_STARTED,
  GET_APPLICATION_STARTED_ByID,
  GET_SERVICE_AREA_STARTED,
  GET_SERVICE_AREA_DETAIL_STARTED,
  ADD_APPLICATION_STEP_STARTED,
  GET_DASHBOARD_MESSAGE_STARTED,
  GET_LEADER_BY_LOCATION_STARTED,
  ADD_LEADER_BY_LOCATION_STARTED,
  GET_DASHBOARD_DATA_STARTED,
  GET_MARKETING_STARTED,
  GET_RECRUIT_CLIENT_STARTED,
  GET_ON_GOING_SERVICE_ORDER_STARTED,
  GET_COMPLETED_SERVICE_ORDER_STARTED,
  MESSAGE_READ,
  CREATE_COMPLETE_APPLICATION_STARTED,
  CMS_DFORM_CONTENT,
  OWNER_BY_LOCATION_STARTED,
  NEW_SERVICE_ORDER_COSTS_STARTED,
  NEW_SERVICE_ORDER_EVIDENCE_STARTED,
  SEND_SIGNATURE_REQUEST_EMAIL_STARTED,
  APPLICATION_PROCESS_STATUS_STARTED,
  SIGNATURE_UPLOAD_STARTED,
  UPLOAD_EVIDENCE_STARTED,
  SAVE_EVIDENCE_DB_STARTED,
  GET_EVIDENCE_DB_STARTED,
  UPLOAD_SERVICE_ORDER_EVIDENCE_STARTED,
  SAVE_EVIDENCE_ERP_STARTED,
  APPLICATION_LOCATION_VALIDATION_STARTED,
  ADD_OWNER_BY_LOCATION_STARTED,
  REGISTER_USER_START_MODIFY,
  GET_DFORM_CMS_BY_ID,
  UPDATE_DFORM_CMS_BY_ID,
  GET_HELPDESK_ARTICLE_LIST_STARTED,
  GET_HELPDESK_ARTICLE_DETAIL_STARTED,
  GET_SERVICE_AREA_TECHNICAL_LEADER_STARTED,
  LIST_USER_FILES_STARTED,
  LIST_DOCUMENT_CATEGORY_STARTED,
  UPLOAD_DOCUMENT_STARTED,
  UPLOAD_DMS_DOCUMENT_STARTED,
  UPLOAD_DOCUMENT_BASE64_STARTED,
  GET_HELPDESK_ARTICLE_CONTENT_STARTED,
  DOWNLOAD_ORDER_STARTED,
  DOWNLOAD_CERTIFICATE_STARTED,
  DOWNLOAD_INVOICE_STARTED,
  DOWNLOAD_XML_STARTED,
  NEW_SERVICE_ORDER_EVIDENCE_PENDING_STARTED,
  GET_EVIDENCE_DB_SO_ID_STARTED,
  GET_GOING_SERVICE_ORDER_PAGINATE_STARTED,
  UPLOAD_SERVER_ORDER_EVIDENCE_STARTED,
  GET_EVIDENCE_FILE_DOWNLOAD_STARTED,
  DELETE_SINGLE_EVIDENCE_STARTED,
  GET_ALL_BUSINESS_STARTED,
  ADD_SERVICE_APPLICATION_STEP_STARTED,
  GET_SERVICE_AREA_DETAIL_MULTI_STARTED,
  LOGOUT_USER_START,
  USER_LOGIN_TIMES_STARTED,
  USER_LOGIN_SESSION_STARTED,
  USER_LOGIN_TIME_DURATION_STARTED,
  VERIFY_REGISTER_STARTED,
  USER_ABANDONED_CART_STARTED,
  EVIDENCE_STATIC_DOCUMENTS_STARTED,
  USER_LOGIN_TIMES_DURATION_STARTED,
  USER_DEVICE_SOURCE_STARTED,
  USER_ANALYTICS_EVIDENCE_STARTED,
  USER_ACTIVITY_ON_STARTED,
  USER_ACTIVITY_STATISTIK_STARTED,
  GET_CMS_BY_KEY_STARTED,
} from "../actionTypes";
import { takeEvery, takeLatest } from "redux-saga/effects";
import { getCMSbyIDHandler, userChangePasswordHandler, userForgotHandler, userLoginHandler, userLogOutHandler, userRegisterHandler, userRegisterHandlerInvitation, userResetHandler, verifyRegisterHandler } from "./handlers/users";
import { fetchProfile, updateProfile } from "./handlers/settings";
import {
  accountListHandler,
  addAccountHandler,
  addUserManagementHandler,
  businessListInviteHandler,
  deleteUserAddress,
  deleteUserManagementByIDHandler,
  fetchAccountList,
  updateUserAddress,
  updateUserManagementByIDHandler,
  userManagementListHandler,
} from "./handlers/userSettings";
import {
  addBusinessPremisesHandler,
  addLeaderByLocationHandler,
  addOwnerByLocationHandler,
  businessPremisesContactHandler,
  businessPremisesDetailHandler,
  businessPremisesListHandler,
  deleteBusinessPremisesHandler,
  getAllBusinessPremisesHandler,
  getLeaderByLocationHandler,
  getProfessionalGroupsHandler,
  ownerByLocationHandler,
  updateBusinessPremisesHandler,
} from "./handlers/business";
import { fetchCMS, fetchCMS_DForm, fetchCMS_DFormById, updateCMSContentHandler, updateDFormCMSbyIdHandler } from "./handlers/cmsDashboard";
import { helpListHandler } from "./handlers/help";
import {
  documentListHandler,
  listDocumentCategorysHandler,
  listUserFilesHandler,
  uploadDMSDocumentHandler,
  uploadDocumentBase64Handler,
  uploadDocumentHandler,
} from "./handlers/document";
import { messageListHandler, messageReadHandler } from "./handlers/inbox";
import { procedureTemplateHandler } from "./handlers/procedure";
import {
  AddApplicationStepHandler,
  AddServiceApplicationStepHandler,
  CreateCompleteApplicationHandler,
  getApplicationBusinessStatusByIdHandler,
  getApplicationbyIDHandler,
  getApplicationProcessStatusHandler,
  newServiceOrderCostHandler,
  sendSignatureRequestEmailHandler,
} from "./handlers/application";
import { getServiceAreaDetailHandler, getServiceAreaDetailMultiHandler, getServiceAreaHandler, getServiceAreaTechnicalLeaderHandler, getStepsHandler } from "./handlers/dform";
import { getDashboardDateHandler, getDashboardMessageHandler } from "./handlers/dashboard";
import { getMarketingHandler } from "./handlers/marketing";
import { getRecruitClientHandler } from "./handlers/recruitClient";
import { getCompletedServiceOrderHandler, getOnGoingServiceOrderHandler, getOnGoingServiceOrderPaginationHandler } from "./handlers/serviceOrder";
import {
  deleteSingleEvidenceFile,
  getEvidenceDBByServiceOrderIdHandler,
  getEvidenceDBHandler,
  getEvidenceStaticDocumentsHandler,
  newServiceOrderEvidenceHandler,
  newServiceOrderEvidencePendingHandler,
  saveEvidenceDBHandler,
  saveEvidenceERPHandler,
  signatureUploadHandler,
  uploadEvidenceHandler,
  uploadServerOrderEvidenceHandler,
  uploadServiceOrderEvidenceHandler,
} from "./handlers/evidence";
import { gethelpDeskContentHandler, gethelpDeskDetailHandler, gethelpDeskListStartedHandler } from "./handlers/helpdesk";
import { getDownloadOrderHandler, getDownloadInvoiceHandler, getDownloadCertificateHandler, getDownloadXmlHandler, getDownloadEvidenceFileHandler } from "./handlers/download";
import { getAbandonedCart, getActivityStatistik, getEvidenceAnalytics, getLoginAverageSession, getLoginDuration, getLoginSession, getLoginTimes, getSourceDevice, postActivityStatistik } from "./handlers/userAnalytics";

export function* watcherSaga() {
  yield takeEvery(LOGIN_USER_START, userLoginHandler);
  yield takeEvery(LOGOUT_USER_START, userLogOutHandler);
  yield takeEvery(REGISTER_USER_START, userRegisterHandler);
  yield takeEvery(VERIFY_REGISTER_STARTED, verifyRegisterHandler);
  yield takeEvery(FORGOT_USER_START, userForgotHandler);
  yield takeEvery(RESET_USER_START, userResetHandler);
  yield takeEvery(CHANGE_PASSWORD_USER_START, userChangePasswordHandler);
  yield takeLatest(FETCH_PROFILE_REQUEST, fetchProfile);
  yield takeEvery(SET_PROFILE, updateProfile);
  yield takeEvery(ADD_USER_ACCOUNT, addAccountHandler);
  yield takeEvery(ADD_USER_MANAGEMENT, addUserManagementHandler);
  yield takeEvery(ACCOUNTS_LIST, accountListHandler);
  yield takeEvery(FETCH_ACCOUNTLIST_REQUEST, fetchAccountList);
  yield takeEvery(UPDATE_USER_ACCOUNT, updateUserAddress);
  yield takeEvery(DELETE_ACCOUNT_LIST, deleteUserAddress);
  yield takeEvery(ADD_BUSINESS_PREMISES, addBusinessPremisesHandler);
  yield takeEvery(LIST_BUSINESS_PREMISES, businessPremisesListHandler);
  yield takeEvery(GET_ALL_BUSINESS_STARTED, getAllBusinessPremisesHandler);
  yield takeEvery(DETAIL_BUSINESS_PREMISES, businessPremisesDetailHandler);
  yield takeEvery(UPDATE_BUSINESS_PREMISES, updateBusinessPremisesHandler);
  yield takeEvery(DELETE_BUSINESS_PREMISES, deleteBusinessPremisesHandler);
  yield takeEvery(UPDATE_CMS_CONTENT, updateCMSContentHandler);
  yield takeEvery(LIST_CMS_CONTENT, fetchCMS);
  yield takeEvery(CMS_DFORM_CONTENT, fetchCMS_DForm);
  yield takeEvery(LIST_CONTACT, businessPremisesContactHandler);
  yield takeEvery(BUSINESSLIST_INVITE_USER_STARTED, businessListInviteHandler);
  yield takeEvery(USER_MANAGEMENT_LIST_STARTED, userManagementListHandler);
  yield takeEvery(FETCH_HELP_LIST_REQUEST, helpListHandler);
  yield takeEvery(UPDATE_USER_MANAGEMENT_BY_ID_STARTED, updateUserManagementByIDHandler);
  yield takeEvery(REMOVE_USER_MANAGEMENT_BY_ID, deleteUserManagementByIDHandler);
  yield takeEvery(LIST_FROM_TO_YOU_STARTED, documentListHandler);
  yield takeEvery(LIST_USER_FILES_STARTED, listUserFilesHandler);
  yield takeEvery(LIST_DOCUMENT_CATEGORY_STARTED, listDocumentCategorysHandler);
  yield takeEvery(MESSAGE_LIST, messageListHandler);
  yield takeEvery(MESSAGE_READ, messageReadHandler);
  yield takeEvery(GET_PROFESSIONAL_GROUP, getProfessionalGroupsHandler);
  yield takeEvery(PROCEDURE_TEMPLATES, procedureTemplateHandler);
  yield takeEvery(GET_APPLICATION_STARTED_ByID, getApplicationbyIDHandler);
  yield takeEvery(GET_STEPS_STARTED, getStepsHandler);
  yield takeEvery(GET_SERVICE_AREA_STARTED, getServiceAreaHandler);
  yield takeEvery(GET_SERVICE_AREA_DETAIL_STARTED, getServiceAreaDetailHandler);
  yield takeEvery(GET_SERVICE_AREA_DETAIL_MULTI_STARTED, getServiceAreaDetailMultiHandler);
  yield takeEvery(ADD_APPLICATION_STEP_STARTED, AddApplicationStepHandler);
  yield takeEvery(ADD_SERVICE_APPLICATION_STEP_STARTED, AddServiceApplicationStepHandler);
  yield takeEvery(CREATE_COMPLETE_APPLICATION_STARTED, CreateCompleteApplicationHandler);
  yield takeEvery(NEW_SERVICE_ORDER_COSTS_STARTED, newServiceOrderCostHandler);
  yield takeEvery(SEND_SIGNATURE_REQUEST_EMAIL_STARTED, sendSignatureRequestEmailHandler);
  yield takeEvery(APPLICATION_PROCESS_STATUS_STARTED, getApplicationProcessStatusHandler);
  yield takeEvery(GET_DASHBOARD_MESSAGE_STARTED, getDashboardMessageHandler);
  yield takeEvery(GET_LEADER_BY_LOCATION_STARTED, getLeaderByLocationHandler);
  yield takeEvery(ADD_LEADER_BY_LOCATION_STARTED, addLeaderByLocationHandler);
  yield takeEvery(GET_DASHBOARD_DATA_STARTED, getDashboardDateHandler);
  yield takeEvery(GET_MARKETING_STARTED, getMarketingHandler);
  yield takeEvery(GET_RECRUIT_CLIENT_STARTED, getRecruitClientHandler);
  yield takeEvery(GET_ON_GOING_SERVICE_ORDER_STARTED, getOnGoingServiceOrderHandler);
  yield takeEvery(GET_COMPLETED_SERVICE_ORDER_STARTED, getCompletedServiceOrderHandler);
  yield takeEvery(OWNER_BY_LOCATION_STARTED, ownerByLocationHandler);
  yield takeEvery(NEW_SERVICE_ORDER_EVIDENCE_STARTED, newServiceOrderEvidenceHandler);
  yield takeEvery(NEW_SERVICE_ORDER_EVIDENCE_PENDING_STARTED, newServiceOrderEvidencePendingHandler);
  yield takeEvery(SIGNATURE_UPLOAD_STARTED, signatureUploadHandler);
  yield takeEvery(UPLOAD_EVIDENCE_STARTED, uploadEvidenceHandler);
  yield takeEvery(UPLOAD_SERVER_ORDER_EVIDENCE_STARTED, uploadServerOrderEvidenceHandler);
  yield takeEvery(SAVE_EVIDENCE_DB_STARTED, saveEvidenceDBHandler);
  yield takeEvery(GET_EVIDENCE_DB_STARTED, getEvidenceDBHandler);
  yield takeEvery(GET_EVIDENCE_DB_SO_ID_STARTED, getEvidenceDBByServiceOrderIdHandler);
  yield takeEvery(UPLOAD_SERVICE_ORDER_EVIDENCE_STARTED, uploadServiceOrderEvidenceHandler);
  yield takeEvery(SAVE_EVIDENCE_ERP_STARTED, saveEvidenceERPHandler);
  yield takeEvery(EVIDENCE_STATIC_DOCUMENTS_STARTED, getEvidenceStaticDocumentsHandler);
  yield takeEvery(APPLICATION_LOCATION_VALIDATION_STARTED, getApplicationBusinessStatusByIdHandler);
  yield takeEvery(ADD_OWNER_BY_LOCATION_STARTED, addOwnerByLocationHandler);
  yield takeEvery(REGISTER_USER_START_MODIFY, userRegisterHandlerInvitation);
  yield takeEvery(GET_DFORM_CMS_BY_ID, fetchCMS_DFormById);
  yield takeEvery(UPDATE_DFORM_CMS_BY_ID, updateDFormCMSbyIdHandler);
  yield takeEvery(GET_HELPDESK_ARTICLE_LIST_STARTED, gethelpDeskListStartedHandler);
  yield takeEvery(GET_HELPDESK_ARTICLE_DETAIL_STARTED, gethelpDeskDetailHandler);
  yield takeEvery(GET_HELPDESK_ARTICLE_CONTENT_STARTED, gethelpDeskContentHandler);
  yield takeEvery(GET_SERVICE_AREA_TECHNICAL_LEADER_STARTED, getServiceAreaTechnicalLeaderHandler);
  yield takeEvery(UPLOAD_DOCUMENT_STARTED, uploadDocumentHandler);
  yield takeEvery(UPLOAD_DOCUMENT_BASE64_STARTED, uploadDocumentBase64Handler);
  yield takeEvery(UPLOAD_DMS_DOCUMENT_STARTED, uploadDMSDocumentHandler);
  yield takeEvery(DOWNLOAD_ORDER_STARTED, getDownloadOrderHandler);
  yield takeEvery(DOWNLOAD_INVOICE_STARTED, getDownloadInvoiceHandler);
  yield takeEvery(DOWNLOAD_CERTIFICATE_STARTED, getDownloadCertificateHandler);
  yield takeEvery(DOWNLOAD_XML_STARTED, getDownloadXmlHandler);
  yield takeEvery(GET_GOING_SERVICE_ORDER_PAGINATE_STARTED, getOnGoingServiceOrderPaginationHandler);
  yield takeEvery(GET_EVIDENCE_FILE_DOWNLOAD_STARTED, getDownloadEvidenceFileHandler);
  yield takeEvery(DELETE_SINGLE_EVIDENCE_STARTED, deleteSingleEvidenceFile);
  yield takeEvery(USER_LOGIN_TIMES_STARTED, getLoginTimes);
  yield takeEvery(USER_LOGIN_SESSION_STARTED, getLoginSession);
  yield takeEvery(USER_LOGIN_TIME_DURATION_STARTED, getLoginAverageSession);
  yield takeEvery(USER_ABANDONED_CART_STARTED, getAbandonedCart);
  yield takeEvery(USER_LOGIN_TIMES_DURATION_STARTED, getLoginDuration);
  yield takeEvery(USER_DEVICE_SOURCE_STARTED, getSourceDevice);
  yield takeEvery(USER_ANALYTICS_EVIDENCE_STARTED, getEvidenceAnalytics);
  yield takeEvery(USER_ACTIVITY_STATISTIK_STARTED, getActivityStatistik);
  yield takeEvery(USER_ACTIVITY_ON_STARTED, postActivityStatistik);
  yield takeEvery(GET_CMS_BY_KEY_STARTED, getCMSbyIDHandler);
}
