import { getApiCall, postApiCall,  } from "src/services/utils/axios";
import { BASE_URL, API } from "src/services/utils/defines";

export const getLoginTimeAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.USER_LOGIN_TIME}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getLoginSessionAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.USER_LOGIN_SESSION}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getLoginAverageSessionAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.USER_LOGIN_AVERAGE_SESSION}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getAbandonedCartAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.USER_ABANDONED_CART}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getLoginDurationAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.LOGIN_TIMES}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getSourceDeviceAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.GET_SOURCE}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getEvidenceAnalyticsAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.EVIDENCE_ANALYTICS}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getActivityStatistikAPI = async (paramsData) => {
  const url = `${BASE_URL}${API.GET_ACTIVITY_STATISTIK}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const postActivityStatistikAPI = async (payload) => {
  const url = `${BASE_URL}${API.POST_ACTIVITY_STATISTIK}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await postApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};