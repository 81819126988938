import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userAnalyticsEvidenceStarted } from "src/store/actions/userAnalytics";
import CircularLoading from "src/components/atoms/loader/CircularLoading";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import UserAnalyticsEvidenceList from "./UserAnalyticsEvidenceList";
import { optionsLoginTime } from "src/helpers/useranalytics";
import DocumentFilterSelect from "src/components/atoms/select/DocumentFilterSelect";

const UserAnalyticsEvidence = () => {

  return <> <UserAnalyticsEvidenceWrap /></>;
};

const UserAnalyticsEvidenceWrap = () => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const userAnalytics = useSelector((state) => state.userAnalytics);
  const [loginTimes, setLoginTimes] = useState();
  const [selectPeriodic, setSelectPeriodic] = useState(optionsLoginTime[0]?.value);
  const dispatch = useDispatch();

  const [view, setView] = useState("chart");

  const handleView = (value) => {
    view !== value && setView(value);
  };

  useEffect(() => {
    dispatch(userAnalyticsEvidenceStarted({period: selectPeriodic}));
    setLoginTimes(null)
  }, [selectPeriodic]);

  useEffect(() => {
    setLoginTimes(userAnalytics?.evidence_analytics?.data)
  }, [userAnalytics?.evidence_analytics?.data]);

  return (
    <>
      <div className="flex flex-col md:flex-row justify-between">
        <h1 className="text-xl my-6 flex items-center gap-2">
          {cmsDashboarddata?.user_analytics?.upload_evidence?.heading}
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "user_analytics",
                  unique: "upload_evidence",
                  heading: "Benutzer Analyse",
                })
              );
            }}
          />
        </h1>
        <DocumentFilterSelect
          extra="w-full w-auto max-w-[220px]"
          extraLabel="hidden"
          label={"Anrede"}
          placeholder="Herr/Frau"
          id="salutation"
          options={optionsLoginTime}
          choose={false}
          setSelectDocument={setSelectPeriodic}
        />
      </div>
      {userAnalytics?.evidence_analytics?.isLoading && (
        <div className="min-h-96 flex justify-center items-center">
          <CircularLoading css="py-6" />
        </div>
      )}
      {loginTimes && <UserAnalyticsEvidenceList data={loginTimes} view={view} handleView={handleView} selectPeriodic={selectPeriodic} />}
    </>
  );
};

export default UserAnalyticsEvidence;

