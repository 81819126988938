/*eslint-disable*/
import { PrimaryButton, SecondaryButton } from "src/components/atoms/button";
import Card from "src/components/atoms/card/Card";
import NewBusiness from "../NewBusiness";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteBusinessPremises } from "src/store/actions/business";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import DeleteModal from "src/components/atoms/modal/DeleteModal";
import { useState } from "react";
import { setApplicationSidebar } from "src/store/actions/application";
import Cookies from "js-cookie";

const BusinessGrid = ({ handleOpen, filterData }) => {
  const profile = useSelector((state) => state.profile);
  const businessState = useSelector((state) => state.management);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const { business_premises } = cmsDashboarddata;

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const handleClick = (id) => {
    if (profile?.data?.role === "admin") {
      navigate("/admin/betriebsstaetten/" + id);
    } else {
      navigate("/betriebsstaetten/" + id);
    }
  };
  const businessDeleteHandle = (id) => {
    dispatch(deleteBusinessPremises(id));
  };

  const handleDelete = () => {
    dispatch(deleteBusinessPremises(selectedId));
  };

  const deleteModalClose = () => {
    setSelectedId("");
    setShowDeleteModal(false);
  };

  const handleSelectedId = (id) => {
    setSelectedId(id);
    setShowDeleteModal(true);
  };

  const InhaberwechselHandle = (service, location_id) => {
    const locationDetail = filterData.find((data) => data.location_id === location_id);
    localStorage.setItem("dformId", service);
    localStorage.setItem("locationDetailForm", JSON.stringify(locationDetail));
    localStorage.setItem("percentageStep", "0");
    Cookies.remove("submitted_id");

    if (profile?.data?.role === "admin") {
      navigate("/admin/verfahren/antrag/" + service);
    } else {
      navigate("/verfahren/antrag/" + service);
    }
    dispatch(setApplicationSidebar());
  };

  return (
    <div>
      <DeleteModal handleOpen={deleteModalClose} showModal={showDeleteModal} confirmHandle={handleDelete} />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 2xl:gap-12">
        {filterData &&
          filterData.map((business, index) => {
            return (
              <Card css="justify-between !px-6  xl:!px-8 2xl:!px-10" key={business.id}>
                <div className="flex items-start flex-col sm:flex-row sm:items-end">
                  <div className={`overflow-hidden ${business?.date?.length > 0 ? "w-1/2" : "w-full"}`}>
                    <h4 className="font-tahoma-bold font-bold leading-6 text-lg mb-1">{business.name}</h4>
                    <h4 className="">
                      {index !== 0 ? <span className="text-SmokeyGray dark:text-BrightGray">Nebenbetriebsstätte</span> : <span className="text-AFPPrimaryLight">Hauptbetriebsstätte</span>}
                    </h4>
                  </div>
                  {business?.date?.length > 0 && (
                    <div className="w-1/2">
                      <h2 className="font-tahoma-bold font-bold leading-6">{business.date}</h2>
                    </div>
                  )}
                </div>
                <hr className="my-4 bg-SmokeyGray dark:bg-BrightGray" />
                <div className="mb-3">
                  <h4 className="font-tahoma-bold font-bold leading-6 text-SmokeyGray tracking-wide dark:text-BrightGray">{business?.street + " " + business?.house_number}</h4>
                  <h4 className="font-tahoma-bold font-bold leading-6 text-SmokeyGray tracking-wide dark:text-BrightGray">{business?.city + " " + business?.country}</h4>
                </div>
                <div className="flex justify-end items-end">
                  <EditIconCMS
                    handleOpen={() => {
                      dispatch(
                        dashboardModalOpenCMS({
                          cms_key: "business_premises",
                          unique: "business_card",
                          heading: "Betriebsstätten",
                        })
                      );
                    }}
                  />
                </div>
                <div>
                  <p className="text-SmokeyGray tracking-wide dark:text-BrightGray">
                    <span className="">{business_premises.business_card.email}:</span>
                    <span className="font-tahoma-bold ml-1">{business.official_email}</span>
                  </p>
                  <p className="text-SmokeyGray tracking-wide dark:text-BrightGray">
                    <span className="">{business_premises.business_card.leaders}:</span>
                    <span className="font-tahoma-bold ml-1">
                      {business.technical_leaders.length > 0 &&
                        business.technical_leaders[0]?.title + " " + business.technical_leaders[0]?.first_name + " " + business.technical_leaders[0]?.last_name}
                    </span>
                  </p>
                  <p className="text-SmokeyGray tracking-wide dark:text-BrightGray">
                    <span className="">{business_premises.business_card.ik_number}:</span>
                    <span className="font-tahoma-bold ml-1">{business?.ik_number.includes("[") ? JSON.parse(business.ik_number)[0] : business.ik_number}</span>
                  </p>
                  <p className="text-SmokeyGray tracking-wide dark:text-BrightGray">
                    <span className="">{business_premises.business_card.phone}:</span>
                    <span className="font-tahoma-bold ml-1">{business.phone_number}</span>
                  </p>
                </div>
                <div className="pt-6 pb-4 grid sm:grid-cols-2 2xl:grid-cols-3 gap-2">
                  <PrimaryButton
                    loading={businessState?.isLoading}
                    title={business_premises.card_button.edit_title}
                    btnStyle={"font-tahoma-bold font-medium !py-1 rounded-2xl"}
                    handler={() => {
                      handleClick(business.id);
                    }}
                  />
                  <SecondaryButton
                    variant="secondary"
                    title={business_premises.card_button.change}
                    css={"font-tahoma-bold font-semibold !px-1"}
                    loading={businessState?.isLoading}
                    handler={() => InhaberwechselHandle("IWA", business?.location_id)}
                  />
                  <SecondaryButton
                    variant="secondary"
                    title={business_premises.card_button.delete}
                    css={"font-tahoma-bold font-semibold !px-1"}
                    handler={() => {
                      handleSelectedId(business.id);
                    }}
                    loading={businessState?.isLoading}
                  />
                  <div className="flex justify-start items-end">
                    <EditIconCMS
                      handleOpen={() => {
                        dispatch(
                          dashboardModalOpenCMS({
                            cms_key: "business_premises",
                            unique: "card_button",
                            heading: "Betriebsstätten",
                          })
                        );
                      }}
                    />
                  </div>
                </div>
              </Card>
            );
          })}
        <NewBusiness handleOpen={handleOpen} />
      </div>
    </div>
  );
};

export default BusinessGrid;
