import { Checkbox } from "@mui/material";
import { green } from "@mui/material/colors";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const CustomCheckboxGreen = ({ isChecked }) => {
  return (
    <Checkbox
      {...label}
      sx={{
        color: "transparent",
        borderRadius: "4px",

        "&.Mui-checked": {
          color: green[600],
          "& .MuiSvgIcon-root": {
            color: green[600],
            borderRadius: "25px",
          },
        },
        "& .MuiCheckbox-indeterminate .MuiSvgIcon-root": {
          color: green[600],
        },
        margin: 0,
        padding: 0,
      }}
      checked={isChecked}
    />
  );
};

export default CustomCheckboxGreen;
