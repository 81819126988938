import Logo from "src/assets/img/newLogo.png";
import LogoDark from "src/assets/img/darkLogo.png";

const Login = ({ children, data }) => {
  return (
    <>
      <div className="grid-cols-5 grid">
        <div className="loginleftwrap">
          <div className="flex justify-around 2xl:justify-start flex-col p-2 sm:p-4 xl:p-6 h-full">
            <div className="w-full">
              <img
                src={Logo}
                className="mt-2 ml-2 2xl:mt-6 2xl:ml-6 dark:hidden"
                alt="logo"
              />
              <img
                src={LogoDark}
                className="mt-2 ml-2 2xl:mt-6 2xl:ml-6 hidden dark:block"
                alt="logo"
              />
            </div>
            <div className="m-4 mb-1 2xl:mb-4 pt-1 2xl:pt-4">
              <h2 className="registerHeading">{data.title}</h2>
              <h2 className="text-center text-lg text-SmokeyGray mt-1 dark:text-BrightGray">
                {data.subTitle}
              </h2>
            </div>
            {children}
            <p className="my-2 2xl:mt-12 text-SmokeyGray text-sm px-4 2xl:text-base dark:text-SilverSandLight">
              {data.LoginPolicy}
            </p>
          </div>
        </div>
        <div className="loginrightwrap"></div>
      </div>
    </>
  );
};

export default Login;
