import { Checkbox } from "@mui/material";
import { green } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import CustomCheckboxGreen from "src/components/atoms/input/CustomCheckbox/CustomCheckboxGreen";
import { evidenceCheckDetail } from "src/helpers/dform/validateEvidence";
import * as Actions from "src/store/actions/evidence";
import { setEvidenceById } from "src/store/actions/evidence";

const EvidenceCheckbox = ({ evedenceData, evidence, handleCheckChangeDetailFill, entriesKey }) => {
  const checked =
    evidence?.evidenceDetail?.data && evedenceData?.inventoryID?.value && evidence?.evidenceDetail?.data[evedenceData?.inventoryID?.value]
      ? evidence?.evidenceDetail?.data[evedenceData?.inventoryID?.value]
      : false;
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(checked);
  const [initialCheck, setInitialCheck] = useState("");

  const handleCheckbox = async (event) => {
    let checkedValue = event.target.checked;
    setIsChecked(checkedValue);

    dispatch(
      setEvidenceById({
        key: evedenceData?.inventoryID?.value,
        value: checkedValue,
        type: "checkbox",
      })
    );

    if (checkedValue) {
      if (!initialCheck) {
        setInitialCheck("check");
      }
    } else {
      setInitialCheck("");
      dispatch(Actions.removeEvidence(entriesKey, evedenceData?.inventoryID?.value));
    }
  };

  useEffect(() => {
    let newObj = {};
    let markObj = {};
    evidence?.data?.forEach((ab) => {
      ab?.prerequisiteGroup?.value;
      if (newObj[ab?.prerequisiteGroup?.value]) {
        newObj[ab?.prerequisiteGroup?.value]?.push(ab);
      } else {
        newObj[ab?.prerequisiteGroup?.value] = [ab];
        markObj[ab?.prerequisiteGroup?.value] = false;
      }
    });
    const isCheckUpdated = evidenceCheckDetail(newObj[entriesKey], evidence);
    if (isCheckUpdated) {
      handleCheckChangeDetailFill(entriesKey, true);
    } else {
      handleCheckChangeDetailFill(entriesKey, false);
    }
  }, [initialCheck]);

  return (
    <div className="w-full mb-5 flex justify-between flex-wrap" id="pdf-content">
      <div className="flex gap-x-2 items-start justify-start pr-3" id={evedenceData?.inventoryID?.value}>
        <div className="flex start">
          <div className="ml-2 p-0">
            <div className="flex justify-between gap-2 items-start mr-2">
              <div
                className="p-0 m-0 font-bold dark:text-BrightGray"
                dangerouslySetInnerHTML={{
                  __html: evedenceData?.prerequisite?.ValueDescription,
                }}
              ></div>
              {isChecked && <CustomCheckboxGreen isChecked={isChecked} />}
            </div>
            <div className="flex justify-start items-start mt-4">
              {evedenceData?.evidenceType?.value === "Erklärung" && (
                <Checkbox
                  sx={{
                    marginLeft: "-10px",
                    marginTop: "-10px",
                    color: "#484848",
                    "&.Mui-checked": {
                      color: green[600],
                    },
                  }}
                  checked={isChecked}
                  onChange={handleCheckbox}
                />
              )}
              <div
                className="text-AFPPrimaryLight !text-[15px]"
                dangerouslySetInnerHTML={{
                  __html: evedenceData?.longDescription?.value,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      {!isChecked ? <span className="text-red-400 text-sm my-3">Pflichtfeld</span> : null}
    </div>
  );
};

export default EvidenceCheckbox;
