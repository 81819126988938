import { Modal, Box } from "@mui/material";
import styles from "../style.module.css";
import { PrimaryButton } from "src/components/atoms/button";
import { AiOutlineClose } from "react-icons/ai";
import HelpDeskCheckbox from "../HelpDeskCheckBox";

const style = {
  p: 2,
  left: "50%",
  top: "50%",
  width: "90%",
  maxWidth: "840px",
  borderRadius: "12px",
  position: "relative",
  border: "1px solid #CBCBCB",
  transform: "translate(-50%, -50%)",
};

const ArticleModal = ({ open, handleClose, formik, itemKey, selectedLink }) => {
  const articleHandle = () => {
    handleClose();
  };

  return (
    <div>
      <Modal open={open} className={styles.customModalRoot}>
        <Box sx={style} className={`${styles.customInnerChild} bg-AFPBGPrimary dark:bg-AFPDarkBgDarkest dark:border-SmokeyGray`}>
          <div className={`max-h-[90vh] overflow-y-auto overflow-x-hidden customScrollBar w-full p-1`}>
            <div className="flex justify-between items-center mb-6 md:pr-8">
              <h2 className="text-xl md:text-2xl font-tahoma-bold dark:text-BrightGray">Artikel</h2>
              <AiOutlineClose size={24} className="text-SmokeyGray cursor-pointer -mt-2 dark:text-BrightGray" onClick={handleClose} />
            </div>
            <HelpDeskCheckbox formik={formik} itemKey={itemKey} selectedLink={selectedLink} />
            <div className="flex flex-col gap-4 my-4 justify-between">
              <PrimaryButton title="Speichern" handler={articleHandle} />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ArticleModal;
