import { Checkbox } from "@mui/material";
import { useFormik } from "formik";
import { green } from "@mui/material/colors";
import { initialValuesRegisterTab2, validationSchemaRegisterTab2 } from "src/services/validation/Auth";
import { PrimaryButton, SecondaryButton } from "src/components/atoms/button";
import { GrNext, GrPrevious } from "react-icons/gr";
import { ValuesValidateDB } from ".";
import { useSelector } from "react-redux";

const heading = "Registrierung";
const subHeading = "Bitte erstellen Sie einen neuen Account";

const SecondRegisterTab = ({ activeTab, handleBack, handleNext, tabs }) => {
  let preinitialValues = ValuesValidateDB(activeTab);
  const cmsLegal = useSelector((state)=> state.users.cms)

  const formik = useFormik({
    initialValues: preinitialValues ? { ...initialValuesRegisterTab2, ...preinitialValues } : initialValuesRegisterTab2,
    validationSchema: validationSchemaRegisterTab2,
    onSubmit: (values) => {
      handleNext(values, activeTab, true);
    },
  });

  const step2NextHandle = () => {
    formik.submitForm();
  };

  const step2BackHandle = () => {
    handleBack(formik.values, activeTab);
  };

  return (
    <>
      <h3 className="registerHeading mt-3">{heading}</h3>
      <h4 className="text-center text-lg text-SmokeyGray mt-1 mb-6 dark:text-BrightGray">{subHeading}</h4>
      <hr className="dark:border-SmokeyGray" />
      <form onSubmit={formik.handleSubmit} className="min-h-[40vh] flex justify-center">
        <div className="w-full lg:w-4/5 mt-6">
          <div className="flex justify-start items-start">
            <Checkbox
              className="!cursor-pointer"
              sx={{
                color: "#484848",
                "&.Mui-checked": {
                  color: green[600],
                },
              }}
              checked={formik.values["data_protection_declaration"]}
              onChange={(e) => formik.setFieldValue("data_protection_declaration", e.target.checked)}
            />
            <div className="mt-2">
              <div className="font-tahoma-bold text-black dark:text-BrightGray font-medium w-full">{isValidURL(cmsLegal?.data?.cms_data?.protection?.link) ? <a target="_blank" href={cmsLegal?.data?.cms_data?.protection?.link} className="underline text-blue-600 dark:text-blue-500">{cmsLegal?.data?.cms_data?.protection?.title}</a> : cmsLegal?.data?.cms_data?.protection?.title}</div>
              {formik.touched["data_protection_declaration"] && formik.errors["data_protection_declaration"] && (
                <div className="text-red-500 text-sm pl-1 py-1">{formik.errors["data_protection_declaration"]}</div>
              )}
            </div>
          </div>
          <div className="flex justify-start items-start mt-3">
            <Checkbox
              className="!cursor-pointer"
              sx={{
                color: "#484848",
                "&.Mui-checked": {
                  color: green[600],
                },
              }}
              checked={formik.values["terms_condition"]}
              onChange={(e) => formik.setFieldValue("terms_condition", e.target.checked)}
            />
            <div className="mt-2">
            <div className="font-tahoma-bold text-black dark:text-BrightGray font-medium w-full">{isValidURL(cmsLegal?.data?.cms_data?.condition?.link) ? <a target="_blank" href={cmsLegal?.data?.cms_data?.condition?.link} className="underline text-blue-600 dark:text-blue-500">{cmsLegal?.data?.cms_data?.condition?.title}</a> : cmsLegal?.data?.cms_data?.condition?.title}</div>
            {formik.touched["terms_condition"] && formik.errors["terms_condition"] && (
                <div className="text-red-500 text-sm pl-1 py-1">{formik.errors["terms_condition"]}</div>
              )}
            </div>
          </div>
          <div className="flex justify-start items-start mt-3">
            <Checkbox
              className="!cursor-pointer"
              sx={{
                color: "#484848",
                "&.Mui-checked": {
                  color: green[600],
                },
              }}
              checked={formik.values["certificate"]}
              onChange={(e) => formik.setFieldValue("certificate", e.target.checked)}
            />
            <div className="mt-2">
            <div className="font-tahoma-bold text-black dark:text-BrightGray font-medium w-full">{isValidURL(cmsLegal?.data?.cms_data?.certificate?.link) ? <a target="_blank" href={cmsLegal?.data?.cms_data?.certificate?.link} className="underline text-blue-600 dark:text-blue-500">{cmsLegal?.data?.cms_data?.certificate?.title}</a> : cmsLegal?.data?.cms_data?.certificate?.title}</div>
            {formik.touched["certificate"] && formik.errors["certificate"] && (
                <div className="text-red-500 text-sm pl-1 py-1">{formik.errors["certificate"]}</div>
              )}
            </div>
          </div>
          <div className="flex justify-start items-start mt-3">
            <Checkbox
              className="!cursor-pointer"
              sx={{
                color: "#484848",
                "&.Mui-checked": {
                  color: green[600],
                },
              }}
              checked={formik.values["declaration"]}
              onChange={(e) => formik.setFieldValue("declaration", e.target.checked)}
            />
            <div className="mt-2">
            <div className="font-tahoma-bold text-black dark:text-BrightGray font-medium w-full">{isValidURL(cmsLegal?.data?.cms_data?.declaration?.link) ? <a target="_blank" href={cmsLegal?.data?.cms_data?.declaration?.link} className="underline text-blue-600 dark:text-blue-500">{cmsLegal?.data?.cms_data?.declaration?.title}</a> : cmsLegal?.data?.cms_data?.declaration?.title}</div>
            {formik.touched["declaration"] && formik.errors["declaration"] && (
                <div className="text-red-500 text-sm pl-1 py-1">{formik.errors["declaration"]}</div>
              )}
            </div>
          </div>
        </div>
      </form>
      <div className="flex justify-center mt-6 gap-8">
        <SecondaryButton
          handler={step2BackHandle}
          variant={`${activeTab === 0 ? "disabled" : "secondary"}`}
          css="w-[150px]"
          title={"Zurück"}
          icon={<GrPrevious />}
        />
        <PrimaryButton
          handler={step2NextHandle}
          disable={activeTab === tabs.length - 1 ? true: false}
          btnStyle="!w-[150px]"
          title="Nächste"
          icon={<GrNext />}
          position={"after"}
        />
      </div>
    </>
  );
};

function isValidURL(url) {
  const regex = /^(https?:\/\/)?([a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})?)(:[0-9]{1,5})?(\/.*)?$/;
  return regex.test(url);
}

export default SecondRegisterTab;
