import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SecondaryButton } from "src/components/atoms/button";
import { IoAdd, IoGrid, IoListSharp } from "react-icons/io5";
import { StandardSearch } from "src/components/molecules/search";
import MainDocument from "src/components/organisms/document/Userpdf";
import UserDocTable from "src/components/organisms/document/UserDocTable";
import DocumentSearch from "src/components/molecules/search/DocumentSearch";
import AddDocumentModal from "src/components/atoms/modal/AddDocumentModal";
import { listDocumentCategory, listUserFiles } from "src/store/actions/document";
import { useDispatch } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";
import { useParams } from "react-router-dom";
import BackdropLoader from "src/components/atoms/loader/BackdropLoader";
import { isMobile, isTablet, browserName } from "react-device-detect";
import { userActivityOnStarted } from "src/store/actions/userAnalytics";
import { useUserActivity } from "src/helpers/useranalytics";

let icons = [
  {
    id: 1,
    icon: <IoListSharp />,
    key: "list",
    css: "text-4xl",
  },
  {
    id: 2,
    icon: <IoGrid />,
    key: "grid",
    css: "text-3xl text-AFPPrimary",
  },
];

const UserFiles = () => {
  const { id } = useParams();
  return id === "eigene" && <UserFilesIn />;
};

const UserFilesIn = () => {
  const document = useSelector((state) => state?.document);
  const selfDocuments = document?.selfDocuments;
  const documentCategory = document?.documentCategory;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userActivityOnStarted(useUserActivity("document", "uploaded_by", isMobile, isTablet, browserName)))
    dispatch(listUserFiles());
    dispatch(listDocumentCategory());
  }, []);

  return (
    <>
      {selfDocuments?.isLoading || documentCategory?.isLoading ? (
        <div className="flex w-full h-[60vh] justify-center items-center">
          <CustomCircularLoader />
        </div>
      ) : (
        selfDocuments?.data?.length > 0 && documentCategory?.data?.length > 0 && <UserFilesContent data={selfDocuments?.data} />
      )}
    </>
  );
};

function UserFilesContent({ data }) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [documentDetails, setDocumentDetails] = useState(null);
  const [view, setView] = useState("grid");
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSearchEnable, setIsSearchEnable] = useState(false);
  const document = useSelector((state) => state?.document);

  const [selectedLocations, setSelectedLocations] = useState([]);

  const allLocations = data.map((item) => item?.location_name);

  const cmsDashboarddata = useSelector((state) => state.cmsDocument);

  const handleLocationChange = (event, newValue) => {
    if (newValue) {
      setSelectedLocations(newValue);
    }
    const selectedLocation = event.target.value;

    setSelectedLocations((prevSelectedLocations) => {
      if (prevSelectedLocations.includes(selectedLocation)) {
        return prevSelectedLocations.filter((location) => location !== selectedLocation);
      } else {
        return [...prevSelectedLocations, selectedLocation];
      }
    });
  };

  const filteredDocuments = selectedLocations.length > 1 ? data?.filter((item) => selectedLocations?.includes(item?.location_name)) : data;

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  const ToggleHandler = (key) => {
    setView(key);
  };

  useEffect(() => {
    if (searchTerm?.length > 0 || selectedLocations.length > 1) {
      setIsSearchEnable(true);
    } else {
      isSearchEnable && setIsSearchEnable(false);
    }
  }, [searchTerm, selectedLocations]);

  const handleOpen = (doc) => {
    setDocumentDetails({ ...doc });
    setOpen(true);
    setIsModalOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setDocumentDetails(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString();
    if (isModalOpen) {
      setDocumentDetails({
        icon: null,
        category: "",
        date: formattedDate.replace(/\//g, "."),
        docs: "",
        doc_type: "",
        doc_url: "",
        location: "",
      });
      setOpen(true);
    } else {
      setDocumentDetails(null);
      setOpen(false);
    }
  }, [isModalOpen]);

  return (
    <>
      <div className="flex flex-col lg:flex-row items-start justify-between mt-5 gap-y-5 gap-x-5 flex-wrap">
        <div className="flex-1 w-full flex justify-between items-center gap-x-2">
          <div className=" flex flex-1 gap-x-3">
            <SecondaryButton
              title={cmsDashboarddata?.document?.upload_button?.title}
              css="max-w-[180px] w-full font-tahoma-bold font-semibold"
              icon={<IoAdd className="w-5 h-6" />}
              handler={() => {
                setIsModalOpen(true);
              }}
            />
            <EditIconCMS
              handleOpen={() => {
                dispatch(
                  dashboardModalOpenCMS({
                    cms_key: "document",
                    unique: "upload_button",
                    heading: "Dokumentenverwaltung",
                  })
                );
              }}
            />
          </div>
          <div className="flex justify-end items-center gap-4">
            {icons.map((icon) => {
              return React.cloneElement(icon.icon, {
                key: icon.key,
                onClick: () => {
                  ToggleHandler(icon.key);
                },
                className: `cursor-pointer duration-600 ${icon.css} ${view === icon.key ? "text-AFPPrimary" : "text-SmokeyGray dark:text-BrightGray"}`,
              });
            })}
          </div>
        </div>
        <div className="flex items-start justify-end flex-wrap lg:flex-nowrap w-full lg:w-auto gap-x-8 gap-y-4">
          <div className="flex items-center gap-x-3">
            <div className="relative w-64 rounded-full  mr-5">
              <DocumentSearch handleChange={handleLocationChange} locations={allLocations} placeholder={cmsDashboarddata?.document?.multi_select?.placeholder} />
            </div>
            <EditIconCMS
              handleOpen={() => {
                dispatch(
                  dashboardModalOpenCMS({
                    cms_key: "document",
                    unique: "multi_select",
                    heading: "Dokumentenverwaltung",
                  })
                );
              }}
            />
          </div>

          <div className="flex items-center gap-x-3">
            <div className="relative w-64 rounded-full">
              <StandardSearch value={searchTerm} handleSearch={handleSearchChange} placeholder={cmsDashboarddata?.document?.search?.title} />
            </div>
            <EditIconCMS
              handleOpen={() => {
                dispatch(
                  dashboardModalOpenCMS({
                    cms_key: "document",
                    unique: "search",
                    heading: "Dokumentenverwaltung",
                  })
                );
              }}
            />
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-7"></div>

      {filteredDocuments.map((document, index) => {
        const filteredItems = document?.documents?.filter(
          (item) =>
            item?.property_filename?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
            item?.date?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
            item?.category?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        );
        return view === "list" ? (
          <React.Fragment key={index}>
            <h1 className="font-tahoma-bold text-[24px] pt-10 mb-4">{document?.location_name + " " + document?.full_address}</h1>
            <div className="w-full flex justify-end">
              <EditIconCMS
                handleOpen={() => {
                  dispatch(
                    dashboardModalOpenCMS({
                      cms_key: "document",
                      unique: "list_table_head",
                      heading: "Dokumentenverwaltung",
                    })
                  );
                }}
              />
            </div>

            <UserDocTable document={filteredItems} />
          </React.Fragment>
        ) : (
          <React.Fragment key={index}>
            {isSearchEnable ? (
              filteredItems.length > 0 && <h1 className="font-tahoma-bold text-[24px] pt-10">{document?.location_name + " " + document?.full_address}</h1>
            ) : (
              <h1 className="font-tahoma-bold text-[24px] pt-10">{document?.location_name + " " + document?.full_address}</h1>
            )}
            <MainDocument
              id={document?.location_id}
              document={filteredItems}
              location={document?.full_address}
              isSearchEnable={isSearchEnable}
              handleOpen={handleOpen}
            />
          </React.Fragment>
        );
      })}
      {documentDetails && open && <AddDocumentModal open={open} handleClose={handleClose} documentDetails={documentDetails} />}
      <BackdropLoader open={document?.documentUpload?.isLoading || document?.dmsDocumentUpload?.isLoading} borderRadius={0} />
    </>
  );
}

export default UserFiles;
