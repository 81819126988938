import { BsEye } from "react-icons/bs";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const ManageApp = ({ business }) => {
  const profile = useSelector((state) => state.profile);
  const navigate = useNavigate();
  const handleClick = (id) => {
    if (profile?.data?.role === "admin") {
      navigate("/admin/betriebsstaetten/" + id);
    } else {
      navigate("/betriebsstaetten/" + id);
    }
  };

  return (
    <>
      <div className="flex-1 py-4">
        <ul className="pl-2">
          {business.map((item) => {
            return (
              item?.locationId && (
                <li key={item?.id} className="flex justify-between gap-1 items-center flex-wrap text-SmokeyGray text-md leading-7 dark:text-BrightGray">
                  <p className="flex-1 text-sm leading-7">{item?.title}</p>
                  <h2 className={`${item?.css} font-bold flex-1 text-sm leading-7`}>{item?.date}</h2>
                  <BsEye
                    className="flex-2 cursor-pointer"
                    onClick={() => {
                      handleClick(item?.id);
                    }}
                  />
                </li>
              )
            );
          })}
        </ul>
      </div>
    </>
  );
};

ManageApp.defaultProps = {
  business: [],
};

export default ManageApp;
