import { Table, TableBody, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import "./pastTable.css";
import React from "react";
import styled from "@emotion/styled";
import xmlImg from "src/assets/icons/xml.png";
import invoiceImg from "src/assets/icons/invoice.png";
import { pastDataHeader } from "src/mockData/procedureMock";
import TableHeader from "src/components/atoms/common/TableHeader";
import certificateImg from "src/assets/icons/certificate_outline.png";
import vector6 from "src/assets/img/vector6.png";
import { useSelector } from "react-redux";
import { parseDateFormat } from "src/helpers/document";
import Cookies from "js-cookie";
import { API, BASE_URL } from "src/services/utils/defines";
import useSortableData from "src/hooks/useSortableData";
import TableContainerDark from "src/components/atoms/table/TableContainerDark";

const StyledTableCell = styled(TableCell)`
  border-bottom: none;
`;

const renderStatus = (status) => {
  return <div className="tracking-wide bg-transparent text-SeaOrange rounded-xl py-2  gap-1 duration-300 w-full ">{status}</div>;
};

const PastTable = ({ title, tableBody, css }) => {
  const { items: sortedData, requestSortIncrease, requestSortDecrease } = useSortableData(tableBody);

  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const { procedures } = cmsDashboarddata;

  const downloadOrderHandle = (serviceOrderNbr) => {
    downloadNewHandler(API?.DOWNLOAD_ORDER, serviceOrderNbr);
  };
  const downloadInvoiceHandle = (serviceOrderNbr) => {
    downloadNewHandler(API?.DOWNLOAD_INVOICE, serviceOrderNbr);
  };
  const downloadCertificateHandle = (serviceOrderNbr) => {
    downloadNewHandler(API?.DOWNLOAD_CERTIFICATE, serviceOrderNbr);
  };
  const downloadXmlHandle = (serviceOrderNbr) => {
    downloadNewHandler(API?.DOWNLOAD_XML, serviceOrderNbr);
  };

  const downloadNewHandler = (endpoint, id) => {
    const link = window.document.createElement("a");
    link.href = `${BASE_URL}${endpoint}?user_id=${Cookies.get("userId")}&service_order_nbr=${id}`;
    link.download = "document";
    link.style.display = "none";
    window.document.body.appendChild(link);
    window.open(link.href, "_blank");
    setTimeout(() => {
      window.document.body.removeChild(link);
    }, 100);
  };

  return (
    <div className={`w-full ${css}`}>
      <h2 className="!mb-5 text-2xl font-tahoma-bold my-3 sm:my-4 md:my-4 flex items-center gap-3">{title}</h2>
      <TableContainerDark>
        <Table aria-label="simple table">
          <TableHeader tableData={pastDataHeader(procedures.past_table_head)} increaseSort={requestSortIncrease} decreaseSort={requestSortDecrease} />
          <TableBody>
            {sortedData.map((item, i) => {
              return (
                <TableRow key={i} className={item?.isActive ? "active-row" : ""}>
                  <StyledTableCell align="left" style={{ paddingLeft: "40px" }}>
                    <Typography>{item?.company_name + " , " + item?.location_name}</Typography>
                  </StyledTableCell>
                  <StyledTableCell align="left">{item?.service_order_nbr}</StyledTableCell>
                  <StyledTableCell align="left">{item?.service_order_type}</StyledTableCell>
                  <StyledTableCell align="left">{parseDateFormat(item?.scheduled_end_date)}</StyledTableCell>

                  <StyledTableCell align="left" sx={{ minWidth: "150px" }}>
                    <div className="flex gap-x-5 items-center">
                      <Tooltip title="Auftrags Dokument">
                        <img src={vector6} alt="vector6" className="h-[18px] w-auto cursor-pointer" onClick={() => downloadOrderHandle(item?.service_order_nbr)} />
                      </Tooltip>
                      <Tooltip title="Rechnungs Dokument">
                        <img src={invoiceImg} alt="invoiceImg" className="h-[18px] w-auto cursor-pointer" onClick={() => downloadInvoiceHandle(item?.service_order_nbr)} />
                      </Tooltip>
                      <Tooltip title="Zertifikat">
                        <img
                          src={certificateImg}
                          alt="certificateImg"
                          className="h-[18px] w-auto cursor-pointer"
                          onClick={() => downloadCertificateHandle(item?.service_order_nbr)}
                        />
                      </Tooltip>
                      <Tooltip title="XML Dokument">
                        <img src={xmlImg} alt="xmlImg" className="h-[18px] w-auto cursor-pointer" onClick={() => downloadXmlHandle(item?.service_order_nbr)} />
                      </Tooltip>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="left">{renderStatus(item?.statusDisplayName ?? item?.status)}</StyledTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainerDark>
    </div>
  );
};

export default PastTable;
