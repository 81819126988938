import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Collapse, IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import Chart from "react-apexcharts";
import { currentRegisterOptions } from "src/helpers/useranalytics";
import ToggleView from "src/components/atoms/select/ToggleView";

const UserAnalyticsEvidenceList = ({ data, view, handleView, selectPeriodic }) => {
  return (
    <>
      <ToggleView view={view} handleView={handleView} />
      {view === "chart" && <UserAnalyticsEvidenceAChart data={data} selectPeriodic={selectPeriodic} />}
      {view === "list" &&
        (selectPeriodic === "all" ? (
          <UserAnalyticsEvidenceAList data={data} selectPeriodic={selectPeriodic} showIcon={true} />
        ) : (
          <UserAnalyticsEvidenceAListObj data={data} selectPeriodic={selectPeriodic} />
        ))}
    </>
  );
};

const UserAnalyticsEvidenceAChart = ({ data, selectPeriodic }) => {
  const [loginSession, setLoginSession] = useState();

  useEffect(() => {
    let loginSessions = {
      labels: [],
      series: [],
    };
    if(selectPeriodic === "all") {
      Object.entries(data).forEach(([device, data]) => {
        const totalCount = Object.values(data).reduce((sum, count) => sum + parseInt(count), 0);
        loginSessions.series.push(totalCount);
        loginSessions.labels.push(device);
      });
    } else {
      Object.entries(data).forEach(([device, deviceData]) => {
        const totalCount = Object.values(deviceData).reduce((sum, newData) => 
          sum + Object.values(newData).reduce((innerSum, count) => 
            innerSum + parseInt(count, 10), 0), 0);

        loginSessions.series.push(totalCount);
        loginSessions.labels.push(device);
      });
    }

    setLoginSession(loginSessions);
  }, []);

  return (
    loginSession && (
      <div className="flex justify-center items-start mt-4">
        <Chart options={currentRegisterOptions(loginSession)} series={loginSession?.series} type="pie" width={380} />
      </div>
    )
  );
};
const UserAnalyticsEvidenceAListObj = ({ data, selectPeriodic }) => {
  return Object.entries(data).map(([device, data], index) => {
    return (
      <>
        <h1 className="text-center mt-4 mb-1">{device}</h1>
        {Object.entries(data)?.length > 0 ? (
          <UserAnalyticsEvidenceAList data={data} selectPeriodic={selectPeriodic} showIcon={index === 0 ? true : false} />
        ) : (
          <h3 className="text-red-300 text-center text-sm">Daten nicht gefunden</h3>
        )}
      </>
    );
  });
};


const UserAnalyticsEvidenceAList = ({ data, selectPeriodic, showIcon = false }) => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const dispatch = useDispatch();
  return (
    <div className={` ${selectPeriodic === "all" && "mt-4"}`}>
      {showIcon && <EditIconCMS
        handleOpen={() => {
          dispatch(
            dashboardModalOpenCMS({
              cms_key: "user_analytics",
              unique: "upload_evidence_table_head",
              heading: "Benutzer Analyse",
            })
          );
        }}
      />}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow className="bg-AFPLightGray">
              <TableCell>{cmsDashboarddata?.user_analytics?.upload_evidence_table_head?.title}</TableCell>
              <TableCell align="right">{cmsDashboarddata?.user_analytics?.upload_evidence_table_head?.status}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(data).map(([device, data]) => (
              <Row key={device} device={device} data={data} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

const Row = ({ device, data }) => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const [open, setOpen] = useState(false);

  const totalCount = Object.values(data).reduce((sum, count) => sum + parseInt(count), 0);

  return (
    <>
      <TableRow>
        <TableCell>{device}</TableCell>
        <TableCell align="right">
          {totalCount}
          <IconButton className="!p-0" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className="!p-0" style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small">
              <TableHead>
                <TableRow className="bg-BrightGray">
                  <TableCell className="md:!pl-8">{cmsDashboarddata?.user_analytics?.upload_evidence_table_head?.sub_title}</TableCell>
                  <TableCell className="md:!pr-8" align="right">
                    {cmsDashboarddata?.user_analytics?.upload_evidence_table_head?.sub_status}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(data).map(([browser, count]) => (
                  <TableRow key={browser}>
                    <TableCell className="md:!pl-8">{browser}</TableCell>
                    <TableCell className="md:!pr-8" align="right">
                      {count}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default UserAnalyticsEvidenceList;
