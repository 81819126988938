import Chart from "react-apexcharts";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import ToggleView from "src/components/atoms/select/ToggleView";
import { globalChartOptions } from "src/helpers/useranalytics";

const UserAnalyticsLoginTimesChart = ({ data, view, handleView }) => {
  return (
    <>
      <ToggleView view={view} handleView={handleView} />
      {view === "chart" && <UserAnalyticsACChart data={data} />}
      {view === "list" && <UserAnalyticsACList data={data} />}
    </>
  );
};

const customOption = {
  tooltipID: "RegisterTimes",
  extra: {
    colors: ["#82CA9D"],
  },
};

const UserAnalyticsACChart = ({ data }) => {
  const series = [
    {
      name: "Sales",
      data: data?.count,
    },
  ];
  return <Chart options={globalChartOptions(data, customOption)} series={series} type="bar" height={350} />;
};

const UserAnalyticsACList = ({ data }) => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const dispatch = useDispatch();
  return (
    <div className="mt-4">
      <EditIconCMS
        handleOpen={() => {
          dispatch(
            dashboardModalOpenCMS({
              cms_key: "user_analytics",
              unique: "login_times_head",
              heading: "Benutzer Analyse",
            })
          );
        }}
      />

      <TableContainer component={Paper} style={{ borderRadius: "12px", background: "#fff" }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className="bg-AFPLightGray">
              <TableCell>{cmsDashboarddata?.user_analytics?.login_times_head?.title}</TableCell>
              <TableCell align={"right"}>{cmsDashboarddata?.user_analytics?.login_times_head?.status}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.category?.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item}</TableCell>
                  <TableCell align={"right"}>{data?.count[index]}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UserAnalyticsLoginTimesChart;
