import { getApiCall, postApiCall } from "src/services/utils/axios";
import { BASE_URL, API } from "src/services/utils/defines";

export const LOGINAPI = async (payload) => {
  const url = `${BASE_URL}${API.LOGIN}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const REGISTERAPI = async (payload) => {
  const url = `${BASE_URL}${API.REGISTER}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const FORGOTPASSWORDAPI = async (payload) => {
  const url = `${BASE_URL}${API.FORGOTPASSWORD}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.message);
  }
};

export const RESETPASSWORDAPI = async (payload) => {
  const url = `${BASE_URL}${API.RESETPASSWORD}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.message);
  }
};

export const CHANGEPASSWORDAPI = async (payload) => {
  const url = `${BASE_URL}${API.CHANGEPASSWORD}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await postApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    throw new Error(err?.message);
  }
};

export const REGISTERAPIMODIFY = async (payload) => {
  const url = `${BASE_URL}${API.REGISTERMODIFY}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await postApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const LOGOUTAPI = async (payload) => {
  const url = `${BASE_URL}${API.LOGOUT}`;
  try {
    const paramsData = { isAuthorized: true };
    const response = await postApiCall(url, payload, paramsData);
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const VERIFYREGISTERAPI = async (payload) => {
  const url = `${BASE_URL}${API.REGISTERVERIFY}`;
  try {
    const response = await postApiCall(url, payload);
    return response;
  } catch (err) {
    throw new Error(err?.message);
  }
};

export const GET_CMS_BY_ID = async (paramsData) => {
  const url = `${BASE_URL}${API.CMS_BY_ID}`;
  try {
    const response = await getApiCall(url, paramsData);
    return response;
  } catch ({ response }) {
    throw new Error(response?.data?.error?.message);
  }
};
