import React from "react";

const CrispTicketFrame = ({ hash, email }) => {
  return (
    <iframe
      title="Ticket Center"
      src={`https://plugins.crisp.chat/urn:crisp.im:ticket-center:0/tickets/95b924a2-c831-4b03-a266-7396f51b95dd?email=${email}&hmac=${hash}`}
      referrerpolicy="origin"
      sandbox="allow-forms allow-popups allow-modals allow-scripts allow-same-origin"
      width="100%"
      height="600px"
      frameborder="0"
    ></iframe>
  );
};

export default CrispTicketFrame;
