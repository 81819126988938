import { useState, useMemo, useEffect } from "react";
import { Table, TableBody, TableRow, TableCell } from "@mui/material";
import DocumentTableHead from "./DocumentTableHead";
import DocumentTableBody from "./DocumentTableBody";
import TableContainerDark from "src/components/atoms/table/TableContainerDark";
const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);
  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = typeof a[sortConfig.key] === "string" ? a[sortConfig.key].toLowerCase() : a[sortConfig.key];
        const bValue = typeof b[sortConfig.key] === "string" ? b[sortConfig.key].toLowerCase() : b[sortConfig.key];
        if (aValue < bValue) {
          return sortConfig.direction === "asc" ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === "asc" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);
  const requestSort = (key, direction = "asc") => {
    if (sortConfig && sortConfig.key === key && sortConfig.direction === direction) {
      return;
    }
    setSortConfig({ key, direction });
  };
  const requestSortIncrease = (key) => {
    requestSort(key, "asc");
  };
  const requestSortDecrease = (key) => {
    requestSort(key, "desc");
  };
  return { items: sortedItems, requestSortIncrease, requestSortDecrease, sortConfig };
};
const DocumentTable = ({ businessPremisesBody, businessPremisesHeader, selectedItemHandle, allSelect, selectedItem, setSelectedItem, allSelectionHandler }) => {
  const { items: sortedData, requestSortIncrease, requestSortDecrease } = useSortableData(businessPremisesBody);
  //initializes a checked list to table items with default unchecked values, It updates the selectedItem state when sortedData changes, ensuring the list reflects the changes
  useEffect(() => {
    let sorted = sortedData.map((item) => {
      let selected = selectedItem?.find((select) => item?.id === select?.id);
      return { id: item.id, checked: selected ? selected.checked : false };
    });
    if (sortedData.length > 0) {
      setSelectedItem(sorted);
    }
  }, [sortedData]);
  // toggles the checked state of an item in table with the given id in the selectedItem
  const selectionHandler = (id) => {
    if (selectedItem.length > 0) {
      setSelectedItem((prev) => {
        let selected = prev.map((item) => {
          if (item.id === id) {
            return { ...item, checked: !item.checked };
          } else {
            return item;
          }
        });
        return selected;
      });
    }
  };
  // toggles all items in the table between checked and unchecked states in response to changes in the "allSelect" checkbox.
  useEffect(() => {
    if (allSelect) {
      setSelectedItem(
        sortedData.map((item) => {
          return {
            id: item.id,
            checked: true,
          };
        })
      );
    } else {
      setSelectedItem(
        sortedData.map((item) => {
          return {
            id: item.id,
            checked: false,
          };
        })
      );
    }
  }, [allSelect]);
  // Controls the visibility of a download button based on whether more than one item is checked in the table.
  useEffect(() => {
    let selectedFilter = selectedItem?.filter((item) => item.checked);
    if (selectedFilter?.length > 0) {
      selectedItemHandle(true);
    } else {
      selectedItemHandle(false);
    }
  }, [selectedItem, businessPremisesBody]);
  return (
    <TableContainerDark>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <DocumentTableHead
          tableHeader={businessPremisesHeader}
          increaseSort={requestSortIncrease}
          decreaseSort={requestSortDecrease}
          allSelect={allSelect}
          allSelectionHandler={allSelectionHandler}
          allCheck={true}
          bodyContent={sortedData}
        />
        {sortedData.length > 0 && selectedItem ? (
          <DocumentTableBody bodyContent={sortedData} selectionHandler={selectionHandler} selectedItem={selectedItem} />
        ) : (
          <TableBody>
            <TableRow>
              <TableCell colSpan={7} align="center">
                <h5 className="text-red-400">Keine Daten gefunden</h5>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainerDark>
  );
};
DocumentTable.defaultProps = {
  DocumentTableBody: [],
  DocumentTableHead: [],
};
export default DocumentTable;
