/*eslint-disable*/
import { ArrayCheckBox, InputStandardField } from "src/components/atoms/input";
import StandardSelect from "src/components/atoms/select/StandardSelect";
import style from "src/components/pages/settings/Management/management.module.css";
import { RiFileCopyFill } from "react-icons/ri";
import { settingRole } from "src/mockData/settingMock";
import { useFormik } from "formik";
import { addUserManagement } from "src/store/actions/userSettings";
import { useDispatch, useSelector } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { getProfessionalGroups } from "src/store/actions/business";
import { VALIDATIONSCHEMAINVITEDUSER } from "src/services/validation/users";
import toast from "react-hot-toast";
import BackdropButton from "src/components/atoms/button/BackdropButton";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";

const NewUser = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const settings = useSelector((state) => state.settings);
  const [businessRole, setBusinessRole] = useState(null);
  const [selectedPersonOption, setSelectedPersonOption] = useState({});

  const professionalGroups = useSelector((state) => state.management.professionalGroup.data);

  useEffect(() => {
    dispatch(getProfessionalGroups());
  }, []);

  const formik = useFormik({
    initialValues: {
      user_id: Cookies.get("userId"),
      email: "",
      first_name: "",
      last_name: "",
      business:
        businessRole?.length > 0
          ? businessRole.map((value) => {
              return {
                ...value,
                role: "bearbeiter",
                selected: false,
              };
            })
          : [],
    },
    validationSchema: VALIDATIONSCHEMAINVITEDUSER,

    onSubmit: (values) => {
      let business = values.business
        .filter((value) => value.selected === true)
        ?.map((value) => {
          return {
            id: value.id,
            role: value.role,
            name: value.name,
            location_id: value.location_id,
            professional_groups: selectedPersonOption[value.location_id] ? selectedPersonOption[value.location_id].map((group) => group.id) : [],
          };
        });

      dispatch(
        addUserManagement({
          ...values,
          business: business,
          base_url: window.location.origin,
          user_id: Cookies.get("userId"),
          company_name: profile.data.company_name,
        })
      );
    },
  });

  useEffect(() => {
    if (settings?.user?.roleBusinessList?.length > 0) {
      setBusinessRole(
        settings.user.roleBusinessList.map((value) => {
          return {
            ...value,
            role: "bearbeiter",
            selected: false,
          };
        })
      );
      formik.setFieldValue(
        "business",
        settings.user.roleBusinessList.map((value) => {
          return {
            ...value,
            role: "bearbeiter",
            selected: false,
          };
        })
      );
    }
  }, [settings?.user?.roleBusinessList]);

  useEffect(() => {
    if (settings?.newmUser?.status === "success") {
      formik.resetForm();
      setSelectedPersonOption({});
      formik.setFieldValue(
        "business",
        settings.user.roleBusinessList.map((value) => {
          return {
            ...value,
            role: "bearbeiter",
            selected: false,
          };
        })
      );
    }
  }, [settings?.newmUser]);

  const groupHandle = (group, index) => {
    let findOne = selectedPersonOption;
    if (findOne[index]) {
      findOne[index] = group;
      setSelectedPersonOption({ ...findOne });
    } else {
      setSelectedPersonOption({ ...findOne, [index]: group });
    }
  };

  const copyToClipboard = () => {
    let text = settings?.newmUser?.status == "success" ? settings?.newmUser?.data?.clip_url : "app.afpq-da.de/dD6e34Taj2";
    navigator.clipboard.writeText(text).then(
      () => {
        toast.success("kopiert");
      },
      (err) => {
        console.log("Kopieren fehlgeschlagen!");
      }
    );
  };

  return (
    <>
      <div className="bg-AFPBGPrimary mt-12 dark:bg-AFPDarkBgDarkest">
        <h3 className="text-2xl font-tahoma-bold mb-12 flex items-center gap-3">
          {cmsDashboarddata?.settings?.ums_heading?.title}
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "settings",
                  unique: "ums_heading",
                  heading: "Einstellungen",
                })
              );
            }}
          />
        </h3>
        <form onSubmit={formik.handleSubmit}>
          <div className="mx-0 md:mx-2">
            <div className="flex items-center gap-3">
              <InputStandardField
                extra="md:!flex-row !mb-5 w-full"
                extraInput="max-w-[300px]"
                label={cmsDashboarddata?.settings?.ums_email?.email}
                placeholder={cmsDashboarddata?.settings?.ums_email?.email}
                id="email"
                type="text"
                formik={formik}
              />
              <EditIconCMS
                handleOpen={() => {
                  dispatch(
                    dashboardModalOpenCMS({
                      cms_key: "settings",
                      unique: "ums_email",
                      heading: "Einstellungen",
                    })
                  );
                }}
              />
            </div>

            <hr className="mb-4 dark:border-SmokeyGray" />

            <div className="flex items-center gap-3 ">
              <InputStandardField
                extra="md:!flex-row !mb-5 w-full"
                extraInput="max-w-[300px]"
                label={cmsDashboarddata?.settings?.ums_name?.name}
                placeholder={cmsDashboarddata?.settings?.ums_name?.name}
                id="first_name"
                type="text"
                formik={formik}
              />
              <EditIconCMS
                handleOpen={() => {
                  dispatch(
                    dashboardModalOpenCMS({
                      cms_key: "settings",
                      unique: "ums_name",
                      heading: "Einstellungen",
                    })
                  );
                }}
              />
            </div>

            <hr className="mb-4 dark:border-SmokeyGray" />

            <div className="flex items-center gap-3 ">
              <InputStandardField
                extra="md:!flex-row !mb-5 w-full"
                extraInput="max-w-[300px]"
                label={cmsDashboarddata?.settings?.ums_last_name?.name}
                placeholder={cmsDashboarddata?.settings?.ums_last_name?.name}
                id="last_name"
                type="text"
                formik={formik}
              />
              <EditIconCMS
                handleOpen={() => {
                  dispatch(
                    dashboardModalOpenCMS({
                      cms_key: "settings",
                      unique: "ums_last_name",
                      heading: "Einstellungen",
                    })
                  );
                }}
              />
            </div>

            <hr className="dark:border-SmokeyGray"/>

            <div className={`block lg:flex items-start my-4`}>
              <h4 className={`${style.roleHeading} flex items-center gap-3 dark:text-BrightGray`}>
                {cmsDashboarddata?.settings?.ums_label_heading?.title}
                <EditIconCMS
                  handleOpen={() => {
                    dispatch(
                      dashboardModalOpenCMS({
                        cms_key: "settings",
                        unique: "ums_label_heading",
                        heading: "Einstellungen",
                      })
                    );
                  }}
                />
              </h4>
              <div className="w-full">
                {settings.user.roleBusinessListLoading ? (
                  <div className="flex w-full justify-center items-center">
                    <CustomCircularLoader />
                  </div>
                ) : (
                  businessRole?.length > 0 &&
                  formik.values.business?.length > 0 &&
                  businessRole?.map((business, index) => {
                    return (
                      <div className={`flex items-start sm:items-center gap-2 mb-3 flex-col sm:flex-row sm:gap-8`} key={index}>
                        <div className="flex justify-start items-center w-[300px]">
                          <ArrayCheckBox
                            css="flex items-center h-5"
                            selection="selected"
                            index={index}
                            set="business"
                            label={business?.name + ", " + business?.city}
                            formik={formik}
                          />
                        </div>
                        <StandardSelect
                          extra="w-[220px]"
                          extraLabel="hidden"
                          placeholder="Herr/Frau"
                          id={`business[${index}]role`}
                          options={settingRole}
                          choose={false}
                          formik={formik}
                        />
                      </div>
                    );
                  })
                )}
              </div>
            </div>
            <hr className="mb-12 dark:border-SmokeyGray" />
            <div className="flex gap-10 mb-8 flex-col lg:flex-row">
              <div className="flex gap-3 items-center">
                <div className="w-60 flex">
                  <BackdropButton title={cmsDashboarddata?.settings?.ums_invite_button?.title} type="submit" loading={settings?.newmUser?.isLoading} />
                </div>
                <EditIconCMS
                  handleOpen={() => {
                    dispatch(
                      dashboardModalOpenCMS({
                        cms_key: "settings",
                        unique: "ums_invite_button",
                        heading: "Einstellungen",
                      })
                    );
                  }}
                />
              </div>

              <div className="w-60">
                <div className="flex justify-between items-center gap-x-4">
                  <input
                    disabled
                    type={"text"}
                    id={"app"}
                    value={settings?.newmUser?.status == "success" ? settings?.newmUser?.data?.clip_url : "app.afpq-da.de/dD6e34Taj2"}
                    name={"app"}
                    placeholder={"app name"}
                    className={`flex h-10 w-full items-center justify-center rounded-xl border border-gray-400 p-3 text-sm outline-none bg-BrightGray dark:bg-AFPDarkBgLight dark:text-BrightGray`}
                  />
                </div>
              </div>
              <div className="flex items-center gap-1">
                <div
                  className="w-9 h-9 rounded-full border border-DarkCharcoal flex justify-center items-center cursor-pointer dark:text-BrightGray dark:border-BrightGray"
                  onClick={() => {
                    copyToClipboard();
                  }}
                >
                  <RiFileCopyFill className="h-6 w-6 text-DarkCharcoal dark:text-BrightGray" />
                </div>
                <h3 className="text-DarkCharcoal flex items-center gap-3 dark:text-BrightGray">
                  {cmsDashboarddata?.settings?.ums_link_button?.title}
                  <EditIconCMS
                    handleOpen={() => {
                      dispatch(
                        dashboardModalOpenCMS({
                          cms_key: "settings",
                          unique: "ums_link_button",
                          heading: "Einstellungen",
                        })
                      );
                    }}
                  />{" "}
                </h3>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default NewUser;
