import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import CircularLoading from "src/components/atoms/loader/CircularLoading";
import { currentRegisterOptions } from "src/helpers/useranalytics";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import { userLoginSessionStarted } from "src/store/actions/userAnalytics";
import Chart from "react-apexcharts";

const UserAnalyticsSessionChart = () => {
  const [loginSession, setLoginSession] = useState({
    labels: null,
    series: null,
    type: "",
  });
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const userAnalytics = useSelector((state) => state.userAnalytics);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(userLoginSessionStarted());
  }, []);

  useEffect(() => {
    if (userAnalytics?.login_session?.data) {
      let labels = ["Aktive Benutzer", "Benutzer insgesamt"];
      let series = [userAnalytics?.login_session?.data?.currentLogins, userAnalytics?.login_session?.data?.totalUser];
      setLoginSession({ labels, series });
    }
  }, [userAnalytics?.login_session]);

  return (
    <>
      <h1 className="text-xl my-6 mb-20 flex items-center gap-2">
        {cmsDashboarddata?.user_analytics?.login_session_duration?.heading}
        <EditIconCMS
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "user_analytics",
                unique: "login_session_duration",
                heading: "Benutzer Analyse",
              })
            );
          }}
        />
      </h1>
      {userAnalytics?.login_session?.isLoading ? (
        <div className="min-h-96 flex justify-center items-center">
          <CircularLoading css="py-6" />
        </div>
      ) : (
        loginSession?.labels &&
        loginSession?.series && (
          <div className="w-full flex justify-center items-center">
            <div id="chartcurrentregister" className="mt-4">
              <Chart options={currentRegisterOptions(loginSession)} series={loginSession?.series} type="pie" width={380} />
            </div>
          </div>
        )
      )}
    </>
  );
};

export default UserAnalyticsSessionChart;
