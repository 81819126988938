import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContentLayout from "src/components/atoms/common/ContentLayout";
import Breadcrumbs from "src/components/molecules/Breadcrumbs";
import { evidenceCMSbreadcrumbs } from "src/components/molecules/Breadcrumbs/BreadcrumbsList";
import EvidenceCMSTabs from "src/components/organisms/EvidenceCMSTabs";

const EvidenceCMS = () => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const navigate = useNavigate();
  useEffect(() => {
    if (!window.location.pathname.includes("admin")) {
      navigate("/");
    }
  }, []);
  return (
    <>
      <ContentLayout>
        <div className="text-black relative md:pt-[4.5rem] pb-0 py-8 min-h-screen bg-AFPBGPrimary dark:bg-AFPDarkBgDarkest">
          {cmsDashboarddata?.sidebar_menu?.sidebar_list?.evidence_form_cms && (
            <Breadcrumbs data={evidenceCMSbreadcrumbs(cmsDashboarddata?.sidebar_menu?.sidebar_list?.evidence_form_cms)} />
          )}
          <div className="bg-AFPBGPrimary mx-3 sm:mx-4 md:mx-6 mt-0 p-0 dark:bg-AFPDarkBgDarkest">
            <EvidenceCMSTabs />
          </div>
        </div>
      </ContentLayout>
    </>
  );
};

export default EvidenceCMS;
