/* eslint-disable*/
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchProfileRequest } from "src/store/actions/settings";
import { cmsContentList } from "src/store/actions/cmsDashboard";
import Logo from "src/assets/img/newLogo.png";
import LogoDark from "src/assets/img/darkLogo.png";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const DHeader = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const navigate = useNavigate();
  const formName = localStorage.getItem("form_id_name");
  const dformId = localStorage.getItem("dformId");

  useEffect(() => {
    if (!profile.loading) {
      dispatch(fetchProfileRequest());
      dispatch(cmsContentList());
    }
  }, []);

  const closeHandle = () => {
    let url = "/verfahren/uebersicht";
    if (profile?.data?.role === "admin") {
      navigate("/admin" + url);
    } else {
      navigate(url);
    }
  };

  const displayFormName = () => {
    if (dformId && formName) {
      const parseName = JSON.parse(formName);
      const findId = parseName.find((data) => data.id == dformId);
      if (findId) {
        return "Neuer " + findId?.description;
      } else {
        return "Neuer Präqualifizierungsantrag";
      }
    } else {
      return "Neuer Präqualifizierungsantrag";
    }
  };

  return (
    <nav className="fixed top-0 left-0 w-full z-40 bg-white md:flex-row md:flex-nowrap md:justify-start flex items-center p-4 bg-PoliceBlue shadow-md dark:bg-AFPDarkBgLight">
      <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
        <div className="text-white text-lg uppercase inline-block font-semibold">
          <div className="w-full mb-2 mx-auto">
            <img src={Logo} alt="logo" className="max-w-[180px] sm:w-full dark:hidden" />
            <img src={LogoDark} alt="logo" className="max-w-[180px] sm:w-full hidden dark:block" />
          </div>
        </div>
        <ul className="flex-col md:flex-row list-none items-center md:flex">
          <div className="relative flex flex-row gap-4 items-center">
            <p className="font-tahoma-bold text-xl text-AFPHeading dark:text-BrightGray">{displayFormName()}</p>
          </div>
        </ul>
      </div>
      <CloseIcon className="hover:cursor-pointer hover:text-red-500 mr-6 ml-2 dark:text-BrightGray" onClick={closeHandle} />
    </nav>
  );
};

export default DHeader;
