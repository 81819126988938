import { useEffect } from "react";
import { getEvidenceDBBySOidStarted, uploadServiceOrderEvidenceReset } from "src/store/actions/evidence";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import EvidenceSuccess from "src/components/organisms/Evidence/EvidenceSuccess";
import ContentLayout from "src/components/atoms/common/ContentLayout";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";

const EvidenceWelcome = () => {
  const { id } = useParams();
  const evidence = useSelector((state) => state.evidence);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEvidenceDBBySOidStarted({ service_order_number: id }));
    dispatch(uploadServiceOrderEvidenceReset());
  }, []);

  return (
    <ContentLayout>
      <div className="text-black relative md:pt-[4.5rem] pb-0 py-8 min-h-screen-minus-3 bg-AFPBGPrimary dark:bg-AFPDarkBgDarkest dark:text-white">
        {evidence?.evidenceDetail?.isLoading ? (
          <div className="h-[80vh] flex items-center justify-center">
            <CustomCircularLoader />
          </div>
        ) : (
          <EvidenceSuccess />
        )}
      </div>
    </ContentLayout>
  );
};

export default EvidenceWelcome;
