import React, { useState } from "react";
import Chart from "react-apexcharts";
import { TableBody, TableCell, TableRow } from "@mui/material";
import { useSelector } from "react-redux";
import { globalChartOptions, timeConvertionSessionInHours } from "src/helpers/useranalytics";
import UserAnalyticsTable from "src/components/molecules/table/UserAnalyticsTable";
import ToggleView from "src/components/atoms/select/ToggleView";

const customOption = {
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return val.toFixed(2);
    },
    style: {
      fontSize: "12px",
      colors: ["#fff"],
    },
  },
  tooltipID: "LoginSession",
  extra: {
    yaxis: {
      labels: {
        formatter: function (val) {
          return Math.floor(val);
        },
      },
    },
    colors: ["#82CA9D"],
  },
};

const AnalyticsAverageBarChart = ({ data }) => {
  const [view, setView] = useState("chart");
  const handleView = (value) => {
    view !== "value" && setView(value);
  };
  return (
    <>
      <ToggleView view={view} handleView={handleView} />
      {view == "chart" && (
        <Chart
          options={globalChartOptions(data, customOption)}
          series={[
            {
              name: "Durchschnittliche Dauer (Stunden)",
              data: data?.count || [],
            },
          ]}
          type="bar"
          height={350}
        />
      )}
      {view == "list" && <AnalyticsAverageList data={data} />}
    </>
  );
};

const AnalyticsAverageList = ({ data }) => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const heads = [cmsDashboarddata?.user_analytics?.login_session_average_head?.title, cmsDashboarddata?.user_analytics?.login_session_average_head?.status];
  return (
    <div className="mt-4">
      <UserAnalyticsTable heads={heads} unique={"login_session_average_head"}>
        <TableBody>
          {data?.category?.map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{item}</TableCell>
                <TableCell align={"right"}>{timeConvertionSessionInHours(data?.count[index])}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </UserAnalyticsTable>
    </div>
  );
};

export default AnalyticsAverageBarChart;
