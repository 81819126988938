import Cookies from "js-cookie";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "src/components/atoms/button";
import { applicationProcessStatusStarted } from "src/store/actions/application";
import ApplicationSuccess from "./ApplicationSuccess";

const EvidenceUploadConfirm = ({ procedure_id }) => {
  const profile = useSelector((state) => state.profile);
  const application = useSelector((state) => state.application);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const stepsCMS = cmsDashboarddata?.dFormCMS?.data;
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const navigateHandler = (url) => {
    if (profile?.data?.role === "admin") {
      navigate("/admin" + url);
    } else {
      navigate(url);
    }
  };

  const pdfHandle = () => {
    const link = window.document.createElement("a");
    link.href = application?.dformStatus?.data?.data?.generate_pdf;
    link.download = "document";
    link.style.display = "none";
    window.document.body.appendChild(link);
    window.open(link.href, "_blank");
    setTimeout(() => {
      window.document.body.removeChild(link);
    }, 100);
  };

  const evidenceHandle = () => {
    if(application?.dformStatus?.data?.data?.erp_service_order_id){
      navigateHandler("/nachweise-hochladen/" + id + "/" + application?.dformStatus?.data?.data?.erp_service_order_id);
    } else {
      toast.error("Etwas ist schief gelaufen!")
    }
  };

  /** get the current dynamic application status - get-procedure-details */

  useEffect(() => {
    dispatch(applicationProcessStatusStarted({ procedure_id: procedure_id ? procedure_id : Cookies.get("submitted_id") }));
  }, []);

  return (
    <>
      <div className="relative md:pt-0 pb-10 py-8">
        <div className="mx-3 sm:mx-4 md:mx-6 mt-0 p-0">
          <h2 className="!mb-5 text-2xl font-tahoma-bold text-center">{stepsCMS?.NACHWEISE_HEAD?.title}</h2>
          <p className="textStyle  mb-5 text-center">{stepsCMS?.NACHWEISE_HEAD?.subtitle}</p>
          <ApplicationSuccess stepsCMS={stepsCMS} />
          <div className="flex w-full gap-4">
            <div className="w-[70%] bg-red-500 flex gap-4"></div>
          </div>
          <div className="flex flex-col md:flex-row items-center gap-4 justify-center mt-32 mb-12 w-full">
            {(id !== "KA") && <PrimaryButton title={cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.upload_evidence_now} btnStyle="w-full md:max-w-[290px]" handler={evidenceHandle} />}
            <SecondaryButton variant={"secondary"} title={cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.download_pdf} css={"w-full md:w-[290px] md:ml-9 mt-4 md:mt-0"} handler={pdfHandle} />
          </div>
          <div className="flex justify-center items-center">
            <SecondaryButton
              variant={"secondary"}
              title={cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.back_to_the_portal}
              css={"w-full md:w-[290px] md:ml-9 mt-4 md:mt-0"}
              handler={() => {
                navigate("/verfahren/uebersicht");
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EvidenceUploadConfirm;
