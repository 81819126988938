import { TableCell, TableRow } from "@mui/material";

const AreaTableReadable = ({ data,  index, css, datadisable, datakey,}) => {
  return (
    <>
      <TableRow key={index} className={css} data-key={datakey} data-disable={datadisable}>
        <TableCell component="th" scope="row">
          <span className="!text-black dark:!text-BrightGray">{data?.description}</span>
        </TableCell>
        <TableCell align="left" className="!text-left textStyle">
          <ul className="list-disc pl-4">
            {data?.includedAid?.split(",")?.map((tool, toolIndex) => (
              <li className="!text-SmokeyGray" key={toolIndex}>
                {tool}
              </li>
            ))}
          </ul>
        </TableCell>
        <TableCell align="left" className="!text-left textStyle">
          <ul className="list-disc pl-4">
            {data?.specialCriteria &&
              data?.specialCriteria?.map((criteria, criteriaIndex) => (
                <li className="!text-SmokeyGray w-[250px]" key={criteriaIndex}>
                  {criteria}
                </li>
              ))}
          </ul>
        </TableCell>

        <TableCell>
          <div className="flex flex-col items-start gap-5 h-full">
            {data?.selectedTechnicalLeaders.map((leader) => {
              return <div className="">{leader.name}</div>;
            })}
          </div>
        </TableCell>
      </TableRow>
    </>
  );
};

export default AreaTableReadable;
