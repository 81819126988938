import Cookies from "js-cookie";
import {
  getAbandonedCartAPI,
  getActivityStatistikAPI,
  getEvidenceAnalyticsAPI,
  getLoginAverageSessionAPI,
  getLoginDurationAPI,
  getLoginSessionAPI,
  getLoginTimeAPI,
  getSourceDeviceAPI,
  postActivityStatistikAPI,
} from "../requests/userAnalytics";
import {
  userAbandonedCartFailure,
  userAbandonedCartSuccess,
  userActivityOnFailure,
  userActivityOnSuccess,
  userActivityStatistikFailure,
  userActivityStatistikSuccess,
  userAnalyticsEvidenceFailure,
  userAnalyticsEvidenceSuccess,
  userDeviceSourceFailure,
  userDeviceSourceSuccess,
  userLoginSessionFailure,
  userLoginSessionSuccess,
  userLoginTimeDurationFailure,
  userLoginTimeDurationSuccess,
  userLoginTimesDurationFailure,
  userLoginTimesDurationSuccess,
  userLoginTimesFailure,
  userLoginTimesSuccess,
} from "src/store/actions/userAnalytics";
import { put } from "redux-saga/effects";

export function* getLoginTimes(action) {
  try {
    const paramsData = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield getLoginTimeAPI(paramsData);
    if (response.status) {
      yield put(userLoginTimesSuccess(response.data));
    } else {
      yield put(
        userLoginTimesFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(userLoginTimesFailure(error));
  }
}

export function* getLoginSession(action) {
  try {
    const paramsData = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield getLoginSessionAPI(paramsData);
    if (response.status) {
      yield put(userLoginSessionSuccess(response.data));
    } else {
      yield put(
        userLoginSessionFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(userLoginSessionFailure(error));
  }
}

export function* getLoginAverageSession(action) {
  try {
    const paramsData = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield getLoginAverageSessionAPI(paramsData);
    if (response.status) {
      yield put(userLoginTimeDurationSuccess(response.data));
    } else {
      yield put(
        userLoginTimeDurationFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(userLoginTimeDurationFailure(error));
  }
}

export function* getAbandonedCart(action) {
  try {
    const paramsData = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield getAbandonedCartAPI(paramsData);
    if (response.status) {
      yield put(userAbandonedCartSuccess(response.data));
    } else {
      yield put(
        userAbandonedCartFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(userAbandonedCartFailure(error));
  }
}

export function* getLoginDuration(action) {
  try {
    const paramsData = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield getLoginDurationAPI(paramsData);
    if (response.status) {
      yield put(userLoginTimesDurationSuccess(response.data));
    } else {
      yield put(
        userLoginTimesDurationFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(userLoginTimesDurationFailure(error));
  }
}

export function* getSourceDevice(action) {
  try {
    const paramsData = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield getSourceDeviceAPI(paramsData);
    if (response.status) {
      yield put(userDeviceSourceSuccess(response.data));
    } else {
      yield put(
        userDeviceSourceFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(userDeviceSourceFailure(error));
  }
}

export function* getEvidenceAnalytics(action) {
  try {
    const paramsData = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield getEvidenceAnalyticsAPI(paramsData);
    if (response.status) {
      yield put(userAnalyticsEvidenceSuccess(response.data));
    } else {
      yield put(
        userAnalyticsEvidenceFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(userAnalyticsEvidenceFailure(error));
  }
}

export function* getActivityStatistik(action) {
  try {
    const paramsData = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield getActivityStatistikAPI(paramsData);
    if (response.status) {
      yield put(userActivityStatistikSuccess(response.data));
    } else {
      yield put(
        userActivityStatistikFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(userActivityStatistikFailure(error));
  }
}

export function* postActivityStatistik(action) {
  try {
    const newPayload = {
      ...action.payload,
      user_id: Cookies.get("userId"),
    };
    const response = yield postActivityStatistikAPI(newPayload);
    if (response.status) {
      yield put(userActivityOnSuccess(response?.data));
    } else {
      yield put(
        userActivityOnFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(userActivityOnFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}
