import React from "react";
import { SecondaryButton } from "src/components/atoms/button";
import { InputStandardField } from "src/components/atoms/input";
import AutoCompleteField from "src/components/atoms/input/AutoCompleteField";
import StandardSelect from "src/components/atoms/select/StandardSelect";
import { salutationOptions } from "src/mockData/register";
import { useSelector } from "react-redux";
import BackdropButton from "src/components/atoms/button/BackdropButton";
const NewUserForm = ({ formik, handleClose }) => {
  const business = useSelector((state) => state.management);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const cmsData = cmsDashboarddata?.business_premises?.new_professional_group_label;

  const isSalutationSelected = formik.values.salutation && formik.values.salutation !== "";

  return (
    <form className="flex flex-col gap-4 mb-2" onSubmit={formik.handleSubmit}>
      <StandardSelect
        extra="!flex-col !items-start"
        extraLabel="w-full md:w-[200px] lg:w-[260px] xl:w-[300px] 2xl:w-[340px]"
        label={cmsData?.salutation}
        choose={!isSalutationSelected}
        placeholder="Herr/Frau"
        id="salutation"
        options={salutationOptions}
        formik={formik}
      />
      <InputStandardField extra="!items-start" label={cmsData?.title} placeholder="Title" id="title" formik={formik} />
      <InputStandardField id="first_name" formik={formik} extra="!items-start" placeholder={cmsData?.first_name} label={cmsData?.first_name} />
      <InputStandardField id="last_name" formik={formik} extra="!items-start" placeholder={cmsData?.last_name} label={cmsData?.last_name} />

      <InputStandardField id="email" formik={formik} extra="!items-start" placeholder={cmsData?.email} label={cmsData?.email} />
      <div>
        <h3 className="text-sm font-tahoma-bold text-black ml-1.5 mb-1 font-medium w-full dark:text-BrightGray">{cmsData?.group}</h3>
        <div className="rounded-[12px] border border-AFPLightGray flex justify-between px-0 pr-2 dark:bg-AFPDarkBgLight">
          <AutoCompleteField
            LegalPersonList={business?.professionalGroup?.data}
            selectedPersonOption={formik.values.selectedPersonOption}
            setSelectedPersonOption={(value) => formik.setFieldValue("selectedPersonOption", value)}
          />
        </div>
        {formik.touched.selectedPersonOption && formik.errors.selectedPersonOption ? <div className="text-red-500 text-sm mt-1">{formik.errors.selectedPersonOption}</div> : null}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mt-6">
        <BackdropButton title="Speichern" type="submit" loading={business?.leaderNew?.isLoading} borderRadius="8px" />
        <SecondaryButton variant="secondary" title="Abbrechen" handler={handleClose} />
      </div>
    </form>
  );
};

export default NewUserForm;
