import "./style.css";
import React, { useEffect, useRef, useState } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { addCurrentStepDetailValue } from "src/store/actions/currentStep";

const SearchDropDown2 = ({ id, selectedVal, handleChange, optionsList, data }) => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars

  const currentStepParam = searchParams.get("active");
  const inputRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  const selectOption = (option) => {
    setQuery(() => "");
    handleChange(option["value"]);
    setIsOpen((isOpen) => !isOpen);
  };

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current);
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (selectedVal) {
      let a = optionsList?.find((option) => option?.value === selectedVal)?.title;
      return a ? a : "";
    }

    return "";
  };

  const filter = (options) => {
    return options.filter((option) => option["title"].toLowerCase().indexOf(query.toLowerCase()) > -1);
  };

  const handleChangeDropdown = (option) => {
    selectOption(option);
    dispatch(
      addCurrentStepDetailValue({
        valueId: data.id,
        value: option.value,
        valueKey: "inputTypeValue",
      })
    );
  };
  let locationDetailForm = JSON.parse(localStorage.getItem("locationDetailForm"));
  return (
    <div className={`searchDropdown relative text-black w-full mt-1 dark:text-BrightGray ${locationDetailForm && currentStepParam == 1 ? "disabled opacity-70" : ""}`}>
      <div className="control">
        <div className="selected-value">
          <input
            ref={inputRef}
            type="text"
            placeholder="Auswählen"
            value={getDisplayValue()}
            name="searchTerm"
            onChange={(e) => {
              setQuery(e.target.value);
              handleChange(null);
            }}
            onClick={toggle}
            disabled={locationDetailForm && currentStepParam == 1 ? true : false}
            className="dark:text-BrightGray dark:!bg-AFPDarkBgLight dark:!border-SmokeyGray"
          />
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`}>{isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}</div>
      </div>

      <div className={`options dark:!bg-AFPDarkBgLight dark:!border-SmokeyGray ${isOpen ? "open" : ""}`}>
        {filter(optionsList).map((option, index) => {
          return (
            <div
              onClick={() => {
                handleChangeDropdown(option);
              }}
              className={`option dark:!text-BrightGray dark:hover:!bg-AFPDarkBgDarkest ${option["value"] === selectedVal ? "selected dark:!bg-AFPDarkBgDarkest" : ""}`}
              key={`${id}-${index}`}
            >
              {option["title"]}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchDropDown2;
