import {
  ADD_EVIDENCE,
  CLEAR_EVIDENCES,
  DELETE_SINGLE_EVIDENCE_FAILURE,
  DELETE_SINGLE_EVIDENCE_STARTED,
  DELETE_SINGLE_EVIDENCE_SUCCESS,
  EVIDENCE_STATIC_DOCUMENTS_FAILURE,
  EVIDENCE_STATIC_DOCUMENTS_STARTED,
  EVIDENCE_STATIC_DOCUMENTS_SUCCESS,
  GET_EVIDENCE_DB_SO_ID_STARTED,
  NEW_SERVICE_ORDER_EVIDENCE_PENDING_STARTED,
  REMOVE_EVIDENCE,
  UPLOAD_SERVER_ORDER_EVIDENCE_FAILURE,
  UPLOAD_SERVER_ORDER_EVIDENCE_STARTED,
  UPLOAD_SERVER_ORDER_EVIDENCE_SUCCESS,
} from "../actionTypes";

const {
  NEW_SERVICE_ORDER_EVIDENCE_FAILURE,
  NEW_SERVICE_ORDER_EVIDENCE_SUCCESS,
  NEW_SERVICE_ORDER_EVIDENCE_STARTED,
  ASIGNATURE_UPLOAD_FAILURE,
  ASIGNATURE_UPLOAD_SUCCESS,
  SIGNATURE_UPLOAD_STARTED,
  SET_EVIDENCE_BY_ID,
  UPLOAD_EVIDENCE_FAILURE,
  UPLOAD_EVIDENCE_SUCCESS,
  UPLOAD_EVIDENCE_STARTED,
  SAVE_EVIDENCE_DB_STARTED,
  SAVE_EVIDENCE_DB_SUCCESS,
  SAVE_EVIDENCE_DB_FAILURE,
  GET_EVIDENCE_DB_STARTED,
  GET_EVIDENCE_DB_SUCCESS,
  GET_EVIDENCE_DB_FAILURE,
  UPLOAD_SERVICE_ORDER_EVIDENCE_STARTED,
  UPLOAD_SERVICE_ORDER_EVIDENCE_SUCCESS,
  UPLOAD_SERVICE_ORDER_EVIDENCE_FAILURE,
  SAVE_EVIDENCE_ERP_STARTED,
  SAVE_EVIDENCE_ERP_SUCCESS,
  SAVE_EVIDENCE_ERP_FAILURE,
  UPLOAD_SERVICE_ORDER_EVIDENCE_RESET,
} = require("../actionTypes");

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  evidenceDetail: {
    data: {},
    details: null,
    isLoading: false,
    error: null,
  },
  signatureUpload: {
    data: null,
    isLoading: false,
    error: null,
  },
  uploadEvidence: {
    data: null,
    isLoading: false,
    error: null,
  },
  uploadEvidenceERP: {
    data: null,
    isLoading: false,
    error: null,
    file_id: "",
    welcome: "pending",
  },
  saveEvidenceDB: {
    data: null,
    isLoading: false,
    error: null,
    actionType: "",
  },
  saveEvidenceERP: {
    data: null,
    isLoading: false,
    error: null,
  },
  deleteEvidenceERP: {
    data: null,
    isLoading: false,
    error: null,
  },
  evidences: {},
  evidenceStaticDocument: {
    data: null,
    isLoading: false,
    error: null,
    status:'idle'
  },
};

const reducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case NEW_SERVICE_ORDER_EVIDENCE_STARTED: {
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    }
    case NEW_SERVICE_ORDER_EVIDENCE_PENDING_STARTED: {
      return {
        ...state,
        isLoading: true,
        data: null,
        error: null,
      };
    }
    case NEW_SERVICE_ORDER_EVIDENCE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: payload,
        error: null,
      };
    }
    case NEW_SERVICE_ORDER_EVIDENCE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        data: null,
        error: null,
      };
    }

    /**  user who got the email for signature  */
    case SIGNATURE_UPLOAD_STARTED: {
      return {
        ...state,
        signatureUpload: {
          ...state.signatureUpload,
          data: null,
          isLoading: true,
          error: null,
        },
      };
    }
    case ASIGNATURE_UPLOAD_SUCCESS: {
      return {
        ...state,
        signatureUpload: {
          ...state.signatureUpload,
          data: payload,
          isLoading: false,
          error: null,
        },
      };
    }
    case ASIGNATURE_UPLOAD_FAILURE: {
      return {
        ...state,
        signatureUpload: {
          ...state.signatureUpload,
          data: null,
          isLoading: false,
          error: null,
        },
      };
    }

    /**  upload files(image, pdf, doc)  */
    case UPLOAD_EVIDENCE_STARTED: {
      return {
        ...state,
        uploadEvidence: {
          ...state.uploadEvidence,
          data: null,
          isLoading: true,
          error: null,
          file_id: payload?.evidenceId,
        },
      };
    }
    case UPLOAD_EVIDENCE_SUCCESS: {
      return {
        ...state,
        uploadEvidence: {
          ...state.uploadEvidence,
          data: payload,
          isLoading: false,
          error: null,
          file_id: "",
        },
      };
    }
    case UPLOAD_EVIDENCE_FAILURE: {
      return {
        ...state,
        uploadEvidence: {
          ...state.uploadEvidence,
          data: null,
          isLoading: false,
          error: null,
          file_id: "",
        },
      };
    }

    /**  upload files(image, pdf, doc) to erp */
    case UPLOAD_SERVICE_ORDER_EVIDENCE_STARTED: {
      return {
        ...state,
        uploadEvidenceERP: {
          ...state.uploadEvidenceERP,
          data: null,
          isLoading: true,
          error: null,
          welcome: "pending",
          evidenceType: payload.evidenceType,
        },
      };
    }
    case UPLOAD_SERVICE_ORDER_EVIDENCE_SUCCESS: {
      return {
        ...state,
        uploadEvidenceERP: {
          ...state.uploadEvidenceERP,
          data: payload,
          isLoading: false,
          error: null,
          // welcome: "pending"
          welcome: payload.proceed,
          evidenceType: "",
        },
      };
    }
    case UPLOAD_SERVICE_ORDER_EVIDENCE_FAILURE: {
      return {
        ...state,
        uploadEvidenceERP: {
          ...state.uploadEvidenceERP,
          data: null,
          isLoading: false,
          error: null,
          welcome: "pending",
          evidenceType: "",
        },
      };
    }
    case UPLOAD_SERVICE_ORDER_EVIDENCE_RESET: {
      return {
        ...state,
        uploadEvidenceERP: {
          ...state.uploadEvidenceERP,
          data: null,
          isLoading: false,
          error: null,
          welcome: "pending",
        },
      };
    }

    /**  UPLOAD SERVER ORDER EVIDENCE to erp */
    case UPLOAD_SERVER_ORDER_EVIDENCE_STARTED: {
      return {
        ...state,
        uploadEvidenceERP: {
          ...state.uploadEvidenceERP,
          data: null,
          isLoading: true,
          error: null,
          welcome: "pending",
          evidenceType: payload.evidenceType,
          file_id: payload.evidence_id,
        },
      };
    }
    case UPLOAD_SERVER_ORDER_EVIDENCE_SUCCESS: {
      return {
        ...state,
        uploadEvidenceERP: {
          ...state.uploadEvidenceERP,
          data: payload,
          isLoading: false,
          error: null,
          // welcome: "pending"
          welcome: payload.proceed,
          evidenceType: "",
        },
      };
    }
    case UPLOAD_SERVER_ORDER_EVIDENCE_FAILURE: {
      return {
        ...state,
        uploadEvidenceERP: {
          ...state.uploadEvidenceERP,
          data: null,
          isLoading: false,
          error: null,
          welcome: "pending",
          evidenceType: "",
        },
      };
    }

    /**  save Evidence to db  */
    case SAVE_EVIDENCE_DB_STARTED: {
      return {
        ...state,
        saveEvidenceDB: {
          ...state.saveEvidenceDB,
          data: null,
          isLoading: true,
          error: null,
          actionType: payload.actionType,
        },
      };
    }
    case SAVE_EVIDENCE_DB_SUCCESS: {
      return {
        ...state,
        saveEvidenceDB: {
          ...state.saveEvidenceDB,
          data: payload,
          isLoading: false,
          error: null,
        },
      };
    }
    case SAVE_EVIDENCE_DB_FAILURE: {
      return {
        ...state,
        saveEvidenceDB: {
          ...state.saveEvidenceDB,
          data: null,
          isLoading: false,
          error: null,
        },
      };
    }
    /**  save Evidence to erp  */
    case SAVE_EVIDENCE_ERP_STARTED: {
      return {
        ...state,
        saveEvidenceERP: {
          ...state.saveEvidenceERP,
          data: null,
          isLoading: true,
          error: null,
        },
        uploadEvidenceERP: {
          ...state.uploadEvidenceERP,
          data: null,
          isLoading: true,
          error: null,
          welcome: "pending",
          evidenceType: "pdf",
        },
      };
    }
    case SAVE_EVIDENCE_ERP_SUCCESS: {
      return {
        ...state,
        saveEvidenceERP: {
          ...state.saveEvidenceERP,
          data: payload.data,
          isLoading: false,
          error: null,
          welcome: payload.proceed,
        },
        uploadEvidenceERP: {
          ...state.uploadEvidenceERP,
          data: payload.data,
          isLoading: false,
          error: null,
          // welcome: "pending"
          welcome: payload.proceed,
          evidenceType: "",
        },
      };
    }
    case SAVE_EVIDENCE_ERP_FAILURE: {
      return {
        ...state,
        saveEvidenceERP: {
          ...state.saveEvidenceERP,
          data: null,
          isLoading: false,
          error: null,
        },
        uploadEvidenceERP: {
          ...state.uploadEvidenceERP,
          data: null,
          isLoading: false,
          error: null,
          welcome: "pending",
          evidenceType: "",
        },
      };
    }

    /**  get Evidence to db  */
    case GET_EVIDENCE_DB_STARTED: {
      return {
        ...state,
        evidenceDetail: {
          ...state.evidenceDetail,
          data: {},
          isLoading: true,
          details: null,
          error: null,
        },
      };
    }
    /**  get Evidence to db by service order id */
    case GET_EVIDENCE_DB_SO_ID_STARTED: {
      return {
        ...state,
        evidenceDetail: {
          ...state.evidenceDetail,
          data: {},
          isLoading: true,
          details: null,
          error: null,
        },
      };
    }
    case GET_EVIDENCE_DB_SUCCESS: {
      return {
        ...state,
        evidenceDetail: {
          ...state.evidenceDetail,
          data: payload.evidence_data,
          details: payload,
          isLoading: false,
          error: null,
        },
      };
    }
    case GET_EVIDENCE_DB_FAILURE: {
      return {
        ...state,
        evidenceDetail: {
          ...state.evidenceDetail,
          data: {},
          details: null,
          isLoading: false,
          error: null,
        },
      };
    }

    /** Evidence Detail save by id */

    case SET_EVIDENCE_BY_ID: {
      if (payload?.type == "file") {
        if (state?.evidenceDetail?.data[payload?.key]) {
          let newArray = state?.evidenceDetail?.data[payload?.key];
          newArray.push(payload.value);
          return {
            ...state,
            evidenceDetail: {
              ...state.evidenceDetail,
              data: { ...state.evidenceDetail.data, [payload.key]: newArray },
              isLoading: false,
              error: null,
            },
          };
        } else {
          return {
            ...state,
            evidenceDetail: {
              ...state.evidenceDetail,
              data: { ...state.evidenceDetail.data, [payload.key]: [payload.value] },
              isLoading: false,
              error: null,
            },
          };
        }
      } else if (payload?.type == "textarea") {
        return {
          ...state,
          evidenceDetail: {
            ...state.evidenceDetail,
            data: { ...state.evidenceDetail.data, [payload.key]: payload?.value },
            isLoading: false,
            error: null,
          },
        };
      } else if (payload?.type == "checkbox") {
        return {
          ...state,
          evidenceDetail: {
            ...state.evidenceDetail,
            data: { ...state.evidenceDetail.data, [payload.key]: payload?.value },
            isLoading: false,
            error: null,
          },
        };
      } else {
        return {
          ...state,
          evidenceDetail: {
            ...state.evidenceDetail,
            data: { ...state.evidenceDetail.data, [payload.key]: [payload?.value] },
            isLoading: false,
            error: null,
          },
        };
      }
    }

    case ADD_EVIDENCE: {
      return {
        ...state,
        evidences: {
          ...state.evidences,
          [payload.key]: [...(state.evidences[payload.key] ?? []), payload.evidence],
        },
      };
    }

    case REMOVE_EVIDENCE: {
      return {
        ...state,
        evidences: {
          ...state.evidences,
          [payload.key]: [...(state.evidences[payload.key] ?? [])].filter((e) => e.evidenceId !== payload.evidenceId),
        },
      };
    }

    case CLEAR_EVIDENCES: {
      return {
        ...state,
        evidences: {
          ...state.evidences,
          [payload]: [],
        },
      };
    }

    case DELETE_SINGLE_EVIDENCE_STARTED: {
      return {
        ...state,
        deleteEvidenceERP: {
          ...state.deleteEvidenceERP,
          isLoading: true,
          error: null,
          data: "",
        },
      };
    }
    case DELETE_SINGLE_EVIDENCE_SUCCESS: {
      // let filterList = state.user.managementList.filter((user) => user.id != action.payload.id);
      // let filterList =state.evidenceDetail?.data[evedenceData?.inventoryID?.value];

      if (state?.evidenceDetail?.data[payload?.data?.evidence_id]) {
        const newArray = state?.evidenceDetail?.data[payload?.data?.evidence_id];
        const Evidencefilter = newArray.filter((evidence) => evidence?.file_name !== payload?.data?.file_name);
        return {
          ...state,
          evidenceDetail: {
            ...state.evidenceDetail,
            data: { ...state.evidenceDetail.data, [payload?.data?.evidence_id]: Evidencefilter },
            isLoading: false,
            error: null,
          },
          deleteEvidenceERP: {
            ...state.deleteEvidenceERP,
            isLoading: false,
            error: null,
            data: payload?.data,
          },
        };
      } else {
        return {
          ...state,
          evidenceDetail: {
            ...state.evidenceDetail,
            isLoading: false,
            error: null,
          },
          deleteEvidenceERP: {
            ...state.deleteEvidenceERP,
            isLoading: false,
            error: null,
            data: payload?.data,
          },
        };
      }
    }
    case DELETE_SINGLE_EVIDENCE_FAILURE: {
      return {
        ...state,
        deleteEvidenceERP: {
          ...state.deleteEvidenceERP,
          isLoading: false,
          error: null,
          data: "",
        },
      };
    }

    /* evidence static documents */

    case EVIDENCE_STATIC_DOCUMENTS_STARTED: {
      return {
        ...state,
        evidenceStaticDocument: {
          ...state.evidenceStaticDocument,
          data: null,
          isLoading: true,
          error: null,
          status:'idle'
        },
      };
    }
    case EVIDENCE_STATIC_DOCUMENTS_SUCCESS: {
      let newEvidence = state?.evidenceDetail?.data;
      let service_order_number = state?.evidenceDetail?.details?.erp_service_order_id;
      payload.forEach((data) => {
        const documents = data?.documents.map((item) => {
          return {
            evidence_id: data?.evidence_id,
            file_name: item?.property_filename,
            service_order_number: service_order_number,
          };
        });
        if (Array.isArray(newEvidence[data?.evidence_id])) {
          if (newEvidence[data?.evidence_id]?.length > 0) {
            newEvidence[data?.evidence_id] = [...newEvidence[data?.evidence_id], ...documents];
          } else {
            newEvidence[data?.evidence_id] = documents;
          }
        } else if (newEvidence[data?.evidence_id] == undefined){
          let checkType = state.data?.find((typedata)=> typedata?.inventoryID?.value == data?.evidence_id)
          if(checkType?.evidenceType?.fieldTpe =='fileupload' || checkType?.evidenceType?.fieldTpe =='imageupload'){
            newEvidence[data?.evidence_id] = documents;
          }
        }
      });
      return {
        ...state,
        evidenceDetail:{
          ...state.evidenceDetail,
          evidence_data: newEvidence,
          data: newEvidence,
        },
        evidenceStaticDocument: {
          ...state.evidenceStaticDocument,
          data: payload,
          isLoading: false,
          error: null,
          status:'success'
        },
      };
    }
    case EVIDENCE_STATIC_DOCUMENTS_FAILURE: {
      return {
        ...state,
        evidenceStaticDocument: {
          ...state.evidenceStaticDocument,
          data: null,
          isLoading: false,
          error: null,
          status:'failed'
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default reducers;
