import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import ContentLayout from "src/components/atoms/common/ContentLayout";
import Breadcrumbs from "src/components/molecules/Breadcrumbs";
import { inboxBreadcrumb } from "src/components/molecules/Breadcrumbs/BreadcrumbsList";
import MessageModal from "src/components/organisms/cms/MessageModal";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import { useNavigate } from "react-router-dom";
import UserAnalyticsChart from "src/components/organisms/UserAnalyticsChart";

const UserAnalytics = () => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const { isModalOpen, modalData, sidebar_menu } = cmsDashboarddata;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!window.location.pathname.includes("admin")) {
      navigate("/");
    }
  }, []);

  return (
    <ContentLayout>
      <div className="text-black relative md:pt-[4.5rem] pb-0 py-8 min-h-screen-minus-3 bg-AFPBGPrimary dark:bg-AFPDarkBgDarkest dark:text-white">
        {sidebar_menu?.sidebar_list["user-analytics"] && <Breadcrumbs data={inboxBreadcrumb(sidebar_menu?.sidebar_list["user-analytics"])} />}
        {cmsDashboarddata.listLoaded && (
          <>
            <h2 className="!mb-5 text-2xl font-tahoma-bold m-3 sm:m-4 md:m-6 flex items-center gap-3">
              {cmsDashboarddata?.user_analytics?.top?.heading}
              <EditIconCMS
                handleOpen={() => {
                  dispatch(
                    dashboardModalOpenCMS({
                      cms_key: "user_analytics",
                      unique: "top",
                      heading: "Benutzer Analyse",
                    })
                  );
                }}
              />
            </h2>
            <div className="bg-AFPBGPrimary mx-3 sm:mx-4 md:mx-6 mt-0 p-0 dark:bg-AFPDarkBgDarkest">
              <p className="textStyle max-w-[540px] mb-5">{cmsDashboarddata?.user_analytics?.top?.sub_heading}</p>
            </div>
            <UserAnalyticsChart />
          </>
        )}
      </div>
      {isModalOpen && <MessageModal open={isModalOpen} cmsData={modalData} />}
    </ContentLayout>
  );
};

export default UserAnalytics;
