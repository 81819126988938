import React, { useEffect, useState } from "react";
import CrispTicketFrame from "./CrispTicketFrame";
import { useDispatch, useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";

const CrispTicket = () => {
  const profile = useSelector((state) => state.profile);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const dispatch = useDispatch();
  const [hash, setHash] = useState("");

  useEffect(() => {
    const hmac = CryptoJS.HmacSHA256(profile?.data?.email, "34cca3d7e2f660b5c87666a5ad2301d98c249ad5644a62a3f5f0244d5c532d39");
    const hmacHex = hmac.toString(CryptoJS.enc.Hex);
    setHash(hmacHex);
  }, []);

  return (
    <div>
      <h1 className="text-xl text-center pt-10 pb-10 font-tahoma-bold text-black flex justify-center items-center gap-3 dark:text-BrightGray">
        {cmsDashboarddata.help_support.table_heading.title}{" "}
        <EditIconCMS
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "help_support",
                unique: "table_heading",
                heading: "Help & Support",
              })
            );
          }}
        />
      </h1>
      {hash && <CrispTicketFrame hash={hash} email={profile?.data?.email} />}
    </div>
  );
};

export default CrispTicket;
