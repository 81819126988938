import React from "react";
import Card from "src/components/atoms/card/Card";
import { BiSolidPlusCircle } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
const NewBusiness = ({ handleOpen }) => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const { business_premises } = cmsDashboarddata;
  const dispatch = useDispatch();

  return (
    <Card css="justify-between">
      <div className="min-h-80 flex flex-col justify-center items-center gap-6">
        <BiSolidPlusCircle className="text-[7rem] text-SilverSand cursor-pointer dark:text-BrightGray" onClick={handleOpen} />
        <h4 className="text-SmokeyGray font-tahoma-bold text-xl cursor-pointer text-center flex justify-center items-center gap-2 w-full dark:text-BrightGray" onClick={handleOpen}>
          <span>{business_premises?.business_addCard?.title} </span>
          <span className="w-5">
            <EditIconCMS
              handleOpen={(e) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(
                  dashboardModalOpenCMS({
                    cms_key: "business_premises",
                    unique: "business_addCard",
                    heading: "Betriebsstätten",
                  })
                );
              }}
            />
          </span>
        </h4>
      </div>
    </Card>
  );
};

export default NewBusiness;
