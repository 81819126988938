import StandardSelect from "../../../select/StandardSelect";
import style from "../inputstyle.module.css";

const InputWithDropDown = (props) => {
  const { label, id, formik, options } = props;
  return (
    <div className={`inputExtraCss w-full`}>
      <label htmlFor={id} className={`${style.labelDropdown} inputExtraLabel dark:text-BrightGray`}>
        {label}
      </label>
      <div className="w-full">
        <div className={`${style.wrapper}`}>
          <StandardSelect extra="w-full" extraLabel="hidden" extraInput="border-gray-400" label={label} placeholder="Herr/Frau" id={id} options={options} formik={formik} />
        </div>
      </div>
    </div>
  );
};

InputWithDropDown.defaultProps = {
  label: "Label",
  id: "inputField",
  name: "inputField",
  type: "text",
  placeholder: "",
  state: "",
  disabled: false,
  formik: {
    getFieldProps: () => ({}),
    touched: {},
    errors: {},
    handleChange: () => {},
    handleBlur: () => {},
    values: {},
  },
};

export default InputWithDropDown;
