import React, { useState } from 'react';
import Line1 from 'src/assets/img/Line1.png';
import { CiCirclePlus } from 'react-icons/ci';
import ContactSignatureCanvas from 'src/components/molecules/signature/ContactSignatureCanvas';
import { SecondaryButton } from 'src/components/atoms/button';
import { useDispatch, useSelector } from 'react-redux';
import { particularApplicationSignatureReset } from 'src/store/actions/application';

const SignatureCard = ({ signature, item, usersignature, title }) => {
  const application = useSelector((state) => state.application);
  const [openSignature, setOpenSignature] = useState(false);
  const dispatch = useDispatch();
  const applicationSignature = useSelector((state) => state.application.contactSignature.data);
  const handleClose = () => {
    setOpenSignature(false);
  };
  const handleOpen = () => {
    setOpenSignature(true);
  };

  const clearExistingSignature = () => {
    if(item?.contact_id in applicationSignature){
      dispatch(particularApplicationSignatureReset({id: item?.contact_id}));
    }
  }

  return (
    <>
      <div className="mt-5">
        <h1 className="text-black text-lg font-tahoma-bold mb-5 dark:text-BrightGray">{title} - {item.title + " " + item.first_name + " " + item.last_name}</h1>
        <div className={`border border-AFPLightGray w-full md:w-[841px] min-h-[259px] flex flex-col justify-center items-center dark:border-SmokeyGray ${!(signature || usersignature) && 'cursor-pointer dark:bg-AFPDarkBgLight'}`} onClick={()=>{
          if(!(signature || usersignature)){
            handleOpen()
          }
        }}>
          {signature || usersignature ? (
            <img src={signature?.signature_image || usersignature || Line1} alt="Line" />
          ) : (
            <>
              <div className="mb-6">
                <CiCirclePlus className="text-[4rem] cursor-pointer" />
              </div>
              <p className="text-black textStyle max-w-[540px] dark:text-BrightGray">Klicken um zu unterschreiben</p> <img src={Line1} alt="Line" />
            </>
          )}
        </div>
      </div>
      {(application?.dformStatus?.data?.data?.SignatureList?.length > 0 &&
        application?.dformStatus?.data?.data?.SignatureList?.find((signature) => signature?.contact_id == item?.contact_id)
          ? false
          : application?.contactSignature?.data[item?.contact_id]
          ? application?.contactSignature?.data[item?.contact_id]
          : false) && <div className="flex justify-center my-4"><SecondaryButton variant="secondary" title={"Zurücksetzen"} css={"w-full max-w-[200px]"} handler={clearExistingSignature} /></div>}
      {openSignature && <ContactSignatureCanvas openSignature={openSignature} handleClose={handleClose} item={item} />}
    </>
  );
};

export default SignatureCard;
