import Cookies from "js-cookie";
import { MenuItem, MenuList, ClickAwayListener, ListItemIcon } from "@mui/material";
import { IoIosArrowUp } from "react-icons/io";
import { LiaUserCircleSolid } from "react-icons/lia";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import { logOutStarted } from "src/store/actions/users";
import { HiOutlineLogout } from "react-icons/hi";
import "./headmenu.css";
import { useDispatch, useSelector } from "react-redux";
import { IoSettingsOutline } from "react-icons/io5";
import ThemeSwitchList from "../ThemeSwitch/ThemeSwitchList";

const MenuLists = ({ handleClose, handleListKeyDown, profile }) => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const users = useSelector((state) => state?.users?.data?.user);
  const { sidebar_menu } = cmsDashboarddata;
  const dispatch = useDispatch();
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <MenuList className="!min-w-44" autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button" onKeyDown={handleListKeyDown}>
        <div className="flex justify-end mx-3">
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "sidebar_menu",
                  unique: "top_menu",
                  heading: "Menu",
                })
              );
            }}
          />
        </div>

        <MenuItem onClick={handleClose} className="!text-gray-500 hover:text-gray-800 hover:bg-BrightGray !font-semibold dark:!text-BrightGray dark:hover:!text-AFPPrimaryLight">
          <ListItemIcon className="!min-w-6">
            <LiaUserCircleSolid className="dark:text-white text-[19px]" />
          </ListItemIcon>
          <div className="flex justify-between gap-1 max-w-36">
            <span className="text-ellipsis overflow-hidden whitespace-nowrap">
              {profile?.first_name} {profile?.last_name}
            </span>
            <IoIosArrowUp className="!text-AFPPrimary dark:!text-AFPPrimaryLight dark:hover:!text-AFPPrimaryLight" />
          </div>
        </MenuItem>

        <MenuItem
          onClick={(event) => {
            handleClose(event, "/einstellungen/persoenliche-daten");
          }}
          className="!text-gray-500 hover:text-gray-800 hover:bg-BrightGray !border-t-2 !border-SilverSand !border-solid !font-semibold dark:!text-BrightGray dark:hover:!text-AFPPrimaryLight dark:border-SmokeyGray"
        >
          <ListItemIcon className="!min-w-6">
            <IoSettingsOutline className="dark:text-white" />
          </ListItemIcon>
          {sidebar_menu?.top_menu?.setting}
        </MenuItem>

        <ThemeSwitchList title={sidebar_menu?.top_menu} handleClose={handleClose} />

        <MenuItem
          onClick={() => {
            dispatch(
              logOutStarted({
                user_id: Cookies.get("userId"),
                email: users?.email || profile?.email,
                token: Cookies.get("token"),
              })
            );
          }}
          className="!text-gray-500 hover:text-gray-800 hover:bg-BrightGray !border-t-2 !border-SilverSand !border-solid !font-semibold dark:!text-BrightGray dark:hover:!text-AFPPrimaryLight dark:border-SmokeyGray"
        >
          <ListItemIcon className="!min-w-6">
            <HiOutlineLogout className="dark:text-white text-[19px]" />
          </ListItemIcon>
          {sidebar_menu?.top_menu?.logout}
        </MenuItem>
      </MenuList>
    </ClickAwayListener>
  );
};

export default MenuLists;
