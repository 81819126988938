import { useNavigate } from "react-router-dom";
import MessageModal from "src/components/organisms/cms/MessageModal";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { useDispatch, useSelector } from "react-redux";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";

const Footer = ({ style }) => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const { isModalOpen, modalData } = cmsDashboarddata;
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const linkHandle = (link) => {
    if(link.includes('http')){
      window.open(link, "_blank");
    } else {
      navigate(link);
    }
  };

  const links = cmsDashboarddata.footer;

  const footerLinks = [
    {
      id: 1,
      label: links?.imprint?.title,
      link: links?.imprint?.link,
      unique: "imprint",
    },
    {
      id: 2,
      label: links?.protection?.title,
      link: links?.imprint?.link,
      unique: "protection",
    },
    {
      id: 3,
      label: links?.conditions?.title,
      link: links?.imprint?.link,
      unique: "conditions",
    },
    {
      id: 4,
      label: links?.contact?.title,
      link: links?.imprint?.link,
      unique: "contact",
    },
  ];

  return (
    <>
      <div className={`bg-white flex items-center z-20 dark:bg-AFPDarkBgLight ${style == "margin" ? " md:ml-80" : "fixed bottom-0 w-full"}`}>
        <div className="text-DarkCharcoal font-tahoma relative px-8 py-4 text-center w-full shadow-footer dark:text-BrightGray">
          <div className="flex justify-between gap-2 flex-col lg:flex-row items-center text-sm">
            <div className="flex justify-start item gap-4 ml-1">
              {footerLinks?.map((item) => {
                const unique = item.unique;

                return (
                  <p
                    key={item.id}
                    className="cursor-pointer flex gap-x-1"
                    onClick={() => {
                      linkHandle(item?.link);
                    }}
                  >
                    {item?.label}
                    <span>
                      <EditIconCMS
                        handleOpen={() => {
                          dispatch(
                            dashboardModalOpenCMS({
                              cms_key: "footer",
                              unique: unique,
                              heading: "Fußzeilen-Links",
                            })
                          );
                        }}
                      />
                    </span>
                  </p>
                );
              })}
            </div>
            <div className="flex gap-x-1 justify-center">
              <p>{links?.copy_right?.title}</p>
              <EditIconCMS
                handleOpen={() => {
                  dispatch(
                    dashboardModalOpenCMS({
                      cms_key: "footer",
                      unique: "copy_right",
                      heading: "Fußzeile kopieren rechts",
                    })
                  );
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && <MessageModal open={isModalOpen} cmsData={modalData} />}
    </>
  );
};

export default Footer;
