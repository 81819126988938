import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import LocationDetail from "src/components/molecules/dform/LocationDetail";
import NewFormTemplate from "src/components/molecules/dform/NewFormTemplate";
import { currentDatePreview } from "src/helpers/document";
import { artDes } from "src/mockData/ImageHtml";

const ImageHtmlFinished = ({ data }) => {
  const { id } = useParams();
  const form_id_name = localStorage?.getItem("form_id_name") && JSON?.parse(localStorage?.getItem("form_id_name"));
  const dform_name = form_id_name && form_id_name.find((name) => id === name.id);

  const flattenedData = data?.flatMap((section) => section);
  const businessPremisesItem = flattenedData?.find((item) => item?.id === "02_FIRMA_BUSINESS_PREMISES");
  const CancellationDate = id === "SA" ? flattenedData?.find((item) => item?.id === "CLOSING_DATE") : {};
  const locationId = businessPremisesItem?.inputTypeValue;

  const currentStep = useSelector((state) => state.currentStep);
  const business = useSelector((state) => state.management);

  const [selectedInhaber, setSelectedInhaber] = useState("");

  useEffect(() => {
    let group = business?.ownerByLocation?.data;
    if (currentStep.data[0].inputTypeValue && group) {
      const selectedInhaber = group.filter((contact) => contact.contact_id === currentStep.data[0].inputTypeValue);
      setSelectedInhaber(selectedInhaber[0]?.title + " " + selectedInhaber[0]?.first_name + " " + selectedInhaber[0]?.last_name);
    }
  }, [currentStep, business?.ownerByLocation?.data]);

  return (
    <div className="text-black relative md:pt-[4.5rem] pb-0 py-8 min-h-screen dark:text-BrightGray">
      <div className="">
        <div className="py-4 px-8 border border-gray-800 dark:border-SmokeyGray">
          <div className="flex md:flex-row flex-col justify-between">
            <div>
              <div className="md:m-16 dark:text-BrightGray">
                <p>Agentur für Präqualifizierung GmbH</p>
                <p>Carl-Mannich-StaBe 26</p>
                <p>65760 Eschborn</p>
              </div>
            </div>
            <div className="md:mt-10 mt-5 w-44">
              {/* <h2 className="font-bold mb-3">{profile?.data?.company_name}</h2> */}
              <h4 className="mt-3 dark:text-BrightGray">{currentDatePreview()}</h4>
            </div>
          </div>
          <div className="font-tahoma-bold border bg-gray-100 border-gray-200 p-4 mt-8 dark:bg-AFPDarkBgLight dark:text-BrightGray dark:border-SmokeyGray">
            <h4>FirmenDaten:</h4>
          </div>
          <div className="grid md:grid-cols-[30%,70%] grid-cols-[100%] mt-8">
            <LocationDetail locationId={locationId} selectedInhaber={selectedInhaber} id={id}/>
          </div>

          <div className="font-tahoma-bold border bg-gray-100 border-gray-200 p-4 mt-8 dark:border-SmokeyGray dark:text-BrightGray dark:bg-AFPDarkBgLight">
            <h4>1. Antrag auf {dform_name?.description}</h4>
          </div>
          <div className="grid md:grid-cols-[20%,80%] grid-cols-[100%] mt-8">
            {artDes(dform_name?.description)?.map((item) => {
              return (
                <Fragment key={item?.key}>
                  <p className="bg-gray-100 py-1 px-4 border border-gray-200 md:text-base text-xs w-full dark:bg-AFPDarkBgDarkest dark:border-SmokeyGray dark:text-BrightGray">{item?.key}</p>
                  <p className="py-1 px-4 border border-gray-200 md:text-base text-xs dark:border-SmokeyGray dark:text-BrightGray">{item?.value}</p>
                </Fragment>
              );
            })}
          </div>
          <div>
            {data &&
              data.map(
                (item, index) =>
                  index < 4 && (
                    <Fragment key={index}>
                      {item && index === 0 ? (
                        <table className="w-full border-[#0e0e0e] mt-6 mb-8 dark:border-SmokeyGray">
                          <tbody>
                            <tr className="mt-12 border border-[#0e0e0e] dark:border-SmokeyGray">
                              <td className="">
                                {item.map((detail) => (
                                  <NewFormTemplate data={detail} key={detail.id} isStep1={true} firstTr={index==0} />
                                ))}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : index === 3 ? (
                        <table key={index} className="w-full border border-black mt-5 dark:border-SmokeyGray">
                          <tbody>
                            <tr>
                              <td>
                                <div className="border-b py-2 pl-2 border-black dark:border-SmokeyGray">
                                  <h2 className="font-tahoma-bold dark:text-BrightGray">Richtigkeit Ihrer Angaben</h2>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {item.map((detail) => (
                                  <NewFormTemplate data={detail} key={detail.id} />
                                ))}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : index === 1 ? (
                        item.map((detail) => (
                          <table key={detail.id} className="w-full border-black">
                            <tbody>
                              <tr>
                                <td>
                                  <NewFormTemplate data={detail} />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        ))
                      ) : (
                        <>
                          {item.map((detail) => (
                            <table key={detail.id} className="w-full border-black">
                              <tbody>
                                <tr>
                                  <td>
                                    <NewFormTemplate data={detail} />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          ))}
                          {id === "SA" && (
                            <div className="flex border py-1 border-black">
                              <h1 className="text-normal font-bold text-black w-1/2 border-r pl-1 border-black dark:text-BrightGray">Stornierungsdatum</h1>
                              <p className="pl-2">{CancellationDate?.inputTypeValue}</p>
                            </div>
                          )}
                        </>
                      )}
                    </Fragment>
                  )
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageHtmlFinished;
