import { USER_ABANDONED_CART_FAILURE, USER_ABANDONED_CART_STARTED, USER_ABANDONED_CART_SUCCESS, USER_ACTIVITY_ON_FAILURE, USER_ACTIVITY_ON_STARTED, USER_ACTIVITY_ON_SUCCESS, USER_ACTIVITY_STATISTIK_FAILURE, USER_ACTIVITY_STATISTIK_STARTED, USER_ACTIVITY_STATISTIK_SUCCESS, USER_ANALYTICS_EVIDENCE_FAILURE, USER_ANALYTICS_EVIDENCE_STARTED, USER_ANALYTICS_EVIDENCE_SUCCESS, USER_DEVICE_SOURCE_FAILURE, USER_DEVICE_SOURCE_STARTED, USER_DEVICE_SOURCE_SUCCESS, USER_LOGIN_SESSION_FAILURE, USER_LOGIN_SESSION_STARTED, USER_LOGIN_SESSION_SUCCESS, USER_LOGIN_TIME_DURATION_FAILURE, USER_LOGIN_TIME_DURATION_STARTED, USER_LOGIN_TIME_DURATION_SUCCESS, USER_LOGIN_TIMES_DURATION_FAILURE, USER_LOGIN_TIMES_DURATION_STARTED, USER_LOGIN_TIMES_DURATION_SUCCESS, USER_LOGIN_TIMES_FAILURE, USER_LOGIN_TIMES_STARTED, USER_LOGIN_TIMES_SUCCESS } from "../actionTypes";

export const userLoginTimesStarted = (payload) => {
  return {
    type: USER_LOGIN_TIMES_STARTED,
    payload,
  };
};

export const userLoginTimesSuccess = (payload) => {
  return {
    type: USER_LOGIN_TIMES_SUCCESS,
    payload,
  };
};
export const userLoginTimesFailure = (payload) => {
  return {
    type: USER_LOGIN_TIMES_FAILURE,
    payload,
  };
};

export const userLoginSessionStarted = (payload) => {
  return {
    type: USER_LOGIN_SESSION_STARTED,
    payload,
  };
};

export const userLoginSessionSuccess = (payload) => {
  return {
    type: USER_LOGIN_SESSION_SUCCESS,
    payload,
  };
};
export const userLoginSessionFailure = (payload) => {
  return {
    type: USER_LOGIN_SESSION_FAILURE,
    payload,
  };
};

export const userLoginTimeDurationStarted = (payload) => {
  return {
    type: USER_LOGIN_TIME_DURATION_STARTED,
    payload,
  };
};

export const userLoginTimeDurationSuccess = (payload) => {
  return {
    type: USER_LOGIN_TIME_DURATION_SUCCESS,
    payload,
  };
};
export const userLoginTimeDurationFailure = (payload) => {
  return {
    type: USER_LOGIN_TIME_DURATION_FAILURE,
    payload,
  };
};

export const userAbandonedCartStarted = (payload) => {
  return {
    type: USER_ABANDONED_CART_STARTED,
    payload,
  };
};

export const userAbandonedCartSuccess = (payload) => {
  return {
    type: USER_ABANDONED_CART_SUCCESS,
    payload,
  };
};
export const userAbandonedCartFailure = (payload) => {
  return {
    type: USER_ABANDONED_CART_FAILURE,
    payload,
  };
};

export const userLoginTimesDurationStarted = (payload) => {
  return {
    type: USER_LOGIN_TIMES_DURATION_STARTED,
    payload,
  };
};

export const userLoginTimesDurationSuccess = (payload) => {
  return {
    type: USER_LOGIN_TIMES_DURATION_SUCCESS,
    payload,
  };
};
export const userLoginTimesDurationFailure = (payload) => {
  return {
    type: USER_LOGIN_TIMES_DURATION_FAILURE,
    payload,
  };
};

export const userDeviceSourceStarted = (payload) => {
  return {
    type: USER_DEVICE_SOURCE_STARTED,
    payload,
  };
};

export const userDeviceSourceSuccess = (payload) => {
  return {
    type: USER_DEVICE_SOURCE_SUCCESS,
    payload,
  };
};
export const userDeviceSourceFailure = (payload) => {
  return {
    type: USER_DEVICE_SOURCE_FAILURE,
    payload,
  };
};

export const userAnalyticsEvidenceStarted = (payload) => {
  return {
    type: USER_ANALYTICS_EVIDENCE_STARTED,
    payload,
  };
};

export const userAnalyticsEvidenceSuccess = (payload) => {
  return {
    type: USER_ANALYTICS_EVIDENCE_SUCCESS,
    payload,
  };
};
export const userAnalyticsEvidenceFailure = (payload) => {
  return {
    type: USER_ANALYTICS_EVIDENCE_FAILURE,
    payload,
  };
};

export const userActivityStatistikStarted = (payload) => {
  return {
    type: USER_ACTIVITY_STATISTIK_STARTED,
    payload,
  };
};

export const userActivityStatistikSuccess = (payload) => {
  return {
    type: USER_ACTIVITY_STATISTIK_SUCCESS,
    payload,
  };
};
export const userActivityStatistikFailure = (payload) => {
  return {
    type: USER_ACTIVITY_STATISTIK_FAILURE,
    payload,
  };
};

export const userActivityOnStarted = (payload) => {
  return {
    type: USER_ACTIVITY_ON_STARTED,
    payload,
  };
};

export const userActivityOnSuccess = (payload) => {
  return {
    type: USER_ACTIVITY_ON_SUCCESS,
    payload,
  };
};
export const userActivityOnFailure = (payload) => {
  return {
    type: USER_ACTIVITY_ON_FAILURE,
    payload,
  };
};
