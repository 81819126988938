import styles from "./style.module.css";
import { Box, Modal } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import AddLocationForm from "./AddLocationForm";
import { useDispatch, useSelector } from "react-redux";
import EditIconCMS from "../../button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import { businessPremisesContacts, contactClearBusinessPremises, getProfessionalGroups } from "src/store/actions/business";
import { useEffect } from "react";

const style = {
  p: 2,
  left: "50%",
  width: "90%",
  maxWidth: "840px",
  // bgcolor: "#fbfbfb",
  overflowY: "auto",
  top: "50%",
  borderRadius: "12px",
  position: "relative",
  border: "1px solid #CBCBCB",
  transform: "translate(-50%, -50%)",
};

const AddBusinessModal = ({ open, handleClose, type='page' }) => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const business = useSelector((state) => state.management);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(businessPremisesContacts());
    dispatch(getProfessionalGroups());
    return () => {
      dispatch(contactClearBusinessPremises());
    };
  }, []);

  return (
    <Modal open={open} className={styles.customModalRoot}>
      <Box sx={style} className={`${styles.customInnerChild} bg-AFPBGPrimary dark:bg-AFPDarkBgDarkest dark:border-SmokeyGray`} tabIndex={"undefined"}>
        <div className={`h-auto max-h-[90vh] overflow-y-auto pt-4 py-10 customScrollBar`}>
          <div className="flex justify-between items-center mb-6 md:px-8">
            <h2 className="text-xl md:text-2xl font-tahoma-bold flex items-center gap-x-3 dark:text-BrightGray">
              {cmsDashboarddata?.business_premises?.add_form_heading?.title}
              <EditIconCMS
                handleOpen={() => {
                  dispatch(
                    dashboardModalOpenCMS({
                      cms_key: "business_premises",
                      unique: "add_form_heading",
                      heading: "Dokumentenverwaltung",
                    })
                  );
                }}
              />
            </h2>
            <AiOutlineClose size={24} className="text-SmokeyGray cursor-pointer -mt-5 dark:text-BrightGray" onClick={handleClose} />
          </div>
          {!business.professionalGroup?.data?.isLoading && <AddLocationForm closeModalHandler={handleClose} type={type} />}
        </div>
      </Box>
    </Modal>
  );
};

export default AddBusinessModal;
