import { useFormik } from "formik";
import React, { useState } from "react";
import AddUserForm from "./AddUserForm";
import { AiFillEdit } from "react-icons/ai";
import { RiDeleteBinFill } from "react-icons/ri";
import { InputStandardField } from "src/components/atoms/input";
import { SecondaryButton } from "src/components/atoms/button";
import BackdropButton from "src/components/atoms/button/BackdropButton";
import { VALIDATIONSCHEMABUSINESSPREMISES, INITIALVALUESBUSINESSPREMISES } from "src/services/validation/buinessPremises";
import { addBusinessPremises, setCheckAddLocation } from "src/store/actions/business";
import { useDispatch, useSelector } from "react-redux";
import AddInhaberForm from "./AddInhaberForm";
import { String12Random } from "src/helpers/setting";
import CircularButton from "../../button/CircularButton";
import toast from "react-hot-toast";
import { CgCloseR } from "react-icons/cg";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import { green } from "@mui/material/colors";
import { locationLegalOptions } from "src/mockData/businessPremises";
import FormikSearchDropDown from "../../Search/FormikSearchDropDown";
import { Checkbox } from "@mui/material";

const AddLocationForm = ({ closeModalHandler, type }) => {
  const profile = useSelector((state) => state?.profile);
  const business = useSelector((state) => state?.management);

  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const cmsData = cmsDashboarddata?.business_premises;

  const inhaber = [
    {
      id: String12Random(12),
      first_name: profile?.data?.first_name,
      last_name: profile?.data?.last_name,
      email: profile?.data?.email,
      title: profile?.data?.title,
      salutation: profile?.data?.salutation,
      status: "add",
    },
  ];

  const [openAddLeaderModal, setOpenAddLeaderModal] = useState(false);
  const [openAddInhaberModal, setOpenAddInhaberModal] = useState(false);
  const [leaderList, setLeaderList] = useState([]);
  const [inhaberList, setInhaberList] = useState(inhaber);
  const [initialValuesInhaberLeader, setInitialValuesInhaberLeader] = useState({
    leader: null,
    inhaber: null,
  });
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: INITIALVALUESBUSINESSPREMISES,
    validationSchema: VALIDATIONSCHEMABUSINESSPREMISES,
    onSubmit: (values) => {
      let newinhaber = inhaberList.map((item) => {
        return {
          first_name: item?.first_name,
          last_name: item?.last_name,
          email: item?.email,
          title: item?.title,
          salutation: item?.salutation,
          status: item?.status,
        };
      });
      let newleader = leaderList?.map((item) => {
        return {
          first_name: item?.first_name,
          last_name: item?.last_name,
          email: item?.email,
          title: item?.title,
          salutation: item?.salutation,
          professional_groups: JSON?.parse(item?.professional_groups),
          status: item?.status,
        };
      });
      if (newinhaber?.length > 0 && newleader?.length > 0) {
        let ik_number = JSON.stringify(values?.ik_number?.map((number) => number?.toString()));
        const country = values?.country === "Deutschland" ? "DE" : values?.country;
        const newPayload = {
          ...values,
          ik_number: JSON?.parse(ik_number),
          business_owners: newinhaber,
          technical_leaders: newleader,
          country,
        };
        dispatch(addBusinessPremises({ ...newPayload, type }));
        dispatch(setCheckAddLocation(true));
      } else {
        toast.error('Mindestens ein "Inhaber" und ein "Fachlicher Leiter" müssen hinzugefügt werden.');
      }
    },
  });

  const closeAddLeaderModalHandle = () => {
    setOpenAddLeaderModal(false);
    setInitialValuesInhaberLeader({
      leader: null,
      inhaber: null,
    });
  };
  const openAddLeaderModalHandle = () => {
    setOpenAddLeaderModal(true);
  };

  const handleCloseAddInhaber = () => {
    setOpenAddInhaberModal(false);
    setInitialValuesInhaberLeader({
      leader: null,
      inhaber: null,
    });
  };
  const openAddInhaberModalHandle = () => {
    setOpenAddInhaberModal(true);
  };

  const addLeaderHandler = (leader) => {
    let group = leader?.professional_groups?.map((leader) => leader?.id);
    if (leader?.id) {
      let UpdateLeader = leaderList?.map((user) => (user?.id === leader?.id ? { ...leader, professional_groups: JSON.stringify(group) } : user));
      setLeaderList(UpdateLeader);
    } else if (leader?.newId) {
      let find = leaderList?.find((item) => item?.id === leader?.newId);
      if (find) {
        let UpdateLeader = leaderList?.map((user) => (user?.id === leader?.id ? { ...leader, professional_groups: JSON.stringify(group) } : user));
        setLeaderList(UpdateLeader);
      } else {
        setLeaderList((prev) => [
          ...prev,
          {
            id: leader?.newId,
            ...leader,
            professional_groups: JSON.stringify(group),
          },
        ]);
      }
    } else {
      setLeaderList((prev) => [
        ...prev,
        {
          id: String12Random(12),
          ...leader,
          professional_groups: JSON.stringify(group),
        },
      ]);
    }
  };

  const removeLeaderHandler = (id) => {
    let leaderFilter = leaderList?.filter((leader) => leader?.id !== id);
    setLeaderList(leaderFilter);
  };

  const editLeaderHandler = (user) => {
    setInitialValuesInhaberLeader({
      leader: user,
      inhaber: null,
    });
    setOpenAddLeaderModal(true);
  };

  const addInhaberHandler = (inhaber) => {
    if (inhaber?.id) {
      let UpdateInhaber = inhaberList?.map((item) => (item?.id === inhaber?.id ? inhaber : item));
      setInhaberList(UpdateInhaber);
    } else if (inhaber?.newId) {
      let find = inhaberList?.find((item) => item?.id === inhaber?.newId);
      if (find) {
        let UpdateInhaber = inhaberList?.map((item) => (item?.id === inhaber?.newId ? inhaber : item));
        setInhaberList(UpdateInhaber);
      } else {
        setInhaberList((prev) => [...prev, { id: inhaber?.newId, ...inhaber }]);
      }
    } else {
      setInhaberList((prev) => [...prev, { id: String12Random(12), ...inhaber }]);
    }
  };

  const removeInhaberHandler = (id) => {
    let inhaberFilter = inhaberList?.filter((inhaber) => inhaber?.id !== id);
    setInhaberList(inhaberFilter);
  };

  const editInhaberHandler = (inhaber) => {
    setInitialValuesInhaberLeader({
      leader: null,
      inhaber: inhaber,
    });
    setOpenAddInhaberModal(true);
  };

  const ikNumberRemoveHandle = (index) => {
    const updatedIkNumbers = [...formik.values.ik_number];
    updatedIkNumbers?.splice(index, 1);
    formik.setFieldValue("ik_number", updatedIkNumbers);
  };

  const editNumberToggler = () => {
    formik.setFieldValue("ik_number", [...formik.values.ik_number, ""]);
  };

  return (
    <>
      <form className="md:px-8" onSubmit={formik.handleSubmit}>
        <div className="w-full flex justify-end ">
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "business_premises",
                  unique: "add_form_name",
                  heading: "Betriebsstätten",
                })
              );
            }}
          />
        </div>
        <InputStandardField
          id="name"
          type="text"
          formik={formik}
          extraLabel="!ml-0"
          placeholder={cmsData?.add_form_name?.name}
          label={cmsData?.add_form_name?.name}
          extra="w-full md:w-[50%] !items-start"
        />
        <hr className="my-5 dark:border-SmokeyGray" />

        {formik.values.ik_number.map((number, index) => {
          return (
            <>
              <div className="w-full flex gap-2 mb-3">
                <InputStandardField
                  id={`ik_number[${index}]`}
                  type="number"
                  formik={formik}
                  extra="w-full md:w-[50%] !items-start"
                  placeholder={cmsData?.add_form_name?.ik_number}
                  label={cmsData?.add_form_name?.ik_number}
                  extraLabel={`!ml-0 ${index > 0 ? "hidden" : ""}`}
                />
                {formik.values.ik_number.length > 1 ? (
                  <CgCloseR
                    className={`cursor-pointer text-red-400 ${index > 0 ? "mt-1" : "mt-8"}`}
                    onClick={() => {
                      ikNumberRemoveHandle(index);
                    }}
                  />
                ) : (
                  <div></div>
                )}
              </div>
            </>
          );
        })}
        <div className="flex gap-x-3 items-center">
          <CircularButton title={cmsData?.add_ik_number_button?.create_button} handler={editNumberToggler} css="!justify-start" />
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "business_premises",
                  unique: "add_ik_number_button",
                  heading: "Betriebsstätten",
                })
              );
            }}
          />
        </div>

        <hr className="mt-5 mb-1 dark:border-SmokeyGray" />

        <div className="w-full flex justify-end ">
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "business_premises",
                  unique: "location_legal_detail",
                  heading: "Betriebsstätten",
                })
              );
            }}
          />
        </div>

        <div className="my-5 grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-10">
        <div className="flex justify-start items-center">
            <Checkbox
              className="!cursor-pointer"
              sx={{
                color: "#484848",
                "&.Mui-checked": {
                  color: green[600],
                },
              }}
              checked={formik.values["is_main_location"]}
              onChange={(e) => formik.setFieldValue("is_main_location", e.target.checked)}
            />
            <div className="text-sm font-tahoma-bold text-black font-medium w-full dark:text-BrightGray">{cmsData?.location_legal_detail?.main_location}</div>
          </div>
          <div className="flex justify-start items-center">
            <Checkbox
              className="!cursor-pointer"
              sx={{
                color: "#484848",
                "&.Mui-checked": {
                  color: green[600],
                },
              }}
              checked={formik.values["is_store_location"]}
              onChange={(e) => formik.setFieldValue("is_store_location", e.target.checked)}
            />
            <div className="text-sm font-tahoma-bold text-black font-medium w-full dark:text-BrightGray">{cmsData?.location_legal_detail?.store_location}</div>
          </div>
          <div className={`flex items-start flex-col gap-1`}>
            <label htmlFor="legal_form" className={`text-sm font-tahoma-bold text-black ml-1.5 font-medium w-full dark:text-BrightGray`}>
              {cmsData?.location_legal_detail?.legal_form}
            </label>
            <FormikSearchDropDown id="legal_form" selectedVal="" optionsList={locationLegalOptions} formik={formik} />
          </div>
        </div>

        <hr className="mt-5 mb-1 dark:border-SmokeyGray" />

        <div className="w-full flex justify-end ">
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "business_premises",
                  unique: "add_form_location",
                  heading: "Betriebsstätten",
                })
              );
            }}
          />
        </div>
        <div className="my-5 grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-10">
          <InputStandardField
            id="street"
            formik={formik}
            extra="!items-start"
            placeholder={cmsData?.add_form_location?.state}
            label={cmsData?.add_form_location?.state}
            extraLabel="!ml-0"
          />
          <InputStandardField
            id="house_number"
            formik={formik}
            type="number"
            extra="!items-start"
            placeholder={cmsData?.add_form_location?.house_number}
            label={cmsData?.add_form_location?.house_number}
            extraLabel="!ml-0"
          />
        </div>
        <hr className="my-5 dark:border-SmokeyGray" />

        <div className="my-5 grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-10">
          <InputStandardField
            id="city"
            formik={formik}
            extra="!items-start"
            placeholder={cmsData?.add_form_location?.city}
            label={cmsData?.add_form_location?.city}
            extraLabel="!ml-0"
          />
          <InputStandardField
            id="post_code"
            formik={formik}
            extra="!items-start"
            placeholder={cmsData?.add_form_location?.postcode}
            type="number"
            label={cmsData?.add_form_location?.postcode}
            extraLabel="!ml-0"
          />
          <InputStandardField
            id="country"
            formik={formik}
            extra="!items-start"
            placeholder={cmsData?.add_form_location?.country}
            label={cmsData?.add_form_location?.country}
            extraLabel="!ml-0"
            disabled={true}
          />
        </div>
        <hr className="mt-5 mb-1 dark:border-SmokeyGray" />
        <div className="w-full flex justify-end ">
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "business_premises",
                  unique: "add_form_mail",
                  heading: "Betriebsstätten",
                })
              );
            }}
          />
        </div>
        <div className="my-5 grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-10">
          <InputStandardField
            id="official_email"
            formik={formik}
            extra="!items-start"
            placeholder={cmsData?.add_form_mail?.email}
            label={cmsData?.add_form_mail?.email}
            extraLabel="!ml-0"
          />
        </div>
        <hr className="my-5 dark:border-SmokeyGray" />
        <div className="my-5 grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-10">
          <InputStandardField
            id="phone_number"
            type="number"
            formik={formik}
            extra="!items-start"
            placeholder={cmsData?.add_form_mail?.telephone}
            label={cmsData?.add_form_mail?.telephone}
            extraLabel="!ml-0"
          />
        </div>
        <hr className="my-5 dark:border-SmokeyGray" />
        <div className="max-w-[600px]">
          <h2 className="text-lg font-semibold flex items-center gap-x-3 dark:text-BrightGray">
            {cmsData?.add_form_owner?.heading}
            <EditIconCMS
              handleOpen={() => {
                dispatch(
                  dashboardModalOpenCMS({
                    cms_key: "business_premises",
                    unique: "add_form_owner",
                    heading: "Betriebsstätten",
                  })
                );
              }}
            />
          </h2>
          {inhaberList.length > 0 ? (
            <ul>
              <li className="text-sm text-SmokeyGray dark:text-BrightGray">{cmsData?.add_form_owner?.name}</li>
              {inhaberList.map((user, index) => {
                return (
                  <li className="flex justify-between text-[#1B1B1B] mt-2 dark:text-BrightGray" key={index}>
                    <div>{user.title + " " + user.first_name + " " + user.last_name}</div>
                    <div className="flex gap-x-2">
                      <AiFillEdit
                        className="text-SmokeyGray cursor-pointer dark:text-BrightGray"
                        onClick={() => {
                          editInhaberHandler(user);
                        }}
                      />
                      <RiDeleteBinFill className="text-SmokeyGray cursor-pointer dark:text-BrightGray" onClick={() => removeInhaberHandler(user.id)} />
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            <h4 className="text-red-400">keine Inhaber</h4>
          )}

          <CircularButton title={cmsData?.add_form_owner?.new_button} handler={openAddInhaberModalHandle} />
        </div>
        <hr className="my-5 dark:border-SmokeyGray" />
        <div className="max-w-[600px]">
          <h2 className="text-lg font-semibold flex items-center gap-x-3 dark:text-BrightGray">
            {cmsData?.add_form_leader?.heading}
            <EditIconCMS
              handleOpen={() => {
                dispatch(
                  dashboardModalOpenCMS({
                    cms_key: "business_premises",
                    unique: "add_form_leader",
                    heading: "Betriebsstätten",
                  })
                );
              }}
            />
          </h2>
          {leaderList.length > 0 ? (
            <ul>
              <li className="text-sm text-SmokeyGray dark:text-BrightGray"> {cmsData?.add_form_leader?.name}</li>
              {leaderList.map((user, index) => {
                return (
                  <li className="flex justify-between text-AFPHeading dark:text-BrightGray" key={index}>
                    <div>{user?.title + " " + user?.first_name + " " + user?.last_name}</div>
                    <div className="flex gap-x-2">
                      <AiFillEdit
                        className="text-SmokeyGray cursor-pointer dark:text-BrightGray"
                        onClick={() => {
                          editLeaderHandler(user);
                        }}
                      />
                      <RiDeleteBinFill
                        className="text-SmokeyGray cursor-pointer dark:text-BrightGray"
                        onClick={() => {
                          removeLeaderHandler(user?.id);
                        }}
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            <h4 className="text-red-400">{cmsData?.add_form_leader?.not_have_leader}</h4>
          )}
        </div>
        <div className="flex gap-x-3 items-center">
          <CircularButton title={cmsData?.new_button_group?.new_button} handler={openAddLeaderModalHandle} />
          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "business_premises",
                  unique: "new_button_group",
                  heading: "Betriebsstätten",
                })
              );
            }}
          />
        </div>

        <div className="flex gap-4 justify-center md:justify-start mt-5 items-center">
          <BackdropButton title={cmsData?.add_form_button?.add_button} btnStyle="max-w-[150px]" type="submit" loading={business?.isLoading} borderRadius="4px" />
          <SecondaryButton
            variant={`${business?.isLoading ? "disabled" : "secondary"}`}
            title={cmsData?.add_form_button?.cancel_button}
            css="w-[150px]"
            handler={closeModalHandler}
          />

          <EditIconCMS
            handleOpen={() => {
              dispatch(
                dashboardModalOpenCMS({
                  cms_key: "business_premises",
                  unique: "add_form_button",
                  heading: "Betriebsstätten",
                })
              );
            }}
          />
        </div>
      </form>
      {openAddLeaderModal && (
        <AddUserForm
          handleClose={closeAddLeaderModalHandle}
          handleOpen={openAddLeaderModal}
          addLeaderHandler={addLeaderHandler}
          initialValues={initialValuesInhaberLeader.leader}
          isLoading={business?.contactList?.isLoading}
        />
      )}
      {openAddInhaberModal && (
        <AddInhaberForm
          handleClose={handleCloseAddInhaber}
          handleOpen={openAddInhaberModal}
          addinhaberHandler={addInhaberHandler}
          initialValues={initialValuesInhaberLeader.inhaber}
          isLoading={business?.contactList?.isLoading}
        />
      )}
    </>
  );
};

export default AddLocationForm;
