import React, { useEffect, useState } from "react";
import { SecondaryButton } from "src/components/atoms/button";
import { BiSolidPlusCircle } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import AddBusinessModal from "src/components/atoms/modal/AddBusinessModal";
import SearchDropDown from "src/components/atoms/Search/SearchDropDown";
import { createdResetBusinessPremises, getLeaderByLocationStarted, setCheckAddLocation } from "src/store/actions/business";
import { PrimaryButton } from "src/components/atoms/button";
import { addCurrentStepDetailValue } from "src/store/actions/currentStep";
import { applicationLocationValidationStarted } from "src/store/actions/application";
import { useParams, useSearchParams } from "react-router-dom";
import CustomCircularLoader from "src/components/atoms/common/CustomCircularLoader";
import BackdropLoader from "src/components/atoms/loader/BackdropLoader";

const BusinessPremisesButton = ({ data, handleHover, stepsCMS, step_id }) => {
  const [selectedBtn, setSelectedBtn] = useState(1); // eslint-disable-line no-unused-vars
  const [openAddBussinessModal, setOpenAddBussinessModal] = useState(false);
  const [businessList, setBusinessList] = useState([]);
  const profile = useSelector((state) => state?.profile);
  const business = useSelector((state) => state?.management);
  const application = useSelector((state) => state?.application);
  const currentStep = useSelector((state) => state.currentStep);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const [search, setSearch] = useState("");
  const { id: serviceId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars

  const currentStepParam = searchParams.get("active");
  const dispatch = useDispatch();
  const locationDetailForm = JSON.parse(localStorage.getItem("locationDetailForm"));

  useEffect(() => {
    if (business?.allLocation?.data?.length > 0) {
      const bList = business?.allLocation?.data?.map((business) => {
        return { title: business?.name, value: business?.location_id };
      });
      setBusinessList(bList);
      setSearch(data?.inputTypeValue ? data?.inputTypeValue : "");
      data?.inputTypeValue &&
        data?.ValidRequiredErp &&
        dispatch(applicationLocationValidationStarted({ service_order_types_id: serviceId, location_id: data?.inputTypeValue, valueId: data?.id, valueKey: "inputTypeValue" }));
    }
    if (!search) {
      if (!data?.inputTypeValue) {
        if (locationDetailForm) {
          setTimeout(() => {
            setSearch(locationDetailForm?.location_id?.toString());
            newhandleChangeDropdown(locationDetailForm?.location_id?.toString());
          }, 1000);
        }
      }
    }
  }, [business?.allLocation?.data]);

  useEffect(() => {
    if (business?.checkNewLocation && businessList?.length > 0) {
      const lastLocation = businessList[businessList?.length - 1].value;
      setSearch(lastLocation);
      newhandleChangeDropdown(lastLocation);
      dispatch(setCheckAddLocation(false));
    }
  }, [businessList]);

  useEffect(() => {
    if (businessList?.length === 1) {
      if (step_id == "02_FIRMA") {
        let location = application?.data["02_FIRMA"]?.find((l) => l?.inputType == "groupButton");
        if (location?.inputTypeValue) {
          console.log("inputTypeValue", location);
        } else {
          const singleBusiness = businessList[0];
          setSearch(singleBusiness?.value);
          newhandleChangeDropdown(singleBusiness?.value);
        }
      } else {
        if (application?.data["02_FIRMA"]) {
          const singleBusiness = businessList[0];
          setSearch(singleBusiness?.value);
          newhandleChangeDropdown(singleBusiness?.value);
        }
      }
    }
  }, [businessList]);

  useEffect(() => {
    business?.newUser?.created && (setOpenAddBussinessModal(false), dispatch(createdResetBusinessPremises()));
  }, [business?.newUser]);

  const handleOpen = () => {
    setOpenAddBussinessModal(true);
  };
  const handleClose = () => {
    setOpenAddBussinessModal(false);
  };

  const handleMouseEnter = () => {
    if (typeof handleHover === "function") {
      handleHover(data);
    }
  };

  const newhandleChangeDropdown = (option) => {
    currentStep?.data?.forEach((data) => {
      if (data?.inputType == "dropdownTag" || data?.inputType == "groupButtonInhaber") {
        dispatch(
          addCurrentStepDetailValue({
            valueId: data?.id,
            value: "",
            valueKey: "inputTypeValue",
          })
        );
      }
    });
    if (data?.ValidRequiredErp) {
      dispatch(applicationLocationValidationStarted({ service_order_types_id: serviceId, location_id: option, valueId: data?.id, valueKey: "inputTypeValue" }));
    } else {
      dispatch(getLeaderByLocationStarted({ location_id: option }));
      dispatch(
        addCurrentStepDetailValue({
          valueId: data?.id,
          value: option,
          valueKey: "inputTypeValue",
        })
      );
    }
  };

  return (
    <div className="card mb-12" data-id={data?.id} onMouseEnter={handleMouseEnter}>
      <div className="w-full max-w-[760px]">
        {selectedBtn == 0 && (
          <>
            <h1 className="text-xl font-tahoma-bold mb-8">{stepsCMS[data?.id]?.inputTitle}</h1>
            <SecondaryButton
              variant="secondary"
              title={"neue Betriebsstätte"}
              css={"font-tahoma-bold font-semibold !px-2"}
              handler={handleOpen}
              icon={<BiSolidPlusCircle className="text-AFPPrimaryDark cursor-pointer hover:text-AFPPrimaryLight" />}
            />
          </>
        )}

        {selectedBtn == 1 && (
          <>
            <h1 className="text-xl font-tahoma-bold mb-8">{stepsCMS[data?.id]?.inputTitle}</h1>
            <div className="">
              {business?.allLocation?.isLoading ? (
                <CustomCircularLoader />
              ) : (
                <>
                  <div className="flex gap-x-3 md:gap-x-6">
                    <SearchDropDown data={data} optionsList={businessList} id="salutation" selectedVal={search} handleChange={(item) => setSearch(item)} />
                    <div className={`w-[180px] max-w-[300px] lg:w-[250px] ${locationDetailForm && currentStepParam != 2 ? "opacity-50" : ""}`}>
                      <PrimaryButton
                        title={cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.create_new}
                        btnStyle="w-full"
                        handler={() => {
                          if (locationDetailForm && currentStepParam != 2) { // eslint-disable-line no-unused-vars
                          } else {
                            handleOpen();
                          }
                        }}
                      />
                    </div>
                  </div>
                  {!search ? <span className="text-red-400 text-sm">* Pflichtfeld</span> : null}
                  {search && data?.ValidRequiredErp && (
                    <>
                      {" "}
                      {application?.locationValid?.isLoading ? (
                        <div className="mt-2">
                          <BackdropLoader open={application?.locationValid?.isLoading} />
                          {/* <CustomCircularLoader /> */}
                          <h3 className="text-red-500 mt-6">Überprüfung der Gültigkeit dieses Unternehmensstandorts</h3>
                        </div>
                      ) : (
                        <h3 className="text-green-500 mt-6"></h3>
                      )}{" "}
                    </>
                  )}
                  {!application?.locationValid?.isLoading && application?.locationValid?.status === "done" && application?.locationValid?.data?.error && (
                    <h3 className="text-red-500 mt-6">{application?.locationValid?.data?.error}</h3>
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
      {profile?.data && openAddBussinessModal && <AddBusinessModal open={openAddBussinessModal} handleClose={handleClose} type="dform" />}
    </div>
  );
};

export default BusinessPremisesButton;
