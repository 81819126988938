import * as React from "react";
import { Breadcrumbs as MUIBreadcrumbs } from "@mui/material";
import Link from "@mui/material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

export default function Breadcrumbs({ data }) {
  const navigate = useNavigate();
  const profile = useSelector((state) => state.profile);

  function handleClick(e, link) {
    e.preventDefault();
    if (profile?.data?.role === "admin") {
      navigate("/admin" + link);
    } else {
      navigate(link);
    }
  }
  const breadcrumbs = data?.map((bread, i) => {
    const isLastItem = i === data?.length - 1;

    return (
      <div key={i}>
        {!isLastItem ? (
          bread.link ? (
            <Link
              className="!text-black dark:!text-BrightGray"
              underline="hover"
              key="1"
              // color="black"
              href={bread.link}
              onClick={(e) => {
                handleClick(e, bread.link);
              }}
            >
              {bread.title}
            </Link>
          ) : (
            <Link underline="none" key="3" className="!text-black dark:!text-BrightGray">
              {bread.title}
            </Link>
          )
        ) : (
          <h3 className="bg-BrightGray text-black rounded-lg px-3 py-1 dark:bg-AFPDarkBgLight dark:text-BrightGray">{bread.title}</h3>
        )}
      </div>
    );
  });

  return (
    <div className="flex flex-col m-3 sm:m-4 md:m-6">
      <MUIBreadcrumbs separator={<NavigateNextIcon className="dark:text-BrightGray" fontSize="small" />} aria-label="breadcrumb">
        {breadcrumbs}
      </MUIBreadcrumbs>
    </div>
  );
}

Breadcrumbs.defaultProps = {
  data: [],
};
