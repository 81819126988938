import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { put } from "redux-saga/effects";
import {
  ADD_LEADERS_BY_LOCATION,
  ADD_OWNER_BY_LOCATION,
  ADDBUSINESSPREMISES,
  BUSINESS_PREMISES_CONTACT_LIST,
  BUSINESS_PREMISES_DETAIL,
  BUSINESS_PREMISES_LIST,
  DELETE_BUSINESS_PREMISES,
  GET_BUSINESS_PREMISESES,
  GET_LEADERS_BY_LOCATION,
  GET_OWNER_BY_LOCATION,
  GET_PROFESSIONAL_GROUPS,
  UPDATE_PREMISES_DETAIL,
} from "../requests/business";
import {
  addLeaderByLocationFailure,
  addLeaderByLocationSuccess,
  addOwnerByLocationFailure,
  addOwnerByLocationSuccess,
  businessPremisesFailure,
  contactSetBusinessPremises,
  createdBusinessPremises,
  detailSetBusinessPremises,
  failureProfessionalGroups,
  getAllBusinessFailure,
  getAllBusinessStarted,
  getAllBusinessSuccess,
  getLeaderByLocationFailure,
  getLeaderByLocationStarted,
  getLeaderByLocationSuccess,
  listBusinessPremises,
  listInitialBusinessPremises,
  ownerByLocationFailure,
  ownerByLocationStarted,
  ownerByLocationSuccess,
  setProfessionalGroups,
  updateBusinessPremisesSuccess,
} from "src/store/actions/business";

export function* addBusinessPremisesHandler(action) {
  try {
    const {type, ...filterPayload} = action.payload
    const newPayload = {
      ...filterPayload,
      user_id: Cookies.get("userId"),
    };
    const response = yield ADDBUSINESSPREMISES(newPayload);
    if (response.status) {
      if(type==="dform"){
        yield put(getAllBusinessStarted());
        yield put(createdBusinessPremises());
      } else {
        yield put(createdBusinessPremises());
        yield put(listBusinessPremises({ user_id: Cookies.get("userId") }));
      }
      toast.success(response?.message);
    } else {
      yield put(
        businessPremisesFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(businessPremisesFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* businessPremisesListHandler() {
  try {
    const paramsData = {
      queryParams: {
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield BUSINESS_PREMISES_LIST(paramsData);
    if (response?.status) {
      // localStorage.setItem("getAllLocation", JSON.stringify(response?.data?.businessList));
      yield put(listInitialBusinessPremises(response?.data));
    } else {
      yield put(
        businessPremisesFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(businessPremisesFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* getAllBusinessPremisesHandler() {
  try {
    const paramsData = {
      queryParams: {
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_BUSINESS_PREMISESES(paramsData);
    if (response?.status) {
      localStorage.setItem("getAllLocation", JSON.stringify(response?.data));
      yield put(getAllBusinessSuccess(response?.data));
    } else {
      yield put(
        getAllBusinessFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(getAllBusinessFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* businessPremisesDetailHandler(action) {
  try {
    const paramsData = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield BUSINESS_PREMISES_DETAIL(paramsData);
    if (response.status) {
      yield put(detailSetBusinessPremises(response.data));
    } else {
      yield put(
        businessPremisesFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(businessPremisesFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* updateBusinessPremisesHandler(action) {
  try {
    const response = yield UPDATE_PREMISES_DETAIL(action.payload);
    if (response.status) {
      toast.success(response?.message);
      yield put(updateBusinessPremisesSuccess({}));
    } else {
      yield put(
        businessPremisesFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(businessPremisesFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* deleteBusinessPremisesHandler(action) {
  try {
    const response = yield DELETE_BUSINESS_PREMISES(action.payload);
    if (response.status) {
      toast.success(response?.message);
      yield put(listBusinessPremises({ user_id: Cookies.get("userId") }));
    } else {
      yield put(
        businessPremisesFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(businessPremisesFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* businessPremisesContactHandler() {
  try {
    const paramsData = {
      queryParams: {
        id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield BUSINESS_PREMISES_CONTACT_LIST(paramsData);
    if (response.status) {
      localStorage.setItem("businessPremisesContactHandler", JSON.stringify(response.data));
      yield put(contactSetBusinessPremises(response.data));
    } else {
      yield put(
        businessPremisesFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(businessPremisesFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* getProfessionalGroupsHandler() {
  try {
    const paramsData = {
      queryParams: {
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_PROFESSIONAL_GROUPS(paramsData);
    if (response.status) {
      yield put(setProfessionalGroups(response.data));
    } else {
      yield put(
        failureProfessionalGroups({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(failureProfessionalGroups({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* getLeaderByLocationHandler(action) {
  try {
    const paramsData = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_LEADERS_BY_LOCATION(paramsData);
    if (response.status) {
      yield put(getLeaderByLocationSuccess(response.data));
    } else {
      yield put(
        getLeaderByLocationFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(getLeaderByLocationFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* ownerByLocationHandler(action) {
  try {
    const paramsData = {
      queryParams: {
        ...action.payload,
        user_id: Cookies.get("userId"),
      },
      isAuthorized: true,
    };
    const response = yield GET_OWNER_BY_LOCATION(paramsData);
    if (response.status) {
      yield put(ownerByLocationSuccess(response.data));
    } else {
      yield put(
        ownerByLocationFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(ownerByLocationFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* addLeaderByLocationHandler(action) {
  try {
    const payload = {
      ...action.payload,
      user_id: Cookies.get("userId"),
    };
    const response = yield ADD_LEADERS_BY_LOCATION(payload);
    if (response.status) {
      yield put(getLeaderByLocationStarted({ location_id: action.payload.location_id }));
      yield put(addLeaderByLocationSuccess(response.data));
    } else {
      yield put(
        addLeaderByLocationFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(addLeaderByLocationFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}

export function* addOwnerByLocationHandler(action) {
  try {
    const payload = {
      ...action.payload,
      user_id: Cookies.get("userId"),
    };
    const response = yield ADD_OWNER_BY_LOCATION(payload);
    if (response.status) {
      yield put(ownerByLocationStarted({ location_id: action.payload.location_id }));
      yield put(addOwnerByLocationSuccess(response.data));
    } else {
      yield put(
        addOwnerByLocationFailure({
          message: response?.message || "Etwas ist schief gelaufen!",
        })
      );
    }
  } catch (error) {
    yield put(addOwnerByLocationFailure({ message: error?.message || "Etwas ist schief gelaufen!" }));
  }
}
