import {
  GET_COMPLETED_SERVICE_ORDER_FAILURE,
  GET_COMPLETED_SERVICE_ORDER_STARTED,
  GET_COMPLETED_SERVICE_ORDER_SUCCESS,
  GET_GOING_SERVICE_ORDER_PAGINATE_FAILURE,
  GET_GOING_SERVICE_ORDER_PAGINATE_STARTED,
  GET_GOING_SERVICE_ORDER_PAGINATE_SUCCESS,
  GET_ON_GOING_SERVICE_ORDER_FAILURE,
  GET_ON_GOING_SERVICE_ORDER_STARTED,
  GET_ON_GOING_SERVICE_ORDER_SUCCESS,
} from "../actionTypes";

const initialState = {
  data: null,
  error: null,
  isLoading: false,
  service_type: "",
  completed: {
    data: null,
    error: null,
    isLoading: false,
    service_type: "",
  },
  paginate: {
    data: null,
    error: null,
    isLoading: false,
    stopLoadMore: false,
    dataLoaded:{
    }
  },
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_ON_GOING_SERVICE_ORDER_STARTED: {
      return { ...state, isLoading: true, error: null, service_type: action.payload.service_type };
    }
    case GET_ON_GOING_SERVICE_ORDER_SUCCESS: {
      return { ...state, isLoading: false, data: action.payload, error: null };
    }
    case GET_ON_GOING_SERVICE_ORDER_FAILURE: {
      return { ...state, isLoading: false, error: action.payload };
    }
    case GET_COMPLETED_SERVICE_ORDER_STARTED: {
      return { ...state, completed: { ...state.completed, isLoading: true, error: null, service_type: action.payload.service_type } };
    }
    case GET_COMPLETED_SERVICE_ORDER_SUCCESS: {
      return { ...state, completed: { ...state.completed, isLoading: false, data: action.payload, error: null } };
    }
    case GET_COMPLETED_SERVICE_ORDER_FAILURE: {
      return { ...state, completed: { ...state.completed, isLoading: false, error: action.payload } };
    }
    case GET_GOING_SERVICE_ORDER_PAGINATE_STARTED: {
      return { ...state, paginate: { ...state.paginate, isLoading: true, error: null, dataLoaded:{...state.paginate.dataLoaded, [action.payload.location_id]: false} } };
    }
    case GET_GOING_SERVICE_ORDER_PAGINATE_SUCCESS: {
      const newPaginateData = action.payload.data;
      let checkEmptyLength = Object.keys(newPaginateData).length !== 0;
      const locationKey = checkEmptyLength && Object.keys(newPaginateData)[0];

      const newServiceOrderData = state.data;
      if(locationKey && newServiceOrderData){
        newServiceOrderData[locationKey] = [...newServiceOrderData[locationKey], ...newPaginateData[locationKey]];
      } 

      return {
        ...state,
        paginate: { ...state.paginate, isLoading: false, data: newPaginateData, error: null, stopLoadMore: checkEmptyLength ? true : false, dataLoaded:{...state.paginate.dataLoaded, [action.payload.location_id]: checkEmptyLength ? false : true} },
        data: newServiceOrderData,
      };
    }
    case GET_GOING_SERVICE_ORDER_PAGINATE_FAILURE: {
      return { ...state, paginate: { ...state.paginate, isLoading: false, error: action.payload } };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
