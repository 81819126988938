import EvindenceSignature from "src/components/molecules/FilterEvidence/EvindenceSignature";
import EvidenceTextArea from "src/components/molecules/FilterEvidence/EvidenceTextArea";
import EvidenceCheckbox from "src/components/molecules/FilterEvidence/EvidenceCheckbox";
import EvendenceImageUpload from "src/components/molecules/FilterEvidence/EvendenceImageUpload";
import EvidenceInput from "src/components/molecules/FilterEvidence/EvidenceInput";
import { useSelector } from "react-redux";

const FilteredData = ({ evedenceData, handleCheckChangeDetailFill, entriesKey, index }) => {
  const evidence = useSelector((state) => state.evidence);
  if (evedenceData?.evidenceType?.fieldTpe === "checkbox") {
    return (
      <div className="my-6 evidence-section" data-key={entriesKey + "-" + index}>
        <EvidenceCheckbox evedenceData={evedenceData} evidence={evidence} handleCheckChangeDetailFill={handleCheckChangeDetailFill} entriesKey={entriesKey} />
      </div>
    );
  } else if (evedenceData?.evidenceType?.fieldTpe === "signature") {
    return (
      <div className="my-6 evidence-section" data-key={entriesKey + "-" + index}>
        <EvindenceSignature evedenceData={evedenceData} evidence={evidence} handleCheckChangeDetailFill={handleCheckChangeDetailFill} entriesKey={entriesKey} />
      </div>
    );
  } else if (evedenceData?.evidenceType?.fieldTpe === "imageupload") {
    return (
      <div className="my-6 evidence-section" data-key={entriesKey + "-" + index}>
        <EvendenceImageUpload
          evedenceData={evedenceData}
          evidence={evidence}
          fileType="imageupload"
          handleCheckChangeDetailFill={handleCheckChangeDetailFill}
          entriesKey={entriesKey}
        />
      </div>
    );
  } else if (evedenceData?.evidenceType?.fieldTpe === "fileupload") {
    return (
      <div className="my-6 evidence-section" data-key={entriesKey + "-" + index}>
        <EvendenceImageUpload
          evedenceData={evedenceData}
          evidence={evidence}
          fileType="fileupload"
          handleCheckChangeDetailFill={handleCheckChangeDetailFill}
          entriesKey={entriesKey}
        />
      </div>
    );
  } else if (evedenceData?.evidenceType?.fieldTpe === "input") {
    return (
      <div className="my-6 evidence-section" data-key={entriesKey + "-" + index}>
        <EvidenceInput evedenceData={evedenceData} evidence={evidence} handleCheckChangeDetailFill={handleCheckChangeDetailFill} entriesKey={entriesKey} />
      </div>
    );
  } else if (evedenceData?.evidenceType?.fieldTpe === "textarea") {
    return (
      <div className="my-6 evidence-section" data-key={entriesKey + "-" + index}>
        <EvidenceTextArea evedenceData={evedenceData} evidence={evidence} handleCheckChangeDetailFill={handleCheckChangeDetailFill} entriesKey={entriesKey} />
      </div>
    );
  } else {
    return null;
  }
};

export default FilteredData;
