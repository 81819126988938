import React, { useEffect, useState } from "react";
import FilterEvidence from "src/components/molecules/FilterEvidence";
import { green } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Checkbox } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { AnimatePresence, motion } from "framer-motion";
import * as Actions from "src/store/actions/evidence";
import jsPDF from "jspdf";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import BackdropButton from "src/components/atoms/button/BackdropButton";
import { isMobile, isTablet, browserName } from "react-device-detect";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const isEvidenceComplete = (evidences, evidenceDetails) => {
  return evidences.every((evidence) => {
    const evidenceDetail = evidenceDetails[evidence.inventoryID.value];
    return evidenceDetail && evidence.evidenceType.fieldTpe === "checkbox"
      ? !!evidenceDetail === true
      : evidence.evidenceType.fieldTpe === "textarea"
      ? evidenceDetail?.length > 1
      : evidenceDetail?.length > 0;
  });
};

const EvidenceKey = ({ entriesKey, value, expanded, handleChange, id, markAllValidHandle, miniAids, checkDocument }) => {
  let description = value?.find((v) => v?.prerequisiteGroup?.value === entriesKey);
  const evidence = useSelector((state) => state.evidence);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const { id: UrlId, service } = useParams();

  const [isChecked, setIsChecked] = useState(false);
  const [showContent, setShowContent] = useState(expanded === id);
  const [otherPendingUploading, setOtherPendingUploading] = useState([]);

  useEffect(() => {
    const isChecked = isEvidenceComplete(value, evidence.evidenceDetail.data);
    setIsChecked(isChecked);
    isChecked && markAllValidHandle(entriesKey, isChecked);
  }, [evidence.evidenceDetail.data, checkDocument]);

  const handleCheckChangeDetailFill = () => {};

  useEffect(() => {
    markAllValidHandle(entriesKey, isChecked);
  }, [checkDocument]);

  const handleButtonClick = () => {
    if (isChecked) {
      const filterEvidence = evidence?.data?.filter((evi) => {
        if (evi?.prerequisiteGroup?.value === entriesKey) {
          if (evi?.evidenceType?.fieldTpe === "textarea" || evi?.evidenceType?.fieldTpe === "checkbox") return evi;
        }
      });
      if (filterEvidence?.length > 0) {
        let newEvidence = filterEvidence.map((evi) => {
          return evi?.inventoryID?.value;
        });
        setOtherPendingUploading(newEvidence);
        filterEvidence?.forEach((e) => generatePdf(e));
      } else {
        saveHandle();
        handleChange(id)();
      }
    } else {
      toast.error("Bitte füllen Sie die Details aus");
    }
  };

  useEffect(() => {
    if (expanded === id) {
      const contentElement = document.getElementById(`panel${id}-content`);
      if (contentElement) {
        contentElement.scrollTop = 0;
      }
    }
  }, [expanded, id]);

  const generatePdf = (evidence) => {
    if (evidence?.evidenceType?.fieldTpe === "textarea") {
      generateTextAreapdf(evidence);
    } else if (evidence?.evidenceType?.fieldTpe === "checkbox") {
      generateCheckpdf(evidence);
    }
  };

  useEffect(() => {
    if (otherPendingUploading) {
      if (otherPendingUploading?.length === 1 && otherPendingUploading.includes(evidence?.uploadEvidenceERP?.data?.evidence_id)) {
        saveHandle();
        handleChange(id)();
      }
    }
  }, [evidence?.uploadEvidenceERP?.data]);

  const generateTextAreapdf = (evedenceData) => {
    const pdf = new jsPDF();
    const longDescriptionHtml = evedenceData.longDescription.value;

    // Convert HTML to plain text
    const parser = new DOMParser();
    const doc = parser.parseFromString(longDescriptionHtml, "text/html");
    const longDescription = doc.body.textContent || "";

    pdf.setFillColor(163, 29, 26);
    pdf.setDrawColor(163, 29, 26);
    pdf.rect(10, 10, 5, 5, "F");

    pdf.setDrawColor(0, 0, 0);
    pdf.line(10, 10, 15, 15);
    pdf.line(15, 10, 10, 15);

    pdf.setFontSize(16);
    pdf.setFont("helvetica", "bold");
    pdf.text(longDescription, 20, 15, { maxWidth: 180 });

    pdf.setFontSize(12);
    pdf.setFont("helvetica", "normal");
    let textareaValue = evidence?.evidenceDetail?.data[evedenceData?.inventoryID?.value];
    pdf.text(textareaValue, 20, 30, { maxWidth: 180 });

    const blob = pdf.output("blob");
    const formData = new FormData();
    formData.append("file", blob, "evidence.pdf");

    const NewFileName = evedenceData?.inventoryID?.value + "-SON-" + evidence?.evidenceDetail?.details?.erp_service_order_id || UrlId;

    formData.append("file_name", NewFileName + "evidence.pdf");
    formData.append("user_id", Cookies.get("userId"));
    formData.append("subject", "Evidence-Inventory");
    formData.append("document_name", evedenceData?.inventoryID?.value + "-SON-" + evidence?.evidenceDetail?.details?.erp_service_order_id || UrlId);
    formData.append("service_order_number", evidence?.evidenceDetail?.details?.erp_service_order_id || UrlId);
    formData.append("evidence_id", evedenceData?.inventoryID?.value);
    formData.append("creator_user", profile.data.first_name + " " + profile.data.last_name);
    formData.append("procedure_id", evidence?.evidenceDetail?.details?.id);

    dispatch(
      Actions.uploadServerOrderEvidenceStarted({
        file: formData,
        evidence_id: evedenceData?.inventoryID?.value,
        service_order_types_id: evidence?.evidenceDetail?.details?.service_order_types_id || service,
        service_order_number: evidence?.evidenceDetail?.details?.erp_service_order_id || UrlId,
        creator_user: profile.data.first_name + " " + profile.data.last_name,
      })
    );
  };

  const generateCheckpdf = (evedenceData) => {
    const pdf = new jsPDF();
    const parseHtml = (html) => {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = html;

      const scripts = tempDiv.getElementsByTagName("script");
      const styles = tempDiv.getElementsByTagName("style");
      for (let script of scripts) script.remove();
      for (let style of styles) style.remove();

      return tempDiv.innerText.replace(/\n/g, ' ').trim();
    };

    const ValueDescription = parseHtml(evedenceData?.prerequisite?.ValueDescription || "");
    const wrappedValueDescription = pdf.splitTextToSize(ValueDescription, 180);
    let yPosition = 20;
    pdf.setDrawColor(72, 72, 72);
    pdf.rect(10, yPosition-3, 5, 5);

    pdf.setFillColor(163, 29, 26);
    pdf.rect(10, yPosition-3, 5, 5, "F");
    pdf.setTextColor(255, 255, 255);
    pdf.setFontSize(12);

    pdf.setTextColor(0, 0, 0);
    pdf.text(wrappedValueDescription, 17, yPosition);

    yPosition += wrappedValueDescription.length * 5 + 1;

    pdf.setFontSize(12);
    const longDescription = parseHtml(evedenceData?.longDescription?.value || "");
    const wrappedLongDescription = pdf.splitTextToSize(longDescription, 170);
    pdf.setDrawColor(72, 72, 72);
    pdf.rect(17, yPosition, 5, 5);

    pdf.setFillColor(163, 29, 26);
    pdf.rect(17, yPosition, 5, 5, "F");
    pdf.setTextColor(255, 255, 255);
    pdf.setFontSize(12);

    pdf.setTextColor(0, 0, 0);
    pdf.setFontSize(12);
    pdf.text(wrappedLongDescription, 24, yPosition + 5);
    yPosition += wrappedLongDescription.length * 10 + 1;

    const blob = pdf.output("blob");
    const formData = new FormData();
    formData.append("file", blob, "evidence.pdf");

    const NewFileName = evedenceData?.inventoryID?.value + "-SON-" + evidence?.evidenceDetail?.details?.erp_service_order_id || UrlId;

    // formData.append("file", e.target.files[0]);
    formData.append("file_name", NewFileName + "evidence.pdf");
    formData.append("user_id", Cookies.get("userId"));
    formData.append("subject", "Evidence-Inventory");
    formData.append("document_name", NewFileName);
    formData.append("service_order_number", evidence?.evidenceDetail?.details?.erp_service_order_id || UrlId);
    formData.append("evidence_id", evedenceData?.inventoryID?.value);
    formData.append("creator_user", profile.data.first_name + " " + profile.data.last_name);
    formData.append("procedure_id", evidence?.evidenceDetail?.details?.id);
    dispatch(
      Actions.uploadServerOrderEvidenceStarted({
        file: formData,
        evidence_id: evedenceData?.inventoryID?.value,
        service_order_types_id: evidence?.evidenceDetail?.details?.service_order_types_id || service,
        service_order_number: evidence?.evidenceDetail?.details?.erp_service_order_id || UrlId,
        creator_user: profile.data.first_name + " " + profile.data.last_name,
      })
    );
  };

  const saveHandle = () => {
    let device_type = "PC";
      if (isMobile && !isTablet) {
        device_type = "Smartphone";
      } else if (isTablet) {
        device_type = "Tablet";
      }
    dispatch(
      Actions.saveEvidenceDBStarted({
        procedure_id: evidence?.evidenceDetail?.details?.id,
        service_order_id: service,
        evidence_data: evidence?.evidenceDetail?.data,
        creator_user: profile.data.first_name + " " + profile.data.last_name,
        actionType: "db",
        device_type: device_type,
        browser: browserName,
      })
    );
  };

  return (
    <div key={entriesKey} className={miniAids ? "w-full md:w-[calc(100%-120px)]" : "w-full md:w-[calc(100%-320px)] lg:w-[calc(100%-320px)] xl:w-[calc(100%-550px)]"}>
      <h2 className="font-tahoma-bold text-xl py-4">{entriesKey}</h2>
      <div className="flex gap-1 lg:gap-4 mt-5">
        <div className="flex-1">
          <Accordion
          className="dark:bg-AFPDarkBgLight"
            sx={{
              borderRadius: "12px!important",
            }}
            expanded={expanded === id}
            onChange={handleChange(id)}
          >
            <AccordionSummary className="dark:bg-AFPDarkBgLight" expandIcon={<ExpandMoreIcon className="dark:text-BrightGray" />} aria-controls="panel1-content" id="panel1-header">
              <h3 className="font-tahoma-bold dark:text-BrightGray">{description?.prerequisite?.ValueDescription}</h3>
            </AccordionSummary>
            <AnimatePresence initial={false}>
              {(expanded === id || showContent) && (
                <motion.div
                  key="content"
                  initial="collapsed"
                  animate={expanded === id ? "open" : "collapsed"}
                  exit="collapsed"
                  variants={{
                    open: { opacity: 1, height: "auto", transition: { duration: 0.5, ease: "easeOut" } },
                    collapsed: { opacity: 0, height: 0, transition: { duration: 0.5, ease: "easeIn" } },
                  }}
                  onAnimationComplete={() => setShowContent(expanded === id)}
                >
                  {showContent && (
                    <AccordionDetails className="border-t border-[#D5D5D5] dark:border-SmokeyGray">
                      {value &&
                        value.map((evedenceData, i) => (
                          <motion.div
                            key={i}
                            variants={{
                              open: {
                                clipPath: "inset(0% 0% 0% 0% round 0px)",
                                transition: {
                                  type: "spring",
                                  bounce: 0,
                                  duration: 0.7,
                                  delayChildren: 0.3,
                                  staggerChildren: 0.05,
                                },
                              },
                              collapsed: {
                                clipPath: "inset(0% 0% 100% 0% round 0px)",
                                transition: {
                                  type: "spring",
                                  bounce: 0,
                                  duration: 0.3,
                                },
                              },
                            }}
                            transition={{ duration: 0.6, ease: "easeOut" }}
                            className=""
                          >
                            <FilterEvidence index={i} evedenceData={evedenceData} handleCheckChangeDetailFill={handleCheckChangeDetailFill} entriesKey={entriesKey} />
                          </motion.div>
                        ))}
                      <motion.div
                        variants={{ collapsed: { opacity: 0 }, open: { opacity: 1 } }}
                        transition={{ duration: 0.8, ease: "easeOut" }}
                        className="flex w-full justify-end"
                      >
                        <BackdropButton
                          title={cmsDashboarddata?.evidence_cms?.Evidence?.next_step}
                          btnStyle="max-w-[180px]"
                          handler={handleButtonClick}
                          loading={evidence?.uploadEvidenceERP?.isLoading}
                        />
                      </motion.div>
                    </AccordionDetails>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </Accordion>
        </div>
        <div>
          <Checkbox
            {...label}
            sx={{
              color: "#A31D1A",
              "&.Mui-checked": {
                color: green[600],
              },
              padding: 0,
            }}
            checked={isChecked}
            disabled={true}
          />
        </div>
      </div>
    </div>
  );
};

export default EvidenceKey;
