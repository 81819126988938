import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { uploadServerOrderEvidenceStarted } from "src/store/actions/evidence";
import { evidenceCheckDetail } from "src/helpers/dform/validateEvidence";
import LightBoxGallery from "./LightBoxGallery";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import EvidenceFileCard from "./EvidenceFileCard";
import MediaModal from "./MediaModal";
import { generateRandomString } from "src/helpers/evidence";
import { FaCirclePlus } from "react-icons/fa6";
import CustomCheckboxGreen from "src/components/atoms/input/CustomCheckbox/CustomCheckboxGreen";

function allowFileType(fileType, fileExtension) {
  if (fileType == "fileupload") {
    return fileExtension === "pdf" || fileExtension === "doc" || fileExtension === "docx" || fileExtension === "jpg" || fileExtension === "jpeg" || fileExtension === "png";
  } else if (fileType == "imageupload") {
    return fileExtension === "jpg" || fileExtension === "jpeg" || fileExtension === "png";
  }
  return false;
}

const EvendenceImageUpload = ({ evedenceData, evidence, fileType, handleCheckChangeDetailFill, entriesKey }) => {
  const [fileCheck, isFileCheck] = useState(evidence.evidenceDetail.data[evedenceData?.inventoryID?.value]?.length > 0 ? true : false);
  const inputRef = useRef();
  const [initialCheck, setInitialCheck] = useState("");
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [selectedFile, setSelectFile] = useState("");

  const [mediaOpen, setMediaOpen] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [cameraAccess, setCameraAccess] = useState(true);

  const { service, id } = useParams();
  const valuesById = evidence?.evidenceDetail?.data[evedenceData?.inventoryID?.value] ? evidence?.evidenceDetail?.data[evedenceData?.inventoryID?.value] : null;
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);

  const evidenceUploadHandler = (e, type = "upload") => {
    let file = "";
    let fileName = "";
    let fileExtension = "";
    let fileTypeCheck;

    let formData = new FormData();
    if (type == "upload") {
      file = e?.target?.files[0];
      fileName = file?.name;
      fileExtension = fileName?.split(".")?.pop()?.toLowerCase();
      fileTypeCheck = fileType == "fileupload" ? allowFileType(fileType, fileExtension) : fileType == "imageupload" ? allowFileType(fileType, fileExtension) : false;
      formData.append("file", file);
    } else {
      file = e;
      fileName = generateRandomString() + ".jpeg";
      fileExtension = "jpeg";
      fileTypeCheck =
        fileType == "fileupload"
          ? fileExtension === "pdf" || fileExtension === "doc" || fileExtension === "docx"
          : fileType == "imageupload"
          ? fileExtension === "jpg" || fileExtension === "jpeg" || fileExtension === "png"
          : false;
      formData.append("file", file, fileName);
      closeHandle();
    }

    if (fileTypeCheck) {
      const NewFileName = evedenceData?.inventoryID?.value + "-SON-" + evidence?.evidenceDetail?.details?.erp_service_order_id || id;
      formData.append("file_name", NewFileName + generateRandomString() + fileName);
      formData.append("user_id", Cookies.get("userId"));
      formData.append("subject", "Evidence-Inventory");
      formData.append("document_name", evedenceData?.inventoryID?.value + "-SON-" + evidence?.evidenceDetail?.details?.erp_service_order_id || id);
      formData.append("service_order_number", evidence?.evidenceDetail?.details?.erp_service_order_id || id);
      formData.append("evidence_id", evedenceData?.inventoryID?.value);
      formData.append("creator_user", profile?.data?.first_name + " " + profile?.data?.last_name);
      formData.append("procedure_id", evidence?.evidenceDetail?.details?.id);
      dispatch(
        uploadServerOrderEvidenceStarted({
          file: formData,
          evidence_id: evedenceData?.inventoryID?.value,
          service_order_types_id: evidence?.evidenceDetail?.details?.service_order_types_id || service,
          service_order_number: evidence?.evidenceDetail?.details?.erp_service_order_id || id,
          creator_user: profile.data.first_name + " " + profile.data.last_name,
          fileType: "file",
        })
      );
      isFileCheck(true);
      inputRef.current.value = null;
      if (evidence.evidenceDetail.data[evedenceData?.inventoryID?.value] && evidence.evidenceDetail.data[evedenceData?.inventoryID?.value]?.length > 0) {
        if (!initialCheck) {
          setInitialCheck("check");
        }
      } else {
        setInitialCheck("");
      }
    } else {
      toast.error(fileType == "fileupload" ? "Ungültiger Dateityp. Es werden nur PDF- und DOC-Dateien akzeptiert." : "Ungültiger Dateityp. Es werden nur Bilder akzeptiert.");
    }
  };

  const imgList = evidence?.evidenceDetail?.data[evedenceData?.inventoryID?.value];

  const fileCheckhandle = (event) => {
    let checkedValue = event.target.checked;
    if (evidence.evidenceDetail.data[evedenceData?.inventoryID?.value]?.length > 0) {
      isFileCheck(checkedValue);
    } else {
      toast.error("Datei aussuchen");
    }
  };

  useEffect(() => {
    if (!(evidence.evidenceDetail.data[evedenceData?.inventoryID?.value]?.length > 0)) {
      isFileCheck(false);
    }
  }, [evidence.evidenceDetail.data]);

  useEffect(() => {
    let newObj = {};
    let markObj = {};
    evidence?.data?.forEach((ab) => {
      ab?.prerequisiteGroup?.value;
      if (newObj[ab?.prerequisiteGroup?.value]) {
        newObj[ab?.prerequisiteGroup?.value]?.push(ab);
      } else {
        newObj[ab?.prerequisiteGroup?.value] = [ab];
        markObj[ab?.prerequisiteGroup?.value] = false;
      }
    });
    const isCheckUpdated = evidenceCheckDetail(newObj[entriesKey], evidence);
    if (isCheckUpdated) {
      handleCheckChangeDetailFill(entriesKey, true);
    } else {
      handleCheckChangeDetailFill(entriesKey, false);
    }
  }, [initialCheck]);

  const initiatePdfPreview = (url, type) => {
    setIsLightboxOpen(true);
    setSelectFile({ url, type });
  };

  const uploadHandle = () => {
    if (fileType == "imageupload") {
      setMediaOpen(true);
      setCameraAccess(true);
      setShowCamera(false);
      setUploadedImage(null);
    } else {
      inputRef.current.click();
    }
  };

  const closeHandle = () => {
    setMediaOpen(false);
  };

  return (
    <>
      <div className=" w-full mb-5 flex justify-between flex-nowrap" id={evedenceData?.inventoryID?.value}>
        <div className="flex gap-x-2 items-start justify-between mr-2 w-full">
          <div className="m-0 p-0">
            <div className="p-0 m-0 font-bold dark:text-BrightGray" dangerouslySetInnerHTML={{ __html: evedenceData?.prerequisite?.ValueDescription }}></div>
            <div className="text-DarkCharcoal text-[14px] my-2 dark:text-BrightGray" dangerouslySetInnerHTML={{ __html: evedenceData?.longDescription?.value }}></div>
          </div>
          {fileCheck && <CustomCheckboxGreen isChecked={fileCheck} handle={fileCheckhandle} />}
        </div>
        <div className=" flex gap-1 items-start text-[16px] min-w-[88px] dark:text-BrightGray">
          {valuesById ? valuesById?.length + " Datei" : ""} <MdOutlineKeyboardArrowRight fontSize={20} />
        </div>
      </div>
      <div className="flex items-start flex-col-reverse gap-4">
        {valuesById &&
          valuesById?.map((data, index) => {
            return <EvidenceFileCard index={index} data={data} fileType={fileType} evidence={evidence} initiatePdfPreview={initiatePdfPreview} />;
          })}

        <div className="w-full flex justify-center items-center border rounded-md text-[#D5D5D5] overflow-hidden dark:border-SmokeyGray">
          {evidence?.uploadEvidenceERP?.isLoading && evedenceData?.inventoryID?.value == evidence?.uploadEvidenceERP?.file_id ? null : (
            <div
              className="bg-white w-full h-full cursor-pointer m-auto text-center p-2 dark:bg-AFPDarkBgLight"
              onClick={() => {
                uploadHandle();
              }}
            >
              <div className="bg-white flex-col cursor-pointer flex justify-center items-center text-gray-300 hover:text-AFPPrimary dark:bg-AFPDarkBgLight dark:text-BrightGray">
                <FaCirclePlus className="text-3xl" />
                <p className="">Datei wählen</p>
              </div>
            </div>
          )}
          <input
            type="file"
            ref={inputRef}
            onChange={evidenceUploadHandler}
            className="hidden"
            accept={fileType == "imageupload" ? ".jpg,.jpeg,.png" : fileType == "fileupload" ? ".pdf,.doc,.docx,.jpg,.jpeg,.png" : "*"}
          />
        </div>
      </div>
      {!fileCheck ? <span className="text-red-400 text-sm">Pflichtfeld</span> : null}
      <LightBoxGallery images={imgList} isOpen={isLightboxOpen} onClose={() => setIsLightboxOpen(false)} selectedFile={selectedFile} />
      {mediaOpen && (
        <MediaModal
          open={mediaOpen}
          closeHandle={closeHandle}
          uploadedImage={uploadedImage}
          setUploadedImage={setUploadedImage}
          showCamera={showCamera}
          setShowCamera={setShowCamera}
          cameraAccess={cameraAccess}
          setCameraAccess={setCameraAccess}
          evidenceUploadHandler={evidenceUploadHandler}
          fileType={fileType}
        />
      )}
    </>
  );
};

export default EvendenceImageUpload;
