/* eslint-disable */
import { Checkbox } from "@mui/material";
import { useFormik } from "formik";
import { InputStandardField } from "src/components/atoms/input";
import FormikSearchDropDown from "src/components/atoms/Search/FormikSearchDropDown";
import RegisterGrid from "src/components/molecules/RegisterGrid";
import { locationLegalOptions } from "src/mockData/businessPremises";
import { green } from "@mui/material/colors";
import { initialValuesRegisterTab3, validationSchemaRegisterTab3 } from "src/services/validation/Auth";
import { SecondaryButton, SubmitButton } from "src/components/atoms/button";
import { GrPrevious } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "src/store/actions/users";
import InputStandardFieldArray from "src/components/atoms/input/InputStandardField/InputStandardFieldArray";
import { CgCloseR } from "react-icons/cg";
import CircularButton from "src/components/atoms/button/CircularButton";

const heading = "Registrierung";
const subHeading = "Bitte erstellen Sie einen neuen Account";
const personalData = "Name der Betriebsstätte:";
const personalData2 = "Adresse:";
const personalData3 = "Standortinformationen:";

const ThirdRegisterTab = ({ activeTab, handleBack, handleNext }) => {
  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: initialValuesRegisterTab3,
    validationSchema: validationSchemaRegisterTab3,
    onSubmit: (values) => {
      let registerTab = JSON.parse(localStorage.getItem("register_tabs"));
      let payload = {
        ...registerTab[0]?.data,
        ...registerTab[1]?.data,
        ...values,
      };
      let { repeat, confirm_email, ...rest } = payload;
      handleNext(values, activeTab, false);
      dispatch(registerUser({ ...rest, base_url: window?.location?.origin }));
    },
  });

  const ikNumberRemoveHandle = (index) => {
    const updatedIkNumbers = [...formik.values.ik_number];
    updatedIkNumbers.splice(index, 1);
    formik.setFieldValue("ik_number", updatedIkNumbers);
  };

  const editNumberToggler = () => {
    formik.setFieldValue("ik_number", [...formik.values.ik_number, ""]);
  };

  return (
    <>
      <h3 className="registerHeading mt-3">{heading}</h3>
      <h4 className="text-center text-lg text-SmokeyGray mt-1 mb-6 dark:text-BrightGray">{subHeading}</h4>
      <hr className="dark:border-SmokeyGray" />
      <form onSubmit={formik.handleSubmit}>
        <RegisterGrid heading={personalData}>
          <InputStandardField extra="!mb-5" placeholder={"Name der Betriebsstätte"} id="company_name" formik={formik} />
        </RegisterGrid>

        <RegisterGrid heading={"IK-Nummer:"}>
          {formik.values.ik_number.map((number, index) => {
            return (
              <div className={`${formik.values.ik_number.length > 1 && "ik_number_container"}`} key={index}>
                <InputStandardFieldArray placeholder={"IK-Nummer"} type="number" id={`ik_number`} idIndex={index} formik={formik} />
                {true && (
                  <>
                    {formik.values.ik_number.length > 1 ? (
                      <CgCloseR
                        className="cursor-pointer text-red-400 mt-4"
                        onClick={() => {
                          ikNumberRemoveHandle(index);
                        }}
                      />
                    ) : (
                      <div></div>
                    )}
                  </>
                )}
              </div>
            );
          })}
          {true && (
            <div className="flex gap-x-3 items-center">
              <CircularButton title={"neu anlegen"} handler={editNumberToggler} css="!justify-start" />
            </div>
          )}
        </RegisterGrid>

        <RegisterGrid heading={personalData3}>
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
            <div className="flex justify-start items-center">
              <Checkbox
                className="!cursor-pointer"
                sx={{
                  color: "#484848",
                  "&.Mui-checked": {
                    color: green[600],
                  },
                }}
                checked={formik.values["is_main_location"]}
                onChange={(e) => formik.setFieldValue("is_main_location", e.target.checked)}
              />
              <div className="text-sm font-tahoma-bold text-black dark:text-BrightGray font-medium w-full">{"Ist Hauptbetriebsstätte"}</div>
            </div>
            <div className="flex justify-start items-center">
              <Checkbox
                className="!cursor-pointer"
                sx={{
                  color: "#484848",
                  "&.Mui-checked": {
                    color: green[600],
                  },
                }}
                checked={formik.values["is_store_location"]}
                onChange={(e) => formik.setFieldValue("is_store_location", e.target.checked)}
              />
              <div className="text-sm font-tahoma-bold text-black dark:text-BrightGray font-medium w-full">{"Ist Geschäftslokal"}</div>
            </div>
          </div>
          <div className={`flex items-start flex-col gap-1 mt-3`}>
            <label htmlFor="legal_form" className={`text-sm font-tahoma-bold text-black dark:text-BrightGray ml-1.5 font-medium w-full`}>
              {"Rechtsform"}
            </label>
            <FormikSearchDropDown id="legal_form" optionsList={locationLegalOptions} formik={formik} />
          </div>
        </RegisterGrid>
        <RegisterGrid heading={personalData2} css={"!mt-8"}>
          <InputStandardField placeholder={"Straße"} label={"Straße"} extra="md:!flex-row !mb-5" id="street" formik={formik} />
          <InputStandardField placeholder={"Hausnummer"} type="number" label={"Hausnummer"} extra="md:!flex-row !mb-5" id="house_number" formik={formik} />
          <InputStandardField placeholder={"Stadt"} label={"Stadt"} extra="md:!flex-row !mb-5" id="city" formik={formik} />
          <InputStandardField placeholder={"PLZ"} label={"PLZ"} type="number" extra="md:!flex-row !mb-5" id="post_code" formik={formik} />
        </RegisterGrid>

        <div className="flex justify-center mt-16 gap-8">
          <SecondaryButton handler={handleBack} variant={`${activeTab === 0 ? "disabled" : "secondary"}`} css="w-[150px]" title={"Zurück"} icon={<GrPrevious />} />
          <SubmitButton css="flexCenter !w-[150px]" type="submit" btnTitle="Registrieren" btnCss="max-w-60 !py-2 !rounded-xl" loading={users.isLoading} />
        </div>
      </form>
    </>
  );
};

export default ThirdRegisterTab;
