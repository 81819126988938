import React, { useEffect, useState } from "react";
import SecondaryButton from "src/components/atoms/button/SecondaryButton";
import { useSelector } from "react-redux";

const NachweiseDashboard = ({ title, nachweiseHandle }) => {
  const [statusNachweise, setStatusNachweise] = useState({
    status: false,
    data: null,
  });
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  useEffect(() => {
    if (cmsDashboarddata?.dashboard?.next && cmsDashboarddata?.dashboard?.next?.submit_evidence_data) {
      if (cmsDashboarddata?.dashboard?.next?.submit_evidence_data?.length > 0) {
        setStatusNachweise({ status: true, data: cmsDashboarddata?.dashboard?.next?.submit_evidence_data[0] });
      }
    }
  }, []);
  return (
    <SecondaryButton
      variant={statusNachweise?.status === false ? "disabled" : "secondary"}
      title={title}
      css={statusNachweise?.status === false ? "px-2" : "font-tahoma font-semibold px-2"}
      handler={() => {
        statusNachweise?.status && nachweiseHandle(statusNachweise?.data);
      }}
    />
  );
};

export default NachweiseDashboard;
