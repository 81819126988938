import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {Stack, Stepper, Step, StepLabel} from "@mui/material";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { TbUserHexagon } from "react-icons/tb";
import { LuBookOpenCheck } from "react-icons/lu";
import { TbBuildingEstate } from "react-icons/tb";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "linear-gradient( 95deg,rgb(0,128,0) 0%,rgb(0,128,0) 50%,rgb(0,128,0) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage: "linear-gradient( 95deg,rgb(0,128,0) 0%,rgb(0,128,0) 50%,rgb(0,128,0) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme }) => ({
  backgroundColor: "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...theme.applyStyles("dark", {
    backgroundColor: theme.palette.grey[700],
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        backgroundColor: "#1c9d5b",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
      },
    },
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        backgroundColor: "#1c9d5b",
      },
    },
  ],
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <TbUserHexagon />,
    2: <LuBookOpenCheck />,
    3: <TbBuildingEstate />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,

  completed: PropTypes.bool,

  icon: PropTypes.node,
};

const steps = ["User Daten", "Rechtliches", "Betriebsstätte"];

export default function RegisterStepper({activeStep, isDark=false}) {
  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon} sx={{
              '& .MuiStepLabel-label': {
                color: isDark ? "#ebebeb": "#333333", 
              },
              '& .MuiStepLabel-label.Mui-active': {
                color: isDark ? "#ebebeb": "#333333", 
              },
              '& .MuiStepLabel-label.Mui-completed': {
                color: isDark ? "#ebebeb": "#333333", 
              }
            }}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
