import { routeController } from "src/services/utils/Utility";
import { Routes, Route } from "react-router-dom";
import routes, { authRoutes } from "./Routes";
import Sidebar from "src/components/organisms/Sidebar";
import Header from "src/components/organisms/Header";
import Footer from "src/components/organisms/Footer";
import CookieStorage from "src/services/storage/cookiesStorage";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import ChatBoat from "src/components/atoms/ChatBoat";
import User from "src/components/template/protected/User";
import Admin from "src/components/template/protected/Admin";
import { resetApplicationSidebar, setApplicationSidebar } from "src/store/actions/application";
import DHeader from "src/components/organisms/Header/DHeader";

const AppNavigator = () => {
  const dispatch = useDispatch()
  const [isLogIn, setLogIn] = useState(false);
  const users = useSelector((state) => state.users);
  const cookie = new CookieStorage();
  const application = useSelector((state)=> state.application)

  useEffect(() => {
    if (cookie.getLogin()) {
      setLogIn(true);
    }
  }, [users]);

  useEffect(()=>{
    if(application?.sidebar){
      if(!window.location.href.includes('dform')){
        dispatch(resetApplicationSidebar())
      }
    } else {
      if(window.location.href.includes('dform')){
        dispatch(setApplicationSidebar())
      }
    }
  },[])

  return (
    <div className="font-tahoma text-SmokeyGray dark:text-BrightGray bg-white dark:bg-AFPDarkBgDarkest">
      {(isLogIn || cookie.getLogin()) && (
        <>
          {!application.sidebar && <Sidebar />}
          {!application.sidebar ? <Header /> : <DHeader />}
        </>
      )}
      <Routes>
        {authRoutes.map(({ id, path, component, protectedRoute }) => (
          <Route exact key={id} path={path} element={routeController(component, protectedRoute)} />
        ))}
        {routes.map(({ id, path, component, protectedRoute }) => (
          <React.Fragment key={id}>
            <Route exact path={"/admin" + path} element={routeController(<Admin>{component}</Admin>, protectedRoute)} />
            <Route exact path={path} element={routeController(<User>{component}</User>, protectedRoute)} />
          </React.Fragment>
        ))}
      </Routes>

      {(isLogIn || cookie.getLogin()) && <ChatBoat />}

      {(isLogIn || cookie.getLogin()) && <Footer style={!application.sidebar ? "margin" : "none"} />}
    </div>
  );
};

export default AppNavigator;
