/*eslint-disable*/
import { SecondaryButton } from "src/components/atoms/button";
import Vector from "src/assets/svg/Vector.svg";
import VectorDark from "src/assets/svg/VectorDark.svg";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useSelector } from "react-redux";
import BackdropButton from "src/components/atoms/button/BackdropButton";

const StepFooterButtons = ({ activeStep, saveHandle, prevStepHandle, nextStepHandle, disable = false }) => {
  const application = useSelector((state) => state.application);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const dform = useSelector((state) => state.dform);
  const { addingStep } = application;
  return (
    <>
      <div className="w-full">
        {dform?.data?.length - 1 !== activeStep ? (
          <div className={`${activeStep === 3 ? "pt-5" : "pt-40"} mb-20`}>
            <div className="flex flex-col md:flex-row items-center md:items-start gap-4 justify-center md:justify-start mt-5 mb-12">
              {activeStep !== 0 &&
                (application?.sendEmail?.isLoading || application?.createdApplication?.isLoading || application?.addingStep?.isLoading ? (
                  <SecondaryButton
                    icon={<><img src={Vector} alt="Vector" className="dark:hidden" /><img src={VectorDark} alt="Vector" className="hidden dark:inline" /></>}
                    variant="disabled"
                    title={cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.save_exit}
                    css="w-full md:w-64 h-12 border-black text-black font-tahoma-bold dark:text-BrightGray dark:border-SmokeyGray dark:bg-AFPDarkBgLight dark:hover:border-AFPPrimary"
                  />
                ) : (
                  <SecondaryButton
                    icon={<><img src={Vector} alt="Vector" className="dark:hidden" /><img src={VectorDark} alt="Vector" className="hidden dark:inline" /></>}
                    variant={addingStep.isLoading ? "variant" : "secondary"}
                    title={cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.save_exit}
                    handler={saveHandle}
                    css="w-full md:w-64 h-12 border-black text-black font-tahoma-bold dark:text-BrightGray dark:border-SmokeyGray dark:bg-AFPDarkBgLight dark:hover:border-AFPPrimary"
                  />
                ))}

              {application?.sendEmail?.isLoading || application?.createdApplication?.isLoading || application?.addingStep?.isLoading || application?.dformStatus?.data?.data?.status === "completed" || application?.dformStatus?.data?.data?.status === "signatures-completed" ? (
                <SecondaryButton
                  icon={<IoIosArrowRoundBack className="text-gray-300 h-7 w-7 cursor-not-allowed" />}
                  variant="disabled"
                  title={
                    activeStep === 0
                      ? cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.cancel
                      : cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.step_back
                  }
                  css={activeStep !== 0 ? "w-full md:w-[180px] md:ml-9 mt-4 md:mt-0" : "w-full md:w-[180px]"}
                />
              ) : (
                <SecondaryButton
                  icon={<IoIosArrowRoundBack className=" cursor-pointer  h-7 w-7" />}
                  variant={addingStep.isLoading ? "variant" : "secondary"}
                  title={
                    activeStep === 0
                      ? cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.cancel
                      : cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.step_back
                  }
                  css={activeStep !== 0 ? "w-full md:w-[180px] md:ml-9 mt-4 md:mt-0" : "w-full md:w-[180px]"}
                  handler={prevStepHandle}
                />
              )}
              {dform.data.length - 2 !== activeStep &&
                (disable ? (
                  <SecondaryButton variant="disabled" title={cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.next_step} css="w-full md:max-w-[180px] h-12" />
                ) : (
                  <BackdropButton
                    title={cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.next_step}
                    btnStyle="w-full md:max-w-[180px] h-12"
                    handler={nextStepHandle}
                    loading={addingStep.isLoading}
                  />
                ))}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default StepFooterButtons;
