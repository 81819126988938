import {
  INITIALVALUESRESET,
  VALIDATIONSCHEMARESET,
} from "src/services/validation/users";
import { useFormik } from "formik";
import { PasswordField } from "src/components/atoms/input";
import { SubmitButton } from "src/components/atoms/button";
import { useParams } from "react-router-dom";
import { authReset, resetUser } from "src/store/actions/users";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const ResetForm = ({isDarkMode}) => {
  const users = useSelector((state)=>state.users)
  const { hash } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const formik = useFormik({
    initialValues: INITIALVALUESRESET,
    validationSchema: VALIDATIONSCHEMARESET,
    onSubmit: (values) => {
      dispatch(resetUser({token:hash, password: values.password}))
    },
  });

  useEffect(()=>{
    if(users.userReset){
      navigate('/login')
      dispatch(authReset({}))
    }
  },[users])

  return (
    <form className="p-4 2xl:pt-20" onSubmit={formik.handleSubmit}>
      <div className="flex flex-col gap-8">
        <PasswordField
          name="password"
          css="!font-tahoma-bold"
          label="Passwort"
          formik={formik}
          isDark={isDarkMode}
        />
        <PasswordField
          name="confirmpassword"
          css="!font-tahoma-bold"
          label="Bestätigen Sie Passwort"
          formik={formik}
          isDark={isDarkMode}
        />
      </div>
      <SubmitButton
        css="flexCenter mt-4 2xl:mt-16"
        type="submit"
        btnTitle="Einreichen"
        loading={false}
        
      />
    </form>
  );
};

export default ResetForm;
