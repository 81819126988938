import React from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import ContentLayout from "src/components/atoms/common/ContentLayout";
import Breadcrumbs from "src/components/molecules/Breadcrumbs";
import { inboxBreadcrumb } from "src/components/molecules/Breadcrumbs/BreadcrumbsList";
import MessageModal from "src/components/organisms/cms/MessageModal";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import TableContainerDark from "src/components/atoms/table/TableContainerDark";

const Rechtliches = () => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const { isModalOpen, modalData, sidebar_menu } = cmsDashboarddata;
  return (
    <ContentLayout>
      <div className="text-black relative md:pt-[4.5rem] pb-0 py-8 min-h-screen-minus-3 bg-AFPBGPrimary dark:bg-AFPDarkBgDarkest dark:text-white">
        {sidebar_menu?.sidebar_list?.legal && <Breadcrumbs data={inboxBreadcrumb(sidebar_menu?.sidebar_list?.legal)} />}
        {cmsDashboarddata.listLoaded && (
          <div class="bg-white p-4 dark:bg-AFPDarkBgDarkest">
            <RechtlichesTable />
          </div>
        )}
      </div>
      {isModalOpen && <MessageModal open={isModalOpen} cmsData={modalData} />}
    </ContentLayout>
  );
};

export const RechtlichesTable = () => {
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);

  const checkboxes = ["protection", "condition", "certificate", "declaration"].map((item) => {
    return {
      title: cmsDashboarddata?.legal?.[item]?.title,
      unique: item,
    };
  });

  return (
    <>
      <TableContainerDark>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow className="bg-AFPLightGray dark:bg-AFPDarkBgLight">
              {["Seriennummer", "Titel", "Aktionen"]?.map((item, index) => {
                return ["Seriennummer", "Titel"].length - 1 === index ? <TableCell align={""}>{item}</TableCell> : <TableCell>{item}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {checkboxes?.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCells index={index} data={item} />
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainerDark>
    </>
  );
};

export const TableCells = ({ index, data }) => {
  const dispatch = useDispatch();
  return (
    <>
      <TableCell>{index+1}</TableCell>
      <TableCell align={""}>{data?.title}</TableCell>
      <TableCell>
        <EditIconCMS
          handleOpen={() => {
            dispatch(
              dashboardModalOpenCMS({
                cms_key: "legal",
                unique: data?.unique,
                heading: "Benutzer Analyse",
              })
            );
          }}
        />
      </TableCell>
    </>
  );
};

export default Rechtliches;
