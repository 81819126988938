import { THEME_USER_START } from "../actionTypes";

const savedTheme = localStorage.getItem("hs_theme");
const initialState = {
  isLoading: false,
  darkmode: savedTheme === "dark" || (!savedTheme && window.matchMedia("(prefers-color-scheme: dark)").matches),
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case THEME_USER_START: {
      return { ...state, darkmode: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
