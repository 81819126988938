import React from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import { HiMiniDocumentArrowDown } from "react-icons/hi2";
import { formatDate } from "src/helpers/document";
import { API, BASE_URL } from "src/services/utils/defines";
import Cookies from "js-cookie";

const UserDocTableBody = ({ bodyContent }) => {
  const downloadNewHandler = (url) => {
    const link = window.document.createElement("a");
    link.href = `${BASE_URL}${API?.DOWNLOAD_DMS_DOCUMENT}?user_id=${Cookies.get("userId")}&document_id=${url}`;
    link.download = "document";
    link.style.display = "none";
    window.document.body.appendChild(link);
    window.open(link.href, "_blank");
    setTimeout(() => {
      window.document.body.removeChild(link);
    }, 100);
  };

  return (
    <TableBody>
      {bodyContent.map((item, index) => {
        return (
          <TableRow
            key={index}
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              td: {
                borderBottom: "none",
                "&:first-of-type": {},
              },
              th: {
                borderBottom: "none",
              },
              background: index % 2 === 0 ? "#FBFBFB" : "inherit",
            }}
          >
            <TableCell>{item.property_filename}</TableCell>
            <TableCell>{item.category}</TableCell>

            <TableCell>
              <span className={`${"text-AFPHeading dark:text-BrightGray"}`}>{formatDate(item.date)}</span>
            </TableCell>
            <TableCell align="left">
              <div className="flex text-OxfordBlue justify-center gap-2">
                <HiMiniDocumentArrowDown
                  className="text-2xl cursor-pointer text-AFPPrimary dark:text-AFPPrimaryLight"
                  onClick={() => {
                    downloadNewHandler(item?.id);
                  }}
                />
              </div>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default UserDocTableBody;
