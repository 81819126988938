import { useFormik } from "formik";
import { Box, Modal } from "@mui/material";
import { SecondaryButton } from "src/components/atoms/button";
import { VALIDATIONSCHEMALEADERS, LEADERSINITIALVALUSE } from "src/services/validation/buinessPremises";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIconCMS from "src/components/atoms/button/EditIconCMS";
import { dashboardModalOpenCMS } from "src/store/actions/cmsDashboard";
import NewUserForm from "./user/NewUserForm";
import ExistingUserForm from "./user/ExistingUserForm";
import { AiOutlineClose } from "react-icons/ai";
import { modalStyleStandard } from "../modalStyle";

const AddUserForm = ({ handleClose, handleOpen, addLeaderHandler, initialValues, isLoading = false }) => {
  const [NewInhablerSelect, setNewInhaberSelect] = useState({
    new: null,
    exiting: null,
  });
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const business = useSelector((state) => state.management);
  const cmsData = cmsDashboarddata?.business_premises?.new_professional_group_label;

  let groups = initialValues
    ? JSON.parse(initialValues.professional_groups).map((leader) => {
        return { id: leader };
      })
    : [];
  const formik = useFormik({
    initialValues: initialValues !== null ? { ...initialValues, selectedPersonOption: groups } : LEADERSINITIALVALUSE,
    validationSchema: VALIDATIONSCHEMALEADERS,
    onSubmit: (values) => {
      let { selectedPersonOption, ...rest } = values; // eslint-disable-next-line no-console
      addLeaderHandler({ ...rest, status: initialValues === null ? "add" : "edit", professional_groups: selectedPersonOption?.length > 0 ? selectedPersonOption : [] });
      handleClose();
      formik.resetForm();
    },
  });

  const dispatch = useDispatch();

  const selectHandle = (values) => {
    setNewInhaberSelect(values);
  };

  useEffect(() => {
    if (initialValues !== null) {
      setNewInhaberSelect({
        new: "selected",
        exiting: null,
      });
    }
  }, []);

  return (
    <Modal open={handleOpen} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" className="!overflow-y-auto !h-auto ">
      <Box sx={modalStyleStandard} className="outline-none bg-AFPBGPrimary dark:bg-AFPDarkBgDarkest dark:border-SmokeyGray">
        <div className={`max-h-[90vh] overflow-y-auto overflow-x-hidden customScrollBar w-full p-1`}>
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-xl md:text-2xl font-tahoma-bold flex items-center gap-x-3 dark:text-BrightGray">
              {cmsData?.heading}
              <EditIconCMS
                handleOpen={() => {
                  dispatch(
                    dashboardModalOpenCMS({
                      cms_key: "business_premises",
                      unique: "new_professional_group_label",
                      heading: "Dokumentenverwaltung",
                    })
                  );
                }}
              />
            </h2>
            <AiOutlineClose size={24} className="text-SmokeyGray cursor-pointer -mt-2 dark:text-BrightGray" onClick={handleClose} />
          </div>
          {NewInhablerSelect?.new === null && NewInhablerSelect?.exiting === null && (
            <>
              {isLoading !== true && (
                business?.contactList?.data?.filter((data) => data?.contact_class !== "INHABER")?.length>0 && <SecondaryButton
                  variant="secondary"
                  title="Vorhandener Fachliche Leiter"
                  css="w-full my-8"
                  handler={() => {
                    selectHandle({
                      new: null,
                      exiting: "selected",
                    });
                  }}
                />
              )}
              <SecondaryButton
                variant="secondary"
                title="neu Fachliche Leiter"
                css="w-full my-8"
                handler={() => {
                  selectHandle({
                    new: "selected",
                    exiting: null,
                  });
                }}
              />
            </>
          )}
          {NewInhablerSelect.new && <NewUserForm formik={formik} handleClose={handleClose} />}
          {NewInhablerSelect.exiting && (
            <ExistingUserForm
              addLeaderHandler={addLeaderHandler}
              handleClose={handleClose}
              userList={business?.contactList?.data?.filter((data) => data.contact_class !== "INHABER")}
            />
          )}
        </div>{" "}
      </Box>
    </Modal>
  );
};

export default AddUserForm;
