import { Box, Modal } from "@mui/material";
import React, { useRef, useState } from "react";
import { WiCloudUp } from "react-icons/wi";
import { AiOutlineClose } from "react-icons/ai";
import CameraEvidence from "src/components/atoms/modal/AddDocumentModal/CameraEvidence";
import { PrimaryButton } from "src/components/atoms/button";
import styles from "./mediamodal.module.css";
import { IoClose } from "react-icons/io5";

const style = {
  p: 2,
  left: "50%",
  width: "90%",
  maxWidth: "840px",
  overflowY: "auto",
  top: "50%",
  borderRadius: "12px",
  position: "relative",
  border: "1px solid #CBCBCB",
  transform: "translate(-50%, -50%)",
};

const MediaModal = ({
  open,
  closeHandle,
  uploadedImage,
  setUploadedImage,
  showCamera,
  setShowCamera,
  cameraAccess,
  setCameraAccess,
  evidenceUploadHandler,
  fileType
}) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const inputRef2 = useRef();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setUploadedFile(file);
      setUploadedFiles(event)
    }
  };

  const handleRemoveFile = () => {
    setUploadedFile(null);
    inputRef2.current.value = ''; // Clear the input field
  };
  return (
    <Modal keepMounted open={open} className={styles.customModalRoot}>
      <Box sx={style} className={`${styles.customInnerChild} bg-AFPBGPrimary dark:bg-AFPDarkBgDarkest dark:border-SmokeyGray`}>
        <input
          type="file"
          ref={inputRef2}
          onChange={handleFileChange}
          className="hidden"
          accept={fileType == "imageupload" ? ".jpg,.jpeg,.png" : fileType == "fileupload" ? ".pdf,.doc,.docx,.jpg,.jpeg,.png" : "*"}
        />
        <div className="relative">
          <div className="flex justify-between items-center mb-6 md:px-2 min-h-12">
            <h2 className="text-xl md:text-2xl font-tahoma-bold flex items-center gap-x-3"></h2>
            <AiOutlineClose
              size={24}
              className="text-SmokeyGray cursor-pointer -mt-5 dark:text-BrightGray"
              onClick={closeHandle}
            />
          </div>
          <hr className="mt-2 mb-9 dark:border-SmokeyGray" />
        </div>
        <div className="flex flex-col gap-6 mb-12">
          <CameraEvidence
            uploadedImage={uploadedImage}
            setUploadedImage={setUploadedImage}
            showCamera={showCamera}
            setShowCamera={setShowCamera}
            cameraAccess={cameraAccess}
            setCameraAccess={setCameraAccess}
          />
          {!uploadedImage && cameraAccess && (
            <h4 className="text-sm text-center">Oder</h4>
          )}
          {!uploadedImage && !uploadedFile && (
              <div className="min-h-20 border border-dashed border-black rounded-lg bg-white flex justify-center items-center cursor-pointer dark:bg-AFPDarkBgLight" onClick={()=>{inputRef2.current.click();}}>
              <WiCloudUp className="text-5xl text-AFPLightGray" />
            </div>
          )}
        
          {uploadedFile && (
            <>
            <div className="min-h-20 border border-solid border-black rounded-lg bg-white flex justify-between items-center p-4 dark:bg-AFPDarkBgLight dark:border-SmokeyGray">
              <span className="text-gray-700 dark:text-BrightGray">{uploadedFile.name}</span>
              <IoClose className="text-xl cursor-pointer text-red-500 dark:text-red-400" onClick={handleRemoveFile} />
            </div>
            <div className="mx-auto w-40">
              <PrimaryButton title="Speichern" type="submit" handler={()=>{evidenceUploadHandler(uploadedFiles);setTimeout(()=>{closeHandle()},400)}}/>
              </div>
            </>
          )}

          {uploadedImage && cameraAccess && (
            <PrimaryButton title="Speichern" type="submit" handler={()=>evidenceUploadHandler(uploadedImage, 'base64')}/>
          )}
        </div>
      </Box>
    </Modal>
  );
};
export default MediaModal;
