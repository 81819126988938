/* eslint-disable */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addCurrentStepDetailValue } from "src/store/actions/currentStep";
import { getServiceAreaDetailStarted, resetServiceAreaDetail } from "src/store/actions/dform";

const PrefiledArea = ({ data, setMarkPrefiledArea }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const currentStep = useSelector((state) => state.currentStep);
  const application = useSelector((state) => state.application);
  const dform = useSelector((state) => state.dform);
  const serviceAreaDetail = JSON.parse(
    localStorage.getItem("locationValidation")
  );
  const dispatchPreFillService = () => {
    setMarkPrefiledArea('')
    let selectedService = serviceAreaDetail?.exsiting_service_areas?.length>0 && serviceAreaDetail?.exsiting_service_areas[0];
    if (dform?.service_area_detail?.data.detailType == "prefiledArea") {
      let { detailType, ...rest } = dform?.service_area_detail?.data;
      let newTech =[]
      if (dform?.service_area_detail?.data?.technicalLeaders?.length > 0) {
        newTech = serviceAreaDetail?.exsiting_team_leaders?.map((teamId)=>{
            let find = dform?.service_area_detail?.data?.technicalLeaders?.find((leader)=> leader?.id == teamId)
            if(find){
              return { name: find?.name, id: find?.id };
            }
        })
      }
      dispatch(
        addCurrentStepDetailValue({
          valueId: data?.id,
          value: [
            ...currentStep?.data[0]?.inputTypeValue,
            {
              selectedTechnicalLeaders: newTech,
              selectedArea: selectedService?.service_area,
              ...rest,
            },
          ],
          valueKey: "inputTypeValue",
        })
      );
    }
  };
  useEffect(() => {
    let selectedService = serviceAreaDetail?.exsiting_service_areas?.length>0 && serviceAreaDetail?.exsiting_service_areas[0];
    if(selectedService){
    if(id == "AA" || id == "EA"){
      dispatch(
        getServiceAreaDetailStarted({
          service_id: selectedService?.service_area,
          service_order_type: id,
          contact_ids: serviceAreaDetail?.exsiting_team_leaders?.length > 0 ? serviceAreaDetail?.exsiting_team_leaders[0] : null,
          location_id: application?.data["02_FIRMA"][1]?.inputTypeValue,
          detailType: "prefiledArea",
        })
      );
    } else {
      dispatch(
        getServiceAreaDetailStarted({
          service_id: selectedService?.service_area,
          service_order_type: id,
          contact_ids: application?.data["02_FIRMA"]?.find(
            (group) => group?.id === "02_FIRMA_TECHNICAL_MANAGER"
          )?.inputTypeValue,
          location_id: application?.data["02_FIRMA"][1]?.inputTypeValue,
          detailType: "prefiledArea",
        })
      );
    }}
  }, []);

  useEffect(() => {
    if(dform?.service_area_detail?.data?.detailType == "prefiledArea"){
        dispatchPreFillService()
        dispatch(resetServiceAreaDetail());
    }
  }, [dform?.service_area_detail]);

  return <></>;
};

export default PrefiledArea;
