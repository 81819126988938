import React, { useEffect, useState } from "react";
import DynamicFormSteps from "../DynamicFormSteps";
import DynamicStepper from "src/components/organisms/dform/DynamicStepper";
import CircularLoading from "src/components/atoms/loader/CircularLoading";
import { getApplicationStartedByID, setApplicationSidebar } from "src/store/actions/application";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Cookies from "js-cookie";

const DynamicFormWrap = ({ id, fromEditScreen, reRender }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeStep, setActiveStep] = useState(null);
  const [steps, setSteps] = useState(null);
  const percentage = JSON?.parse(localStorage?.getItem("percentageStep"));
  const currentStepParam = searchParams.get("active");
  const dispatch = useDispatch();
  const { dform, application } = useSelector(
    (state) => ({
      dform: state.dform,
      application: state.application,
    }),
    (prev, next) => {
      return prev.dform === next.dform && prev.application === next.application;
    }
  );

  useEffect(() => {
    if (!application?.sidebar) {
      dispatch(setApplicationSidebar());
    }
  }, []);

  useEffect(() => {
    if (fromEditScreen == true) {
      setActiveStep(parseInt(currentStepParam));
    }
  }, [reRender]);

  useEffect(() => {
    if (dform?.data?.length > 0 && activeStep === null) {
      if (currentStepParam) {
        setActiveStep(parseInt(currentStepParam));
      } else {
        setActiveStep(0);
      }
      let stepList = dform.data.map((d) => d.title);
      setSteps(stepList);
    }
  }, [dform]);

  useEffect(() => {
    if (activeStep !== null) {
      let filterId = dform?.data[activeStep]?.id;
      /** getting procedure/get-meta-data api */
      let payload = {
        step_id: filterId,
        service_order_type: id,
        procedure_form_id: Cookies.get("submitted_id") ? Cookies.get("submitted_id") : null,
      };
      dispatch(getApplicationStartedByID(payload));
    }
  }, [activeStep]);

  /** step change and dform, once data store in erp */
  useEffect(() => {
    if (application?.createdApplication?.status == "completed" && steps?.length - 1 !== activeStep) {
      stepChangeHandle(activeStep + 1);
    }
  }, [application?.createdApplication?.status]);

  const stepChangeHandle = (step) => {
    if (application?.sendEmail?.isLoading || application?.createdApplication?.isLoading || application?.addingStep?.isLoading) {
      return;
    }
    setSearchParams(
      {
        ...Object.fromEntries(searchParams),
        active: step,
      },
      { replace: true }
    );
    setActiveStep(step);
    let percentage = JSON.parse(localStorage.getItem("percentageStep"));
    if (step > percentage) {
      localStorage.setItem("percentageStep", JSON.stringify(step));
    }
  };

  return (
    <>
      {activeStep !== null && (
        <>
          <DynamicStepper
            stepChangeHandle={(index) => {
              if (
                index <= percentage &&
                application?.dformStatus?.data?.data?.status !== "completed" &&
                application?.dformStatus?.data?.data?.status !== "signatures-completed" &&
                application?.createdApplication?.isLoading !== true
              ) {
                stepChangeHandle(index);
              }
            }}
            steps={steps}
            activeStep={activeStep}
          />
          {application?.isLoading ? (
            <CircularLoading css="min-h-[40vh]" />
          ) : (
            application?.data !== null && <DynamicFormSteps id={id} activeStep={activeStep} stepChangeHandle={stepChangeHandle} step_id={dform?.data[activeStep]?.id} />
          )}
        </>
      )}
    </>
  );
};

export default DynamicFormWrap;
