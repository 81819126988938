import Signin from "src/components/pages/Signin";
import Signup from "src/components/pages/Signup";
import ForgotPassword from "src/components/pages/ForgotPassword";
import ResetPassword from "src/components/pages/ResetPassword";
import Home from "src/components/pages/Home";
import NotFound from "src/components/pages/NotFound";
import Settings from "src/components/pages/settings";
import Help from "src/components/pages/Help";
import BusinessPremises from "src/components/pages/BusinessPremises";
import EditBusinessPremises from "src/components/pages/BusinessPremises/EditBusinessPremises";
import DocumentNavigator from "src/components/pages/document/DocumentNavigator";
import Inbox from "src/components/pages/Inbox";
import ApplicationNavigator from "src/components/pages/procedure/ApplicationNavigator";
import DynamicForm from "src/components/pages/dform/DynamicForm";
import Evidence from "src/components/pages/Evidence";
import DynamicFormVerify from "src/components/pages/dform/DynamicFormVerify";
import Signature from "src/components/pages/Signature";
import EvidenceWelcome from "src/components/pages/Evidence/EvidenceWelcome";
import ProcedureCMS from "src/components/pages/ProcedureCMS";
import EvidencePending from "src/components/pages/Evidence/EvidencePending";
import EvidenceCMS from "src/components/pages/EvidenceCMS";
import UserAnalytics from "src/components/pages/UserAnalytics";
import SignupVerification from "src/components/pages/Signup/SignupVerification";
import InvitedSignup from "src/components/pages/Signup/InvitedSignup";
import Rechtliches from "src/components/pages/Rechtliches";

export const authRoutes = [
  {
    id: "login",
    path: "/login",
    component: <Signin />,
  },
  {
    id: "forgot-password",
    path: "/passwort-vergessen",
    component: <ForgotPassword />,
  },
  {
    id: "reset_password",
    path: "/passwort-zuruecksetzen/:hash",
    component: <ResetPassword />,
  },
  {
    id: "register",
    path: "/registrieren/:id",
    component: <InvitedSignup />,
  },
  {
    id: "verifizieren",
    path: "/verifizieren/:id",
    component: <SignupVerification />,
  },
  {
    id: "register",
    path: "/registrieren",
    component: <Signup />,
  },
  {
    id: "asterisk",
    path: "*",
    component: <NotFound />,
    protectedRoute: true,
  },
];

const routes = [
  {
    id: "dashboard",
    path: "/",
    component: <Home />,
    protectedRoute: true,
  },
  {
    id: "settings",
    path: "/einstellungen/:id",
    component: <Settings />,
    protectedRoute: true,
  },
  {
    id: "help",
    path: "/hilfe",
    component: <Help />,
    protectedRoute: true,
  },
  {
    id: "procedurecms",
    path: "/verfahren/verfahren-cms",
    component: <ProcedureCMS />,
    protectedRoute: true,
  },
  {
    id: "procedurecms",
    path: "/verfahren/nachweise-hochladen-cms",
    component: <EvidenceCMS />,
    protectedRoute: true,
  },
  {
    id: "businesspremises",
    path: "/betriebsstaetten",
    component: <BusinessPremises />,
    protectedRoute: true,
  },
  {
    id: "businesspremises-edit",
    path: "/betriebsstaetten/:id",
    component: <EditBusinessPremises />,
    protectedRoute: true,
  },

  {
    id: "procedures",
    path: "/verfahren/:id",
    component: <ApplicationNavigator />,
    protectedRoute: true,
  },
  {
    id: "procedures",
    path: "/verfahren/antrag/:id",
    component: <DynamicForm />,
    protectedRoute: true,
  },
  {
    id: "procedures",
    path: "/verfahren/antrag-bearbeiten/:id",
    component: <DynamicForm fromEditScreen={true} />,
    protectedRoute: true,
  },
  {
    id: "procedures",
    path: "/verfahren/antrag-eingereicht/:id/:procedure_id",
    component: <DynamicFormVerify />,
    protectedRoute: true,
  },
  {
    id: "document",
    path: "/dokumente/:id",
    component: <DocumentNavigator />,
    protectedRoute: true,
  },
  {
    id: "inbox",
    path: "/posteingang",
    component: <Inbox />,
    protectedRoute: true,
  },

  {
    id: "evidence",
    path: "/nachweise-hochladen/:service/:id",
    component: <Evidence />,
    protectedRoute: true,
  },
  {
    id: "evidence",
    path: "/nachweise-hochladen-ausstehend/:service/:id",
    component: <EvidencePending />,
    protectedRoute: true,
  },
  {
    id: "evidence",
    path: "/evidence-welcome/:service/:id",
    component: <EvidenceWelcome />,
    protectedRoute: true,
  },
  {
    id: "signature",
    path: "/unterschreiben/:id",
    component: <Signature />,
    protectedRoute: true,
  },
  {
    id: "user-analytics",
    path: "/benutzer-analyse",
    component: <UserAnalytics />,
    protectedRoute: true,
  },
  {
    id: "user-analytics",
    path: "/rechtliches",
    component: <Rechtliches />,
    protectedRoute: true,
  },
];

export default routes;
