import React, { Fragment, useEffect, useState } from "react";
import Line1 from "src/assets/img/Line1.png";
import { BsPlusLg } from "react-icons/bs";
import ImageHtmlFinished from "src/components/pages/ImageHtml/ImageHtmlFinished";
import { PrimaryButton } from "src/components/atoms/button";
import { FaCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { applicationProcessStatusStarted, newServiceOrderCostStarted, sendSignatureRequestEmailStarted } from "src/store/actions/application";
import FilterField from "src/components/molecules/dform/FilterField";
import { businessPremisesContacts } from "src/store/actions/business";
import Cookies from "js-cookie";
import { sendSignaturePayloadModify, ServiceOrderPayloadModify, setShowDifferntNameModify } from "src/helpers/dform";
import toast from "react-hot-toast";

function ApplicationVerifyFinished({ data, step_id, createServiceOrderSubmit, procedure_id }) {
  const [showName, setShowName] = useState([]);
  const [showDifferntName, setShowDifferntName] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const currentStep = useSelector((state) => state.currentStep);
  const application = useSelector((state) => state.application);
  const business = useSelector((state) => state?.management);
  const cmsDashboarddata = useSelector((state) => state.cmsDocument);
  const stepsCMS = cmsDashboarddata?.dFormCMS?.data;

  useEffect(() => {
    const savedData = Object?.values(application?.data).filter((item, index) => {
      return index == 1;
    });
    const result = savedData[0].filter((data) => {
      return data.inputType === "dropdownTag";
    });
    const hello = business?.contactList?.data?.filter((contact) => {
      return result[0]?.inputTypeValue?.includes(contact?.contact_id);
    });
    hello && setShowName([...hello]);
  }, [business?.contactList?.data]);

  useEffect(() => {
    dispatch(businessPremisesContacts());
  }, []);

  useEffect(()=>{
    if(application?.orderCosts.data == null){
      if(data && currentStep.stepID && currentStep.data){
        dispatch(
          newServiceOrderCostStarted(ServiceOrderPayloadModify({
            "procedure_data": [{...application.data, [currentStep.stepID]: currentStep.data}],
            "step_id": step_id,
            "service_order_types_id": id,
            "procedure_form_id": procedure_id,
            "user_id": Cookies.get("userId")
          }))
        );
      }
    }
  },[data])

  /** get the current dynamic application status - get-procedure-details */

  useEffect(()=>{
    dispatch(applicationProcessStatusStarted({procedure_id:procedure_id}))
  },[])

  const sendSignatureRequestEmailHandle = () => {
    if(currentStep?.data[0]?.inputTypeValue){
    dispatch(
      sendSignatureRequestEmailStarted(sendSignaturePayloadModify({
        "procedure_data": [{...application.data, [currentStep.stepID]: currentStep.data}],
        "service_order_types_id": id,
        "procedure_form_id": procedure_id,
        "user_id": Cookies.get("userId"),
        "inhaber_id": currentStep?.data[0]?.inputTypeValue?.toString()
      }))
    );
  } else {
    toast.error("Bitte wählen Sie den Inhaber aus");
  }
  }

  useEffect(() => {
    const inhaber = currentStep?.data.find((step) => step?.inputType === "signaturedropdown");
    setShowDifferntName(
      setShowDifferntNameModify({
        procedure_data: [{ ...application.data, [currentStep?.stepID]: currentStep?.data }],
        service_order_types_id: id,
        inhaber_id: inhaber?.inputTypeValue?.toString(),
        contactSignature: application?.contactSignature?.data,
      })
    );
  }, [application?.contactSignature]);

  return (
    <div className="">
      <h1 className="!mb-5 text-xl font-tahoma-bold m-3 sm:m-4 md:m-6 text-black dark:text-BrightGray">{stepsCMS?.['05_ALMOST_THERE']?.title}</h1>
      <div className="mx-3 sm:mx-4 md:mx-6 mt-0 p-0">
        <div className="justify-start gap-12 flex flex-col md:flex-row">
          <p className="textStyle max-w-[540px] mb-5 pt-5">{stepsCMS?.['05_ALMOST_THERE']?.description}</p>
        </div>
        {data?.map((fdata, i) => {
          if (fdata?.inputType === "shorten-final") {
            return (
              <Fragment key={i}>
                <FilterField data={fdata} />
              </Fragment>
            );
          }
        })}
        <div>
        <div className="w-full md:w-[841px]">{Object?.values(application?.data) && <ImageHtmlFinished data={Object?.values(application?.data)} />}</div>
          <div className="pt-10">
            <h1 className="text-black text-lg font-tahoma-bold dark:text-BrightGray">Unterschrift Inhaber</h1>

            <div className="pt-5 md:pt-0 flex flex-col md:flex-row gap-8">
              <div className="flex flex-col gap-8 w-full md:w-[841px]">
                {data?.map((fdata, i) => {
                  if (fdata?.inputType === "service-order-cost") {
                    return null;
                  } else {
                    return (
                      <Fragment key={i}>
                        <FilterField data={fdata} />
                      </Fragment>
                    );
                  }
                })}

                <div className="pt-10">
                  {showName.length > 0 &&
                    showName.map((item) => {
                      let signature = application?.dformStatus?.data?.data?.SignatureList?.length > 0 && application?.dformStatus?.data?.data?.SignatureList?.find((signature)=> signature?.contact_id == item.contact_id)
                      return (
                        <div className="mt-5">
                          <h1 className="text-black text-lg font-tahoma-bold mb-5 dark:text-BrightGray">Unterschrift Fachlicher Leiter - {item.first_name + " " + item.last_name}</h1>
                          <div className="border border-AFPLightGray w-full md:w-[841px] h-[259px] flex flex-col justify-center items-center">
                            <div className="flex items-center justify-center w-10 h-10 sm:w-10 sm:h-10 rounded-full">
                              <BsPlusLg className="!text-white h-10 w-10 sm:h-10 sm:w-10" />
                            </div>
                            {signature ? <img src={signature.signature_image || Line1} alt="Line" /> :<><p className="text-black textStyle max-w-[540px] dark:text-BrightGray">Klicken um zu unterschreiben</p> <img src={Line1} alt="Line" /></>}
                          </div>
                        </div>
                      );
                    })}

                  
                  <div className="justify-center flex flex-col items-center gap-8 pt-12">
                    {application?.dformStatus?.data?.data?.status === "ongoing" || application?.dformStatus?.data?.data?.status === "signatures-requested" ? (
                    (application?.dformStatus?.data?.data?.status !== "signatures-requested" && application?.dformStatus?.data?.data?.status !== "signatures-completed" && application?.dformStatus?.data?.data?.status !== "completed") && (!currentStep?.data[0].inputTypeValue ? (
                      <SecondaryButton
                        variant="disabled"
                        title={
                          showDifferntName
                            ? cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.check_signatures
                            : cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.request_signature
                        }
                        css="w-full md:max-w-[420px] !px-8 !py-3 !text-xl"
                      />
                    ) : (
                      <BackdropButton
                        title={
                          showDifferntName
                            ? cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.check_signatures
                            : cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.request_signature
                        }
                        btnStyle="w-full md:max-w-[420px] !px-8 !py-3 !text-xl"
                        icon={<FaCheck />}
                        loading={application?.sendEmail?.isLoading}
                        position="after"
                        handler={sendSignatureRequestEmailHandle}
                      />
                    ))
                    ): 
                    application?.dformStatus?.data?.data?.status === "signatures-completed" ?  <PrimaryButton
                        title={cmsDashboarddata?.dynamic_form_buttons?.Verfahren_Formular_Buttons?.submit_for_a_fee}
                        btnStyle="w-full md:max-w-[420px] !px-8 !py-3 !text-xl"
                        icon={<FaCheck />}
                        loading={application?.createdApplication?.isLoading}
                        position="after"
                        handler={createServiceOrderSubmit}
                      /> : null
                  }
                  </div>

                  {data?.map((fdata, i) => {
                    if (fdata?.inputType === "service-order-cost") {
                      return (
                        <Fragment key={i}>
                          <FilterField data={fdata} />
                        </Fragment>
                      );
                    } else {
                      return null;
                    }
                  })}

                  {(application?.dformStatus?.data?.data?.status !== "signatures-completed" &&  application?.dformStatus?.data?.data?.status !== "completed") &&<h4 className="text-red-400 text-center mt-8 text-md">Unterschriften fehlen</h4> }

                  <p className="h-32"></p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicationVerifyFinished;
